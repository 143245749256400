import { Alert, Modal, Select } from 'antd'
import { useEffect, useState } from 'react'

import { Button, Heading, IconInfo } from 'components/common'

interface ExcludeUsersModalProps {
  isVisable: boolean
  handleCancel: () => void
  handleConfirm: (userIdsToExclude: string[] | undefined) => void
  isLoading?: boolean
  excludedUserIds: string[]
}

export const ExcludeUsersModal = ({
  isVisable,
  handleCancel,
  handleConfirm,
  isLoading,
  excludedUserIds,
}: ExcludeUsersModalProps) => {
  const [userIdsToExclude, setUserIdsToExclude] = useState<string[] | undefined>(undefined)

  useEffect(() => {
    setUserIdsToExclude(excludedUserIds)
  }, [excludedUserIds])

  const handleChange = (value: string[]) => {
    setUserIdsToExclude(value.map((id) => id.trim()))
  }

  return (
    <Modal
      title={'Exclude Users'}
      open={isVisable}
      onCancel={handleCancel}
      centered
      className='exclude-users-modal'
      footer={[
        <Button key='cancel' text='Cancel' type='secondary' testId='cancel-btn' onClickHandler={handleCancel} />,
        <Button
          key='submit'
          text='Confirm'
          testId='confirm-btn'
          onClickHandler={() => handleConfirm(userIdsToExclude)}
          loading={isLoading}
        />,
      ]}>
      <Alert
        className='alert exclude-users-modal__info-alert'
        message='Enter the Universal User ID to exclude a user from this motion'
        type='info'
        showIcon
        icon={<IconInfo />}
      />

      <div className='exclude-users-modal__header'>
        <Heading level='1' variant='6'>
          User IDs to Exclude
        </Heading>

        <p>
          If this motion is already running, excluded participants will be removed immediately and from any future runs
          going forward.
        </p>
      </div>

      <div className='exclude-users-modal__id-select'>
        <Select
          mode='tags'
          style={{ width: '100%' }}
          listHeight={200}
          onChange={handleChange}
          tokenSeparators={[',']}
          placeholder='Enter participants via User IDs, separated by commas...'
          notFoundContent={null}
          dropdownStyle={{ display: 'none' }}
          suffixIcon={null}
          value={userIdsToExclude}
        />
      </div>

      <div className='exclude-users-modal__body'>
        <p className='exclude-users-modal__body__heading'>
          <span>Note:</span> Excluded participants cannot be added back to a running motion.
        </p>

        <p className='exclude-users-modal__body__text'>
          Not sure how to find the right User IDs? Learn more about best practices{' '}
          <span>
            <a
              href={
                'https://magnifyio.zendesk.com/hc/en-us/articles/16923229335451-Frequently-Asked-Questions-FAQs#01J4244FAATSNWHRCZYMGVD6Y5'
              }
              target='_blank'
              rel='noreferrer'>
              here
            </a>
          </span>
          .
        </p>
      </div>
    </Modal>
  )
}
ExcludeUsersModal.displayName = 'ExcludeUsersModal'
