import { Alert, Dropdown, Form, Input, message, Modal, Row, Select, Spin, Table } from 'antd'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useEffectOnce } from 'react-use'

import EllipsisVertical from 'assets/images/ellipsis-vertical.svg?react'
import { Button, Heading } from 'components/common'
import { useDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import { Patterns } from 'services/Utils/Patterns'
import { RoleEnum, Roles } from 'services/Utils/Role'
import { getRoleName } from 'services/Utils/users.service'
import useStore from 'store/useStore'

import type { MenuProps } from 'antd'

import type { User, UserPost } from 'models/users.model'

export const Users = observer(() => {
  const { userStore } = useStore()
  const { isLoading, getAll, role, isSsoUser } = userStore
  const { Option } = Select
  const [form] = Form.useForm<UserPost>()
  const [isAddUsersModalVisible, setIsAddUsersModalVisible] = useState(false)
  const [isDeleteUsersModalVisible, setIsDeleteUsersModalVisible] = useState(false)
  const [userToDelete, setUserToDelete] = useState<string | null>('')
  const [error, setError] = useState<string>('')

  useEffectOnce(() => {
    getAll().catch(console.error)
  })

  useDisplayErrorNotification(userStore)

  const users = userStore.users.map((user) => {
    return {
      key: user.username,
      username: user.username,
      email: user.email,
      role: getRoleName(user.roleId, Roles),
      status:
        user.status === 'FORCE_CHANGE_PASSWORD' ? `Invite sent ${dayjs(user.created_at).format('MM/DD')}` : user.status,
    }
  })

  const deleteUser = async (username: string | null) => {
    if (username) {
      await userStore.remove(username)
      handleDeleteUserCancel()
      message.success('User deleted successfully', 4)
    }
  }

  const deleteMenuItems = (user: User): MenuProps['items'] => {
    return [
      {
        key: '1',
        label: <p>Delete user</p>,
        onClick: () => {
          showDeleteModal()
          setUserToDelete(user.username)
        },
      },
    ]
  }

  // Show all roles if the current user is a super admin, otherwise show only user and admin
  let roles = [{ id: RoleEnum.User, role: 'USER' }]
  if (role === RoleEnum.SuperAdmin) {
    roles = [
      { id: RoleEnum.User, role: 'USER' },
      { id: RoleEnum.Admin, role: 'ADMIN' },
      { id: RoleEnum.SuperAdmin, role: 'SUPER_ADMIN' },
    ]
  } else if (role === RoleEnum.Admin) {
    roles = [
      { id: RoleEnum.User, role: 'USER' },
      { id: RoleEnum.Admin, role: 'ADMIN' },
    ]
  }

  const rolesOptions = roles.map((role) => (
    <Option key={role.id} value={role.id}>
      {role.role}
    </Option>
  ))

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a: User, b: User) => {
        return a?.email?.localeCompare(b?.email ?? '') ?? 0
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      render: (item: string, record: User) => {
        // item is one of: ADMIN, SUPER ADMIN, USER
        return (
          <Select
            defaultValue={item}
            style={{ width: '100%' }}
            disabled={record.role === 'SUPER ADMIN'}
            onChange={(selectValue: string) => {
              handleUpdateRole(record, selectValue as RoleEnum).catch(console.error)
            }}>
            {rolesOptions}
          </Select>
        )
      },
      sorter: (a: User, b: User) => {
        return a?.role?.localeCompare(b?.role ?? '') ?? 0
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a: User, b: User) => {
        return a?.status?.localeCompare(b?.status ?? '') ?? 0
      },
    },
    {
      title: '',
      dataIndex: '',
      render: (user: User) => {
        if (role === RoleEnum.SuperAdmin || role === RoleEnum.Admin) {
          return (
            <Dropdown className='delete-user-dropdown' menu={{ items: deleteMenuItems(user) }} placement='bottomLeft'>
              <div className='delete-user-dropdown__trigger'>
                <EllipsisVertical />
              </div>
            </Dropdown>
          )
        }
        return null
      },
    },
  ]

  const addNewUser = async () => {
    try {
      const values = await form.validateFields()
      const { email, roleId }: UserPost = values
      if (email && roleId) {
        const data = {
          email,
          roleId,
        }

        await userStore.post(data)
        if (userStore.apiError) {
          setError(userStore.apiError.message)
        } else {
          handleAddUsersCancel()
          message.success('User added successfully', 4)
        }
      }
    } catch (error: unknown) {
      if (typeof error === 'object' && error !== null && 'errorFields' in error) {
        setError('The form is invalid!')
      } else if (error instanceof Error) {
        message.error(error.message, 4)
        setError(error.message)
      }
    }
  }

  const showAddModal = () => {
    setIsAddUsersModalVisible(true)
  }

  const handleAddUsersCancel = () => {
    form.resetFields()
    setIsAddUsersModalVisible(false)
    setError('')
  }

  const showDeleteModal = () => {
    setIsDeleteUsersModalVisible(true)
  }
  const handleDeleteUserCancel = () => {
    setIsDeleteUsersModalVisible(false)
  }

  const handleUpdateRole = async (record: User, newRole: RoleEnum) => {
    try {
      await userStore.update({ username: record.username, roleId: newRole })
      message.success('User updated successfully!', 4)
    } catch (error: unknown) {
      message.error('User update failed!', 4)
    }
  }

  return (
    <div className='container'>
      <div className='content'>
        <Row align='middle' className='m-b-20'>
          <Heading level='3' variant='1'>
            Manage Users
          </Heading>
          <div className='c-button--right'>
            <Button
              key='submit'
              text='+ Add new user'
              htmlType='submit'
              onClickHandler={showAddModal}
              disabled={isSsoUser}
              tooltipText='SSO is enabled for this tenant. Please contact support to add users.'
            />
          </div>
        </Row>

        <Spin size='large' spinning={isLoading}></Spin>

        {/* Add users modal */}
        <Modal
          title='Add new user'
          centered
          open={isAddUsersModalVisible}
          onCancel={handleAddUsersCancel}
          footer={[
            <Button
              key='cancel'
              text='Cancel'
              htmlType='button'
              type='secondary'
              onClickHandler={handleAddUsersCancel}
            />,
            <Button key='submit' text='Save' htmlType='submit' onClickHandler={addNewUser} />,
          ]}>
          <Form data-testid='add-new-user-modal' form={form} name='add_user_form' autoComplete='off'>
            <Form.Item
              label='Email'
              name='email'
              colon={false}
              labelCol={{ xs: { span: 6 }, md: { offset: 4 } }}
              wrapperCol={{ md: 12 }}
              rules={[
                {
                  required: true,
                  message: 'Please input the email!',
                  pattern: new RegExp(Patterns.email),
                },
              ]}>
              <Input type='email' autoComplete='off' />
            </Form.Item>

            <Form.Item
              label='Role'
              name='roleId'
              colon={false}
              labelCol={{ xs: { span: 6 }, md: { offset: 4 } }}
              wrapperCol={{ md: 12 }}
              rules={[
                {
                  required: true,
                  message: 'Please input the role!',
                },
              ]}>
              <Select style={{ width: '100%' }} placeholder='Please input the role'>
                {rolesOptions}
              </Select>
            </Form.Item>

            {error && <Alert message={error} type='error' />}
          </Form>
        </Modal>
        {/* END Add users modal */}

        {/* Remove user modal */}
        <Modal
          title='Delete user'
          centered
          open={isDeleteUsersModalVisible}
          onCancel={handleDeleteUserCancel}
          footer={[
            <Button
              key='cancel'
              text='Cancel'
              htmlType='button'
              type='secondary'
              onClickHandler={handleDeleteUserCancel}
            />,
            <Button
              key='submit'
              type='danger'
              text='Delete'
              htmlType='submit'
              onClickHandler={() => {
                deleteUser(userToDelete).catch(console.error)
              }}
            />,
          ]}>
          <p>Are you sure you want to delete this user? This action cannot be undone.</p>
        </Modal>
        {/* End Remove user modal */}

        <Table
          data-testid='users-table'
          columns={columns}
          dataSource={users}
          pagination={false}
          sortDirections={['ascend', 'descend', 'ascend']}
          scroll={{ x: 360 }}></Table>
      </div>
    </div>
  )
})
Users.displayName = 'Users'
