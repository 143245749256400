import { Badge, Popover } from 'antd'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'

import NotificationBell from 'assets/icons/sideMenu/notification-bell.svg?react'
import { Button, Heading } from 'components/common'
import { NOTIFICATIONS_PER_PAGE } from 'configs'
import useStore from 'store/useStore'

export const NotificationsPopUp = observer(() => {
  const { notificationStore } = useStore()
  const { unSeenNotifications, markSeenNotifications } = notificationStore

  const content = (
    <>
      <div className='notifications-popup__heading'>
        <Heading level='3' variant='2'>
          Notifications
        </Heading>
        <Link to={'/account-settings/notifications'}>
          <Button
            testId='notifications-popup-view-all'
            text='View all'
            link
            onClickHandler={() => {
              changeVisibilityHandler(false)
            }}
          />
        </Link>
      </div>

      {unSeenNotifications.length ? (
        unSeenNotifications.map((notification) => (
          <div
            key={notification.id}
            className={`notification-card notification-card--${
              notification.type === 's3Download' ? 'success' : notification.priority
            }`}>
            <div className='notification-text'>
              <span>{notification.message}</span>
              {notification?.info?.app && (
                <Link to={`/integrations/${notification.info.app}/edit/${notification.info.connectionId}`}>
                  Adress the issue
                </Link>
              )}
            </div>

            <p className='notification-time'>
              {dayjs(notification.createdAt).format('MMM DD, YYYY')} at {dayjs(notification.createdAt).format('h:mm A')}
            </p>
          </div>
        ))
      ) : (
        <div className='notifications-popup-container'>No new notifications</div>
      )}
    </>
  )
  const changeVisibilityHandler = (visible: boolean) => {
    if (!visible && unSeenNotifications.length) {
      markSeenNotifications().catch(console.error)
    }
  }

  return (
    <Popover
      content={content}
      trigger='click'
      overlayClassName='notifications-popup'
      onOpenChange={changeVisibilityHandler}
      data-testid='notifications-popup'>
      <Badge
        count={
          unSeenNotifications.length > NOTIFICATIONS_PER_PAGE - 1
            ? `${NOTIFICATIONS_PER_PAGE - 1}+`
            : unSeenNotifications.length
        }
        offset={[-6, 4]}
        size='small'
        className='notifications-popup-badge'
        data-testid='notifications-popup-badge'>
        <NotificationBell data-testid='notifications-popup-bell' />
      </Badge>
    </Popover>
  )
})
