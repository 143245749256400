import { Modal } from 'antd'

import TemplateList from 'components/EmailTemplates/components/TemplateList'

import type { Dayjs } from 'dayjs'

interface EmailTemplateModalProps {
  open: boolean
  onCancel: () => void
  onConfirm: () => void
  selectedTemplate: string
  changeFieldValue: (
    value: boolean | number | string | string[] | Dayjs | Dayjs[] | null,
    fieldKey: string | undefined,
    index?: number,
  ) => void
  isInActionFlow: boolean
  initializeEdit?: { isEdit: boolean; templateId: string | null; templateVersion: number | null }
}

const EmailTemplateModal = ({
  open,
  onCancel,
  onConfirm,
  changeFieldValue,
  isInActionFlow,
  initializeEdit = { isEdit: false, templateId: null, templateVersion: null },
}: EmailTemplateModalProps) => {
  return (
    <Modal
      key='modal__email-template'
      title='Choose Email'
      open={open}
      onOk={onConfirm}
      onCancel={onCancel}
      closable={false}
      centered
      destroyOnClose
      className='modal__email-template'
      footer={null}>
      <TemplateList
        isInActionFlow={isInActionFlow}
        closeTemplateListModal={onCancel}
        changeActionConfigPanelFieldValue={changeFieldValue}
        initializeEdit={initializeEdit}
      />
    </Modal>
  )
}

export default EmailTemplateModal
