import { CloseOutlined } from '@ant-design/icons'
import { Button as CloseButton, Empty, Tag } from 'antd'
import { observer } from 'mobx-react-lite'

import { Button, IconExperiment } from 'components/common'
import {
  generateMotionTag,
  generateRunSchedule,
  generateTitleAndDescription,
} from 'components/MotionDetails/Header/index.utils'
import { DateService } from 'services/Utils/date'
import useStore from 'store/useStore'

import { MotionStateEnum } from 'models/motion.model'

interface MotionDetailsHeaderProps {
  onClickClose: () => void
  onClickViewMotion: () => void
  isExperiment?: boolean
}

const MotionDetailsHeader = observer(({ onClickClose, onClickViewMotion, isExperiment }: MotionDetailsHeaderProps) => {
  const { reportingStore, motionStore } = useStore()
  const { currentMotion } = motionStore
  const {
    data: { motion },
    isMotionEmpty,
  } = reportingStore
  const isMotionExecutingOrCompleted =
    motion?.currState === MotionStateEnum.Executing || motion?.currState === MotionStateEnum.Completed

  const handleViewMotionClick = () => {
    motionStore.setIsInMotionReportingEnabled(isMotionExecutingOrCompleted)
    onClickViewMotion()
  }

  return (
    <header className='motion-details-header content__header'>
      <section className='motion-details-header-top'>
        <div className='motion-details-header-top-left'>
          {!isMotionEmpty && (
            <>
              <h3
                data-testid='heading'
                title={currentMotion?.title !== motion?.title ? `Original Title: ${motion?.title}` : undefined}>
                {currentMotion?.title ?? motion?.title}
              </h3>
              {generateMotionTag(motion)}
              {isExperiment && (
                <Tag className='tag tag--blue'>
                  <IconExperiment /> <span>With Experiment</span>
                </Tag>
              )}
            </>
          )}
        </div>
        <div className='motion-details-header-top-right'>
          <Button
            text={`View Motion${isMotionExecutingOrCompleted ? ' State' : ''}`}
            testId='motion-details-view-motion'
            onClickHandler={handleViewMotionClick}
          />
          <CloseButton shape='circle' onClick={onClickClose} data-testid='close-details'>
            <CloseOutlined />
          </CloseButton>
        </div>
      </section>
      <section className='motion-details-header-bottom'>
        {isMotionEmpty ? (
          <Empty />
        ) : (
          <>
            {generateTitleAndDescription(
              'Started on',
              motion?.stateHistory?.firstExecution
                ? DateService.parseDateTimestamp(motion.stateHistory.firstExecution, { showTime: true })
                : 'N/A',
            )}
            {generateTitleAndDescription('Run Schedule', generateRunSchedule(motion))}
            {generateTitleAndDescription('Created by', motion?.createdBy ?? 'N/A')}
          </>
        )}
      </section>
    </header>
  )
})
MotionDetailsHeader.displayName = 'MotionDetailsHeader'

export default MotionDetailsHeader
