import { Tooltip } from 'antd'
import classNames from 'classnames'

import type { CSSProperties } from 'react'

interface ButtonProps {
  text: string
  type?: 'primary' | 'secondary' | 'danger' | 'secondary-danger'
  size?: 'XL' | 'L' | 'M' | 'full-width'
  icon?: {
    element: JSX.Element
    position?: 'left' | 'right'
  }
  link?: boolean
  testId?: string
  htmlType?: 'button' | 'submit' | 'reset'
  className?: string
  style?: CSSProperties
  rounded?: boolean
  disabled?: boolean
  loading?: boolean
  tooltipText?: string
  form?: string
  onClickHandler?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onMouseEnterHandler?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onMouseLeaveHandler?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const Button = ({
  text,
  type = 'primary',
  size = 'XL',
  icon,
  link,
  testId = '',
  className = '',
  style,
  rounded = false,
  htmlType = 'button',
  disabled,
  loading = false,
  tooltipText = '',
  form,
  onClickHandler,
  onMouseEnterHandler,
  onMouseLeaveHandler,
}: ButtonProps) => {
  const BaseButton = (
    <button
      data-testid={`button-${type} ${testId}`}
      className={classNames(`button ${className} button-${type} button-${size}`, {
        'button-link': link,
        'button-disabled': disabled,
        'button-rounded': rounded,
      })}
      type={htmlType}
      form={form}
      style={style}
      disabled={disabled}
      onClick={onClickHandler}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}>
      {loading && <div data-testid='button-loading' className={`button-${type}--loading m-r-5`}></div>}
      <div>
        {icon?.element && (
          <span className={`icon-${icon?.position ?? 'left'}`} data-testid='btn-icon'>
            {icon?.element}
          </span>
        )}{' '}
        {text}
      </div>
    </button>
  )

  if (tooltipText) {
    return (
      <Tooltip title={tooltipText} placement='top'>
        <span>{BaseButton}</span>
      </Tooltip>
    )
  }

  return BaseButton
}

Button.displayName = 'Button'

export default Button
