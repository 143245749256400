import type { DefaultOptionType } from 'antd/lib/select'
import type { Dayjs } from 'dayjs'
import type { Dispatch, SetStateAction } from 'react'
import type { Elements, Node } from 'react-flow-renderer'

import type { MetadataField } from 'models/motion/dynamicInput.model'
import type { NodeTypeEnum, RelationOperator, SegmentExport } from 'models/motion.model'
import type { CreateSlackMessagePayload, DynamicInputs } from 'models/slack-messages'

export enum ShapeTypeEnum {
  Circle = 'circle',
  Rhomb = 'rhomb',
  Square = 'square',
}
export type unitsValue = 'minutes' | 'hours' | 'days' | 'weeks'
export type payloadStructureError = 'none' | 'empty' | 'invalid'
export type DragOverGroupType = number | string
export type ValueType = KeyValueField | MetadataField | Array<string | number> | string | number | boolean
export interface Action {
  name: string
  type: string
  shape: ShapeTypeEnum
  platform?: string
  iconName?: string
  payload?: CreateActionsPayload | Record<string, any>
  description?: string
  object?: string
  actionId?: string
  actionType?: string
  action?: string
  actionLevel?: string
  actionName?: string
  fakeAction?: boolean
  solutionInstanceId?: string
  // For ReactFlow DSL
  data?: Record<string, any>
}
export interface Platform {
  name: string
  type: string
  actions: Action[]
}
export interface ToolboxItems {
  rules: Action[]
  actions: Platform[]
  inactiveActions: Platform[]
}

export interface BuilderAction extends Action {
  data: Record<string, string>
  type: NodeTypeEnum
}

export interface DroppedAction {
  data: BuilderAction
  edgeToDrop: EdgeToDrop
}

export interface EdgeToDrop {
  id: string
  source: string
  target: string
}

export interface SegmentBuilderData {
  action?: ActionEnum
  actionId: string
  actionType: ActionTypeEnum
  description: string
  excludedUserIds?: string[]
  fakeAction?: boolean
  iconName: string
  id: string
  isHiddenSegment?: boolean
  isInitial: boolean
  loopSourceId?: string
  magnifyDisplayName?: string
  name: string
  nodeId: string
  object: string
  onShowDrawer: (data: SegmentBuilderData) => void
  payload: ConfigPanelPayload
  platform: string
  setElements: (elements: Elements) => void
  shape: ShapeTypeEnum
  solutionInstanceId?: string
  status?: string
  targetNodeId?: string
  type: NodeTypeEnum
  // For ReactFlow
  data?: Record<string, any>
  actionLevel?: string
}

export interface SegmentBuilderProps {
  onCloseDrawer: () => void
  segmentBuilderData: SegmentBuilderData
  setSegmentBuilderData: Dispatch<SetStateAction<SegmentBuilderData>>
}

export interface BreadcrumbInfo {
  entityType?: string
  key?: string
  magnifyDisplayName?: string
  name: string
  path: string[]
}

export interface SourceData {
  aggregationLevel?: string | boolean
  aggregationType?: string | boolean
  aggregationUuid?: string
  fields?: SourceData[]
  isDynamicInput?: boolean
  key?: string
  magnifyDisplayName?: string
  name: string
  object?: string
  platform?: string
  solutionInstanceId?: string
  type?: string
}

export interface DragItemEvent {
  name: string
  item: SourceData
}

interface Field5 {
  name: string
  type: string
  fields: any[]
}

interface Field4 {
  name: string
  type: string
  fields: Field5[]
}

interface Field3 {
  name: string
  type: string
  fields: Field4[]
}

interface Field2 {
  name: string
  type?: string
  fields?: Field3[]
}

interface Field {
  name: string
  type?: string
  fields?: Field2[]
}

export interface mockDataItems {
  name: string
  type: string
  fieldType?: string
  fields: Field[]
}
export interface CreateEdgeProps {
  parentNode: Node
  childNode: Node | string
  dropEdge?: BuilderAction | string
  isOnNoBranch?: boolean
}

export interface DragEvents {
  isDragStarting: boolean
  dragOverGroup: DragOverGroupType
}

export interface PayloadData {
  groups: Groups[]
  relationOperator: RelationOperator
}

export interface Groups {
  groups: Item[]
  relationOperator: RelationOperator
}

export interface Item {
  aggregationId?: string
  aggregationLevel?: string | boolean
  aggregationType?: string | boolean
  displayValue?: string
  field?: string
  isAggregationCriteria?: boolean
  isDynamicInput?: boolean
  isNewStatement: boolean
  key?: string
  magnifyDisplayName?: string
  object: string
  onlyDynamicInput?: boolean
  operator: InputOperatorEnum
  platform: string
  solutionInstanceId?: string
  type?: string
  value: boolean | string | string[] | number | number[] | null | Record<string, string> | CreateActionFields
}

export interface SelectOptions {
  // Added for handleGainsightActions
  id?: string | number | undefined
  label: string
  value: string | boolean | null
}

export interface ExtendedSelectOptions {
  label: string | JSX.Element
  value: string | boolean | null
}

export interface TimeDelayPayload {
  units: unitsValue
  amount: number
}

export interface LoopPayload {
  timeOfLoop: number
  amount: number
  units: unitsValue
  exitCondition: PayloadData
}

export interface MergePayload {
  targets: string[]
}

export interface CreateActionsPayload {
  fields: CreateActionFields[]
}

export interface KeyValueField {
  key: string
  value:
    | boolean
    | number
    | number[]
    | string
    | string[]
    | Dayjs
    | Dayjs[]
    | CreateSlackMessagePayload
    | CreateActionFields
    | Record<string, number | string | CreateSlackMessagePayload | KeyValueField | DynamicInputs>
    | undefined
    | null
  displayValue?: string
  isDynamicInput?: boolean
  type: string
}

export interface CreateActionFields extends KeyValueField {
  createable?: boolean
  error?: boolean
  field?: string
  isMessageContent?: boolean
  magnifyDisplayName?: string
  name?: string
  object?: string
  onlyDynamicInput?: boolean
  operator?: string
  platform?: string
  required?: boolean
  solutionInstanceId?: string
  tooltip?: string
  updateable?: boolean
  // MAGPROD-969
  ctaTypeId?: string
  ctaTypeValue?: string
  defaultValue?: string
  displayName?: string
}

// GainSight specific fields
export interface CreateActionFieldsGainsight extends CreateActionFields {
  dropdownValues: GainsightDropdownValue[]
  displayName?: string
}

export interface DataCreateActionFieldsDropdown {
  data: CreateActionFieldsGainsight
}

export interface GainsightDropdownValue {
  controllerValues?: null
  color?: string
  systemName?: null
  validFor?: null
  defaultValue?: string | boolean
  groupId?: null | string
  displayOrder?: number
  active?: boolean
  description?: null
  label?: string
  value?: string | number
  group?: string
}

export interface MetadataActionFields {
  data: {
    [platform: string]: {
      [object: string]: {
        [action: string]: CreateActionFields[]
      }
    }
  }
  isLoading: boolean
}

export interface UpdateActionSelectCriteria {
  value: string
  option: DefaultOptionType | DefaultOptionType[]
  index: number
}

export interface ConfigPanelStatementsProps {
  payload: ConfigPanelPayload
  setPayload: Dispatch<SetStateAction<ConfigPanelPayload>>
}

export type ConfigPanelPayload = PayloadData &
  TimeDelayPayload &
  LoopPayload &
  CreateActionsPayload &
  MergePayload &
  CreateSlackMessagePayload

export enum HeighlightNodeStatusEnum {
  Valid = 'valid',
  Invalid = 'invalid',
}

export interface SegmentExportData {
  journeyName: string | undefined
  type: string
  payload: ConfigPanelPayload
  playbookId: string | undefined
  version: number | undefined
  aggregations: AggregationData[]
  excludedUserIds: string[]
}

export enum ActionEnum {
  Create = 'create',
  Update = 'update',
}

export enum ActionTypeEnum {
  Create = 'create',
  Update = 'update',
  UpdateAccount = 'updateAccount',
  TriggerEvent = 'triggerEvent',
  SendEmail = 'sendEmail',
}

export enum InputOperatorEnum {
  LessThan = 'lessThan',
  EqualsOrLessThan = 'equalsOrLessThan',
  Equals = 'equals',
  EqualsOrGreaterThan = 'equalsOrGreaterThan',
  GreaterThan = 'greaterThan',
  Between = 'between',
  AnyOf = 'anyOf',
  NoneOf = 'noneOf',
  Contains = 'contains',
  NotContains = 'notContains',
  ContainsOneOf = 'containsOneOf',
  NotContainsAnyOf = 'notContainsAnyOf',
  Include = 'include',
  NotInclude = 'notInclude',
  WithinNext = 'withinNext',
  WithinLast = 'withinLast',
  NotEquals = 'notEquals',
  NotFound = 'notFound',
  IsNull = 'isNull',
  IsNotNull = 'isNotNull',
}

export interface AggregationOptions {
  isLoading: boolean
  aggregationLevel: SelectOptions[]
  aggregationTypes: SelectOptions[]
}

export interface AggregationDataResult {
  isLoading: boolean
  aggregationLevel: {
    [level: string]: boolean
  }
  aggregationTypes: string[]
}

export interface AggregationData {
  aggregationLevel: string | boolean
  aggregationType: string | boolean
  aggregationUuid?: string
  field?: string
  filters?: PayloadData
  key?: string
  magnifyDisplayName?: string
  name: string
  object?: string
  platform?: string
  solutionInstanceId?: string
}

export interface CurrentUsedAggregation {
  itemIndex: number
  groupIndex: number
  nodeId?: string
  nodeName?: string
  nodeDescription?: string
  hasSamePayload?: boolean
}

export interface SegmentOutput {
  message: string
  segmentExports: SegmentExport[]
  status: number
  isLoading: boolean
  isModalDisplayed: boolean
}
