import { useState } from 'react'

import { Button } from 'components/common'
import { downloadSegment } from 'components/MotionBuilder/Utils/segmentBuilder.utils'

const SegmentDownloadNotification = ({ filename }: { filename: string }) => {
  const [isLoading, setIsLoading] = useState(false)

  const onClick = async () => {
    setIsLoading(true)
    await downloadSegment(filename)
    setIsLoading(false)
  }

  return <Button text='Download' className='notification-card__download' onClickHandler={onClick} loading={isLoading} />
}

export default SegmentDownloadNotification
