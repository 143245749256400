import { LogoutOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import Sider from 'antd/lib/layout/Sider'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

import {
  DashboardIcon,
  DefaultPic,
  HelpCircle,
  IntegrationsIcon,
  LogoMark,
  LogoType,
  MotionsIcon,
  NotificationBell,
  PerceptionsIcon,
  PremiumSelectedImage,
  PremiumUnselectedImage,
  WorkspaceIcon,
} from 'components/Navigation/NavigationIcons'
import { NotificationsPopUp } from 'components/NotificationsPopUp/NotificationsPopUp'
import type { LaunchDarklyFeatureFlags } from 'configs/featureFlags'
import { useDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import useStore from 'store/useStore'

import type { MenuProps } from 'antd'

interface NavigationProps {
  locationPathname: string
}
export const Navigation: React.FunctionComponent<NavigationProps> = observer(({ locationPathname }) => {
  const { actionsStore, notificationStore, userStore } = useStore()
  const { getUnseenNotifications, unSeenNotifications } = notificationStore
  const { email } = userStore
  const { filteredToolboxItems, fetchActions } = actionsStore
  const { perceptions } = useFlags<LaunchDarklyFeatureFlags>()

  useDisplayErrorNotification(notificationStore)

  useEffect(() => {
    // Fetch the actions if they are not already fetched.
    if (!filteredToolboxItems?.actions?.length) {
      fetchActions().catch(console.error)
    }

    // Fetch the unseen notifications.
    getUnseenNotifications().catch(console.error)
  }, [])

  const { search } = useLocation()

  let selectedKey
  switch (locationPathname?.split('/')[1]) {
    case 'dashboard':
      selectedKey = '1'
      break
    case 'motions':
      selectedKey = '2'
      break
    case 'email-templates':
      selectedKey = '3'
      break
    case 'integrations':
      selectedKey = '4'
      break
    case 'perceptions':
      selectedKey = '5'
      break
    default:
      selectedKey = '6'
      break
  }

  const isMotionRoute = locationPathname?.includes('motions/motion') && !locationPathname.includes('status')
  const isHelpRoute = locationPathname?.includes('/help-center')
  const isPerceptionRoute = locationPathname?.includes('/perceptions')

  /** Should we show the Workspace link in the menu. */
  let shouldShowWorkspaceHub = false
  if (filteredToolboxItems?.actions) {
    // Currently there is only one Magnify action, so we can just check for that.
    shouldShowWorkspaceHub = filteredToolboxItems.actions.some((action) => action.name === 'magnify')
  }

  const subMenuItems: MenuProps['items'] = [
    {
      key: 1,
      label: (
        <span className='ant-dropdown-menu-title-content' role='menuitem' id='account-settings-button'>
          <Link to='/account-settings/profile' data-testid='account-settings-slide-menu'>
            Account Settings
          </Link>
        </span>
      ),
    },
    {
      key: 2,
      label: (
        <Link to='/signout' data-testid='signout-slide-menu'>
          <span role='menuitem' id='sign-out-button' data-testid='sign-out-button'>
            <LogoutOutlined /> <span> Sign out</span>
          </span>
        </Link>
      ),
    },
  ]

  const mainMenuItems = [
    {
      key: '1',
      label: (
        <Link className='first-menu-item link-container' to={`/dashboard/${search}`} data-testid='dashboard-menu-side'>
          <DashboardIcon />
          <span className={`${isMotionRoute && 'collapsed-item'}`}>Dashboard</span>
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link className='second-menu-item link-container' to='/motions/' data-testid='motions-menu-side'>
          <MotionsIcon />
          <span className={`${isMotionRoute && 'collapsed-item'}`} data-testid='motions-menu-side-icon'>
            Motions
          </span>
        </Link>
      ),
    },
    shouldShowWorkspaceHub
      ? {
          key: '3',
          label: (
            <Link className='second-menu-item link-container' to='/email-templates/' data-testid='workspace-menu-side'>
              <WorkspaceIcon />
              <span className={`${isMotionRoute && 'collapsed-item'}`} data-testid='workspace-menu-side-icon'>
                Workspace
              </span>
            </Link>
          ),
        }
      : null,
    {
      key: '4',
      label: (
        <Link className='third-menu-item link-container' to='/integrations' data-testid='integrations-menu-side'>
          <IntegrationsIcon />
          <span className={`${isMotionRoute && 'collapsed-item'}`}>Integrations</span>
        </Link>
      ),
    },
    perceptions
      ? {
          key: '5',
          label: (
            <Link className='fifth-menu-item link-container' to='/perceptions' data-testid='perceptions-menu-side'>
              <PerceptionsIcon />
              <span className={`${isMotionRoute && 'collapsed-item'}`}>
                Perceptions
                <img
                  src={isPerceptionRoute ? PremiumSelectedImage : PremiumUnselectedImage}
                  width='16'
                  height='16'
                  alt='Premium'
                  className='premium-tag'
                />
              </span>
            </Link>
          ),
        }
      : null,
  ]

  return (
    <Sider
      className={`side-main-menu ${isMotionRoute ? 'collapsed-menu' : 'extended-menu'}`}
      data-testid='side-main-menu'
      trigger={null}
      width={`${isMotionRoute ? '60px' : '260px'}`}>
      <Link
        className={`logo ${isMotionRoute ? 'logo-collapsed' : 'logo-expanded'}`}
        data-testid='logo-icon'
        to={`/dashboard/${search}`}>
        <LogoMark />
        {!isMotionRoute && <LogoType />}
      </Link>

      <Menu mode='inline' defaultSelectedKeys={[selectedKey]} selectedKeys={[selectedKey]} items={mainMenuItems} />

      <div className='bottom-container' data-testid='bottom-container'>
        <Link className={`bottom__help-center ${isHelpRoute ? 'selected' : ''}`} to='/help-center'>
          <HelpCircle />
          {!isMotionRoute && <span>Help center</span>}
        </Link>
        <div className='bottom__profile' data-testid='bottom-profile'>
          <Dropdown className='dropdown-menu' menu={{ items: subMenuItems }} placement='topRight'>
            <div className='ant-dropdown-link' data-testid='profile-dropdown'>
              <DefaultPic className='user-icon' />
              {isMotionRoute && unSeenNotifications.length ? (
                <NotificationBell className='notification-bell' />
              ) : (
                <span className='bottom__profile-user-info'>{email}</span>
              )}
            </div>
          </Dropdown>

          {!isMotionRoute && (
            <div className='bottom__profile__notifications'>
              <NotificationsPopUp />
            </div>
          )}
        </div>
      </div>
    </Sider>
  )
})
Navigation.displayName = 'Navigation'
