import { Button as AntBtn, Dropdown, Popover, Row, Tooltip } from 'antd'
import classNames from 'classnames'

import EllipsisVertical from 'assets/images/ellipsis-vertical.svg?react'
import { LogoList, IconFeedbackAlert, IconExperiment } from 'components/common'
import { formatNumberWithAbbreviation } from 'components/common/Utils/insights'
import HorizontalStackedChart from 'components/MotionObservability/components/HorizontalStackedChart'
import { DateService } from 'services/Utils/date'
import { sortAlphabetically, sorterNumbers } from 'services/Utils/Sorters'

import type { ColumnProps } from 'antd/lib/table'

import type { Motion, MotionIdentifiers, MotionObservabilityMetrics } from 'models/motion.model'
import { MotionStateEnum } from 'models/motion.model'

export const DEFAULT_PAGE_SIZE = 10
const IS_NON_PROD = import.meta.env.VITE_ENV !== 'production'

interface Options {
  motion: Motion
  search: string
  isMockApiEnabled: boolean
  redirectMotion: (id: string, version: number, suffix: string, shouldEnableInMotionReporting?: boolean) => void
  handleClone: (motion: Motion) => Promise<void>
  handleExecute: (motion: Motion) => void
  handleCancel: (motion: MotionIdentifiers) => Promise<void>
  handleArchive: (motion: Motion) => Promise<void>
  handleDeleteSchedule: (motion: Motion) => Promise<void>
  handleExperimentReport: (motion: { id: string; version: number }) => void
  handleExecuteNow: (motion: Motion) => Promise<void>
  totalMotions: number
}

export interface MotionTableSource {
  data: Motion[]
  page: number
  total: number
}

enum MotionActionMenuItemsEnum {
  ViewMotionState = 'View Motion State',
  Edit = 'Edit',
  Clone = 'Clone',
  Run = 'Run',
  ExecuteNow = 'Execute now',
  Cancel = 'Cancel',
  DeleteSchedule = 'Delete Schedule',
  Delete = 'Delete Motion',
}

const menuItems = ({
  motion,
  search,
  redirectMotion,
  handleClone,
  handleExecute,
  handleCancel,
  handleArchive,
  handleDeleteSchedule,
  handleExecuteNow,
}: Options) => {
  const isMotionInDraft = motion.currState === MotionStateEnum.Draft
  const isMotionScheduled = motion.currState === MotionStateEnum.Scheduled
  const isMotionExecuting = [MotionStateEnum.Executing, MotionStateEnum.Scheduled].includes(motion.currState)
  const isMotionReportingAvailable = [
    MotionStateEnum.Executing,
    MotionStateEnum.Scheduled,
    MotionStateEnum.Completed,
    MotionStateEnum.Failed,
  ].includes(motion.currState)
  const isValid = !motion.isInvalid

  let motionSchedule: { executionCadence: string } = { executionCadence: '' }
  try {
    motionSchedule = JSON.parse(motion.schedule ?? '{}') as { executionCadence: string }
  } catch (error: unknown) {
    console.error('Error parsing Motion schedule:', motion.schedule)
  }
  const isReadyToExecuteNow =
    isMotionScheduled && motion.schedule && motionSchedule?.executionCadence === 'multiple' && IS_NON_PROD

  return Object.values(MotionActionMenuItemsEnum)
    .map((item) => {
      switch (item) {
        case MotionActionMenuItemsEnum.ViewMotionState:
          return isMotionReportingAvailable
            ? {
                key: item,
                label: item,
                onClick: () => redirectMotion(motion.playbookId, motion.version, search, true),
              }
            : null
        case MotionActionMenuItemsEnum.Edit:
          return isMotionInDraft
            ? {
                key: item,
                label: item,
                onClick: () => redirectMotion(motion.playbookId, motion.version, search),
              }
            : null
        case MotionActionMenuItemsEnum.Clone:
          return {
            key: item,
            label: item,
            onClick: () => void handleClone(motion),
          }
        case MotionActionMenuItemsEnum.Run:
          return isMotionInDraft && isValid
            ? {
                key: item,
                label: item,
                onClick: () => handleExecute(motion),
              }
            : null
        case MotionActionMenuItemsEnum.ExecuteNow:
          return isReadyToExecuteNow
            ? {
                key: item,
                label: item,
                onClick: () => void handleExecuteNow(motion),
              }
            : null
        case MotionActionMenuItemsEnum.Cancel:
          return isMotionExecuting
            ? {
                key: item,
                label: item,
                onClick: () => void handleCancel(motion),
              }
            : null
        case MotionActionMenuItemsEnum.DeleteSchedule:
          return isMotionScheduled
            ? {
                key: item,
                label: item,
                onClick: () => void handleDeleteSchedule(motion),
              }
            : null

        case MotionActionMenuItemsEnum.Delete:
          return {
            key: item,
            label: item,
            className: 'dropdown__item__danger',
            onClick: () => void handleArchive(motion),
          }

        default:
          return null
      }
    })
    .filter(Boolean)
}

export const getTableColumns = (options: Omit<Options, 'motion'>): ColumnProps<Motion>[] => {
  return [
    {
      title: `Motions (${formatNumberWithAbbreviation(options.totalMotions)})`,
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => {
        return (
          <Popover
            key={`${record.playbookId}-title-row`}
            overlayClassName='motion-observability__popover'
            placement='bottom'
            title={
              <div className='motion-observability__popover__header'>
                <h6>{record.title}</h6>
              </div>
            }
            content={
              <div>
                <p>
                  <strong>Last Executed:</strong>{' '}
                  {(record.stateHistory?.EXECUTING &&
                    DateService.parseDateTimestamp(new Date(record.stateHistory.EXECUTING).getTime(), {
                      divider: '-',
                      showTime: false,
                    })) ||
                    'N/A'}
                </p>
                <p>
                  <strong>Author:</strong> {record.createdBy}
                </p>
              </div>
            }>
            <div className='motions-table-title__container'>
              {options.isMockApiEnabled &&
                (record.currState === MotionStateEnum.Completed || record.currState === MotionStateEnum.Executing) && (
                  <span
                    className='experiment'
                    onClick={() => {
                      options.handleExperimentReport({ id: record.playbookId, version: record.version })
                    }}>
                    <IconExperiment />
                  </span>
                )}
              <span
                className={classNames({
                  'motions-table-row-title': true,
                  link: [MotionStateEnum.Completed, MotionStateEnum.Executing].includes(record.currState),
                })}
                onClick={() => {
                  const goToReporting = [MotionStateEnum.Completed, MotionStateEnum.Executing].includes(
                    record.currState,
                  )
                  if (goToReporting) {
                    return options.redirectMotion(record.playbookId, record.version, '/details')
                  }
                }}>
                {record.title}
              </span>
              <div className='motions-table-row-integrations'>
                <LogoList
                  integrations={record.integrations?.map((integration) => integration.platform) ?? []}
                  limit={10}
                />
              </div>
            </div>
          </Popover>
        )
      },
      sorter: (a: Motion, b: Motion) => {
        return sortAlphabetically(a.title ?? '', b.title ?? '')
      },
    },
    {
      title: 'Actions',
      dataIndex: 'observabilityMetrics.actionStatistics.totals.SUCCEEDED',
      key: 'action-totals',
      render: (_: any, { observabilityMetrics = {} as MotionObservabilityMetrics }: Motion) => {
        const total = observabilityMetrics?.actionStatistics?.totals?.SUCCEEDED ?? 0
        return <div>{formatNumberWithAbbreviation(total)}</div>
      },
      sorter: (a: Motion, b: Motion) => {
        const totalA = a?.observabilityMetrics?.actionStatistics?.totals?.SUCCEEDED ?? 0
        const totalB = b?.observabilityMetrics?.actionStatistics?.totals?.SUCCEEDED ?? 0
        return sorterNumbers(totalA, totalB)
      },
    },
    {
      title: 'Users',
      dataIndex: 'observabilityMetrics.participantStatistics.totals.users',
      key: 'user-totals',
      render: (_: any, { observabilityMetrics = {} as MotionObservabilityMetrics }: Motion) => {
        return (
          <div>{formatNumberWithAbbreviation(observabilityMetrics?.participantStatistics?.totals?.users ?? 0)}</div>
        )
      },
      sorter: (a: Motion, b: Motion) => {
        const totalA = a?.observabilityMetrics?.participantStatistics?.totals?.users ?? 0
        const totalB = b?.observabilityMetrics?.participantStatistics?.totals?.users ?? 0
        return sorterNumbers(totalA, totalB)
      },
    },
    {
      title: 'Accounts',
      dataIndex: 'observabilityMetrics.participantStatistics.totals.accounts',
      key: 'accounts-totals',
      render: (_: any, { observabilityMetrics = {} as MotionObservabilityMetrics }: Motion) => {
        return (
          <div>{formatNumberWithAbbreviation(observabilityMetrics?.participantStatistics?.totals?.accounts ?? 0)}</div>
        )
      },
      sorter: (a: Motion, b: Motion) => {
        const totalA = a?.observabilityMetrics?.participantStatistics?.totals?.accounts ?? 0
        const totalB = b?.observabilityMetrics?.participantStatistics?.totals?.accounts ?? 0
        return sorterNumbers(totalA, totalB)
      },
    },
    {
      title: 'Status',
      dataIndex: 'currState',
      key: 'currState',
      render: (_: any, record: Motion) => {
        let displayStatus =
          record.currState.toLowerCase().charAt(0).toUpperCase() + record.currState.slice(1).toLowerCase()
        const showReportingBtn = [MotionStateEnum.Completed, MotionStateEnum.Executing].includes(record.currState)

        if (record.currState === MotionStateEnum.Executing) {
          displayStatus = 'Running'
        }

        return (
          <section className='motions-table-row-status-container'>
            <Row>
              <span
                className='action'
                onClick={() =>
                  options.redirectMotion(record.playbookId, record.version, options.search, showReportingBtn)
                }>
                {displayStatus}
              </span>
              {record.isInvalid && (
                <Tooltip title='This Motion has errors with the configuration. It can not be scheduled to run.'>
                  <IconFeedbackAlert data-testid='is-invalid-icon' style={{ marginLeft: '4px' }} />
                </Tooltip>
              )}
            </Row>
          </section>
        )
      },
      sorter: (a: Motion, b: Motion) => {
        return sortAlphabetically(a.currState ?? '', b.currState ?? '')
      },
    },
    {
      title: 'Motion State',
      key: 'motion-state',
      render: (_: any, record: Motion) => {
        const total: number = Object.values(record?.observabilityMetrics?.participantStatistics?.state ?? {}).reduce(
          (prev: number, current: number) => prev + current,
          0,
        ) as number
        const items: Record<string, any> = {}
        Object.entries(record?.observabilityMetrics?.participantStatistics?.state ?? {}).forEach(
          ([key, value]: [string, number]) => {
            let percentageTotal = (value / total) * 100
            if (Number.isNaN(percentageTotal)) {
              percentageTotal = 0
            }
            items[key] = { total: value, percentageTotal, actions: {} }
          },
        )
        return (
          <HorizontalStackedChart
            className='motions-table'
            showLegend={false}
            items={items}
            hasAnyData={Object.keys(items).length > 0}
          />
        )
      },
    },
    {
      title: '',
      key: 'motion-actions',
      render: (_: any, motion: Motion) => {
        return (
          <Dropdown
            // motion-actions-menu is used as a data-testid
            menu={{ items: menuItems({ ...options, motion }), className: '[ motion-actions-menu ]' }}
            placement='bottomRight'
            trigger={['click']}>
            <AntBtn data-testid='motion-actions-button'>
              <EllipsisVertical />
            </AntBtn>
          </Dropdown>
        )
      },
    },
  ]
}
