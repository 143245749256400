import { Modal } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useStoreState } from 'react-flow-renderer'

import Button from 'components/common/Button'
import {
  getElementsAfterDeleteNode,
  isElementDeleted,
} from 'components/common/MotionThumbnail/Nodes/Segment/SegmentUtils'
import { unmarkTargetNodes } from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/LoopPanel/LoopActionUtils'
import { unsetMerge } from 'services/Utils/merge/MergeAction.utils'
import { elementsSeparator } from 'services/Utils/motionHelpers/motionHelpers.utils'
import useStore from 'store/useStore'

import type { Elements, Node } from 'react-flow-renderer'

import type { ConfigPanelPayload, SegmentBuilderData } from 'models/motion/motionBuilder.model'
import type { NodePayload } from 'models/motion.model'
import { NodeTypeEnum } from 'models/motion.model'

interface DeleteNodeModalProps {
  setElements: (elements: Elements) => void
  setReset: React.Dispatch<React.SetStateAction<boolean>>
}

const DeleteNodeModal = observer(({ setElements, setReset }: DeleteNodeModalProps) => {
  const { motionStore, mergePanelStore, metadataStore } = useStore()
  const nodes: Node<SegmentBuilderData>[] = useStoreState((state) => state.nodes) as Node<SegmentBuilderData>[]
  const edges = useStoreState((state) => state.edges)

  const [modalTitle, setModalTitle] = useState<string>('')
  const [modalMessage, setModalMessage] = useState<string>('')

  useEffect(() => {
    setModalText(motionStore.currentNode.data as SegmentBuilderData)
  }, [motionStore.currentNode])

  const setModalText = (nodeData: SegmentBuilderData) => {
    const isDefaultName = nodeData?.name === nodeData?.description
    const nodeName = isDefaultName ? nodeData?.name : `"${nodeData?.description}" ${nodeData?.name}`
    switch (nodeData?.type) {
      case NodeTypeEnum.Branch:
        setModalTitle(`Delete ${nodeName} and branches?`)
        setModalMessage(`Please note both branches will be deleted with the ${nodeName}.`)
        break

      case NodeTypeEnum.Segment:
        setModalTitle('Reset Segment?')
        setModalMessage('Please note this will remove all segment criteria.')
        break

      default:
        setModalTitle(`Delete ${nodeName}?`)
        setModalMessage('Are you sure you want to delete this action?')
        break
    }
  }

  const initModal = () => {
    motionStore.setDisplayRemoveModal(false)
    setModalTitle('')
    setModalMessage('')
  }

  const handleOk = () => {
    const currentNode: Node<SegmentBuilderData> | undefined = nodes.find(
      (element) => element.id === motionStore.currentNode?.id,
    )

    if (!currentNode) {
      return
    }

    const elementsWithoutCurrentNode = nodes.filter((element) => element.id !== motionStore.currentNode?.id)

    if (motionStore.currentNode?.data?.type === NodeTypeEnum.Segment) {
      setReset(true)
      currentNode.data!.payload = {} as ConfigPanelPayload
      setElements([...elementsWithoutCurrentNode, currentNode, ...edges])
    } else {
      let elementsToUpdate = getElementsAfterDeleteNode(motionStore.currentNode.id, nodes, edges)

      if (motionStore.configPanelNode && isElementDeleted(elementsToUpdate, motionStore.configPanelNode)) {
        // Hide the configuration panel if was opened for one of the deleted elements
        motionStore.setConfigPanelNode(null)
      }

      if (currentNode.data?.type === NodeTypeEnum.Merge) {
        // Remove edges connected into the current node
        for (const targetId of currentNode.data.payload?.targets ?? []) {
          elementsToUpdate = unsetMerge(elementsToUpdate, targetId)
        }

        if (mergePanelStore.isSelectingTarget) {
          // cleanup store and nodes state as target/invalid
          mergePanelStore.setIsSelectingTarget(false)
          const { edges, nodes, loopEdges } = elementsSeparator<
            SegmentBuilderData & { type: string; payload: NodePayload; isInitial: boolean; isFinal: boolean },
            { edgeLabel: string; edgeType: NodeTypeEnum }
          >(elementsToUpdate)
          const unmarkedNodes = unmarkTargetNodes(nodes)
          elementsToUpdate = [...unmarkedNodes, ...edges, ...loopEdges]

          const focusedMergeId = mergePanelStore.currentSourceMergeId
          const mergeState = mergePanelStore.merges.get(focusedMergeId || '')

          if (focusedMergeId && mergeState) {
            mergePanelStore.merges.delete(focusedMergeId)
          }
        }

        setElements(elementsToUpdate)
      } else {
        setElements(elementsToUpdate)
      }
      mergePanelStore.setMergeBasedOnElements(elementsToUpdate)
    }
    metadataStore.initMetadata()
    initModal()
  }

  return (
    <Modal
      title={modalTitle}
      open={motionStore.displayRemoveModal}
      onOk={handleOk}
      onCancel={initModal}
      centered
      footer={[
        <Button key='cancel' text='Cancel' type='secondary' testId='cancel-btn' onClickHandler={initModal} />,
        <Button key='confirm' text='Confirm' testId='confirm-btn' type='danger' onClickHandler={handleOk} />,
      ]}>
      {modalMessage && (
        <p className='message__text' role='alert' data-testid='modal-message'>
          {modalMessage}
        </p>
      )}
    </Modal>
  )
})
DeleteNodeModal.displayName = 'DeleteNodeModal'

export default DeleteNodeModal
