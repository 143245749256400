import { Empty } from 'antd'
import Table from 'antd/lib/table'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import { accountsListMock } from 'api/mockResponses/reporting.mock'
import { DynamicCell, Heading, Search } from 'components/common'
import useDebounce from 'hooks/useDebounce'
import useDemoFeature from 'hooks/useDemoFeature'
import { sortAlphabetically, sorterNumbers } from 'services/Utils/Sorters'
import { DEFAULT_PAGE_SIZE } from 'store/reporting/reporting.store.utils'
import useStore from 'store/useStore'

import type { SortOrder } from 'antd/es/table/interface'
import type { ColumnProps, ColumnsType } from 'antd/lib/table'

import type { TableColumn } from 'models/insights'
import type { AccountListRow, AccountsTableSource, Composite } from 'models/reporting.model'
import { AccountListTypeEnum } from 'models/reporting.model'

/**
 * Generate Ant Design table columns from data.
 * @see https://ant.design/components/table/#column
 */
export function getTableColumns(
  data: TableColumn[] = [],
  defaultSortKey?: string,
  defaultSortDirection: SortOrder = 'descend',
): ColumnsType<AccountListRow> {
  return data.map((column) => {
    const output: ColumnProps<AccountListRow> = {
      title: column.title,
      dataIndex: column.key,
      key: column.key,
      render: (_: any, record: AccountListRow) => <DynamicCell column={column} record={record} />,
      sorter: (a: AccountListRow, b: AccountListRow) => {
        const valueA = a[column.key] as string | number | Composite
        const valueB = b[column.key] as string | number | Composite
        switch (column.type) {
          case AccountListTypeEnum.String: {
            return sortAlphabetically(valueA as string, valueB as string)
          }
          case AccountListTypeEnum.Number: {
            return sorterNumbers(valueA as number, valueB as number)
          }
          case AccountListTypeEnum.Composite: {
            return sorterNumbers((valueA as Composite)?.new ?? 0, (valueB as Composite)?.new ?? 0)
          }
          default: {
            return sorterNumbers(valueA as number, valueB as number)
          }
        }
      },
    }
    if (column.key === defaultSortKey) {
      output.defaultSortOrder = defaultSortDirection
    }
    return output
  })
}

const AccountList = observer(
  ({ journeyId = '', journeyVersion = '' }: { journeyId: string | undefined; journeyVersion: string | undefined }) => {
    const { isMockApiEnabled } = useDemoFeature()
    const { insightsStore, reportingStore, observabilityStore } = useStore()
    const {
      data: { accountList },
      isAccountListEmpty,
      loading,
      getAccountList,
    } = reportingStore
    const { selectedDimension } = insightsStore
    const { tenantInMotionReporting } = observabilityStore
    const [searchInput, setSearchInput] = useState<string>('')
    const [accountsTableSource, setAccountsTableSource] = useState<AccountsTableSource>({ data: [], page: 1, total: 0 })

    /** Debounce fetching the search as the user types. */
    const fetchSearch = useDebounce(async (value: string) => {
      // Support fake searching for demo data.
      if (isMockApiEnabled) {
        const data = accountsListMock.rows.filter(
          (row: AccountListRow) =>
            row.account.toLowerCase().includes(value.toLowerCase()) ||
            row.csManager.toLowerCase().includes(value.toLowerCase()),
        )
        setAccountsTableSource((prev) => ({
          ...prev,
          data,
          page: 1,
          total: data.length,
        }))
        return
      }

      await getAccountList({
        journeyId,
        version: journeyVersion,
        limit: DEFAULT_PAGE_SIZE,
        offset: 0,
        search: value,
        reset: true,
        dimension: selectedDimension,
      })
    }, 1000)

    /** Callback for executing the search. */
    const handleOnSearch = async (value: string) => {
      await fetchSearch(value)
      setSearchInput(value)
    }

    /** Callback for closing the search. */
    const handleSearchClose = async () => {
      setSearchInput('')
      setAccountsTableSource((prev) => ({ data: [], page: 1, total: 0 }))
      // Reset the data back to the first page.
      await getAccountList({
        journeyId,
        version: journeyVersion,
        limit: DEFAULT_PAGE_SIZE,
        offset: 0,
        search: '',
        reset: true,
        dimension: selectedDimension,
      })
    }

    /** Callback for page changes for AntD tables that support custom page sizes. */
    const handlePageChange = async (page: number, pageSize: number = DEFAULT_PAGE_SIZE) => {
      // Update the demo data page.
      if (isMockApiEnabled) {
        setAccountsTableSource((prev) => ({ ...prev, page }))
        return
      }

      const queryLimit = 100
      const newPageNumber = page
      const currentPageNumber = accountsTableSource.page
      const lastKey = accountList?.lastKey
      const doesCurrentPageContainLastKey = accountsTableSource.data.some((account) => account.id === lastKey)
      const numberOfFetchedPages = queryLimit / pageSize
      const isFirstPageOfFetch = newPageNumber % numberOfFetchedPages === 0

      const shouldFetchNextPage = doesCurrentPageContainLastKey && newPageNumber > currentPageNumber && lastKey
      const shouldFetchPreviousPage = newPageNumber < currentPageNumber && isFirstPageOfFetch

      if (shouldFetchNextPage) {
        await getAccountList({
          journeyId,
          version: journeyVersion,
          limit: pageSize,
          offset: page * pageSize - pageSize,
          search: searchInput,
          dimension: selectedDimension,
          paginateFromId: lastKey,
          lastKeyHistory: (accountList.lastKeyHistory || []).concat(lastKey),
        })
      } else if (shouldFetchPreviousPage) {
        const newLastKeyHistory = accountList.lastKeyHistory?.slice(0, -1) || []
        await getAccountList({
          journeyId,
          version: journeyVersion,
          limit: pageSize,
          offset: page * pageSize - pageSize,
          search: searchInput,
          dimension: selectedDimension,
          paginateFromId: newLastKeyHistory.pop() || '',
          lastKeyHistory: newLastKeyHistory,
        })
      }
      setAccountsTableSource((prev) => ({ ...prev, page }))
    }

    useEffect(() => {
      if (!isMockApiEnabled) {
        getAccountList({
          journeyId,
          version: journeyVersion,
          limit: DEFAULT_PAGE_SIZE,
          offset: 0,
          search: '',
          reset: true,
          dimension: selectedDimension,
        }).catch(console.error)
      }
    }, [])

    useEffect(() => {
      // Break the demo data array into pages.
      if (isMockApiEnabled) {
        setAccountsTableSource((prev) => ({
          ...prev,
          data: accountsListMock.rows.slice(
            (accountsTableSource.page - 1) * DEFAULT_PAGE_SIZE,
            accountsTableSource.page * DEFAULT_PAGE_SIZE,
          ),
          total: accountsListMock.rows.length,
        }))
        return
      }

      if (accountList?.data && accountList?.data.length > 0) {
        let currentPageIndex = accountsTableSource.page % 10
        if (currentPageIndex === 0) {
          currentPageIndex = 10
        }

        const pageAccounts: AccountListRow[] =
          accountList.data && accountList.data.length > 0
            ? accountList.data[0].items.slice(
                (currentPageIndex - 1) * DEFAULT_PAGE_SIZE,
                currentPageIndex * DEFAULT_PAGE_SIZE,
              )
            : []

        setAccountsTableSource((prev) => ({
          ...prev,
          data: pageAccounts,
          total: accountList?.total,
        }))
      }
    }, [accountList, accountsTableSource.page])

    return (
      <section className='account-list-container' id='accountList' data-testid='account-list-container'>
        <header>
          <Heading variant='7' level='2'>
            Account List
          </Heading>

          {!isAccountListEmpty && (
            <Search
              data-testid='account-list-table-search'
              placeholder='Search account name...'
              onClose={handleSearchClose}
              searchType={['onEnter', 'onType']}
              onFilter={handleOnSearch}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
            />
          )}
        </header>
        {isAccountListEmpty ? (
          <Empty data-testid='empty-description' />
        ) : (
          <Table
            className='account-list-table'
            data-testid='account-list-table'
            loading={loading.isAccountListLoading}
            rowKey='id'
            dataSource={accountsTableSource.data}
            columns={getTableColumns(accountList?.columns)}
            pagination={{
              current: accountsTableSource.page,
              onChange: handlePageChange,
              showSizeChanger: false,
              total: tenantInMotionReporting?.journey.accounts || accountsTableSource.total,
              defaultPageSize: DEFAULT_PAGE_SIZE,
              showQuickJumper: false,
              hideOnSinglePage: true,
              simple: { readOnly: true },
            }}
            showSorterTooltip={false}
            onRow={(record) => {
              return {
                onClick: () => {
                  if (record.account) {
                    window.open(`/accounts/${record.account}`, '_blank', 'noopener, noreferrer')
                  }
                },
              }
            }}
          />
        )}
      </section>
    )
  },
)
AccountList.displayName = 'AccountList'

export default AccountList
