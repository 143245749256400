import dayjs from 'dayjs'

import { getElementSelectOptions } from 'components/MotionBuilder/Utils/serviceUtils'
import { getMultipleStatus } from 'components/MotionBuilder/Utils/useInputType'

import { type PayloadData, type ValueType } from 'models/motion/motionBuilder.model'

type Bracket = '(' | ')'
type Operator = 'AND' | 'OR'

interface FieldProps {
  field: string | undefined
  value: ValueType | null
  operator: string
  bracket?: undefined
  open?: undefined
}

interface BracketProp {
  bracket: Bracket
  open: boolean
  field?: undefined
  value?: undefined
  operator?: undefined
}

export type ParsedGroupsProps = FieldProps | BracketProp | Operator

const dateRegex = /\d{4}-\d{2}-\d{2}/g

export const getOperatorLabel = (operator: string, type: string | undefined) => {
  const options = getElementSelectOptions(type)
  const result = options?.filter((option) => option.value === operator)

  return result?.[0]?.label
}

export const displayPayloadGroupsInline = (payload: PayloadData) => {
  if (!payload?.groups?.length) return []
  const parsedGroups: ParsedGroupsProps[] = []

  payload.groups.forEach((group, groupIndex) => {
    if (payload.groups.length > 1) {
      parsedGroups.push({ bracket: '(', open: true })
    }

    group.groups.forEach((item, itemIndex) => {
      let value = item.value

      if (typeof item.value === 'string' && dateRegex.test(item.value)) {
        value = dayjs(new Date(item.value)).format('YYYY-MM-DD')
      }

      if (Array.isArray(item.value)) {
        const lastItem = item.value.length - 1
        const isMultipleInput = getMultipleStatus(item)
        value = ''
        item.value.forEach((element, index) => {
          if (typeof value === 'string') {
            if (typeof element === 'string' && typeof item.value === 'string' && dateRegex.test(item.value)) {
              const dateString = dayjs(new Date(element)).format('YYYY-MM-DD')
              value += dateString
            } else {
              value += element
            }
            if (index !== lastItem) {
              value += isMultipleInput ? ' or ' : ' & '
            }
          }
        })
      }

      parsedGroups.push({
        field: item.field,
        operator: getOperatorLabel(item.operator, item.type),
        value,
      })

      if (group.groups.length !== itemIndex + 1) parsedGroups.push('AND')
    })
    if (payload.groups.length > 1) {
      parsedGroups.push({ bracket: ')', open: false })
    }
    if (payload.groups.length !== groupIndex + 1) parsedGroups.push('OR')
  })

  return parsedGroups
}
