import { Modal } from 'antd'
import { observer } from 'mobx-react-lite'

import IconLoader from 'assets/icons/insightsDetails/loader.svg?react'
import { Button } from 'components/common'
import useStore from 'store/useStore'

const InsightsLoadingModal = observer(() => {
  const { insightsStore } = useStore()

  const { isRecalculating, setIsRecalculating } = insightsStore

  const handleCancel = () => {
    setIsRecalculating(false)
  }

  return (
    <Modal
      open={isRecalculating}
      footer={[<Button text='Cancel' type='secondary' onClickHandler={() => handleCancel} testId='cancel-btn' />]}
      centered
      maskClosable={true}
      title='Recalculating information with new target'
      onCancel={handleCancel}
      onOk={handleCancel}
      className='recalculate-modal'>
      <IconLoader className='loading-icon'></IconLoader>
    </Modal>
  )
})

InsightsLoadingModal.displayName = 'InsightsLoadingModal'

export default InsightsLoadingModal
