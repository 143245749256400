import { DatePicker, Input, InputNumber } from 'antd'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { isDayjsObject } from 'utils/type-helpers'

import { Button } from 'components/common'
import { DropdownArrowDown } from 'components/common/Icons/Icons'
import { MultipleSelect } from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput/MultipleSelect/MultipleSelect'
import MultiValueInput from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput/MultiValueInput'
import RichText from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput/RichText'
import { SingleSelect } from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput/SingleSelect/SingleSelect'
import useInputType from 'components/MotionBuilder/Utils/useInputType'
import EmailTemplateModal from 'pages/Motions/EmailTemplateModal'
import useStore from 'store/useStore'

import type { Dayjs } from 'dayjs'

import { FieldTypeEnum } from 'models/metadata.model'
import type { CreateActionFields, ExtendedSelectOptions, Item, SelectOptions } from 'models/motion/motionBuilder.model'
import { InputOperatorEnum } from 'models/motion/motionBuilder.model'
import { MotionStateEnum } from 'models/motion.model'
import type { CreateSlackMessagePayload } from 'models/slack-messages'

const { RangePicker } = DatePicker

const DATE_ONLY_FORMAT = 'YYYY-MM-DD'

interface FieldInputProps {
  item: CreateActionFields | Item
  range: boolean
  autoFocus?: boolean
  isAction?: boolean
  defaultOptions?: SelectOptions[] | ExtendedSelectOptions[]
  placeholder?: string
  disabled?: boolean
  payloadFields?: CreateActionFields[]
  setFocusOnInput?: (focus: string) => void
  changeFieldValue: (
    value: boolean | number | number[] | string | string[] | Dayjs | Dayjs[] | null,
    fieldKey: string | undefined,
    index?: number,
  ) => void
}

const FieldInput = observer(
  ({
    item,
    range,
    autoFocus,
    isAction,
    defaultOptions,
    placeholder,
    disabled,
    payloadFields,
    setFocusOnInput,
    changeFieldValue,
  }: FieldInputProps) => {
    const { emailTemplatesStore, motionStore } = useStore()
    const { getTemplate } = emailTemplatesStore
    const { currentMotion } = motionStore
    const [emailThumbnailUrl, setEmailThumbnailUrl] = useState('')
    const [showEmailModal, setShowEmailModal] = useState(false)
    const isSlackAction = item.platform === 'slack'

    useEffect(() => {
      const getEmailTemplateThumbnail = async () => {
        const templateId = String(payloadFields?.find((field) => field.key === 'templateId')?.value) || null
        const templateVersion = Number(payloadFields?.find((field) => field.key === 'templateVersion')?.value) || null

        if (!templateId || !templateVersion) return

        const existingTemplate = await getTemplate({
          magnifyTemplateId: templateId,
          magnifyVersion: templateVersion,
        })

        if (!existingTemplate || !existingTemplate.thumbnailUrl) return

        return existingTemplate?.thumbnailUrl
      }

      if (item.type?.toLowerCase() !== FieldTypeEnum.HTML || isSlackAction) return

      getEmailTemplateThumbnail()
        .then((emailThumbnailUrl) => {
          if (!emailThumbnailUrl) {
            setEmailThumbnailUrl('')
          } else {
            setEmailThumbnailUrl(emailThumbnailUrl)
          }
        })
        .catch(console.error)
    }, [payloadFields])

    const updateValue = (
      inputType: string,
      newVal: number | string | boolean | Dayjs | Dayjs[] | CreateSlackMessagePayload | null,
    ) => {
      switch (inputType) {
        case 'num': {
          if (typeof newVal !== 'number') {
            console.error(
              `Invalid type passed to updateValue with inputType of '${inputType}': expecting a number`,
              newVal,
            )
          }
          changeFieldValue(newVal as number, item?.key)
          break
        }

        case 'html':
        case 'select': {
          // Select input types can also have booleans as their options
          if (typeof newVal !== 'string' && typeof newVal !== 'boolean') {
            console.error(
              `Invalid type passed to updateValue with inputType of '${inputType}': expecting a string or boolean`,
              newVal,
            )
          }
          changeFieldValue(newVal as string | boolean, item?.key)
          break
        }

        case 'str': {
          if (typeof newVal !== 'string') {
            console.error(
              `Invalid type passed to updateValue with inputType of '${inputType}': expecting a string`,
              newVal,
            )
          }
          changeFieldValue(newVal as string, item?.key)
          break
        }

        case 'num_min': {
          if (typeof newVal !== 'string') {
            console.error(
              `Invalid type passed to updateValue with inputType of '${inputType}' expecting a string:`,
              newVal,
            )
          }
          changeFieldValue(newVal as string, item?.key, 0)
          break
        }

        case 'num_max': {
          if (typeof newVal !== 'string') {
            console.error(
              `Invalid type passed to updateValue with inputType of '${inputType}' expecting a string:`,
              newVal,
            )
          }
          changeFieldValue(newVal as string, item?.key, 1)
          break
        }

        case 'date': {
          // MAGPROD-538 - only pass date format downstream to orchestrator
          if (!isDayjsObject(newVal)) {
            console.error(
              `Invalid type passed to updateValue with inputType of '${inputType}' expecting a Dayjs:`,
              newVal,
            )
          }
          if (newVal) {
            changeFieldValue((newVal as Dayjs).format(DATE_ONLY_FORMAT), item.key)
          } else {
            changeFieldValue(null, item?.key)
          }
          break
        }

        case 'date_range': {
          // MAGPROD-538 - only pass date format downstream to orchestrator
          if (newVal) {
            if (!Array.isArray(newVal) || (Array.isArray(newVal) && !newVal.every(isDayjsObject))) {
              console.error(
                `Invalid type passed to updateValue with inputType of '${inputType}' expecting a Dayjs[]:`,
                newVal,
              )
            }
            changeFieldValue(
              (newVal as Dayjs[])?.map((x: Dayjs) => x.format(DATE_ONLY_FORMAT)),
              item.key,
            )
          } else {
            changeFieldValue(null, item?.key)
          }
          break
        }

        default:
          console.warn(`Unknown updateValue inputType '${inputType}' with value:`, newVal)
          break
      }
    }

    const { isMultipleInput, isNullishInput } = useInputType(item)

    const isSelectValue = (item: Item | CreateActionFields) => {
      const isSelectValue = [InputOperatorEnum.NotInclude, InputOperatorEnum.Include, InputOperatorEnum.Between].some(
        (operator) => (item as Item)?.operator === operator,
      )

      return isSelectValue || item.type === 'boolean'
    }

    useEffect(() => {
      if (isNullishInput) {
        return changeFieldValue(true, item?.key)
      }

      if (Array.isArray(item.value) && !isMultipleInput && !isSelectValue(item)) {
        return changeFieldValue('', item?.key)
      }

      if (!Array.isArray(item.value) && isMultipleInput && isSelectValue(item)) {
        return changeFieldValue([], item?.key)
      }

      if (typeof item.value === 'boolean' && !isNullishInput && !isSelectValue(item)) {
        return changeFieldValue('', item?.key)
      }
    }, [(item as Item).operator])

    const inputAttributes = {
      key: `${item.platform}-${item.object}-${item.operator}`,
      autoFocus: autoFocus,
      placeholder: placeholder,
      disabled: disabled || motionStore.isSegmentBuilderEditDisabled,
      onBlur: () => setFocusOnInput?.(''),
    }

    const selectInputAttributes = {
      key: `${item.platform}-${item.object}-${item.operator}`,
      defaultOpen: autoFocus,
      autoFocus: autoFocus,
      disabled: disabled || motionStore.isSegmentBuilderEditDisabled,
      onBlur: () => setFocusOnInput?.(''),
    }

    const multipleSelectAttributes = {
      key: `${item.platform}-${item.object}-${item.operator}`,
      autoFocus: autoFocus,
      defaultOptions: defaultOptions,
      disabled: disabled || motionStore.isSegmentBuilderEditDisabled,
      setFocusOnInput: setFocusOnInput,
      isAction: isAction,
    }

    switch (item.type?.toLowerCase()) {
      case FieldTypeEnum.Int:
      case FieldTypeEnum.Integer:
      case FieldTypeEnum.Number:
      case FieldTypeEnum.Currency:
      case FieldTypeEnum.Double:
      case FieldTypeEnum.Decimal:
      case FieldTypeEnum.Percent:
        if (!range && !isMultipleInput) {
          if (Array.isArray(item.value)) {
            item.value = item.value[0]
          }
          return (
            <InputNumber
              {...inputAttributes}
              data-testid='criteria-input-field-numeric'
              size='large'
              onChange={(e: number | null) => {
                updateValue('num', e)
              }}
              value={item.value as number}
              controls={{ upIcon: <DropdownArrowDown />, downIcon: <DropdownArrowDown /> }}
            />
          )
        }

        if (isMultipleInput) {
          return (
            <MultiValueInput
              autoFocus={autoFocus}
              item={item}
              changeFieldValue={changeFieldValue}
              setFocusOnInput={setFocusOnInput}
              dataType='number'
            />
          )
        } else {
          return (
            <>
              <InputNumber
                {...inputAttributes}
                key={`${inputAttributes.key}-min`}
                data-testid='criteria-input-field-numeric'
                size='large'
                className='range-number-input-min'
                placeholder='Minimum'
                onChange={(e: number | null) => {
                  updateValue('num_min', e)
                }}
                value={Array.isArray(item.value) ? (item.value[0] as number) : (item.value as number)}
                controls={{ upIcon: <DropdownArrowDown />, downIcon: <DropdownArrowDown /> }}
              />
              <InputNumber
                {...inputAttributes}
                key={`${inputAttributes.key}-max`}
                data-testid='criteria-input-field-numeric'
                size='large'
                className='site-input-right range-number-input-max'
                placeholder='Maximum'
                onChange={(e) => {
                  updateValue('num_max', e)
                }}
                value={Array.isArray(item.value) ? (item.value[1] as number) : (item.value as number)}
                controls={{ upIcon: <DropdownArrowDown />, downIcon: <DropdownArrowDown /> }}
              />
            </>
          )
        }

      case FieldTypeEnum.Url:
      case FieldTypeEnum.Id:
      case FieldTypeEnum.Textarea:
      case FieldTypeEnum.Address:
      case FieldTypeEnum.String:
      case FieldTypeEnum.Phone:
      case FieldTypeEnum.Reference:
      case FieldTypeEnum.Regexp:
      case FieldTypeEnum.Text:
      case FieldTypeEnum.Email:
        if (isMultipleInput) {
          return (
            <MultiValueInput
              autoFocus={autoFocus}
              item={item}
              changeFieldValue={changeFieldValue}
              setFocusOnInput={setFocusOnInput}
              dataType='string'
            />
          )
        }
        return (
          <Input
            {...inputAttributes}
            data-testid='criteria-input-field-text'
            size='large'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateValue('str', e.target.value)
            }}
            value={item.value as string}
          />
        )

      case FieldTypeEnum.HTML:
        if (isSlackAction) {
          const messageId = payloadFields?.find((field) => field.key === 'messageId')?.value
          const messageVersion = payloadFields?.find((field) => field.key === 'messageVersion')?.value

          return (
            <RichText
              item={item as CreateActionFields}
              updateValue={updateValue}
              messageInfo={{
                messageId: messageId && typeof messageId !== 'object' ? String(messageId) : null,
                messageVersion: messageVersion ? Number(messageVersion) : null,
              }}
            />
          )
        }

        return (
          <>
            {!!item.value && (
              <div>
                <span className='magnify-send-email-action-config-selected-template-text'>
                  Selected Email: {item.value}
                </span>
                {emailThumbnailUrl && (
                  <img
                    src={emailThumbnailUrl}
                    alt={`${item.value as string} thumbnail`}
                    title={`${item.value as string} thumbnail`}
                    className='thumbnail'
                    loading='lazy'
                    onError={() => setEmailThumbnailUrl('')}
                  />
                )}
              </div>
            )}
            {currentMotion?.currState !== MotionStateEnum.Executing && (
              <Button
                className='magnify-send-email-action-config-selected-template-button'
                text={!!item.value ? 'Change Email' : 'Choose Email'}
                size='full-width'
                onClickHandler={() => setShowEmailModal((val) => !val)}
                disabled={motionStore.isSegmentBuilderEditDisabled}
              />
            )}
            <EmailTemplateModal
              open={showEmailModal}
              onCancel={() => setShowEmailModal(false)}
              onConfirm={() => setShowEmailModal(false)}
              selectedTemplate={item.value as string}
              changeFieldValue={changeFieldValue}
              isInActionFlow
            />
          </>
        )

      case FieldTypeEnum.Payload:
        return (
          <Input.TextArea
            {...inputAttributes}
            data-testid='criteria-input-field-textarea'
            rows={4}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              updateValue('str', e.target.value)
            }}
            value={item.value as string}
          />
        )

      case FieldTypeEnum.Date:
      case FieldTypeEnum.Datetime:
      case FieldTypeEnum.Datepicker:
        // Specific use case for relative date calculation based on # of days
        if (item.operator === 'withinLast' || item.operator === 'withinNext') {
          return (
            <InputNumber
              {...inputAttributes}
              min={1}
              data-testid='criteria-input-field-numeric'
              size='large'
              onChange={(e: number | null) => {
                updateValue('num', e)
              }}
              value={item.value as number}
              controls={{ upIcon: <DropdownArrowDown />, downIcon: <DropdownArrowDown /> }}
            />
          )
        }
        if (isNullishInput) {
          return (
            <Input
              {...inputAttributes}
              data-testid='criteria-input-field-null'
              size='large'
              value={item.value as string}
            />
          )
        }

        const singleDate =
          item.value && !Array.isArray(item.value) && typeof item.value !== 'boolean'
            ? dayjs(item.value as string)
            : undefined
        const startDate = Array.isArray(item.value) && item.value[0] ? dayjs(String(item.value[0])) : null
        const endDate = Array.isArray(item.value) && item.value[1] ? dayjs(String(item.value[1])) : null

        if (!range) {
          return (
            <DatePicker
              {...selectInputAttributes}
              key={`${selectInputAttributes.key}-datepicker`}
              data-testid='criteria-input-field-datepicker'
              size='large'
              value={singleDate}
              onChange={(e) => {
                updateValue('date', e)
              }}
            />
          )
        } else {
          return (
            <RangePicker
              {...selectInputAttributes}
              key={`${selectInputAttributes.key}-datepicker-range`}
              data-testid='criteria-input-field-datepicker-range'
              size='large'
              value={[startDate, endDate]}
              onChange={(e) => {
                updateValue('date_range', e as Dayjs[])
              }}
            />
          )
        }

      case FieldTypeEnum.Picklist:
      case FieldTypeEnum.Collection:
        if (item.value === '') {
          item.value = []
        }
        return <MultipleSelect {...multipleSelectAttributes} item={item} updateValue={updateValue} />
      case FieldTypeEnum.Dropdown:
      case FieldTypeEnum.Select:
        return <SingleSelect {...multipleSelectAttributes} item={item} updateValue={updateValue} />
      case FieldTypeEnum.Boolean:
        const options: SelectOptions[] = [
          { label: 'True', value: true },
          { label: 'False', value: false },
        ]
        return (
          <SingleSelect {...multipleSelectAttributes} item={item} updateValue={updateValue} defaultOptions={options} />
        )

      default: {
        console.error('Unknown Field Type:', item.type)
        return <Input {...inputAttributes} size='large' placeholder='Unknown type' value={item.value as string} />
      }
    }
  },
)
FieldInput.displayName = 'FieldInput'

export default FieldInput
