import { Modal } from 'antd'

import { Button } from 'components/common'

interface EditSegmentModalProps {
  isModalOpen: boolean
  handleCancel: () => void
  handleClose: () => void
  handleSubmit: () => void
  cancelButtonText: string
  submitButtonText: string
  modalHeadingText: string
  modalBodyText: string
  isSubmitButtonDisabled?: boolean
}

const MotionAlertModal = ({
  isModalOpen,
  handleCancel,
  handleClose,
  handleSubmit,
  cancelButtonText,
  submitButtonText,
  modalHeadingText,
  modalBodyText,
  isSubmitButtonDisabled = false,
}: EditSegmentModalProps) => {
  return (
    <Modal
      title={modalHeadingText}
      open={isModalOpen}
      centered
      onCancel={handleClose}
      footer={[
        <Button
          key='cancel'
          text={cancelButtonText}
          type='secondary-danger'
          testId='cancel-btn'
          onClickHandler={handleCancel}
        />,
        <Button
          key='submit'
          text={submitButtonText}
          testId='confirm-btn'
          onClickHandler={handleSubmit}
          disabled={isSubmitButtonDisabled}
        />,
      ]}>
      <p role='alert' data-testid='modal-message'>
        {modalBodyText}
      </p>
    </Modal>
  )
}
MotionAlertModal.displayName = 'MotionAlertModal'

export default MotionAlertModal
