/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { CloseOutlined } from '@ant-design/icons'
import { Button as CloseButton, Col, Divider, Layout, Popover, Row, Table } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { Link } from 'react-router-dom'

import IconPerceptionUsers from 'assets/icons/perceptions/icon-users.svg?react'
import HeaderPremium from 'assets/icons/perceptions/tag-premium.svg'
import { Button, DynamicCell, Heading, Logo } from 'components/common'
import { PerceptionsIcon } from 'components/Navigation/NavigationIcons'
import MotionGoalsModal from 'pages/Perceptions/MotionGoalsModal'
import { sortAlphabetically, sorterNumbers } from 'services/Utils/Sorters'
import useStore from 'store/useStore'

import type { ColumnProps } from 'antd/lib/table/Column'
import type { ColumnsType, SortOrder } from 'antd/lib/table/interface'
import type { ScriptableScaleContext } from 'chart.js'
import type { Context } from 'chartjs-plugin-datalabels'
import type { Align, Anchor } from 'chartjs-plugin-datalabels/types/options'

import type { Impact, TableColumn } from 'models/insights'
import { DataFormatEnum, TableColumnTypeEnum } from 'models/insights'

import type { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'

/**
 * Generate Ant Design table columns from data.
 * @see https://ant.design/components/table/#column
 */
function getTableColumns(
  data: TableColumn[],
  impact: Impact,
  defaultSortKey: string = '',
  defaultSortDirection: SortOrder = 'descend',
): ColumnsType<any> {
  return data.map((column) => {
    const output: ColumnProps<any> = {
      title: column.title,
      dataIndex: column.key,
      key: column.key,
      render: (_: any, record) => (
        <div className='table__row'>
          <DynamicCell column={column} record={record} impact={impact} />
        </div>
      ),
      sorter: (a, b) => {
        switch (column.type) {
          case TableColumnTypeEnum.String:
          case TableColumnTypeEnum.Str:
          case TableColumnTypeEnum.Prediction:
            return sortAlphabetically(a[column.key]?.value as string, b[column.key]?.value as string)
          case TableColumnTypeEnum.Number:
          case TableColumnTypeEnum.Int:
          case TableColumnTypeEnum.Float:
          case TableColumnTypeEnum.Percent:
            return sorterNumbers(a[column.key]?.value as number, b[column.key]?.value as number)
          default:
            return sorterNumbers(a[column.key] as number, b[column.key] as number)
        }
      },
    }
    if (column.key === defaultSortKey) {
      output.defaultSortOrder = defaultSortDirection
    }
    return output
  })
}

const slackMessages = (name: string, account: string) => (
  <div className='slack-message-container'>
    <div className='slack-message-header'>
      <div className='name'>{name}</div>
      <div className='account'>{account}</div>
    </div>
    <div className='slack-messages'>
      <div className='slack-message'>
        <p>
          “As we continue to evaluate our expenses for the upcoming quarter, I wanted to reach out to discuss our
          current subscription plan. We've been really happy with the service provided,{' '}
          <span className='highlight'>but we're concerned about our budget constraints</span> moving forward. I'd
          appreciate your insights on how we can best align our <span className='highlight'>needs with our budget</span>
          .”
        </p>
        <div className='slack-message-footer'>
          <div className='slack-channel'>
            <Logo platformName='slack' bordered={false} /> #customer-openlane
          </div>
          <div className='date'>4/16/24</div>
        </div>
      </div>
      <div className='slack-message'>
        <p>
          “We're currently <span className='highlight'>reviewing our financial projections</span> for the next fiscal
          year and <span className='highlight'>looking closely</span> at our software expenses. Could you please provide
          a breakdown of our current subscription costs and{' '}
          <span className='highlight'>any potential savings or discounts</span> we might be eligible for?”
        </p>
        <div className='slack-message-footer'>
          <div className='slack-channel'>
            <Logo platformName='slack' bordered={false} /> #customer-openlane
          </div>
          <div className='date'>4/14/24</div>
        </div>
      </div>
      <div className='slack-message'>
        <p>
          “With our <span className='highlight'>upcoming budget meeting</span>, we're exploring options we've discussed
          earlier. Can we add more flexibility to the plans or features that could help us{' '}
          <span className='highlight'>manage costs</span> without compromising too much on functionality?”
        </p>
        <div className='slack-message-footer'>
          <div className='slack-channel'>
            <Logo platformName='slack' bordered={false} /> @{name}
          </div>
          <div className='date'>4/8/24</div>
        </div>
      </div>
      <div className='slack-message'>
        <p>
          “Can we schedule a meeting to{' '}
          <span className='highlight'>discuss potential adjustments to our subscription</span> plan or any available{' '}
          <span className='highlight'>discounts</span> that could help us better{' '}
          <span className='highlight'>manage our budget</span>?”
        </p>
        <div className='slack-message-footer'>
          <div className='slack-channel'>
            <Logo platformName='slack' bordered={false} /> @{name}
          </div>
          <div className='date'>4/8/24</div>
        </div>
      </div>
    </div>
  </div>
)

function generateData(numItems = 100) {
  const data = []
  const segments = ['high', 'medium', 'low']
  const companies = [
    'TechCorp',
    'DataSolutions',
    'InnovateTech',
    'SoftWorks',
    'CloudSystems',
    "Coconut's",
    'Crafts Canada',
    'Maxaprofit',
    'Stratagee',
    'Harvest Foods',
  ]
  const firstNames = [
    'Yumiko',
    'John',
    'Jane',
    'Michael',
    'Emily',
    'David',
    'Sarah',
    'Dương',
    'Ladislav',
    'Delmare',
    'Kian',
    'Rinkashizumoshikiku',
    'Nora',
  ]
  const lastNames = [
    'Kinjou',
    'Smith',
    'Johnson',
    'Williams',
    'Jones',
    'Brown',
    'Davis',
    'Quang',
    'Staněk',
    'Roux',
    'Avell',
    'Shikimirikashite',
    'Øvstedal',
  ]

  for (let i = 0; i < numItems; i++) {
    const segmentIndex = Math.floor(Math.random() * segments.length)
    const companyIndex = Math.floor(Math.random() * companies.length)
    const firstNameIndex = Math.floor(Math.random() * firstNames.length)
    const lastNameIndex = Math.floor(Math.random() * lastNames.length)

    const name = `${firstNames[firstNameIndex]} ${lastNames[lastNameIndex]}`
    const account = `${companies[companyIndex]}`
    const item = {
      account_id: `${name} ${account} ${i}`,
      segment: segments[segmentIndex],
      account_name: {
        value: account,
      },
      user: {
        value: name,
      },
      most_recent: {
        value: `4/${Math.floor(Math.random() * 31)}/24`,
      },
      messages: {
        value: (
          <Popover
            key='pop-10'
            trigger='click'
            placement='bottomLeft'
            overlayClassName='slack-message-popover'
            showArrow={true}
            content={slackMessages(name, account)}>
            <div className='messages-cell'>
              <Logo platformName='slack' /> {Math.floor(Math.random() * 4) + 1}
            </div>
          </Popover>
        ),
      },
      propensity_improve_from: {
        value: Math.random().toFixed(2),
        segment: '',
      },
      revenue_past: {
        value: Math.floor(Math.random() * 100000) + 50000,
      },
      revenue_forecast: {
        value: Math.floor(Math.random() * 100000) + 50000,
      },
    }
    data.push(item)
  }
  return data
}

const PerceptionDetails = () => {
  const { motionGoalsStore } = useStore()
  const { setMotionGoalsModal } = motionGoalsStore

  // Table
  const accountData = [
    {
      account_id: '00141000004ehJIAAY',
      segment: 'high',
      account_name: {
        value: 'Openlane',
      },
      user: {
        value: 'Sam Smith',
      },
      most_recent: {
        value: '4/16/24',
      },
      messages: {
        value: (
          <Popover
            key='pop-1'
            trigger='click'
            placement='bottomLeft'
            overlayClassName='slack-message-popover'
            showArrow={true}
            content={slackMessages('Sam Smith', 'Openlane')}>
            <div className='messages-cell'>
              <Logo platformName='slack' /> 4
            </div>
          </Popover>
        ),
      },
      propensity_improve_from: {
        value: 0.34,
        segment: '',
      },
      revenue_past: {
        value: 45900,
      },
      revenue_forecast: {
        value: 37900,
      },
    },
    {
      account_id: '0011M00002CrtUxQAJ',
      segment: 'high',
      account_name: {
        value: 'SamSbrough',
      },
      user: {
        value: 'Trevor Tate',
      },
      most_recent: {
        value: '4/1/24',
      },
      messages: {
        value: (
          <Popover
            key='pop-2'
            trigger='click'
            placement='bottomLeft'
            overlayClassName='slack-message-popover'
            showArrow={true}
            content={slackMessages('Trevor Tate', 'SamSbrough')}>
            <div className='messages-cell'>
              <Logo platformName='slack' /> 2
            </div>
          </Popover>
        ),
      },
      propensity_improve_from: {
        value: 0.24,
        segment: '',
      },
      revenue_past: {
        value: 43600,
      },
      revenue_forecast: {
        value: 43600,
      },
    },
    {
      account_id: '0018Y00002jdkjJQAQ',
      segment: 'high',
      account_name: {
        value: 'Goodsilron',
      },
      user: {
        value: 'Maggie Jones',
      },
      most_recent: {
        value: '3/28/24',
      },
      messages: {
        value: (
          <Popover
            key='pop-3'
            trigger='click'
            placement='bottomLeft'
            overlayClassName='slack-message-popover'
            showArrow={true}
            content={slackMessages('Maggie Jones', 'Goodsilron')}>
            <div className='messages-cell'>
              <Logo platformName='slack' /> 2
            </div>
          </Popover>
        ),
      },
      propensity_improve_from: {
        value: 0.16,
        segment: '',
      },
      revenue_past: {
        value: 42200,
      },
      revenue_forecast: {
        value: 45200,
      },
    },
    {
      account_id: '001G000001uH2bvIAC',
      segment: 'high',
      account_name: {
        value: 'Pandora Paint',
      },
      user: {
        value: 'Allie Packer',
      },
      most_recent: {
        value: '3/27/24',
      },
      messages: {
        value: (
          <Popover
            key='pop-4'
            trigger='click'
            placement='bottomLeft'
            overlayClassName='slack-message-popover'
            showArrow={true}
            content={slackMessages('Allie Packer', 'Pandora Paint')}>
            <div className='messages-cell'>
              <Logo platformName='slack' /> 2
            </div>
          </Popover>
        ),
      },
      propensity_improve_from: {
        value: 0.25,
        segment: '',
      },
      revenue_past: {
        value: 41600,
      },
      revenue_forecast: {
        value: 43200,
      },
    },
    {
      account_id: '00141000004ehJIAAYasdfadsf',
      segment: 'high',
      account_name: {
        value: 'Handy Andy',
      },
      user: {
        value: 'Nari Kaai',
      },
      most_recent: {
        value: '4/16/24',
      },
      messages: {
        value: (
          <Popover
            key='pop-5'
            trigger='click'
            placement='bottomLeft'
            overlayClassName='slack-message-popover'
            showArrow={true}
            content={slackMessages('Nari Kaai', 'Handy Andy')}>
            <div className='messages-cell'>
              <Logo platformName='slack' /> 4
            </div>
          </Popover>
        ),
      },
      propensity_improve_from: {
        value: 0.24,
        segment: '',
      },
      revenue_past: {
        value: 44800,
      },
      revenue_forecast: {
        value: 41800,
      },
    },
    {
      account_id: '0011M00002CrtUxQAJzxcvzcvx',
      segment: 'high',
      account_name: {
        value: 'Universo Realtors',
      },
      user: {
        value: 'Rieko Miyashita',
      },
      most_recent: {
        value: '4/1/24',
      },
      messages: {
        value: (
          <Popover
            key='pop-6'
            trigger='click'
            placement='bottomLeft'
            overlayClassName='slack-message-popover'
            showArrow={true}
            content={slackMessages('Rieko Miyashita', 'Universo Realtors')}>
            <div className='messages-cell'>
              <Logo platformName='slack' /> 2
            </div>
          </Popover>
        ),
      },
      propensity_improve_from: {
        value: 0.44,
        segment: '',
      },
      revenue_past: {
        value: 23600,
      },
      revenue_forecast: {
        value: 23600,
      },
    },
    {
      account_id: '0018Y00002jdkjJQAQqewrqr',
      segment: 'high',
      account_name: {
        value: 'McDade',
      },
      user: {
        value: 'Wen Lin',
      },
      most_recent: {
        value: '3/28/24',
      },
      messages: {
        value: (
          <Popover
            key='pop-7'
            trigger='click'
            placement='bottomLeft'
            overlayClassName='slack-message-popover'
            showArrow={true}
            content={slackMessages('Wen Lin', 'McDade')}>
            <div className='messages-cell'>
              <Logo platformName='slack' /> 2
            </div>
          </Popover>
        ),
      },
      propensity_improve_from: {
        value: 0.26,
        segment: '',
      },
      revenue_past: {
        value: 52200,
      },
      revenue_forecast: {
        value: 55200,
      },
    },
    {
      account_id: '001G000001uH2bvIAChfgdhgs',
      segment: 'high',
      account_name: {
        value: 'Dave Cooks',
      },
      user: {
        value: 'Xia Chiu',
      },
      most_recent: {
        value: '3/27/24',
      },
      messages: {
        value: (
          <Popover
            key='pop-8'
            trigger='click'
            placement='bottomLeft'
            overlayClassName='slack-message-popover'
            showArrow={true}
            content={slackMessages('Xia Chiu', 'Dave Cooks')}>
            <div className='messages-cell'>
              <Logo platformName='slack' /> 2
            </div>
          </Popover>
        ),
      },
      propensity_improve_from: {
        value: 0.25,
        segment: '',
      },
      revenue_past: {
        value: 41600,
      },
      revenue_forecast: {
        value: 43200,
      },
    },
    {
      account_id: '0018Y00002jdkjJQAQcvbncnb',
      segment: 'high',
      account_name: {
        value: 'Franklin Music',
      },
      user: {
        value: 'Freya Patel',
      },
      most_recent: {
        value: '3/28/24',
      },
      messages: {
        value: (
          <Popover
            key='pop-9'
            trigger='click'
            placement='bottomLeft'
            overlayClassName='slack-message-popover'
            showArrow={true}
            content={slackMessages('Freya Patel', 'Franklin Music')}>
            <div className='messages-cell'>
              <Logo platformName='slack' /> 2
            </div>
          </Popover>
        ),
      },
      propensity_improve_from: {
        value: 0.23,
        segment: '',
      },
      revenue_past: {
        value: 32500,
      },
      revenue_forecast: {
        value: 34200,
      },
    },
    {
      account_id: '001G000001uH2bvIACyhsrtg',
      segment: 'high',
      account_name: {
        value: 'Parklane Hosiery',
      },
      user: {
        value: 'Bùi Kim Chín',
      },
      most_recent: {
        value: '3/27/24',
      },
      messages: {
        value: (
          <Popover
            key='pop-10'
            trigger='click'
            placement='bottomLeft'
            overlayClassName='slack-message-popover'
            showArrow={true}
            content={slackMessages('Bùi Kim Chín', 'Parklane Hosiery')}>
            <div className='messages-cell'>
              <Logo platformName='slack' /> 2
            </div>
          </Popover>
        ),
      },
      propensity_improve_from: {
        value: 0.31,
        segment: '',
      },
      revenue_past: {
        value: 44300,
      },
      revenue_forecast: {
        value: 39100,
      },
    },
    ...generateData(118),
  ]
  const accountsColumns = getTableColumns(
    [
      {
        key: 'user',
        title: 'User',
        type: 'string',
        format: DataFormatEnum.Text,
        nullable: false,
      },
      {
        key: 'account_name',
        title: 'Account',
        type: 'string',
        format: DataFormatEnum.Text,
        nullable: false,
      },
      {
        key: 'messages',
        title: 'Messages',
        type: 'string',
        format: DataFormatEnum.Text,
        decimal: 1,
        nullable: false,
      },
      {
        key: 'most_recent',
        title: 'Most Recent',
        type: 'string',
        format: DataFormatEnum.Text,
        decimal: 2,
        nullable: false,
      },
      {
        key: 'propensity_improve_from',
        title: 'Churn Risk %',
        type: 'number',
        format: DataFormatEnum.Percentage,
        decimal: 0,
        nullable: false,
      },
      {
        key: 'revenue_past',
        title: 'Current ARR',
        type: 'number',
        format: DataFormatEnum.CurrencyKMB,
        decimal: 1,
        nullable: false,
      },
      {
        key: 'revenue_forecast',
        title: 'Forecasted ARR',
        type: 'number',
        format: DataFormatEnum.CurrencyKMB,
        decimal: 1,
        nullable: false,
      },
    ],
    'negative' as Impact,
    'revenue_elift_improve_by',
    'descend',
  )
  const rowSelection = {
    // Autoselect all the data items
    selectedRowKeys: accountData.map(({ account_id }) => account_id),
    onSelectAll: (selected: boolean, selectedRows: any, changeRows: any) => {
      return []
    },
    onSelect: (record: any) => {},
  }

  // Chart
  const chartRef: React.ForwardedRef<ChartJSOrUndefined<'bar'>> = useRef(null)
  const [chartData, _setChartData] = useState<any>({
    labels: ['Apr 13', 'Apr 20', 'Apr 23', 'May 1', 'May 8', 'May 15'],
    datasets: [
      {
        label: 'New Users',
        data: [15, 14, 12, 8, 3, 5],
        // borderColor: '#EB2058',
        backgroundColor: '#EB2058',
        barThickness: 36,
      },
      {
        label: 'Departed Users',
        data: [-3, -5, -4, -7, -6, -8],
        // borderColor: '#2E4EEE',
        backgroundColor: '#2E4EEE',
        barThickness: 36,
      },
    ],
  })
  const [chartOptions, _setChartOptions] = useState({
    plugins: {
      tableUnderChart: {
        display: false,
      },
      datalabels: {
        borderWidth: 0.5,
        color: 'white',
        anchor: 'center' as Anchor,
        align: 'center' as Align,
        offset: 0,
        formatter: (value: string, context: Context) => value,
        font: {
          size: 12,
        },
      },
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    layout: {
      padding: {
        bottom: 0,
        right: 0,
        top: 0,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        alignToPixels: true,
        grid: {
          display: false,
        },
        stacked: true,
        ticks: {
          autoSkip: false,
          color: '#717171',
        },
        offset: true, // This adds padding at the end of the axis
      },
      y: {
        alignToPixels: true,
        // This allows for space so the bars do not overlap the Forecast flag
        grace: 0,
        grid: {
          color: (ctx: ScriptableScaleContext) => (ctx.tick.value === 0 ? '#E6E6E6' : 'transparent'),
          lineWidth: 2,
        },
        stacked: false,
        ticks: {
          color: '#717171',
        },
        title: {
          color: '#717171',
          display: true,
          text: 'Users',
        },
      },
    },
  })

  return (
    <main className='bg-secondary ant-layout-has-sider perception-details' data-testid='perception-details-container'>
      <Layout className='container'>
        <Content className='content'>
          <section className='perception-details-heading-container' data-testid='perception-details-heading-container'>
            <div className='perception-details-banner' data-testid='perception-details-banner'>
              <div className='superhead'>
                <h4 className='heading' data-testid='perception-details-banner-heading'>
                  Customer Warning #1
                  <img src={HeaderPremium} alt='Premium' height='25' className='premium-tag' />
                </h4>
                <CloseButton shape='circle' data-testid='perception-details-banner-close-button'>
                  <Link to='/perceptions' title='Back to Perceptions'>
                    <CloseOutlined />
                  </Link>
                </CloseButton>
              </div>
              <div className='detail' data-testid='perception-details-banner-driver-detail'>
                <div className='heading'>
                  <p>
                    <span>Budget Concerns</span>
                  </p>
                  <Logo platformName='slack' />
                  <Logo platformName='zendesk' />
                  <Logo platformName='email' />
                </div>
              </div>

              <Divider />
            </div>
          </section>
          <section className='perception-details-impact-container' data-testid='perception-details-impact-container'>
            <div className='perception-details-impact' data-testid='perception-details-impact'>
              <div className='section'>
                <Row className='header bigger-icon'>
                  <IconPerceptionUsers />
                  <h3 className='header-text'>Users</h3>
                </Row>
                <Row className='body' gutter={[25, 10]}>
                  <Col className='item'>
                    <div className='item-value highlight'>128</div>
                    <div className='item-description'>Total</div>
                  </Col>
                  <Col className='item'>
                    <div className='item-value highlight green'>-3</div>
                    <div className='item-description'>WoW Change</div>
                  </Col>
                </Row>
              </div>
              <div className='section divider'></div>
              <div className='section'>
                <Row className='header blue-fill'>
                  <PerceptionsIcon />
                  <h3 className='header-text'>Perceptions</h3>
                </Row>
                <Row className='body' gutter={[25, 10]}>
                  <Col className='item'>
                    <div className='item-value highlight'>24</div>
                    <div className='item-description'>This Week</div>
                  </Col>
                  <Col className='item'>
                    <div className='item-value highlight'>287</div>
                    <div className='item-description'>Past 3 months</div>
                  </Col>
                  <Col className='item'>
                    <div className='item-value highlight'>1.5K</div>
                    <div className='item-description'>All-Time</div>
                  </Col>
                </Row>
              </div>
              <div className='section divider'></div>
              <div className='section'>
                <Row className='header'>
                  <h3 className='header-text'>Accounts</h3>
                </Row>
                <Row className='body' gutter={[25, 10]}>
                  <Col className='item'>
                    <div className='item-value highlight'>$383K</div>
                    <div className='item-description'>Total ARR</div>
                  </Col>
                  <Col className='item'>
                    <div className='item-value highlight red'>24%</div>
                    <div className='item-description'>Average Churn Risk</div>
                  </Col>
                </Row>
              </div>
            </div>
          </section>
          <section className='perception-details-chart-container' data-testid='perception-details-chart-container'>
            <header>
              <Heading variant='1' level='1'>
                Perception Details
              </Heading>
            </header>
            <Row justify='center' align='middle' className='perception-details-chart-content'>
              <div className='chart-data-col'>
                <div className='chart-cta'>
                  <div className='chart-cta-top'>
                    <div className='chart-cta-top-left'>
                      <div className='chart-top-text'>Channels</div>
                    </div>
                    <div className='chart-cta-top-right'>
                      <div className='chart-top-text'>Edit</div>
                    </div>
                  </div>
                  <div className='chart-top-icons'>
                    <Logo platformName='slack' />
                    <Logo platformName='zendesk' />
                    <Logo platformName='email' />
                  </div>
                  <div className='chart-cta-sub-title'>Definition</div>
                  <div className='chart-cta-sub-body'>
                    <p>
                      Includes users whose last 2+ mentions of budget in the last 90 days had negative or uncertain
                      sentiment.
                    </p>
                  </div>
                  <div className='chart-legend'>
                    <div className='chart-legend-item'>
                      <div className='chart-legend-left'>
                        <div className='chart-legend-color' style={{ backgroundColor: '#EB2058' }} />
                        <div className='chart-legend-text'>New Users</div>
                      </div>
                      <div className='chart-legend-value'>+5</div>
                    </div>
                    <div className='chart-legend-item'>
                      <div className='chart-legend-left'>
                        <div className='chart-legend-color' style={{ backgroundColor: '#4477FA' }} />
                        <div className='chart-legend-text'>Departed Users</div>
                      </div>
                      <div className='chart-legend-value'>-8</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='chart-chart-col'>
                <h2>Budget Concerns: Change in Users</h2>
                <div className='chart-container'>
                  <Bar data-testid='canvas' ref={chartRef} data={chartData} options={chartOptions} />
                </div>
              </div>
            </Row>
          </section>
          <section className='perception-details-users-container' data-testid='perceptions-accounts-container'>
            <Heading level='3' variant='3' className='heading'>
              Users in Segment
            </Heading>
            <Table
              rowKey={'account_id'}
              bordered={false}
              dataSource={accountData}
              columns={accountsColumns}
              pagination={{ hideOnSinglePage: true }}
              showSorterTooltip={false}
              rowSelection={{ ...rowSelection }}
              className='perception-details-users-table'
              data-testid='segment-account-table'
              scroll={{ x: true }}
            />
            <div className='perception-details-footer' data-testid='perception-details-footer'>
              <div className='selection-total'>
                <div className='selection-users' data-testid='selection-users'>
                  <span className='selected' data-testid='selected-users'>
                    128
                  </span>
                  <span> of </span>
                  <span className='total' data-testid='total-users'>
                    128
                  </span>
                  <span className='label'> Users selected</span>
                </div>
              </div>
              <Button text='Create Motion' onClickHandler={() => setMotionGoalsModal({ isOpen: true })}></Button>
            </div>
          </section>
        </Content>
        <MotionGoalsModal />
      </Layout>
    </main>
  )
}
PerceptionDetails.displayName = 'PerceptionDetails'

export default observer(PerceptionDetails)
