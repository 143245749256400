export interface CoreAPIErrorResponse {
  detail: string
  instance: string
  message: string
  status: number
  type: string
}

class CoreApiError extends Error {
  /**
   * Detailed error metadata from the server.
   */
  detail: string
  /**
   * URL path that triggered the error.
   */
  instance: string
  /**
   * Error message from the server.
   */
  message: string
  /**
   * HTTP status code.
   */
  status: number
  /**
   * Error grouping type from the server.
   */
  type: string
  /**
   * Used to group items in Datadog.
   * @see {@link https://docs.datadoghq.com/error_tracking/custom_grouping/?tab=browser#rum|Datadog - Error Tracking - Custom Grouping}
   */
  dd_fingerprint: string

  constructor(coreApiResponse: CoreAPIErrorResponse) {
    super(coreApiResponse.message)
    this.name = this.constructor.name

    this.detail = coreApiResponse.detail
    this.instance = coreApiResponse.instance
    this.message = coreApiResponse.message
    this.status = coreApiResponse.status
    this.type = coreApiResponse.type
    this.dd_fingerprint = `${coreApiResponse.status}-${coreApiResponse.instance}`
  }
}

export { CoreApiError }
