import { Alert, Form, Input, Modal } from 'antd'
import { useState } from 'react'

import { Button } from 'components/common'
import { TEST_EMAIL_FROM_ADDRESS, triggerSendTestEmail } from 'components/MotionBuilder/Utils/segmentBuilder.utils'

import type { ErrorMesage } from 'models'

interface SendTestEmailModalProps {
  isModalOpen: boolean
  setIsModalOpen: (isOpen: boolean) => void
  htmlContent: string
  templateName: string
}

const SendTestEmailModal = ({ isModalOpen, setIsModalOpen, htmlContent, templateName }: SendTestEmailModalProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ErrorMesage | null>(null)

  const handleOk = () => {
    setLoading(true)
    form
      .validateFields()
      .then(async (values: { to: string; subject: string }) => {
        await triggerSendTestEmail(values.to, values.subject, htmlContent)
        setLoading(false)
        form.resetFields()
        setIsModalOpen(false)
      })
      .catch(() => {
        setError({
          message: 'There was a problem sending this test email. Please try again later.',
          name: 'error',
          code: 'error',
        })
        setLoading(false)
      })
  }

  const handleCancel = () => {
    form.resetFields()
    setError(null)
    setIsModalOpen(false)
  }

  return (
    <Modal
      title='Send test email'
      centered
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key='send-test-email-go-back-btn' text='Cancel' type='secondary' onClickHandler={handleCancel} />,
        <Button key='send-test-email-submit-btn' text='Send Test Email' onClickHandler={handleOk} disabled={loading} />,
      ]}>
      <p>
        Send a test version of your email to yourself or another email address. Test emails are sent from{' '}
        <span className='modal__text--bold'>{TEST_EMAIL_FROM_ADDRESS}.</span>
      </p>
      <p>
        This email will be sent with template: <span className='modal__text--bold'>{templateName}</span>.
      </p>

      <Form
        className='form'
        form={form}
        name='send-test-email-form'
        layout='vertical'
        initialValues={{ from: TEST_EMAIL_FROM_ADDRESS }}>
        <Form.Item
          name='to'
          label='To'
          rules={[
            { required: true, message: '"To" is required' },
            { pattern: /^\S+@\S+\.\S+$/, message: 'Please enter a valid email address' },
          ]}>
          <Input placeholder='Enter recipient email address' />
        </Form.Item>
        <Form.Item name='from' label='From'>
          <Input disabled />
        </Form.Item>
        <Form.Item
          name='subject'
          label='Subject Line'
          rules={[{ required: true, message: '"Subject Line" is required' }]}>
          <Input placeholder='Enter email subject' />
        </Form.Item>
      </Form>
      {error && <Alert message={error.message} type='error' />}
    </Modal>
  )
}
SendTestEmailModal.displayName = 'SendTestEmailModal'

export default SendTestEmailModal
