import { Skeleton } from 'antd'
import Sider from 'antd/lib/layout/Sider'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo } from 'react'

import { Heading } from 'components/common'
import { IconSearch } from 'components/common/Icons/Icons'
import { ActionSource } from 'components/MotionBuilder/MotionSidebar/ActionSource/ActionSource'
import { SidebarSearch } from 'components/MotionBuilder/MotionSidebar/SidebarSearch/SidebarSearch'
import { filterActions } from 'components/MotionBuilder/Utils/filterUtils'
import { useDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import useStore from 'store/useStore'

import type { Action, Platform } from 'models/motion/motionBuilder.model'

export const MotionSidebar = observer(() => {
  const { actionsStore } = useStore()
  const { isLoading, filteredToolboxItems, fetchActions, setFilteredToolboxItems, toolboxItems } = actionsStore

  useEffect(() => {
    if (!filteredToolboxItems.actions?.length) {
      fetchActions().catch(console.error)
    }
  }, [])

  useDisplayErrorNotification(actionsStore)

  const renderElements = useMemo(() => {
    return Object.entries(filteredToolboxItems).map(([category, categorySources]: [string, Platform[]]) =>
      categorySources?.length > 0 ? (
        <div key={category} className={`action-group ${category}`}>
          <Heading level='5' variant='5'>
            {category === 'inactiveActions' ? 'INACTIVE ACTIONS' : category.toUpperCase()}
          </Heading>
          {isLoading && !filteredToolboxItems.actions?.length && ['actions', 'inactiveActions'].includes(category) ? (
            Array(3)
              .fill(null)
              .map((_, index) => (
                <div key={index} className='skeleton-container'>
                  <Skeleton.Input active block />
                </div>
              ))
          ) : (
            <ActionSource
              category={category}
              categorySources={categorySources as Action[] | Platform[]}
              toolboxItems={filteredToolboxItems}
            />
          )}
        </div>
      ) : null,
    )
  }, [filteredToolboxItems, isLoading])

  const searchFilterHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value?.length <= 1) {
        setFilteredToolboxItems(toolboxItems)
        return
      }
      setFilteredToolboxItems(filterActions(toolboxItems, event.target.value))
    },
    [setFilteredToolboxItems, toolboxItems],
  )

  return (
    <Sider className='c-sider motion-sidebar' width={265} data-testid='motion-sidebar'>
      <span className='c-sider__header' data-testid='motion-header'>
        Motion Builder
      </span>
      <SidebarSearch prefix={<IconSearch />} onChangeHandler={searchFilterHandler} />
      {renderElements}
    </Sider>
  )
})
MotionSidebar.displayName = 'MotionSidebar'
