import { Modal } from 'antd'

import { Button } from 'components/common'

interface ISegmentExportModal {
  isVisable: boolean
  handleCancelModal: () => void
  handleSegmentExport: () => void
  isLoading?: boolean
}

export const SegmentExportModal = ({
  isVisable,
  handleCancelModal,
  handleSegmentExport,
  isLoading,
}: ISegmentExportModal) => {
  return (
    <Modal
      title='Export segment data to CSV'
      open={isVisable}
      onCancel={handleCancelModal}
      centered
      footer={[
        <Button key='cancel' text='Cancel' type='secondary' testId='cancel-btn' onClickHandler={handleCancelModal} />,
        <Button
          key='submit'
          text='Export'
          testId='export-btn'
          onClickHandler={handleSegmentExport}
          loading={isLoading}
        />,
      ]}>
      <p className='' data-testid='modal-message'>
        You are about to export the data of this segment to a CSV. Are you sure you want to continue?
      </p>
    </Modal>
  )
}
SegmentExportModal.displayName = 'SegmentExportModal'
