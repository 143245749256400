import { Alert, Form, Input, message, Modal, Spin } from 'antd'
import { fetchUserAttributes, getCurrentUser, setUpTOTP, updateMFAPreference, verifyTOTPSetup } from 'aws-amplify/auth'
import { observer } from 'mobx-react-lite'
import { QRCodeSVG } from 'qrcode.react'
import { useEffect, useState } from 'react'

import { Button } from 'components/common'
import useStore from 'store/useStore'

import type { AuthUser, FetchUserAttributesOutput } from 'aws-amplify/auth'

export interface MFAModalProps {
  isModalOpen: boolean
  handleModalClose: () => void
}

export interface CurrentAuthUser extends AuthUser {
  attributes: FetchUserAttributesOutput
}

const INVALID_MFA_CODE = 'The one-time passcode is incorrect, please try again.'
const SUCCESSFUL_SETUP = 'Successfully configured MFA'

const buildOTPURI = (currentUser: CurrentAuthUser | null, challengeSecret: string) =>
  `otpauth://totp/${currentUser?.attributes?.email}?secret=${challengeSecret}&issuer=Magnify`

const MFAModal = observer(({ isModalOpen, handleModalClose }: MFAModalProps) => {
  const { userStore } = useStore()
  const { get, username } = userStore

  const [currentUser, setCurrentUser] = useState<CurrentAuthUser | null>(null)
  const [challengeSecret, setChallengeSecret] = useState<string | null>(null)
  const [showDetails, setShowDetails] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const [form] = Form.useForm()

  /**
   * This method consumes the form values and performs the validation (i.e. ensures that mfaCode is populated)
   * It will then interface with Cognito and verify the TOTP token before setting it as the verified method for the user in Cognito
   */
  const confirmTOTPSetup = () => {
    form
      .validateFields()
      .then(async (values: { mfaCode: number }) => {
        try {
          const { mfaCode }: { mfaCode: number } = values
          setLoading(true)

          try {
            await verifyTOTPSetup({ code: mfaCode.toString() })
            await updateMFAPreference({ totp: 'PREFERRED' })
          } catch (error: unknown) {
            // Token is not verified
            void message.error(INVALID_MFA_CODE, 4)
            setError({ message: INVALID_MFA_CODE } as Error)

            setLoading(false)
            return
          }

          setError(null)
          form.resetFields()

          // Refresh the user profile to ensure that MFA was configured as expected server-side
          await get(username!)

          void message.success(SUCCESSFUL_SETUP, 4)
          setLoading(false)
          handleModalClose()
        } catch (error: unknown) {
          if (error instanceof Error) {
            void message.error(error.message, 4)
            setError(error)
          }

          setLoading(false)
        }
      })
      .catch(() => {
        setError({ message: 'The form is invalid!', name: 'error' })
      })
  }

  const setupTOTPAuth = async () => {
    try {
      const { sharedSecret } = await setUpTOTP()
      setChallengeSecret(sharedSecret)
    } catch (error: unknown) {
      if (error instanceof Error) {
        void message.error(error.message, 4)
        setError(error)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (currentUser && !challengeSecret) {
      setLoading(true)
      setupTOTPAuth().catch(console.error)
    }
  }, [currentUser, challengeSecret])

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user: AuthUser = await getCurrentUser()
      const attributes: FetchUserAttributesOutput = await fetchUserAttributes()
      // Interestingly, doing this spread and adding a key inside the setter causes a TypeScript error
      const output: CurrentAuthUser = { ...user, attributes }
      setCurrentUser(output)
    }

    if (!currentUser) {
      fetchCurrentUser().catch(console.error)
    }
  }, [currentUser])

  return (
    <Modal
      title='Setup MFA'
      open={isModalOpen}
      onCancel={handleModalClose}
      centered
      footer={[
        <Button key='cancel' text='Cancel' htmlType='button' type='secondary' onClickHandler={handleModalClose} />,
        <Button key='submit' text='Save' htmlType='submit' onClickHandler={confirmTOTPSetup} />,
      ]}>
      {loading && (
        <div data-testid='mfa-modal-spinner' className='mfa-modal-spinner-container'>
          <Spin />
        </div>
      )}
      {challengeSecret && !loading && (
        <div>
          <p data-testid='mfa-modal-paragraph'>
            To complete the setup for time-based one time passcode MFA, scan the following QR code with your preferred
            authenticator app:
          </p>

          <div>
            <div className='mfa-modal-qr-container'>
              <QRCodeSVG value={buildOTPURI(currentUser, challengeSecret)} />
            </div>
            <div className='mfa-modal-details-container'>
              <Alert
                style={{
                  marginBottom: 0,
                  borderRadius: showDetails ? '8px 8px 0px 0px' : '8px',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                message={`Details - click to ${showDetails ? 'hide' : 'show'}`}
                type='info'
                showIcon
                onClick={() => setShowDetails(!showDetails)}
              />
              {showDetails && (
                <div className='mfa-modal-extended-details-container'>
                  <div>
                    If you are unable to scan the QR code above, please enter the following secret configuration value
                    into your authenticator application:
                  </div>
                  <pre className='mfa-modal-extended-details-code'>
                    <code>{challengeSecret}</code>
                  </pre>
                </div>
              )}
            </div>
            <div>Once you've configured the application, enter the authentication code below to confirm the setup:</div>
            <Form
              className='mfa-modal-form-container'
              form={form}
              name='setup_mfa_form'
              labelCol={{
                span: 10,
              }}
              autoComplete='off'>
              <Form.Item
                label='MFA code'
                name='mfaCode'
                rules={[
                  {
                    required: true,
                    message: 'Please input the generated MFA code on your device',
                  },
                  {
                    pattern: /^\d*$/,
                    message: 'MFA code must be a numeric value',
                  },
                ]}
                colon={false}>
                <Input className='mfa-modal-form-input' autoComplete='off' />
              </Form.Item>
              {error && <Alert message={error.message} type='error' />}
            </Form>
          </div>
        </div>
      )}
    </Modal>
  )
})

export default MFAModal
