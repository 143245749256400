import { makeAutoObservable, observable, runInAction } from 'mobx'

import { API } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import type { ChildStore } from 'store/StoreTypes'

import type { AdminTenant } from 'models/admin'

export class AdminStore implements ChildStore {
  loading = {
    isTenantsLoading: false,
  }
  data = {
    tenants: [] as AdminTenant[],
  }

  apiError: CoreAPIErrorResponse | null = null

  constructor() {
    makeAutoObservable(this, {
      loading: observable,
    })
  }

  reset = () => {
    this.loading = {
      isTenantsLoading: false,
    }
    this.data = {
      tenants: [] as AdminTenant[],
    }
    this.apiError = null
  }

  /**
   * Fetch Tenants.
   * Currently unused as it will cause an unsafe HTTPS -> HTTP error.
   */
  getTenants = async () => {
    this.loading.isTenantsLoading = true
    try {
      const { tenants } = await API.admin.getTenants()
      runInAction(() => {
        this.data.tenants = tenants
      })
    } catch (error: unknown) {
      runInAction(() => {
        this.setApiError(error as CoreAPIErrorResponse)
      })
    } finally {
      runInAction(() => {
        this.loading.isTenantsLoading = false
      })
    }
  }

  setApiError = (error: CoreAPIErrorResponse | null) => {
    runInAction(() => {
      this.apiError = error
    })
  }
}
