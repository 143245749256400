import { CaretDownFilled } from '@ant-design/icons'
import { notification, Form, Modal, Row, Col, Select, Input } from 'antd'
import { observer } from 'mobx-react-lite'
import React from 'react'

import Button from 'components/common/Button'
import { useDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import useStore from 'store/useStore'

import type { SupportFormValues } from 'models/support.model'

interface Props {
  description?: string
}

const SupportModal = ({ description }: Props) => {
  const [form] = Form.useForm()

  const { helpCenterStore } = useStore()
  useDisplayErrorNotification(helpCenterStore)

  const { supportModal, onChangeField, currentSupportTicket, post } = helpCenterStore

  /** Once the form is validated we send the payload to the /support/request API. */
  const onFinish = async (values: SupportFormValues) => {
    try {
      await post(values)

      // Close the modal and reset the form.
      form.resetFields()
      helpCenterStore.reset()
      notification.success({
        message: 'Support request sent, someone will get back to you as soon as possible',
        duration: 5,
      })
    } catch (error: unknown) {
      // Errors are caught & displayed by the store.
    }
  }

  return (
    <Modal
      className='support-modal'
      title='Submit Support Request'
      centered
      open={supportModal.isOpen}
      keyboard
      maskClosable={false}
      onOk={form.submit}
      onCancel={() => helpCenterStore.setSupportModal({ isOpen: false })}
      data-testid='support-modal'
      footer={[
        <React.Fragment key='modal__footer'>
          <Button
            text='Cancel'
            type='secondary'
            size='XL'
            onClickHandler={() => {
              form.resetFields()
              helpCenterStore.setSupportModal({ isOpen: false })
            }}
            testId='cancel-btn'
          />
          <Button
            text='Submit'
            size='XL'
            className='m-l-10'
            htmlType='submit'
            testId='submit-btn'
            onClickHandler={() => {
              form.submit()
            }}
          />
        </React.Fragment>,
      ]}>
      <Form data-testid='support-modal-body' form={form} onFinish={onFinish}>
        {description && <p data-testid='support-description'>{description}</p>}
        <div className='support-modal__inputs'>
          <Row>
            <Form.Item
              data-testid='support-modal__inputs--email'
              label='Email Address'
              name='email'
              className='required-item full-width'
              rules={[
                {
                  message: 'Please provide a valid email address',
                  required: true,
                  type: 'email',
                  validateTrigger: 'onSubmit',
                  whitespace: true,
                },
              ]}>
              <Input
                data-testid='email-input'
                value={currentSupportTicket.email}
                name='email'
                type='email'
                placeholder='Email'
                onChange={(e) => onChangeField('email', e.target.value)}
              />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item
              data-testid='support-modal__inputs--name'
              label='Name'
              name='name'
              className='required-item full-width'
              rules={[
                {
                  message: 'Please provide your name',
                  required: true,
                  validateTrigger: 'onSubmit',
                  whitespace: true,
                },
              ]}>
              <Input
                data-testid='name-input'
                value={currentSupportTicket.name}
                name='name'
                type='text'
                placeholder='Name'
                onChange={(e) => onChangeField('name', e.target.value)}
              />
            </Form.Item>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                data-testid='support-modal__inputs--topic'
                label='Help needed with'
                name='topic'
                className='required-item'
                rules={[
                  {
                    message: 'Please select what you need help with',
                    required: true,
                    validateTrigger: 'onSubmit',
                  },
                ]}>
                <Select
                  data-testid='topic-input'
                  suffixIcon={<CaretDownFilled />}
                  labelInValue={false}
                  placeholder={'Select an option...'}
                  className='support-request-topic'
                  onChange={(value: string) => onChangeField('topic', value)}>
                  <Select.Option value={'Dashboard'}>Dashboard</Select.Option>
                  <Select.Option value={'Motions'}>Motions</Select.Option>
                  <Select.Option value={'Integrations Management'}>Integrations Management</Select.Option>
                  <Select.Option value={'User Management'}>User Management</Select.Option>
                  <Select.Option value={'Account Profile & Passwords'}>Account Profile & Passwords</Select.Option>
                  <Select.Option value={'Other'}>Other</Select.Option>
                  <Select.Option value={'Product Request'}>Product Request</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                data-testid='support-modal__inputs--severity'
                label='Severity'
                name='severity'
                className='required-item'
                rules={[
                  {
                    message: 'Please select the severity of your issue',
                    required: true,
                    validateTrigger: 'onSubmit',
                  },
                ]}>
                <Select
                  data-testid='severity-input'
                  suffixIcon={<CaretDownFilled />}
                  labelInValue={false}
                  placeholder={'Select an option...'}
                  className='support-request-severity'
                  onChange={(value: string) => onChangeField('severity', value)}>
                  <Select.Option value={'Urgent'}>Urgent - major feature or system down; security issue</Select.Option>
                  <Select.Option value={'High'}>High - critical function issue</Select.Option>
                  <Select.Option value={'Medium'}>Medium - minor functional issue</Select.Option>
                  <Select.Option value={'Low'}>Low - cosmetic issue</Select.Option>
                  <Select.Option value={'General'}>General - product questions or feature request</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item
              data-testid='support-modal__inputs--subject'
              label='Subject'
              name='subject'
              className='required-item full-width'
              rules={[
                {
                  message: 'Please provide a subject for your issue',
                  required: true,
                  validateTrigger: 'onSubmit',
                  whitespace: true,
                },
              ]}>
              <Input
                data-testid='subject-input'
                value={currentSupportTicket.subject}
                name='subject'
                type='text'
                placeholder='Subject'
                onChange={(e) => onChangeField('subject', e.target.value)}
              />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item
              data-testid='support-modal__inputs--description'
              label='Description'
              name='description'
              className='required-item full-width'
              rules={[
                {
                  message: 'Please provide a description of the issue',
                  required: true,
                  validateTrigger: 'onSubmit',
                  whitespace: true,
                },
              ]}>
              <Input.TextArea
                data-testid='description-input'
                value={currentSupportTicket.subject}
                name='description'
                rows={4}
                placeholder='Please describe your issue'
                onChange={(e) => onChangeField('description', e.target.value)}
              />
            </Form.Item>
          </Row>
        </div>
      </Form>
    </Modal>
  )
}
SupportModal.displayName = 'SupportModal'

export default observer(SupportModal)
