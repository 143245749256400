import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined'
import Select from 'antd/lib/select'
import { type SelectProps } from 'antd/lib/select'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import useStore from 'store/useStore'

import type { Dayjs } from 'dayjs'

import { InputOperatorEnum, type CreateActionFields, type Item } from 'models/motion/motionBuilder.model'

enum DataTypeEnum {
  String = 'string',
  Number = 'number',
}

interface MultiValueInputProps {
  changeFieldValue: (
    value: boolean | number | number[] | string | string[] | Dayjs | Dayjs[] | null,
    fieldKey?: string,
    index?: number,
  ) => void
  setFocusOnInput?: (focus: string) => void
  autoFocus?: boolean
  item: Item | CreateActionFields
  dataType: `${DataTypeEnum}`
}

const MultiValueInput = observer(
  ({ item, autoFocus, dataType, changeFieldValue, setFocusOnInput }: MultiValueInputProps) => {
    const { motionStore } = useStore()
    const [isOpen, setIsOpen] = useState(false)

    const fieldValues = item?.value || []
    const options: SelectProps<string>['options'] = []
    if (Array.isArray(fieldValues)) {
      for (const value of fieldValues) {
        options.push({
          label: String(value),
          value: String(value),
        })
      }
    }

    function handleInputChange(values: string | number | number[] | boolean | string[] | Dayjs | Dayjs[] | null) {
      const newValues = []
      for (const value of values as string[]) {
        const number = Number.parseInt(value)
        if (!Number.isNaN(number)) {
          newValues.push(number)
        }
      }
      return newValues
    }

    /**
     * We want to enforce some sanitisation whenever the user copy/pastes CSV into the input field
     * If the value is comma-separated, we are going to trim any whitespace and remove any empty values to prevent mistypes/formatting from the end user
     */
    const handleCsvInputChange = (values: string[]) => {
      const newValues = []
      for (const value of values) {
        if (value.includes(',')) {
          const splitValues = value
            .split(',')
            .map((splitValue: string) => splitValue.trim())
            .filter((strippedValue: string) => strippedValue !== '')

          newValues.push(...splitValues)
        } else {
          newValues.push(value)
        }
      }
      return newValues
    }

    return (
      <Select
        disabled={motionStore.isSegmentBuilderEditDisabled}
        className='criteria-input_select_right multi-value'
        popupClassName='multi-value-popup'
        data-testid='multi-value'
        maxTagCount={'responsive'}
        size='large'
        mode='tags'
        open={isOpen}
        defaultOpen={autoFocus}
        autoFocus={autoFocus}
        value={options}
        options={options}
        defaultValue={options}
        menuItemSelectedIcon={<CloseOutlined />}
        notFoundContent={<></>}
        onBlur={() => setFocusOnInput?.('')}
        onChange={(values) => {
          // These values come in as string[], but AntD types are weird and expect an DefaultOptionType[].
          // Changing the type of values changes the expected types for options and defaultValue above as well which breaks.
          switch (dataType) {
            case DataTypeEnum.Number:
              changeFieldValue(handleInputChange(values as unknown as string[]), item?.key)
              break
            case DataTypeEnum.String:
              if (
                item.operator === InputOperatorEnum.ContainsOneOf ||
                item.operator === InputOperatorEnum.NotContainsAnyOf
              ) {
                changeFieldValue(handleCsvInputChange(values as unknown as string[]), item?.key)
                break
              } else {
                changeFieldValue(values as unknown as string[], item?.key)
                break
              }
            default:
              console.error(`Unknown MultiValueInput dataType ${dataType}`)
          }
        }}
        onInputKeyDown={(event) => {
          if (event.key === 'Enter') {
            const value = (event.target as HTMLTextAreaElement).value
            const isInOptions = options.map((option) => option.value).includes(value)
            if (isInOptions) {
              event.preventDefault()
              event.stopPropagation()
            } else {
              setIsOpen(false)
            }
          }
        }}
        onDropdownVisibleChange={(open) => {
          setIsOpen(open)
        }}
      />
    )
  },
)
MultiValueInput.displayName = 'MultiValueInput'

export default MultiValueInput
