import { Popover } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import { Button } from 'components/common'
import { IconFlag } from 'components/common/Icons/Icons'
import useStore from 'store/useStore'

const motionGoalsPopoverUtils = {
  message:
    "You haven't set the goal of this Motion, click here to set your goal and metrics to track your Motion performance.",
  buttonText: 'OK, I got it',
}

const MotionGoalsPopover = observer(() => {
  const { motionGoalsStore, motionStore } = useStore()
  const {
    motionGoal,
    motionMetrics,
    motionGoalsModal,
    motionGoalsPopover,
    setMotionGoalsModal,
    setMotionGoalsVisibility,
  } = motionGoalsStore
  const isVisible = motionGoalsPopover.isVisible && !motionGoalsPopover.forceHidden && !motionStore.isLoading

  useEffect(() => {
    if (
      motionGoal &&
      !Object.keys(motionGoal).length &&
      (!motionMetrics?.length || !motionMetrics?.[0]?.field) &&
      !motionGoalsModal.isEditing &&
      !motionGoalsPopover.forceHidden
    ) {
      setMotionGoalsVisibility({ isVisible: true })
    } else {
      setMotionGoalsVisibility({ isVisible: false })
    }
  }, [motionGoal, motionMetrics, motionGoalsModal.isEditing])

  return (
    <Popover
      open={isVisible}
      placement='leftBottom'
      overlayClassName='motion-goals-popover'
      arrow={true}
      content={
        <>
          <span>{motionGoalsPopoverUtils.message}</span>
          <Button
            testId='motion-goal-popover-btn'
            text={motionGoalsPopoverUtils.buttonText}
            type='secondary'
            size='L'
            onClickHandler={() => setMotionGoalsVisibility({ isVisible: false })}
          />
        </>
      }>
      <Button
        text=''
        type='secondary'
        size='M'
        className='motion-goals-btn'
        testId='goals-btn'
        icon={{ element: <IconFlag /> }}
        onClickHandler={() => setMotionGoalsModal({ isOpen: true, isEditing: true })}
      />
    </Popover>
  )
})

export default MotionGoalsPopover
