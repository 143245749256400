import { Menu } from 'antd'
import classNames from 'classnames'
import { useMemo, type DragEvent } from 'react'

import { IconArrowTop } from 'components/common/Icons/Icons'
import { useDraggable } from 'hooks/useDraggable'
import { BuilderIcon } from 'services/Utils/BuilderIcon'
import { LogoService } from 'services/Utils/logo'
import useStore from 'store/useStore'

import type { SubMenuProps } from 'antd'

import type { Action, Platform, ToolboxItems } from 'models/motion/motionBuilder.model'

interface ActionSourceProps {
  category: string
  categorySources: Action[] | Platform[]
  toolboxItems: ToolboxItems
}

export const ActionSource = ({ category, categorySources, toolboxItems }: ActionSourceProps) => {
  const { motionStore, mergePanelStore } = useStore()
  const { setDraggable } = useDraggable()

  const expandMenuIcon = (props: SubMenuProps) => {
    const noOfChildren = (props.children as Array<Element>).length
    return <span className='submenu-icon'>{noOfChildren}</span>
  }

  const onDragStart = (event: DragEvent, nodeType: string, action: Action) => {
    if (mergePanelStore.isSelectingTarget) {
      // don't do anything if the user wants to drag/drop while is selecting merge target
      return
    }
    motionStore.setDisplayEdgeDrops(true)
    motionStore.setDraggedToolBoxElement(action)
    event.currentTarget.className += ' c-disabled'
    event?.dataTransfer.setData('application/reactflow', nodeType)
    event.dataTransfer.setData('application/json', JSON.stringify(action))
    event.dataTransfer.effectAllowed = 'copy'
    setDraggable(true)
  }

  const onDragEnd = (event: DragEvent) => {
    motionStore.setDisplayEdgeDrops(false)
    event.currentTarget.className = event.currentTarget.className.replaceAll(' c-disabled', '')
    setDraggable(false)
  }

  // We pull from the toolboxItems.inactiveActions if the category is inactiveActions
  const items = category === 'inactiveActions' ? toolboxItems.inactiveActions : toolboxItems.actions
  const menuItems = useMemo(
    () =>
      items?.map((item) => ({
        label: item.name,
        icon: (
          <>
            <img src={`${LogoService.getIcon(item.name)}`} alt={item.name} title={item.name} width='24' height='24' />
            <span className='submenu-icon icon-reverse'>
              <IconArrowTop />
            </span>
          </>
        ),
        key: item.name,
        children:
          item.actions?.map((action) => ({
            label: action.name,
            title: action.name,
            key: `${action.actionId}`,
            icon: (
              <div
                key={action.actionId}
                className={classNames([`c-targeting c-targeting--${action.shape || 'square'}`])}>
                <BuilderIcon name={`${action.iconName}`} options={{ width: 22, height: 22 }} />
              </div>
            ),
            'data-testid': 'submenu-platform',
            draggable: category === 'inactiveActions' ? false : true,
            onDragStart: (event: DragEvent) => onDragStart(event, 'segment', action),
            onDragEnd,
            className: category === 'inactiveActions' ? 'inactive' : 'active',
          })) ?? [],
      })),
    [items],
  )

  const categorySourcesItems = useMemo(
    () =>
      (categorySources as Action[]).map((source) => (
        <div
          className='action-source'
          data-testid={`action-source-${source.type}`}
          key={source.name}
          draggable
          onDragStart={(event: DragEvent) => onDragStart(event, 'segment', source)}
          onDragEnd={(event: DragEvent) => onDragEnd(event)}>
          <div className='action-icon' data-testid='action-icon'>
            <div
              className={classNames([`c-targeting c-targeting--${source.shape || 'square'}`])}
              data-testid='icon-container'>
              <BuilderIcon name={`${source.type}`} options={{ width: 22, height: 22 }} />
            </div>
          </div>
          <div className='source-name' data-testid='source-name'>
            <span>{source.name}</span>
          </div>
        </div>
      )),
    [categorySources],
  )

  // If there are no items, we don't want to render the menu
  if (menuItems?.length === 0) {
    return null
  }

  return (
    <>
      {category && !['actions', 'inactiveActions'].includes(category) ? (
        categorySourcesItems
      ) : (
        <Menu
          style={{ width: '100%' }}
          inlineIndent={0}
          expandIcon={expandMenuIcon}
          mode='inline'
          selectable={false}
          data-testid='action-menu'
          items={menuItems}
        />
      )}
    </>
  )
}
ActionSource.displayName = 'ActionSource'
