/**
 * Get the filtered and sorted forecast
 * @param {string} sortKey The key to sort by
 * @param {string} sortDirection The direction to sort by
 * @param {string} revenuePeriod The revenue period to filter by
 * @param {number} page The page number to paginate by
 * @param {number} pageSize The number of results to return
 * @returns {AccountForecast} The filtered and sorted forecast
 */
export const getFilteredAndSortedForecast = ({
  sortKey,
  sortDirection,
  revenuePeriod,
  page,
  pageSize,
}: {
  sortKey: string
  sortDirection: string
  revenuePeriod?: string
  page: number
  pageSize: number
}) => {
  const { results } = demoGetAccountForecast

  // Filter by revenuePeriod
  let filteredResults = results
  if (revenuePeriod) {
    filteredResults = results.filter((item) => item.revenuePeriod === revenuePeriod)
  }

  // Sort by the specified key and direction
  const sortedResults = filteredResults.sort((a, b) => {
    let aValue = a[sortKey as keyof typeof a]
    let bValue = b[sortKey as keyof typeof b]

    // Handle different data types
    if (sortKey === 'accountName' || sortKey === 'contractEndDate') {
      // Sort alphabetically for accountName
      aValue = String(aValue ?? '').toLowerCase()
      bValue = String(bValue ?? '').toLowerCase()

      if (sortDirection === 'asc') {
        return aValue.localeCompare(bValue)
      } else {
        return bValue.localeCompare(aValue)
      }
    }

    // Convert other numeric strings to numbers
    aValue = Number(aValue)
    bValue = Number(bValue)

    if (sortDirection === 'asc') {
      return aValue > bValue ? 1 : -1
    } else {
      return aValue < bValue ? 1 : -1
    }
  })

  // Apply pagination
  const startIndex = (page - 1) * pageSize
  const paginatedResults = sortedResults.slice(startIndex, startIndex + pageSize)

  return { ...demoGetAccountForecast, results: paginatedResults }
}

export const getDriverSegmentAccounts = ({ dimension, target }: { dimension: string; target: string }) => {
  if (dimension === 'overall') {
    if (target === 'churn') {
      return demoGetInsightDetailsAccountsOverallChurn
    }
    if (target === 'expansion') {
      return demoGetInsightDetailsAccountsOverallExpansion
    }
    if (target === 'conversion') {
      return demoGetInsightDetailsAccountsOverallConversion
    }
  }
  return demoGetInsightDetailsAccountsOverallChurn
}

export const getDriverSegment = ({ dimension, target }: { dimension: string; target: string }) => {
  if (dimension === 'overall') {
    if (target === 'churn') {
      return demoGetInsightDetailsSegmentOverallChurn
    }
    if (target === 'expansion') {
      return demoGetInsightDetailsSegmentOverallExpansion
    }
    if (target === 'conversion') {
      return demoGetInsightDetailsSegmentOverallConversion
    }
  }
  return demoGetInsightDetailsSegmentOverallChurn
}

export const getAllDemoInsights = ({ dimension }: { dimension: string }) => {
  if (dimension === 'overall') {
    return demoGetAllInsightsOverall
  } else if (dimension === 'enterprise') {
    return demoGetAllInsightsEnterprise
  } else if (dimension === 'SMB/Scale') {
    return demoGetAllInsightsSmbScale
  }
}

/**
 * Used to easily update the date range on Account pages for the demos.
 * See the chart on the demo page: /accounts/0011U00001gUmjFQAS
 */
export const revenueForcastingQuerters: string[] = ["Q1'24", "Q2'24", "Q3'24", "Q4'24", "Q1'25", "Q2'25"]

export const demoGetParticipatedMotionsInsights = [
  {
    'accountId#tenantId': '1#1',
    journeyId: 'a71299a6-5862-47eb-8201-7ec1060c7309',
    journeyVersion: '11',
    journeyName: 'Onboarding Motion',
    participatedSince: '2023-10-15',
    participationStatus: 'DRAFT',
    firstExecutionId: '1',
    latestExecutionId: '11',
  },
  {
    'accountId#tenantId': '2#1',
    journeyId: '7d32b75d-58cb-44ad-a3fc-1b25d5331c16',
    journeyVersion: '1',
    journeyName: 'Join service group email campaign',
    participatedSince: '2023-10-17',
    participationStatus: 'DRAFT',
    firstExecutionId: '1',
    latestExecutionId: '1',
  },
  {
    'accountId#tenantId': '3#1',
    journeyId: '45091118-1292-48f8-b2e1-8912b6825855',
    journeyVersion: '1',
    journeyName: 'Complete onboarding modules',
    participatedSince: '2023-10-02',
    participationStatus: 'SUCCEEDED',
    firstExecutionId: '1',
    latestExecutionId: '1',
  },
]

export const demoGetDimensions = {
  overall: {
    alias: 'Overall',
  },
  enterprise: {
    alias: 'Overall',
  },
  'SMB/Scale': {
    alias: 'Overall',
  },
}

export const demoGetAllInsightsOverall = {
  grid: 'account',
  dimension: 'overall',
  train_datetime: '2024-04-16T19-24-41Z',
  infer_datetime: '2024-04-16T20-43-40Z',
  dataset_date: '2024-04-07',
  is_revenue_quantified: true,
  events: {
    churn: {
      full_name: ['churn', 'churn in next 180 days'],
      impact: 'negative',
      drivers: [
        {
          driver_id: '2-001',
          driver_type: 'insight',
          feature:
            'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1',
          family: {
            platform: 'snowflake',
            object: 'Product Telemetry',
            field: 'Minutes on [Feature A]',
            std_platform: 'pendo',
            std_object: 'feature_telemetry',
            std_field: 'numminutes@name[ar-invoices-table-followup-activity-email-views]',
          },
          segments: {
            high: {
              account_count: 4012,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Minutes on [Feature A]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numminutes@name[ar-invoices-table-followup-activity-email-views]',
              },
              metrics: {
                '30-day average': {
                  improve_from: 166.06499999999997,
                  improve_to: 284.17619047619036,
                },
              },
              propensity: {
                improve_from: 0.3230527205390279,
                improve_from_text: '32%',
                improve_to: 0.3219645206605563,
                improve_to_text: '32%',
                improve_by: -0.0010881998784715685,
              },
              revenue: {
                past: 193853322.8416443,
                elift_improve_from: 40927378.06415201,
                elift_improve_to: 40778779.26520206,
                elift_improve_by: 3681519.66,
              },
            },
            low: {
              account_count: 4277,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Minutes on [Feature A]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numminutes@name[ar-invoices-table-followup-activity-email-views]',
              },
              metrics: {
                '30-day average': {
                  improve_from: 284.17619047619036,
                  improve_to: 299.57619047619,
                },
              },
              propensity: {
                improve_from: 0.3095759864811509,
                improve_from_text: '31%',
                improve_to: 0.3092825838688459,
                improve_to_text: '31%',
                improve_by: -0.00029340261230495886,
              },
              revenue: {
                past: 211878248.02150726,
                elift_improve_from: 43858903.960561536,
                elift_improve_to: 43824923.65844307,
                elift_improve_by: 4525592.6,
              },
            },
            medium: {
              account_count: 5711,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Minutes on [Feature A]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numminutes@name[ar-invoices-table-followup-activity-email-views]',
              },
              metrics: {
                '30-day average': {
                  improve_from: 1.0701754385964912,
                  improve_to: 284.17619047619036,
                },
              },
              propensity: {
                improve_from: 0.32771773234673357,
                improve_from_text: '33%',
                improve_to: 0.3270290011091582,
                improve_to_text: '33%',
                improve_by: -0.0006887312375752781,
              },
              revenue: {
                past: 235492731.58578873,
                elift_improve_from: 47643495.85442367,
                elift_improve_to: 47540269.98886779,
                elift_improve_by: 4335736.14,
              },
            },
          },
        },
        {
          driver_id: '2-002',
          driver_type: 'insight',
          feature: 'pendo:reports:daysactive:value:p0d1',
          family: {
            platform: 'snowflake',
            object: 'Customer health',
            field: 'Seat usage',
            std_platform: 'pendo',
            std_object: 'reports',
            std_field: 'daysactive',
          },
          segments: {
            high: {
              account_count: 4743,
              family: {
                platform: 'snowflake',
                object: 'Customer health',
                field: 'Seat usage',
                std_platform: 'pendo',
                std_object: 'reports',
                std_field: 'daysactive',
              },
              metrics: {
                '90-day average': {
                  improve_from: 13.148936170212766,
                  improve_to: 27.962265014648438,
                },
              },
              propensity: {
                improve_from: 0.3318444978413888,
                improve_from_text: '33%',
                improve_to: 0.32932915311142263,
                improve_to_text: '33%',
                improve_by: -0.0025153447299660825,
              },
              revenue: {
                past: 131946618.39337349,
                elift_improve_from: 20323483.223288193,
                elift_improve_to: 20181038.918003432,
                elift_improve_by: 4278251.35,
              },
            },
            low: {
              account_count: 5389,
              family: {
                platform: 'snowflake',
                object: 'Customer health',
                field: 'Seat usage',
                std_platform: 'pendo',
                std_object: 'reports',
                std_field: 'daysactive',
              },
              metrics: {
                '90-day average': {
                  improve_from: 27.962264150943398,
                  improve_to: 27.962265014648438,
                },
              },
              propensity: {
                improve_from: 0.31404006326138667,
                improve_from_text: '31%',
                improve_to: 0.31394195481611836,
                improve_to_text: '31%',
                improve_by: -0.00009810844526824083,
              },
              revenue: {
                past: 337786702.5627136,
                elift_improve_from: 77551124.62957919,
                elift_improve_to: 77533139.3162019,
                elift_improve_by: 3182892.1,
              },
            },
            medium: {
              account_count: 5903,
              family: {
                platform: 'snowflake',
                object: 'Customer health',
                field: 'Seat usage',
                std_platform: 'pendo',
                std_object: 'reports',
                std_field: 'daysactive',
              },
              metrics: {
                '90-day average': {
                  improve_from: 22.54237288135593,
                  improve_to: 27.962265014648438,
                },
              },
              propensity: {
                improve_from: 0.3251050921765595,
                improve_from_text: '33%',
                improve_to: 0.32419280070835205,
                improve_to_text: '32%',
                improve_by: -0.0009122914682075077,
              },
              revenue: {
                past: 244706913.16051483,
                elift_improve_from: 48315603.20439018,
                elift_improve_to: 48174140.59917852,
                elift_improve_by: 3709670.82,
              },
            },
          },
        },
        {
          driver_id: '2-003',
          driver_type: 'insight',
          feature: 'pendo:reports:usagetrending:value:p0d1',
          family: {
            platform: 'intercom',
            object: 'Conversations',
            field: 'Number of messages',
            std_platform: 'pendo',
            std_object: 'reports',
            std_field: 'usagetrending',
          },
          segments: {
            high: {
              account_count: 4775,
              family: {
                platform: 'intercom',
                object: 'Conversations',
                field: 'Number of messages',
                std_platform: 'pendo',
                std_object: 'reports',
                std_field: 'usagetrending',
              },
              metrics: {
                '30-day average': {
                  improve_from: 43.95744680851064,
                  improve_to: 286.9591979980469,
                },
              },
              propensity: {
                improve_from: 0.3298322463899867,
                improve_from_text: '33%',
                improve_to: 0.32844067707229185,
                improve_to_text: '33%',
                improve_by: -0.0013915693176947998,
              },
              revenue: {
                past: 173240831.043005,
                elift_improve_from: 32572711.988458443,
                elift_improve_to: 32434546.497420665,
                elift_improve_by: 4363367.3,
              },
            },
            low: {
              account_count: 4976,
              family: {
                platform: 'intercom',
                object: 'Conversations',
                field: 'Number of messages',
                std_platform: 'pendo',
                std_object: 'reports',
                std_field: 'usagetrending',
              },
              metrics: {
                '30-day average': {
                  improve_from: 286.9591836734694,
                  improve_to: 286.9591979980469,
                },
              },
              propensity: {
                improve_from: 0.32212746419789884,
                improve_from_text: '32%',
                improve_to: 0.3211511864923017,
                improve_to_text: '32%',
                improve_by: -0.0009762777055971413,
              },
              revenue: {
                past: 229591619.10095215,
                elift_improve_from: 47509888.57612113,
                elift_improve_to: 47351717.09065279,
                elift_improve_by: 3156137.8,
              },
            },
            medium: {
              account_count: 6184,
              family: {
                platform: 'intercom',
                object: 'Conversations',
                field: 'Number of messages',
                std_platform: 'pendo',
                std_object: 'reports',
                std_field: 'usagetrending',
              },
              metrics: {
                '30-day average': {
                  improve_from: 7,
                  improve_to: 286.9591979980469,
                },
              },
              propensity: {
                improve_from: 0.32122342024183625,
                improve_from_text: '32%',
                improve_to: 0.32000415715650105,
                improve_to_text: '32%',
                improve_by: -0.0012192630853352102,
              },
              revenue: {
                past: 307334192.8273201,
                elift_improve_from: 65734517.1505183,
                elift_improve_to: 65480663.99670518,
                elift_improve_by: 3187505.75,
              },
            },
          },
        },
        {
          driver_id: '2-011',
          driver_type: 'insight',
          feature: 'chorusai',
          family: {
            platform: 'chorusai',
            object: 'Chorus.ai = [COMPETITOR]',
            field: 'Engagement_tracker_match name',
            std_platform: 'chorusai',
            std_object: 'chorusai',
            std_field: 'chorusai',
          },
          segments: {
            high: {
              account_count: 4322,
              family: {
                platform: 'chorusai',
                object: 'Chorus.ai = [COMPETITOR]',
                field: 'Engagement_tracker_match name',
                std_platform: 'chorusai',
                std_object: 'chorusai',
                std_field: 'chorusai',
              },
              metrics: {
                '30-day average': {
                  improve_from: 1,
                  improve_to: 0,
                },
              },
              propensity: {
                improve_from: 0.3230527205390279,
                improve_from_text: '32%',
                improve_to: 0.3219645206605563,
                improve_to_text: '32%',
                improve_by: -0.0010881998784715685,
              },
              revenue: {
                past: 193853322.8416443,
                elift_improve_from: 40927378.06415201,
                elift_improve_to: 40778779.26520206,
                elift_improve_by: 3681519.66,
              },
            },
            low: {
              account_count: 4011,
              family: {
                platform: 'chorusai',
                object: 'Chorus.ai = [COMPETITOR]',
                field: 'Engagement_tracker_match name',
                std_platform: 'chorusai',
                std_object: 'chorusai',
                std_field: 'chorusai',
              },
              metrics: {
                '30-day average': {
                  improve_from: 1,
                  improve_to: 0,
                },
              },
              propensity: {
                improve_from: 0.3095759864811509,
                improve_from_text: '31%',
                improve_to: 0.3092825838688459,
                improve_to_text: '31%',
                improve_by: -0.00029340261230495886,
              },
              revenue: {
                past: 211878248.02150726,
                elift_improve_from: 43858903.960561536,
                elift_improve_to: 43824923.65844307,
                elift_improve_by: 4525592.6,
              },
            },
            medium: {
              account_count: 5680,
              family: {
                platform: 'chorusai',
                object: 'Chorus.ai = [COMPETITOR]',
                field: 'Engagement_tracker_match name',
                std_platform: 'chorusai',
                std_object: 'chorusai',
                std_field: 'chorusai',
              },
              metrics: {
                '30-day average': {
                  improve_from: 1,
                  improve_to: 0,
                },
              },
              propensity: {
                improve_from: 0.32771773234673357,
                improve_from_text: '33%',
                improve_to: 0.3270290011091582,
                improve_to_text: '33%',
                improve_by: -0.0006887312375752781,
              },
              revenue: {
                past: 235492731.58578873,
                elift_improve_from: 47643495.85442367,
                elift_improve_to: 47540269.98886779,
                elift_improve_by: 4335736.14,
              },
            },
          },
        },
        {
          driver_id: '2-004',
          driver_type: 'insight',
          feature: 'pendo:feature_telemetry:_row@name[dashboard-workspace-menu]:count|lift:p180d1',
          family: {
            platform: 'pendo',
            object: 'Guide',
            field: 'Number of [Guides shown]',
            std_platform: 'pendo',
            std_object: 'feature_telemetry',
            std_field: '_row@name[dashboard-workspace-menu]',
          },
          segments: {
            high: {
              account_count: 3697,
              family: {
                platform: 'pendo',
                object: 'Guide',
                field: 'Number of [Guides shown]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[dashboard-workspace-menu]',
              },
              metrics: {
                '180-day total': {
                  improve_from: 21.128205128205128,
                  improve_to: 43.659090909090786,
                },
              },
              propensity: {
                improve_from: 0.32077392822753503,
                improve_from_text: '32%',
                improve_to: 0.31985411299862354,
                improve_to_text: '32%',
                improve_by: -0.0009198152289114942,
              },
              revenue: {
                past: 196231291.89004898,
                elift_improve_from: 41807573.67557411,
                elift_improve_to: 41675844.18440581,
                elift_improve_by: 3498216.9,
              },
            },
            medium: {
              account_count: 5887,
              family: {
                platform: 'pendo',
                object: 'Guide',
                field: 'Number of [Guides shown]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[dashboard-workspace-menu]',
              },
              metrics: {
                '180-day total': {
                  improve_from: 0.896551724137931,
                  improve_to: 43.659090909090786,
                },
              },
              propensity: {
                improve_from: 0.32996774387704325,
                improve_from_text: '33%',
                improve_to: 0.3293481929201555,
                improve_to_text: '33%',
                improve_by: -0.0006195509568876739,
              },
              revenue: {
                past: 221672186.70816422,
                elift_improve_from: 42572525.5653025,
                elift_improve_to: 42495200.34114196,
                elift_improve_by: 3064092.93,
              },
            },
            low: {
              account_count: 4478,
              family: {
                platform: 'pendo',
                object: 'Guide',
                field: 'Number of [Guides shown]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[dashboard-workspace-menu]',
              },
              metrics: {
                '180-day total': {
                  improve_from: 43.659090909090786,
                  improve_to: 43.659090909090786,
                },
              },
              propensity: {
                improve_from: 0.308251372952682,
                improve_from_text: '31%',
                improve_to: 0.30810479276121494,
                improve_to_text: '31%',
                improve_by: -0.00014658019146714864,
              },
              revenue: {
                past: 234714482.32355118,
                elift_improve_from: 49812948.777919464,
                elift_improve_to: 49800765.05343902,
                elift_improve_by: 4376712.62,
              },
            },
          },
        },
        {
          driver_id: '2-005',
          driver_type: 'insight',
          feature: 'pendo:feature_telemetry:_row@name[Tesorio Logo]:count|lift:p90d1',
          family: {
            platform: 'gainsight',
            object: 'Playbook',
            field: 'Active plays',
            std_platform: 'pendo',
            std_object: 'feature_telemetry',
            std_field: '_row@name[Tesorio Logo]',
          },
          segments: {
            medium: {
              account_count: 4697,
              family: {
                platform: 'gainsight',
                object: 'Playbook',
                field: 'Active plays',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[Tesorio Logo]',
              },
              metrics: {
                '30-day average': {
                  improve_from: 2.9565217391304346,
                  improve_to: 6.8604651162790695,
                },
              },
              propensity: {
                improve_from: 0.32333460045355394,
                improve_from_text: '32%',
                improve_to: 0.3231280939712885,
                improve_to_text: '32%',
                improve_by: -0.00020650648226547153,
              },
              revenue: {
                past: 226047682.71903992,
                elift_improve_from: 48244439.03392003,
                elift_improve_to: 48211540.24308982,
                elift_improve_by: 4174537.57,
              },
            },
            low: {
              account_count: 4357,
              family: {
                platform: 'gainsight',
                object: 'Playbook',
                field: 'Active plays',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[Tesorio Logo]',
              },
              metrics: {
                '30-day average': {
                  improve_from: 6.8604651162790695,
                  improve_to: 6.8604651162790695,
                },
              },
              propensity: {
                improve_from: 0.31357124526591185,
                improve_from_text: '31%',
                improve_to: 0.31351100868546417,
                improve_to_text: '31%',
                improve_by: -0.000060236580447682504,
              },
              revenue: {
                past: 185339316.7087555,
                elift_improve_from: 36068973.5941971,
                elift_improve_to: 36063842.859716214,
                elift_improve_by: 3148737.19,
              },
            },
            high: {
              account_count: 3823,
              family: {
                platform: 'gainsight',
                object: 'Playbook',
                field: 'Active plays',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[Tesorio Logo]',
              },
              metrics: {
                '30-day average': {
                  improve_from: 4.473684210526315,
                  improve_to: 6.8604651162790695,
                },
              },
              propensity: {
                improve_from: 0.3186987928371051,
                improve_from_text: '32%',
                improve_to: 0.31771009545079976,
                improve_to_text: '32%',
                improve_by: -0.0009886973863053711,
              },
              revenue: {
                past: 206402409.13295746,
                elift_improve_from: 45069540.082332,
                elift_improve_to: 44939630.20128272,
                elift_improve_by: 3180685.79,
              },
            },
          },
        },
        {
          driver_id: '2-006',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:numminutes@name[Registration]:sum:p90d1',
          family: {
            platform: 'snowflake',
            object: 'Job execution',
            field: 'Is business critical',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: 'numminutes@name[Registration]',
          },
          segments: {
            medium: {
              account_count: 4174,
              family: {
                platform: 'snowflake',
                object: 'Job execution',
                field: 'Is business critical',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numminutes@name[Registration]',
              },
              metrics: {
                '60-day total': {
                  improve_from: 6.463414634146342,
                  improve_to: 33.36666666666667,
                },
              },
              propensity: {
                improve_from: 0.32021891148998066,
                improve_from_text: '32%',
                improve_to: 0.3188976963748885,
                improve_to_text: '32%',
                improve_by: -0.0013212151150922083,
              },
              revenue: {
                past: 196188119.02971268,
                elift_improve_from: 41518231.99392394,
                elift_improve_to: 41337091.11794535,
                elift_improve_by: 4001017.65,
              },
            },
            high: {
              account_count: 2425,
              family: {
                platform: 'snowflake',
                object: 'Job execution',
                field: 'Is business critical',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numminutes@name[Registration]',
              },
              metrics: {
                '60-day total': {
                  improve_from: 1.4583333333333333,
                  improve_to: 33.36666666666667,
                },
              },
              propensity: {
                improve_from: 0.32673280083023465,
                improve_from_text: '33%',
                improve_to: 0.32515007155814973,
                improve_to_text: '33%',
                improve_by: -0.0015827292720848735,
              },
              revenue: {
                past: 113885696.2474823,
                elift_improve_from: 23790922.169966724,
                elift_improve_to: 23670775.165425166,
                elift_improve_by: 4506416.69,
              },
            },
            low: {
              account_count: 3095,
              family: {
                platform: 'snowflake',
                object: 'Job execution',
                field: 'Is business critical',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numminutes@name[Registration]',
              },
              metrics: {
                '60-day total': {
                  improve_from: 33.36666666666667,
                  improve_to: 33.36666666666667,
                },
              },
              propensity: {
                improve_from: 0.3104021410669912,
                improve_from_text: '31%',
                improve_to: 0.3100553799278615,
                improve_to_text: '31%',
                improve_by: -0.00034676113912974577,
              },
              revenue: {
                past: 148982897.38311768,
                elift_improve_from: 31014162.244441606,
                elift_improve_to: 30982812.9454455,
                elift_improve_by: 4172876.78,
              },
            },
          },
        },
        {
          driver_id: '2-007',
          driver_type: 'insight',
          feature: 'salesforce:task:salesforce_task_custom_field_boolean_1:sum:p90d1',
          family: {
            platform: 'salesforce',
            object: 'Contact',
            field: '# of cases',
            std_platform: 'salesforce',
            std_object: 'task',
            std_field: 'salesforce_task_custom_field_boolean_1',
          },
          segments: {
            medium: {
              account_count: 5246,
              family: {
                platform: 'salesforce',
                object: 'Contact',
                field: '# of cases',
                std_platform: 'salesforce',
                std_object: 'task',
                std_field: 'salesforce_task_custom_field_boolean_1',
              },
              metrics: {
                '': {
                  improve_from: 1.3269230769230769,
                  improve_to: 6.36,
                },
              },
              propensity: {
                improve_from: 0.3279065566398441,
                improve_from_text: '33%',
                improve_to: 0.3273070469842185,
                improve_to_text: '33%',
                improve_by: -0.0005995096556256885,
              },
              revenue: {
                past: 211768396.79374695,
                elift_improve_from: 41789858.79513557,
                elift_improve_to: 41714437.47478836,
                elift_improve_by: 3424899.0,
              },
            },
            low: {
              account_count: 5086,
              family: {
                platform: 'salesforce',
                object: 'Contact',
                field: '# of cases',
                std_platform: 'salesforce',
                std_object: 'task',
                std_field: 'salesforce_task_custom_field_boolean_1',
              },
              metrics: {
                '': {
                  improve_from: 6.36,
                  improve_to: 6.36,
                },
              },
              propensity: {
                improve_from: 0.30918777071299475,
                improve_from_text: '31%',
                improve_to: 0.3090289781144731,
                improve_to_text: '31%',
                improve_by: -0.00015879259852160431,
              },
              revenue: {
                past: 267297277.7478218,
                elift_improve_from: 57428735.40866776,
                elift_improve_to: 57403977.77610897,
                elift_improve_by: 4274445.35,
              },
            },
            high: {
              account_count: 6658,
              family: {
                platform: 'salesforce',
                object: 'Contact',
                field: '# of cases',
                std_platform: 'salesforce',
                std_object: 'task',
                std_field: 'salesforce_task_custom_field_boolean_1',
              },
              metrics: {
                '': {
                  improve_from: 9,
                  improve_to: 2,
                },
              },
              propensity: {
                improve_from: 0.33492082130830214,
                improve_from_text: '33%',
                improve_to: 0.3341541710681929,
                improve_to_text: '33%',
                improve_by: -0.0007666502401092347,
              },
              revenue: {
                past: 259566144.4337368,
                elift_improve_from: 51095710.67494819,
                elift_improve_to: 50976253.26907348,
                elift_improve_by: 3316150.35,
              },
            },
          },
        },
        {
          driver_id: '2-008',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:_row@name[Invoices - Tesorio]:count@universal_user_id:p360d1',
          family: {
            platform: 'snowflake',
            object: 'Product Telemetry',
            field: 'Provision time (seconds)',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: '_row@name[Invoices - Tesorio]',
          },
          segments: {
            medium: {
              account_count: 6101,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Provision time (seconds)',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Invoices - Tesorio]',
              },
              metrics: {
                '30-day average': {
                  improve_from: 47.64017779361686,
                  improve_to: 168.7132664954093,
                },
              },
              propensity: {
                improve_from: 0.3242392863760897,
                improve_from_text: '32%',
                improve_to: 0.32344243512414805,
                improve_to_text: '32%',
                improve_by: -0.0007968512519417617,
              },
              revenue: {
                past: 249195161.0508442,
                elift_improve_from: 48457531.499072224,
                elift_improve_to: 48346126.76985835,
                elift_improve_by: 4082808.95,
              },
            },
            high: {
              account_count: 4780,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Provision time (seconds)',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Invoices - Tesorio]',
              },
              metrics: {
                '30-day average': {
                  improve_from: 9.636347517730496,
                  improve_to: 168.7132664954093,
                },
              },
              propensity: {
                improve_from: 0.3305238599583316,
                improve_from_text: '33%',
                improve_to: 0.32946755099493213,
                improve_to_text: '33%',
                improve_by: -0.0010563089633994912,
              },
              revenue: {
                past: 191270928.03897858,
                elift_improve_from: 37968381.72180472,
                elift_improve_to: 37850489.54830186,
                elift_improve_by: 4254642.3,
              },
            },
            low: {
              account_count: 4978,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Provision time (seconds)',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Invoices - Tesorio]',
              },
              metrics: {
                '30-day average': {
                  improve_from: 168.7132664954093,
                  improve_to: 191.1132664954093,
                },
              },
              propensity: {
                improve_from: 0.3147476822578903,
                improve_from_text: '31%',
                improve_to: 0.3145923874008816,
                improve_to_text: '31%',
                improve_by: -0.00015529485700875278,
              },
              revenue: {
                past: 256586683.4552765,
                elift_improve_from: 55487296.23683817,
                elift_improve_to: 55463366.7131235,
                elift_improve_by: 4625409.45,
              },
            },
          },
        },
        {
          driver_id: '2-009',
          driver_type: 'insight',
          feature: 'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-notes]:sum|lift:p180d1',
          family: {
            platform: 'pendo',
            object: 'Page',
            field: 'Minutes on [Job profile]',
            std_platform: 'pendo',
            std_object: 'feature_telemetry',
            std_field: 'numminutes@name[ar-invoices-table-followup-activity-notes]',
          },
          segments: {
            medium: {
              account_count: 5634,
              family: {
                platform: 'pendo',
                object: 'Page',
                field: 'Minutes on [Job profile]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numminutes@name[ar-invoices-table-followup-activity-notes]',
              },
              metrics: {
                '30-day total': {
                  improve_from: 24.410714285714285,
                  improve_to: 1706.2121951219508,
                },
              },
              propensity: {
                improve_from: 0.32530025013471253,
                improve_from_text: '33%',
                improve_to: 0.3247901591744092,
                improve_to_text: '32%',
                improve_by: -0.0005100909603034,
              },
              revenue: {
                past: 219303595.19262314,
                elift_improve_from: 41630559.22773541,
                elift_improve_to: 41523143.83624889,
                elift_improve_by: 3107154.06,
              },
            },
            low: {
              account_count: 4186,
              family: {
                platform: 'pendo',
                object: 'Page',
                field: 'Minutes on [Job profile]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numminutes@name[ar-invoices-table-followup-activity-notes]',
              },
              metrics: {
                '30-day total': {
                  improve_from: 1706.2121951219508,
                  improve_to: 1706.2121951219508,
                },
              },
              propensity: {
                improve_from: 0.30884668546228994,
                improve_from_text: '31%',
                improve_to: 0.3086844176576718,
                improve_to_text: '31%',
                improve_by: -0.00016226780461805018,
              },
              revenue: {
                past: 250408731.86731339,
                elift_improve_from: 55989157.4223603,
                elift_improve_to: 55953835.202034965,
                elift_improve_by: 3775682.55,
              },
            },
            high: {
              account_count: 3874,
              family: {
                platform: 'pendo',
                object: 'Page',
                field: 'Minutes on [Job profile]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numminutes@name[ar-invoices-table-followup-activity-notes]',
              },
              metrics: {
                '30-day total': {
                  improve_from: 525.2368421052631,
                  improve_to: 1706.2121951219508,
                },
              },
              propensity: {
                improve_from: 0.3251281671888984,
                improve_from_text: '33%',
                improve_to: 0.3244545195292375,
                improve_to_text: '32%',
                improve_by: -0.0006736476596609276,
              },
              revenue: {
                past: 177441293.72177124,
                elift_improve_from: 37448301.18489995,
                elift_improve_to: 37332895.3365945,
                elift_improve_by: 3902845.82,
              },
            },
          },
        },
        {
          driver_id: '2-010',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:numevents@name[Customer Payments]:mean|lift:p180d1',
          family: {
            platform: 'snowflake',
            object: 'Product Telemetry',
            field: 'Usage (minutes)',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: 'numevents@name[Customer Payments]',
          },
          segments: {
            low: {
              account_count: 4964,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Usage (minutes)',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents@name[Customer Payments]',
              },
              metrics: {
                '30-day average': {
                  improve_from: 4.442909304075212,
                  improve_to: 9.182909304075212,
                },
              },
              propensity: {
                improve_from: 0.3194569762070196,
                improve_from_text: '32%',
                improve_to: 0.3193380425597991,
                improve_to_text: '32%',
                improve_by: -0.00011893364722054614,
              },
              revenue: {
                past: 239975960.256958,
                elift_improve_from: 51432322.92157135,
                elift_improve_to: 51417078.562137686,
                elift_improve_by: 4282497.68,
              },
            },
            medium: {
              account_count: 6243,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Usage (minutes)',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents@name[Customer Payments]',
              },
              metrics: {
                '30-day average': {
                  improve_from: 2.5549585662662695,
                  improve_to: 4.442909304075212,
                },
              },
              propensity: {
                improve_from: 0.32136137225842004,
                improve_from_text: '32%',
                improve_to: 0.32091271628177565,
                improve_to_text: '32%',
                improve_by: -0.00044865597664438126,
              },
              revenue: {
                past: 250574653.04403305,
                elift_improve_from: 47838883.47145619,
                elift_improve_to: 47772120.592930354,
                elift_improve_by: 3538760.08,
              },
            },
            high: {
              account_count: 4625,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Usage (minutes)',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents@name[Customer Payments]',
              },
              metrics: {
                '30-day average': {
                  improve_from: 1.0922434246212667,
                  improve_to: 4.442909304075212,
                },
              },
              propensity: {
                improve_from: 0.3271174650354915,
                improve_from_text: '33%',
                improve_to: 0.3263160725288747,
                improve_to_text: '33%',
                improve_by: -0.0008013925066168835,
              },
              revenue: {
                past: 213321911.0637188,
                elift_improve_from: 45043226.55457992,
                elift_improve_to: 44934723.0976386,
                elift_improve_by: 4745329.74,
              },
            },
          },
        },
      ],
    },
    conversion: {
      full_name: ['conversion', 'conversion in next 180 days'],
      impact: 'positive',
      drivers: [
        {
          driver_id: '2-001',
          driver_type: 'insight',
          feature: 'pendo:reports:numvisitors:value:p0d1',
          family: {
            platform: 'snowflake',
            object: 'Product Telemetry',
            field: 'Jobs exceed quota',
            std_platform: 'pendo',
            std_object: 'reports',
            std_field: 'numvisitors',
          },
          segments: {
            low: {
              account_count: 5072,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Jobs exceed quota',
                std_platform: 'pendo',
                std_object: 'reports',
                std_field: 'numvisitors',
              },
              metrics: {
                '30-day total': {
                  improve_from: 24.24,
                  improve_to: 44.239999771118164,
                },
              },
              propensity: {
                improve_from: 0.3418236130634962,
                improve_from_text: '34%',
                improve_to: 0.34192393255418635,
                improve_to_text: '34%',
                improve_by: 0.0001003194906901761,
              },
              revenue: {
                past: 309885751.9592285,
                elift_improve_from: -7271655.56693658,
                elift_improve_to: -6740646.158505606,
                elift_improve_by: 4613549.01,
              },
            },
            medium: {
              account_count: 7064,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Jobs exceed quota',
                std_platform: 'pendo',
                std_object: 'reports',
                std_field: 'numvisitors',
              },
              metrics: {
                '30-day total': {
                  improve_from: 6.314285714285714,
                  improve_to: 24.239999771118164,
                },
              },
              propensity: {
                improve_from: 0.3369060398517509,
                improve_from_text: '34%',
                improve_to: 0.3372143127973818,
                improve_to_text: '34%',
                improve_by: 0.0003082729456307934,
              },
              revenue: {
                past: 299375279.85305786,
                elift_improve_from: -2842758.7703273147,
                elift_improve_to: -784628.5146125121,
                elift_improve_by: 3467861.62,
              },
            },
            high: {
              account_count: 3912,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Jobs exceed quota',
                std_platform: 'pendo',
                std_object: 'reports',
                std_field: 'numvisitors',
              },
              metrics: {
                '30-day total': {
                  improve_from: 2.128205128205128,
                  improve_to: 24.239999771118164,
                },
              },
              propensity: {
                improve_from: 0.32771607285081944,
                improve_from_text: '33%',
                improve_to: 0.328104205272203,
                improve_to_text: '33%',
                improve_by: 0.00038813242138353176,
              },
              revenue: {
                past: 105179202.30431557,
                elift_improve_from: 2105057.9694197793,
                elift_improve_to: 3191660.1161139845,
                elift_improve_by: 4600560.84,
              },
            },
          },
        },
        {
          driver_id: '2-002',
          driver_type: 'insight',
          feature: 'pendo:feature_telemetry:_row@name[Invoice Drawer > Invoice Info Tab]:count|lift:p180d1',
          family: {
            platform: 'pendo',
            object: 'Feature',
            field: 'Minutes on [Request quota increase]',
            std_platform: 'pendo',
            std_object: 'feature_telemetry',
            std_field: '_row@name[Invoice Drawer > Invoice Info Tab]',
          },
          segments: {
            medium: {
              account_count: 6045,
              family: {
                platform: 'pendo',
                object: 'Feature',
                field: 'Minutes on [Request quota increase]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[Invoice Drawer > Invoice Info Tab]',
              },
              metrics: {
                '60-day average': {
                  improve_from: 3.216666666666667,
                  improve_to: -13.58974358974359,
                },
              },
              propensity: {
                improve_from: 0.3333937159041609,
                improve_from_text: '33%',
                improve_to: 0.3334738026405479,
                improve_to_text: '33%',
                improve_by: 0.0000800867363869769,
              },
              revenue: {
                past: 244428757.03382492,
                elift_improve_from: -2711884.9409441426,
                elift_improve_to: -2597154.9571031206,
                elift_improve_by: 4370420.82,
              },
            },
            low: {
              account_count: 3966,
              family: {
                platform: 'pendo',
                object: 'Feature',
                field: 'Minutes on [Request quota increase]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[Invoice Drawer > Invoice Info Tab]',
              },
              metrics: {
                '60-day average': {
                  improve_from: 10.8974358974359,
                  improve_to: 13.58974358974359,
                },
              },
              propensity: {
                improve_from: 0.3386351335322148,
                improve_from_text: '34%',
                improve_to: 0.33865755825648547,
                improve_to_text: '34%',
                improve_by: 0.000022424724270628746,
              },
              revenue: {
                past: 167723105.2163601,
                elift_improve_from: 38001.57711061274,
                elift_improve_to: 67702.91173122083,
                elift_improve_by: 4483987.41,
              },
            },
            high: {
              account_count: 4475,
              family: {
                platform: 'pendo',
                object: 'Feature',
                field: 'Minutes on [Request quota increase]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[Invoice Drawer > Invoice Info Tab]',
              },
              metrics: {
                '60-day average': {
                  improve_from: 8.69090909090905,
                  improve_to: 13.58974358974359,
                },
              },
              propensity: {
                improve_from: 0.3385798392703095,
                improve_from_text: '34%',
                improve_to: 0.33900800564767575,
                improve_to_text: '34%',
                improve_by: 0.0004281663773662885,
              },
              revenue: {
                past: 230168919.74945068,
                elift_improve_from: -3789893.170631617,
                elift_improve_to: -2807790.178792418,
                elift_improve_by: 3666400.11,
              },
            },
          },
        },
        {
          driver_id: '2-003',
          driver_type: 'insight',
          feature: 'pendo:feature_telemetry:_row@name[action-bar-set-date]:count|lift:p180d1',
          family: {
            platform: 'salesforce',
            object: 'Contact',
            field: '# of cases',
            std_platform: 'pendo',
            std_object: 'feature_telemetry',
            std_field: '_row@name[action-bar-set-date]',
          },
          segments: {
            low: {
              account_count: 3957,
              family: {
                platform: 'salesforce',
                object: 'Contact',
                field: '# of cases',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[action-bar-set-date]',
              },
              metrics: {
                '': {
                  improve_from: 156.9705128205133,
                  improve_to: 156.9705128205133,
                },
              },
              propensity: {
                improve_from: 0.33984149790099355,
                improve_from_text: '34%',
                improve_to: 0.33984305638063195,
                improve_to_text: '34%',
                improve_by: 0.0000015584796384085568,
              },
              revenue: {
                past: 233213920.1748848,
                elift_improve_from: -4900725.595866064,
                elift_improve_to: -4769219.8459333135,
                elift_improve_by: 3694764.45,
              },
            },
            medium: {
              account_count: 5124,
              family: {
                platform: 'salesforce',
                object: 'Contact',
                field: '# of cases',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[action-bar-set-date]',
              },
              metrics: {
                '': {
                  improve_from: 25.49019607843137,
                  improve_to: 156.9705128205133,
                },
              },
              propensity: {
                improve_from: 0.3352793665620924,
                improve_from_text: '34%',
                improve_to: 0.33530030097359526,
                improve_to_text: '34%',
                improve_by: 0.00002093441150285653,
              },
              revenue: {
                past: 188599851.21188164,
                elift_improve_from: 2243989.2017041827,
                elift_improve_to: 3534340.501517019,
                elift_improve_by: 4320108.87,
              },
            },
            high: {
              account_count: 3826,
              family: {
                platform: 'salesforce',
                object: 'Contact',
                field: '# of cases',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[action-bar-set-date]',
              },
              metrics: {
                '': {
                  improve_from: 37.421052631578945,
                  improve_to: 156.9705128205133,
                },
              },
              propensity: {
                improve_from: 0.3386372013328352,
                improve_from_text: '34%',
                improve_to: 0.3386729255831603,
                improve_to_text: '34%',
                improve_by: 0.000035724250325045915,
              },
              revenue: {
                past: 177283548.08721542,
                elift_improve_from: -3759046.495455275,
                elift_improve_to: -2793633.727567996,
                elift_improve_by: 4500268.51,
              },
            },
          },
        },
        {
          driver_id: '2-011',
          driver_type: 'insight',
          feature: 'chorusai',
          family: {
            platform: 'chorusai',
            object: 'Chorus.ai = [COMPETITOR]',
            field: 'Engagement_tracker_match name',
            std_platform: 'chorusai',
            std_object: 'chorusai',
            std_field: 'chorusai',
          },
          segments: {
            high: {
              account_count: 4322,
              family: {
                platform: 'chorusai',
                object: 'Chorus.ai = [COMPETITOR]',
                field: 'Engagement_tracker_match name',
                std_platform: 'chorusai',
                std_object: 'chorusai',
                std_field: 'chorusai',
              },
              metrics: {
                '30-day average': {
                  improve_from: 1,
                  improve_to: 0,
                },
              },
              propensity: {
                improve_from: 0.3230527205390279,
                improve_from_text: '32%',
                improve_to: 0.3219645206605563,
                improve_to_text: '32%',
                improve_by: -0.0010881998784715685,
              },
              revenue: {
                past: 193853322.8416443,
                elift_improve_from: 40927378.06415201,
                elift_improve_to: 40778779.26520206,
                elift_improve_by: 3681519.66,
              },
            },
            low: {
              account_count: 4011,
              family: {
                platform: 'chorusai',
                object: 'Chorus.ai = [COMPETITOR]',
                field: 'Engagement_tracker_match name',
                std_platform: 'chorusai',
                std_object: 'chorusai',
                std_field: 'chorusai',
              },
              metrics: {
                '30-day average': {
                  improve_from: 1,
                  improve_to: 0,
                },
              },
              propensity: {
                improve_from: 0.3095759864811509,
                improve_from_text: '31%',
                improve_to: 0.3092825838688459,
                improve_to_text: '31%',
                improve_by: -0.00029340261230495886,
              },
              revenue: {
                past: 211878248.02150726,
                elift_improve_from: 43858903.960561536,
                elift_improve_to: 43824923.65844307,
                elift_improve_by: 4525592.6,
              },
            },
            medium: {
              account_count: 5680,
              family: {
                platform: 'chorusai',
                object: 'Chorus.ai = [COMPETITOR]',
                field: 'Engagement_tracker_match name',
                std_platform: 'chorusai',
                std_object: 'chorusai',
                std_field: 'chorusai',
              },
              metrics: {
                '30-day average': {
                  improve_from: 1,
                  improve_to: 0,
                },
              },
              propensity: {
                improve_from: 0.32771773234673357,
                improve_from_text: '33%',
                improve_to: 0.3270290011091582,
                improve_to_text: '33%',
                improve_by: -0.0006887312375752781,
              },
              revenue: {
                past: 235492731.58578873,
                elift_improve_from: 47643495.85442367,
                elift_improve_to: 47540269.98886779,
                elift_improve_by: 4335736.14,
              },
            },
          },
        },
        {
          driver_id: '2-004',
          driver_type: 'insight',
          feature: 'salesforce:lead:salesforce_lead_custom_field_boolean_6:value@converted_contact_id:p0d1',
          family: {
            platform: 'pendo',
            object: 'Feature',
            field: 'Number of [Execution logs]',
            std_platform: 'salesforce',
            std_object: 'Contact',
            std_field: 'salesforce_lead_custom_field_boolean_6',
          },
          segments: {
            high: {
              account_count: 5155,
              family: {
                platform: 'pendo',
                object: 'Feature',
                field: 'Number of [Execution logs]',
                std_platform: 'salesforce',
                std_object: 'Contact',
                std_field: 'salesforce_lead_custom_field_boolean_6',
              },
              metrics: {
                '30-day total': {
                  improve_from: 0,
                  improve_to: 0.757482644982645,
                },
              },
              propensity: {
                improve_from: 0.3346919924399675,
                improve_from_text: '33%',
                improve_to: 0.334889890188791,
                improve_to_text: '33%',
                improve_by: 0.00019789774882353502,
              },
              revenue: {
                past: 207278377.60076523,
                elift_improve_from: -102179.57480132778,
                elift_improve_to: 814654.4898413988,
                elift_improve_by: 4591344.42,
              },
            },
            medium: {
              account_count: 7423,
              family: {
                platform: 'pendo',
                object: 'Feature',
                field: 'Number of [Execution logs]',
                std_platform: 'salesforce',
                std_object: 'Contact',
                std_field: 'salesforce_lead_custom_field_boolean_6',
              },
              metrics: {
                '30-day total': {
                  improve_from: 0.757482644982645,
                  improve_to: 0.757482644982645,
                },
              },
              propensity: {
                improve_from: 0.33709063756786745,
                improve_from_text: '34%',
                improve_to: 0.3371283941634987,
                improve_to_text: '34%',
                improve_by: 0.00003775659563124393,
              },
              revenue: {
                past: 353365652.84371376,
                elift_improve_from: -6983755.286090942,
                elift_improve_to: -6775116.398148138,
                elift_improve_by: 4065687.07,
              },
            },
          },
        },
        {
          driver_id: '2-005',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:numevents:mean:p360d1',
          family: {
            platform: 'snowflake',
            object: 'Job execution',
            field: 'Usage (minutes)',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: 'numevents',
          },
          segments: {
            medium: {
              account_count: 6675,
              family: {
                platform: 'snowflake',
                object: 'Job execution',
                field: 'Usage (minutes)',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents',
              },
              metrics: {
                '30-day average': {
                  improve_from: 2.863790268586246,
                  improve_to: 4.413589127407062,
                },
              },
              propensity: {
                improve_from: 0.3363909911126935,
                improve_from_text: '34%',
                improve_to: 0.33676636042598057,
                improve_to_text: '34%',
                improve_by: 0.0003753693132870402,
              },
              revenue: {
                past: 286679340.32621384,
                elift_improve_from: -3735820.4809878897,
                elift_improve_to: -2681161.984380618,
                elift_improve_by: 3990474.47,
              },
            },
            low: {
              account_count: 5146,
              family: {
                platform: 'snowflake',
                object: 'Job execution',
                field: 'Usage (minutes)',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents',
              },
              metrics: {
                '30-day average': {
                  improve_from: 4.413589127407062,
                  improve_to: 4.413589127407062,
                },
              },
              propensity: {
                improve_from: 0.3401189909944745,
                improve_from_text: '34%',
                improve_to: 0.3402337835097796,
                improve_to_text: '34%',
                improve_by: 0.00011479251530505,
              },
              revenue: {
                past: 282240254.1727066,
                elift_improve_from: -7216713.18931558,
                elift_improve_to: -7040673.642933509,
                elift_improve_by: 4550054.26,
              },
            },
            high: {
              account_count: 5011,
              family: {
                platform: 'snowflake',
                object: 'Job execution',
                field: 'Usage (minutes)',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents',
              },
              metrics: {
                '30-day average': {
                  improve_from: 2.152331020650463,
                  improve_to: 4.413589127407062,
                },
              },
              propensity: {
                improve_from: 0.3290315532869388,
                improve_from_text: '33%',
                improve_to: 0.3295743417075749,
                improve_to_text: '33%',
                improve_by: 0.0005427884206360679,
              },
              revenue: {
                past: 165623018.94049644,
                elift_improve_from: 2552213.90109337,
                elift_improve_to: 3393000.750289266,
                elift_improve_by: 3046759.55,
              },
            },
          },
        },
        {
          driver_id: '2-006',
          driver_type: 'insight',
          feature: 'pendo:reports:daysactive:value:p0d1',
          family: {
            platform: 'snowflake',
            object: 'Job execution',
            field: 'Is business critical',
            std_platform: 'pendo',
            std_object: 'reports',
            std_field: 'daysactive',
          },
          segments: {
            medium: {
              account_count: 5924,
              family: {
                platform: 'snowflake',
                object: 'Job execution',
                field: 'Is business critical',
                std_platform: 'pendo',
                std_object: 'reports',
                std_field: 'daysactive',
              },
              metrics: {
                '60-day total': {
                  improve_from: 22.54237288135593,
                  improve_to: 27.962265014648438,
                },
              },
              propensity: {
                improve_from: 0.33760960144774105,
                improve_from_text: '34%',
                improve_to: 0.337976467207387,
                improve_to_text: '34%',
                improve_by: 0.0003668657596461347,
              },
              revenue: {
                past: 244706913.16051483,
                elift_improve_from: -1296504.8726293058,
                elift_improve_to: -839656.3815900323,
                elift_improve_by: 3392825.15,
              },
            },
            high: {
              account_count: 4746,
              family: {
                platform: 'snowflake',
                object: 'Job execution',
                field: 'Is business critical',
                std_platform: 'pendo',
                std_object: 'reports',
                std_field: 'daysactive',
              },
              metrics: {
                '60-day total': {
                  improve_from: 13.148936170212766,
                  improve_to: 27.962265014648438,
                },
              },
              propensity: {
                improve_from: 0.32822971882355195,
                improve_from_text: '33%',
                improve_to: 0.32922263506834387,
                improve_to_text: '33%',
                improve_by: 0.0009929162447919122,
              },
              revenue: {
                past: 131946618.39337349,
                elift_improve_from: 2778537.017544954,
                elift_improve_to: 3552106.024649873,
                elift_improve_by: 3392825.15,
              },
            },
            low: {
              account_count: 5389,
              family: {
                platform: 'snowflake',
                object: 'Job execution',
                field: 'Is business critical',
                std_platform: 'pendo',
                std_object: 'reports',
                std_field: 'daysactive',
              },
              metrics: {
                '60-day total': {
                  improve_from: 27.962264150943398,
                  improve_to: 27.962265014648438,
                },
              },
              propensity: {
                improve_from: 0.34169371724255976,
                improve_from_text: '34%',
                improve_to: 0.3417325965321553,
                improve_to_text: '34%',
                improve_by: 0.00003887928959549124,
              },
              revenue: {
                past: 337786702.5627136,
                elift_improve_from: -9491388.512759767,
                elift_improve_to: -9455449.518188044,
                elift_improve_by: 3614016.38,
              },
            },
          },
        },
        {
          driver_id: '2-007',
          driver_type: 'insight',
          feature: 'pendo:feature_telemetry:_row@name[Customer Drawer > Add Note]:count:p360d1',
          family: {
            platform: 'snowflake',
            object: 'Product Telemetry',
            field: 'Total jobs',
            std_platform: 'pendo',
            std_object: 'feature_telemetry',
            std_field: '_row@name[Customer Drawer > Add Note]',
          },
          segments: {
            medium: {
              account_count: 4308,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Total jobs',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[Customer Drawer > Add Note]',
              },
              metrics: {
                '30-day total': {
                  improve_from: 24.186046511627907,
                  improve_to: 283.5151515151515,
                },
              },
              propensity: {
                improve_from: 0.3366844196345366,
                improve_from_text: '34%',
                improve_to: 0.33722499980067855,
                improve_to_text: '34%',
                improve_by: 0.0005405801661419166,
              },
              revenue: {
                past: 208855115.28053284,
                elift_improve_from: -3617923.9361042464,
                elift_improve_to: -2939392.7980410377,
                elift_improve_by: 3147150.56,
              },
            },
            low: {
              account_count: 3386,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Total jobs',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[Customer Drawer > Add Note]',
              },
              metrics: {
                '30-day total': {
                  improve_from: 236.7951515151515,
                  improve_to: 283.5151515151515,
                },
              },
              propensity: {
                improve_from: 0.3434363767836747,
                improve_from_text: '34%',
                improve_to: 0.34361582679816355,
                improve_to_text: '34%',
                improve_by: 0.00017945001448886147,
              },
              revenue: {
                past: 198350972.12648392,
                elift_improve_from: -3857428.9794292026,
                elift_improve_to: -3639533.978479706,
                elift_improve_by: 3601471.41,
              },
            },
            high: {
              account_count: 3178,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Total jobs',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[Customer Drawer > Add Note]',
              },
              metrics: {
                '30-day total': {
                  improve_from: 1.6774193548387097,
                  improve_to: 283.5151515151515,
                },
              },
              propensity: {
                improve_from: 0.3364592758506687,
                improve_from_text: '34%',
                improve_to: 0.337047924598969,
                improve_to_text: '34%',
                improve_by: 0.0005886487483002317,
              },
              revenue: {
                past: 139409191.85028076,
                elift_improve_from: -1555769.1608118224,
                elift_improve_to: -813547.0877511364,
                elift_improve_by: 4416201.73,
              },
            },
          },
        },
        {
          driver_id: '2-008',
          driver_type: 'insight',
          feature: 'pendo:feature_telemetry:_row@name[Invoice Drawer > Contacts Tab]:count|lift:p180d1',
          family: {
            platform: 'pendo',
            object: 'Page',
            field: 'Number of [Billing]',
            std_platform: 'pendo',
            std_object: 'feature_telemetry',
            std_field: '_row@name[Invoice Drawer > Contacts Tab]',
          },
          segments: {
            low: {
              account_count: 3765,
              family: {
                platform: 'pendo',
                object: 'Page',
                field: 'Number of [Billing]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[Invoice Drawer > Contacts Tab]',
              },
              metrics: {
                '30-day total': {
                  improve_from: 24.81081081081081,
                  improve_to: 24.1081081081081,
                },
              },
              propensity: {
                improve_from: 0.34063496977987157,
                improve_from_text: '34%',
                improve_to: 0.34065858986091446,
                improve_to_text: '34%',
                improve_by: 0.000023620081042826076,
              },
              revenue: {
                past: 188033305.48553467,
                elift_improve_from: -2329897.7155313967,
                elift_improve_to: -2260567.0955147035,
                elift_improve_by: 3935158.53,
              },
            },
            medium: {
              account_count: 5746,
              family: {
                platform: 'pendo',
                object: 'Page',
                field: 'Number of [Billing]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[Invoice Drawer > Contacts Tab]',
              },
              metrics: {
                '30-day total': {
                  improve_from: 1.263157894736842,
                  improve_to: -24.81081081081081,
                },
              },
              propensity: {
                improve_from: 0.3343476975703983,
                improve_from_text: '33%',
                improve_to: 0.33440451027293544,
                improve_to_text: '33%',
                improve_by: 0.00005681270253723564,
              },
              revenue: {
                past: 241099734.8856926,
                elift_improve_from: -3363053.1780401776,
                elift_improve_to: -3186988.881426939,
                elift_improve_by: 4673579.3,
              },
            },
            high: {
              account_count: 4246,
              family: {
                platform: 'pendo',
                object: 'Page',
                field: 'Number of [Billing]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[Invoice Drawer > Contacts Tab]',
              },
              metrics: {
                '30-day total': {
                  improve_from: 65.4523809523813,
                  improve_to: -24.81081081081081,
                },
              },
              propensity: {
                improve_from: 0.3364578203279526,
                improve_from_text: '34%',
                improve_to: 0.3366771662009481,
                improve_to_text: '34%',
                improve_by: 0.0002193458729955374,
              },
              revenue: {
                past: 194039048.8986969,
                elift_improve_from: -832149.4005031249,
                elift_improve_to: -110571.87982157255,
                elift_improve_by: 4403198.69,
              },
            },
          },
        },
        {
          driver_id: '2-009',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:numevents@name[Customers]:mean:p360d1',
          family: {
            platform: 'gainsight',
            object: 'Playbook',
            field: 'Dry run (minutes)',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: 'numevents@name[Customers]',
          },
          segments: {
            medium: {
              account_count: 6534,
              family: {
                platform: 'gainsight',
                object: 'Playbook',
                field: 'Dry run (minutes)',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents@name[Customers]',
              },
              metrics: {
                '60-day total': {
                  improve_from: 5.516605713107802,
                  improve_to: 10.638263336609691,
                },
              },
              propensity: {
                improve_from: 0.3357998153231701,
                improve_from_text: '34%',
                improve_to: 0.3362395497513914,
                improve_to_text: '34%',
                improve_by: 0.0004397344282213295,
              },
              revenue: {
                past: 277521384.2511177,
                elift_improve_from: -283916.63535393274,
                elift_improve_to: 593491.1387041244,
                elift_improve_by: 4088844.21,
              },
            },
            high: {
              account_count: 5022,
              family: {
                platform: 'gainsight',
                object: 'Playbook',
                field: 'Dry run (minutes)',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents@name[Customers]',
              },
              metrics: {
                '60-day total': {
                  improve_from: 3.512734668880512,
                  improve_to: 10.638263336609691,
                },
              },
              propensity: {
                improve_from: 0.330483392830831,
                improve_from_text: '33%',
                improve_to: 0.33108948696354373,
                improve_to_text: '33%',
                improve_by: 0.000606094132712659,
              },
              revenue: {
                past: 172535620.5309391,
                elift_improve_from: -258621.75646067856,
                elift_improve_to: 403400.4731964081,
                elift_improve_by: 4605102.36,
              },
            },
            low: {
              account_count: 5135,
              family: {
                platform: 'gainsight',
                object: 'Playbook',
                field: 'Dry run (minutes)',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents@name[Customers]',
              },
              metrics: {
                '60-day total': {
                  improve_from: 10.638263336609691,
                  improve_to: 10.638263336609691,
                },
              },
              propensity: {
                improve_from: 0.34012245524489565,
                improve_from_text: '34%',
                improve_to: 0.3402480233341547,
                improve_to_text: '34%',
                improve_by: 0.00012556808925907146,
              },
              revenue: {
                past: 282488348.4492302,
                elift_improve_from: -7978435.372632436,
                elift_improve_to: -7775629.969863139,
                elift_improve_by: 3265350.36,
              },
            },
          },
        },
        {
          driver_id: '2-010',
          driver_type: 'insight',
          feature: 'pendo:feature_telemetry:_row@name[dashboard-workspace-menu]:count|lift:p180d1',
          family: {
            platform: 'salesforce',
            object: 'Contact',
            field: '# of contacts',
            std_platform: 'pendo',
            std_object: 'feature_telemetry',
            std_field: '_row@name[dashboard-workspace-menu]',
          },
          segments: {
            low: {
              account_count: 4446,
              family: {
                platform: 'salesforce',
                object: 'Contact',
                field: '# of contacts',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[dashboard-workspace-menu]',
              },
              metrics: {
                '# of contacts': {
                  improve_from: 43.659090909090786,
                  improve_to: 43.659090909090786,
                },
              },
              propensity: {
                improve_from: 0.33873322448951443,
                improve_from_text: '34%',
                improve_to: 0.33874363181046196,
                improve_to_text: '34%',
                improve_by: 0.000010407320947545212,
              },
              revenue: {
                past: 234714482.32355118,
                elift_improve_from: -3128748.2144713053,
                elift_improve_to: -3005509.7724675797,
                elift_improve_by: 3569280.1,
              },
            },
            medium: {
              account_count: 5875,
              family: {
                platform: 'salesforce',
                object: 'Contact',
                field: '# of contacts',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[dashboard-workspace-menu]',
              },
              metrics: {
                '# of contacts': {
                  improve_from: 0.896551724137931,
                  improve_to: 43.659090909090786,
                },
              },
              propensity: {
                improve_from: 0.33296978577773184,
                improve_from_text: '33%',
                improve_to: 0.3330252138245858,
                improve_to_text: '33%',
                improve_by: 0.00005542804685383986,
              },
              revenue: {
                past: 221672186.70816422,
                elift_improve_from: -921912.2028349146,
                elift_improve_to: -407041.19092981983,
                elift_improve_by: 3259211.71,
              },
            },
            high: {
              account_count: 3967,
              family: {
                platform: 'salesforce',
                object: 'Contact',
                field: '# of contacts',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[dashboard-workspace-menu]',
              },
              metrics: {
                '# of contacts': {
                  improve_from: 21.128205128205128,
                  improve_to: 43.659090909090786,
                },
              },
              propensity: {
                improve_from: 0.3408054080027339,
                improve_from_text: '34%',
                improve_to: 0.34087713786731394,
                improve_to_text: '34%',
                improve_by: 0.00007172986457998923,
              },
              revenue: {
                past: 196231291.89004898,
                elift_improve_from: -3651595.304297565,
                elift_improve_to: -2991167.0607888754,
                elift_improve_by: 3415222.49,
              },
            },
          },
        },
      ],
    },
    expansion: {
      full_name: ['expansion', 'expansion in next 180 days'],
      impact: 'positive',
      drivers: [
        {
          driver_id: '2-001',
          driver_type: 'insight',
          feature: 'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1',
          family: {
            platform: 'snowflake',
            object: 'Job execution',
            field: 'Is business critical',
            std_platform: 'pendo',
            std_object: 'feature_telemetry',
            std_field: 'numminutes@name[dashboard-workspace-menu]',
          },
          segments: {
            low: {
              account_count: 2557,
              family: {
                platform: 'snowflake',
                object: 'Job execution',
                field: 'Is business critical',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numminutes@name[dashboard-workspace-menu]',
              },
              metrics: {
                '60-day total': {
                  improve_from: 11.141999999999953,
                  improve_to: 11.141999999999953,
                },
              },
              propensity: {
                improve_from: 0.35135766691891873,
                improve_from_text: '35%',
                improve_to: 0.35147366062667246,
                improve_to_text: '35%',
                improve_by: 0.00011599370775369877,
              },
              revenue: {
                past: 132748801.99899673,
                elift_improve_from: 14983303.8077084,
                elift_improve_to: 15112620.431702254,
                elift_improve_by: 3936803.84,
              },
            },
            medium: {
              account_count: 3165,
              family: {
                platform: 'snowflake',
                object: 'Job execution',
                field: 'Is business critical',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numminutes@name[dashboard-workspace-menu]',
              },
              metrics: {
                '60-day total': {
                  improve_from: 1.3270609318996418,
                  improve_to: 11.141999999999953,
                },
              },
              propensity: {
                improve_from: 0.3409320079680043,
                improve_from_text: '34%',
                improve_to: 0.3413580694269407,
                improve_to_text: '34%',
                improve_by: 0.00042606145893635933,
              },
              revenue: {
                past: 148057392.62981415,
                elift_improve_from: 17279707.126545403,
                elift_improve_to: 17691831.28762916,
                elift_improve_by: 3839955.88,
              },
            },
            high: {
              account_count: 2289,
              family: {
                platform: 'snowflake',
                object: 'Job execution',
                field: 'Is business critical',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numminutes@name[dashboard-workspace-menu]',
              },
              metrics: {
                '60-day total': {
                  improve_from: 5.331060606060607,
                  improve_to: 11.141999999999953,
                },
              },
              propensity: {
                improve_from: 0.341148462777923,
                improve_from_text: '34%',
                improve_to: 0.34186224178818586,
                improve_to_text: '34%',
                improve_by: 0.0007137790102629625,
              },
              revenue: {
                past: 103481438.35773468,
                elift_improve_from: 633314909.0260214,
                elift_improve_to: 636270383.3353622,
                elift_improve_by: 4185936.28,
              },
            },
          },
        },
        {
          driver_id: '2-002',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:_row@name[Insights]:count|lift:p90d1',
          family: {
            platform: 'pendo',
            object: 'Report',
            field: 'Job run trending',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: '_row@name[Insights]',
          },
          segments: {
            low: {
              account_count: 4399,
              family: {
                platform: 'pendo',
                object: 'Report',
                field: 'Job run trending',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Insights]',
              },
              metrics: {
                'Job run trending': {
                  improve_from: 10.325581395348838,
                  improve_to: 10.325581395348838,
                },
              },
              propensity: {
                improve_from: 0.3504649688619601,
                improve_from_text: '35%',
                improve_to: 0.3505722102189408,
                improve_to_text: '35%',
                improve_by: 0.00010724135698066677,
              },
              revenue: {
                past: 235337225.21839142,
                elift_improve_from: 23626046.903799176,
                elift_improve_to: 23637045.384219486,
                elift_improve_by: 4713756.16,
              },
            },
            medium: {
              account_count: 4606,
              family: {
                platform: 'pendo',
                object: 'Report',
                field: 'Job run trending',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Insights]',
              },
              metrics: {
                'Job run trending': {
                  improve_from: 0.391304347826087,
                  improve_to: 10.325581395348838,
                },
              },
              propensity: {
                improve_from: 0.3364521282267655,
                improve_from_text: '34%',
                improve_to: 0.3367733149023232,
                improve_to_text: '34%',
                improve_by: 0.00032118667555759044,
              },
              revenue: {
                past: 206614839.44792747,
                elift_improve_from: 25565642.45815333,
                elift_improve_to: 25605239.204311404,
                elift_improve_by: 3115242.87,
              },
            },
            high: {
              account_count: 2667,
              family: {
                platform: 'pendo',
                object: 'Report',
                field: 'Job run trending',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Insights]',
              },
              metrics: {
                'Job run trending': {
                  improve_from: 8,
                  improve_to: 10.325581395348838,
                },
              },
              propensity: {
                improve_from: 0.343131162084077,
                improve_from_text: '34%',
                improve_to: 0.34405650631847445,
                improve_to_text: '34%',
                improve_by: 0.0009253442343974704,
              },
              revenue: {
                past: 107058906.07881546,
                elift_improve_from: 634184738.1127408,
                elift_improve_to: 637087452.3609822,
                elift_improve_by: 3864023.43,
              },
            },
          },
        },
        {
          driver_id: '2-003',
          driver_type: 'insight',
          feature: 'pendo:feature_telemetry:_row@name[Tesorio Logo]:count|lift:p90d1',
          family: {
            platform: 'intercom',
            object: 'Conversations',
            field: 'Time to response',
            std_platform: 'pendo',
            std_object: 'feature_telemetry',
            std_field: '_row@name[Tesorio Logo]',
          },
          segments: {
            medium: {
              account_count: 4664,
              family: {
                platform: 'intercom',
                object: 'Conversations',
                field: 'Time to response',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[Tesorio Logo]',
              },
              metrics: {
                '90-day average': {
                  improve_from: 2.9565217391304346,
                  improve_to: 6.8604651162790695,
                },
              },
              propensity: {
                improve_from: 0.3397577194508012,
                improve_from_text: '34%',
                improve_to: 0.34011735009807437,
                improve_to_text: '34%',
                improve_by: 0.00035963064727317917,
              },
              revenue: {
                past: 226047682.71903992,
                elift_improve_from: 24930837.16961569,
                elift_improve_to: 25008374.629157122,
                elift_improve_by: 4683220.99,
              },
            },
            high: {
              account_count: 3824,
              family: {
                platform: 'intercom',
                object: 'Conversations',
                field: 'Time to response',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[Tesorio Logo]',
              },
              metrics: {
                '90-day average': {
                  improve_from: -4.473684210526315,
                  improve_to: 6.8604651162790695,
                },
              },
              propensity: {
                improve_from: 0.3419054457987489,
                improve_from_text: '34%',
                improve_to: 0.3436456899833146,
                improve_to_text: '34%',
                improve_by: 0.001740244184565723,
              },
              revenue: {
                past: 206402409.13295746,
                elift_improve_from: 640423247.189138,
                elift_improve_to: 642844213.9166402,
                elift_improve_by: 4545076.46,
              },
            },
            low: {
              account_count: 4335,
              family: {
                platform: 'intercom',
                object: 'Conversations',
                field: 'Time to response',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[Tesorio Logo]',
              },
              metrics: {
                '90-day average': {
                  improve_from: 6.8604651162790695,
                  improve_to: 6.8604651162790695,
                },
              },
              propensity: {
                improve_from: 0.350724377444347,
                improve_from_text: '35%',
                improve_to: 0.3508294383651847,
                improve_to_text: '35%',
                improve_by: 0.00010506092083770245,
              },
              revenue: {
                past: 185339316.7087555,
                elift_improve_from: 24717425.084459443,
                elift_improve_to: 24743981.179145314,
                elift_improve_by: 4133989.4,
              },
            },
          },
        },
        {
          driver_id: '2-011',
          driver_type: 'insight',
          feature: 'chorusai',
          family: {
            platform: 'chorusai',
            object: 'Chorus.ai = [COMPETITOR]',
            field: 'Engagement_tracker_match name',
            std_platform: 'chorusai',
            std_object: 'chorusai',
            std_field: 'chorusai',
          },
          segments: {
            high: {
              account_count: 4322,
              family: {
                platform: 'chorusai',
                object: 'Chorus.ai = [COMPETITOR]',
                field: 'Engagement_tracker_match name',
                std_platform: 'chorusai',
                std_object: 'chorusai',
                std_field: 'chorusai',
              },
              metrics: {
                '30-day average': {
                  improve_from: 1,
                  improve_to: 0,
                },
              },
              propensity: {
                improve_from: 0.3230527205390279,
                improve_from_text: '32%',
                improve_to: 0.3219645206605563,
                improve_to_text: '32%',
                improve_by: -0.0010881998784715685,
              },
              revenue: {
                past: 193853322.8416443,
                elift_improve_from: 40927378.06415201,
                elift_improve_to: 40778779.26520206,
                elift_improve_by: 3681519.66,
              },
            },
            low: {
              account_count: 4011,
              family: {
                platform: 'chorusai',
                object: 'Chorus.ai = [COMPETITOR]',
                field: 'Engagement_tracker_match name',
                std_platform: 'chorusai',
                std_object: 'chorusai',
                std_field: 'chorusai',
              },
              metrics: {
                '30-day average': {
                  improve_from: 1,
                  improve_to: 0,
                },
              },
              propensity: {
                improve_from: 0.3095759864811509,
                improve_from_text: '31%',
                improve_to: 0.3092825838688459,
                improve_to_text: '31%',
                improve_by: -0.00029340261230495886,
              },
              revenue: {
                past: 211878248.02150726,
                elift_improve_from: 43858903.960561536,
                elift_improve_to: 43824923.65844307,
                elift_improve_by: 4525592.6,
              },
            },
            medium: {
              account_count: 5680,
              family: {
                platform: 'chorusai',
                object: 'Chorus.ai = [COMPETITOR]',
                field: 'Engagement_tracker_match name',
                std_platform: 'chorusai',
                std_object: 'chorusai',
                std_field: 'chorusai',
              },
              metrics: {
                '30-day average': {
                  improve_from: 1,
                  improve_to: 0,
                },
              },
              propensity: {
                improve_from: 0.32771773234673357,
                improve_from_text: '33%',
                improve_to: 0.3270290011091582,
                improve_to_text: '33%',
                improve_by: -0.0006887312375752781,
              },
              revenue: {
                past: 235492731.58578873,
                elift_improve_from: 47643495.85442367,
                elift_improve_to: 47540269.98886779,
                elift_improve_by: 4335736.14,
              },
            },
          },
        },
        {
          driver_id: '2-004',
          driver_type: 'insight',
          feature: 'pendo:reports:daysactive:value:p0d1',
          family: {
            platform: 'gainsight',
            object: 'Playbook',
            field: 'Active plays',
            std_platform: 'pendo',
            std_object: 'reports',
            std_field: 'daysactive',
          },
          segments: {
            medium: {
              account_count: 5954,
              family: {
                platform: 'gainsight',
                object: 'Playbook',
                field: 'Active plays',
                std_platform: 'pendo',
                std_object: 'reports',
                std_field: 'daysactive',
              },
              metrics: {
                '30-day average': {
                  improve_from: 22.54237288135593,
                  improve_to: 27.962265014648438,
                },
              },
              propensity: {
                improve_from: 0.3372853063756996,
                improve_from_text: '34%',
                improve_to: 0.3378307320842609,
                improve_to_text: '34%',
                improve_by: 0.0005454257085613806,
              },
              revenue: {
                past: 244706913.16051483,
                elift_improve_from: 31035071.999961197,
                elift_improve_to: 31605554.984923456,
                elift_improve_by: 4148644.91,
              },
            },
            low: {
              account_count: 5356,
              family: {
                platform: 'gainsight',
                object: 'Playbook',
                field: 'Active plays',
                std_platform: 'pendo',
                std_object: 'reports',
                std_field: 'daysactive',
              },
              metrics: {
                '30-day average': {
                  improve_from: 27.962264150943398,
                  improve_to: 27.962265014648438,
                },
              },
              propensity: {
                improve_from: 0.3442662194960534,
                improve_from_text: '34%',
                improve_to: 0.3443254486517262,
                improve_to_text: '34%',
                improve_by: 0.00005922915567275273,
              },
              revenue: {
                past: 337786702.5627136,
                elift_improve_from: 649160721.4597659,
                elift_improve_to: 649202090.9205201,
                elift_improve_by: 4388373.73,
              },
            },
            high: {
              account_count: 4756,
              family: {
                platform: 'gainsight',
                object: 'Playbook',
                field: 'Active plays',
                std_platform: 'pendo',
                std_object: 'reports',
                std_field: 'daysactive',
              },
              metrics: {
                '30-day average': {
                  improve_from: 13.148936170212766,
                  improve_to: 27.962265014648438,
                },
              },
              propensity: {
                improve_from: 0.3399257833350593,
                improve_from_text: '34%',
                improve_to: 0.3414482118202335,
                improve_to_text: '34%',
                improve_by: 0.001522428485174162,
              },
              revenue: {
                past: 131946618.39337349,
                elift_improve_from: 26727181.283784706,
                elift_improve_to: 28648654.83361354,
                elift_improve_by: 3538057.23,
              },
            },
          },
        },
        {
          driver_id: '2-005',
          driver_type: 'insight',
          feature: 'pendo:feature_telemetry:_row@name[Invoice Drawer > Add Note]:count|lift:p90d1',
          family: {
            platform: 'pendo',
            object: 'Page',
            field: 'Minutes on [Home page]',
            std_platform: 'pendo',
            std_object: 'feature_telemetry',
            std_field: '_row@name[Invoice Drawer > Add Note]',
          },
          segments: {
            low: {
              account_count: 3854,
              family: {
                platform: 'pendo',
                object: 'Page',
                field: 'Minutes on [Home page]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[Invoice Drawer > Add Note]',
              },
              metrics: {
                '30-day average': {
                  improve_from: 210.7815789473685,
                  improve_to: 210.7815789473685,
                },
              },
              propensity: {
                improve_from: 0.352015635006789,
                improve_from_text: '35%',
                improve_to: 0.35224154327184215,
                improve_to_text: '35%',
                improve_by: 0.00022590826505307872,
              },
              revenue: {
                past: 210353991.60089493,
                elift_improve_from: 20376789.652518217,
                elift_improve_to: 20438373.06353271,
                elift_improve_by: 3630429.36,
              },
            },
            medium: {
              account_count: 4834,
              family: {
                platform: 'pendo',
                object: 'Page',
                field: 'Minutes on [Home page]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[Invoice Drawer > Add Note]',
              },
              metrics: {
                '30-day average': {
                  improve_from: 3.3125,
                  improve_to: 210.7815789473685,
                },
              },
              propensity: {
                improve_from: 0.33871838865822407,
                improve_from_text: '34%',
                improve_to: 0.3393571522117415,
                improve_to_text: '34%',
                improve_by: 0.0006387635535174997,
              },
              revenue: {
                past: 207770844.37479973,
                elift_improve_from: 26965351.148905486,
                elift_improve_to: 27239976.070489656,
                elift_improve_by: 4592731.3,
              },
            },
            high: {
              account_count: 3636,
              family: {
                platform: 'pendo',
                object: 'Page',
                field: 'Minutes on [Home page]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[Invoice Drawer > Add Note]',
              },
              metrics: {
                '30-day average': {
                  improve_from: 60.09166666666666,
                  improve_to: 210.7815789473685,
                },
              },
              propensity: {
                improve_from: 0.33925887390958814,
                improve_from_text: '34%',
                improve_to: 0.34009352061490183,
                improve_to_text: '34%',
                improve_by: 0.0008346467053136045,
              },
              revenue: {
                past: 177122971.90761566,
                elift_improve_from: 639393156.3298094,
                elift_improve_to: 641250440.1045921,
                elift_improve_by: 3805534.01,
              },
            },
          },
        },
        {
          driver_id: '2-006',
          driver_type: 'insight',
          feature: 'pendo:feature_telemetry:numevents@name[Sync Status Icon]:sum|lift:p180d1',
          family: {
            platform: 'pendo',
            object: 'Guide',
            field: 'Number of [Guides shown]',
            std_platform: 'pendo',
            std_object: 'feature_telemetry',
            std_field: 'numevents@name[Sync Status Icon]',
          },
          segments: {
            medium: {
              account_count: 6178,
              family: {
                platform: 'pendo',
                object: 'Guide',
                field: 'Number of [Guides shown]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numevents@name[Sync Status Icon]',
              },
              metrics: {
                '180-day total': {
                  improve_from: 1.2295081967213115,
                  improve_to: 36.888888888888886,
                },
              },
              propensity: {
                improve_from: 0.3377768916401248,
                improve_from_text: '34%',
                improve_to: 0.3385398653527088,
                improve_to_text: '34%',
                improve_by: 0.0007629737125840347,
              },
              revenue: {
                past: 275204129.50401306,
                elift_improve_from: 32772243.693089563,
                elift_improve_to: 32653374.361049455,
                elift_improve_by: 4193370.97,
              },
            },
            high: {
              account_count: 4357,
              family: {
                platform: 'pendo',
                object: 'Guide',
                field: 'Number of [Guides shown]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numevents@name[Sync Status Icon]',
              },
              metrics: {
                '180-day total': {
                  improve_from: 25.74418604651163,
                  improve_to: 36.888888888888886,
                },
              },
              propensity: {
                improve_from: 0.33748390976934994,
                improve_from_text: '34%',
                improve_to: 0.33882582151824114,
                improve_to_text: '34%',
                improve_by: 0.001341911748891177,
              },
              revenue: {
                past: 212911163.0305767,
                elift_improve_from: 644828837.00086,
                elift_improve_to: 646639055.6633437,
                elift_improve_by: 3851331.96,
              },
            },
            low: {
              account_count: 4575,
              family: {
                platform: 'pendo',
                object: 'Guide',
                field: 'Number of [Guides shown]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numevents@name[Sync Status Icon]',
              },
              metrics: {
                '180-day total': {
                  improve_from: 36.888888888888886,
                  improve_to: 36.888888888888886,
                },
              },
              propensity: {
                improve_from: 0.3513207379363974,
                improve_from_text: '35%',
                improve_to: 0.35149041452996604,
                improve_to_text: '35%',
                improve_by: 0.0001696765935685993,
              },
              revenue: {
                past: 192376461.97214127,
                elift_improve_from: 24979509.405557733,
                elift_improve_to: 24955633.580999974,
                elift_improve_by: 3942717.07,
              },
            },
          },
        },
        {
          driver_id: '2-007',
          driver_type: 'insight',
          feature: 'salesforce:lead:salesforce_lead_custom_field_numeric_35:value:p0d1',
          family: {
            platform: 'snowflake',
            object: 'Product Telemetry',
            field: 'Provision time (seconds)',
            std_platform: 'salesforce',
            std_object: 'Contact',
            std_field: 'salesforce_lead_custom_field_numeric_35',
          },
          segments: {
            low: {
              account_count: 3847,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Provision time (seconds)',
                std_platform: 'salesforce',
                std_object: 'Contact',
                std_field: 'salesforce_lead_custom_field_numeric_35',
              },
              metrics: {
                '90-day average': {
                  improve_from: 1,
                  improve_to: 1,
                },
              },
              propensity: {
                improve_from: 0.3446420511534841,
                improve_from_text: '34%',
                improve_to: 0.3446420511534841,
                improve_to_text: '34%',
                improve_by: 0,
              },
              revenue: {
                past: 156772972.87187576,
                elift_improve_from: 21111815.107172348,
                elift_improve_to: 21111815.107172348,
                elift_improve_by: 3824194.6,
              },
            },
            high: {
              account_count: 4489,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Provision time (seconds)',
                std_platform: 'salesforce',
                std_object: 'Contact',
                std_field: 'salesforce_lead_custom_field_numeric_35',
              },
              metrics: {
                '90-day average': {
                  improve_from: 3,
                  improve_to: 1,
                },
              },
              propensity: {
                improve_from: 0.33491353660173145,
                improve_from_text: '33%',
                improve_to: 0.3354473899790635,
                improve_to_text: '34%',
                improve_by: 0.0005338533773320641,
              },
              revenue: {
                past: 186573977.9592991,
                elift_improve_from: 648039140.7202431,
                elift_improve_to: 649679609.4457474,
                elift_improve_by: 3602526.45,
              },
            },
            medium: {
              account_count: 4490,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Provision time (seconds)',
                std_platform: 'salesforce',
                std_object: 'Contact',
                std_field: 'salesforce_lead_custom_field_numeric_35',
              },
              metrics: {
                '90-day average': {
                  improve_from: 2,
                  improve_to: 1,
                },
              },
              propensity: {
                improve_from: 0.3390374505757954,
                improve_from_text: '34%',
                improve_to: 0.33930500359386817,
                improve_to_text: '34%',
                improve_by: 0.0002675530180727755,
              },
              revenue: {
                past: 218818102.59075165,
                elift_improve_from: 21907278.8262215,
                elift_improve_to: 22159037.25000646,
                elift_improve_by: 3851350.18,
              },
            },
          },
        },
        {
          driver_id: '2-008',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:numminutes@name[Settings > Company]:mean:p60d1',
          family: {
            platform: 'pendo',
            object: 'Page',
            field: 'Number of [User profile]',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: 'numminutes@name[Settings > Company]',
          },
          segments: {
            medium: {
              account_count: 4005,
              family: {
                platform: 'pendo',
                object: 'Page',
                field: 'Number of [User profile]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numminutes@name[Settings > Company]',
              },
              metrics: {
                '30-day total': {
                  improve_from: 1.470563114216365,
                  improve_to: 2.6520052666439233,
                },
              },
              propensity: {
                improve_from: 0.34928259935637396,
                improve_from_text: '35%',
                improve_to: 0.3497897252251268,
                improve_to_text: '35%',
                improve_by: 0.0005071258687528016,
              },
              revenue: {
                past: 182254980.30109406,
                elift_improve_from: 21440489.564681586,
                elift_improve_to: 21684870.21411086,
                elift_improve_by: 3140619.74,
              },
            },
            high: {
              account_count: 3259,
              family: {
                platform: 'pendo',
                object: 'Page',
                field: 'Number of [User profile]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numminutes@name[Settings > Company]',
              },
              metrics: {
                '30-day total': {
                  improve_from: 1,
                  improve_to: 2.6520052666439233,
                },
              },
              propensity: {
                improve_from: 0.3420521500455792,
                improve_from_text: '34%',
                improve_to: 0.3427550610148716,
                improve_to_text: '34%',
                improve_by: 0.0007029109692924078,
              },
              revenue: {
                past: 139273870.03126144,
                elift_improve_from: 640958505.9408513,
                elift_improve_to: 642582422.930608,
                elift_improve_by: 4437766.55,
              },
            },
            low: {
              account_count: 3540,
              family: {
                platform: 'pendo',
                object: 'Page',
                field: 'Number of [User profile]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numminutes@name[Settings > Company]',
              },
              metrics: {
                '30-day total': {
                  improve_from: 2.6520052666439233,
                  improve_to: 2.6520052666439233,
                },
              },
              propensity: {
                improve_from: 0.3436028411714666,
                improve_from_text: '34%',
                improve_to: 0.34372231209227094,
                improve_to_text: '34%',
                improve_by: 0.00011947092080433684,
              },
              revenue: {
                past: 170800742.14525223,
                elift_improve_from: 18406502.51714681,
                elift_improve_to: 18437300.852944825,
                elift_improve_by: 3715188.66,
              },
            },
          },
        },
        {
          driver_id: '2-009',
          driver_type: 'insight',
          feature: 'pendo:reports:usagetrending:value:p0d1',
          family: {
            platform: 'snowflake',
            object: 'Product Telemetry',
            field: 'Usage (minutes)',
            std_platform: 'pendo',
            std_object: 'reports',
            std_field: 'usagetrending',
          },
          segments: {
            high: {
              account_count: 4775,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Usage (minutes)',
                std_platform: 'pendo',
                std_object: 'reports',
                std_field: 'usagetrending',
              },
              metrics: {
                '30-day average': {
                  improve_from: 43.95744680851064,
                  improve_to: 286.9591979980469,
                },
              },
              propensity: {
                improve_from: 0.3361696586592921,
                improve_from_text: '34%',
                improve_to: 0.33709855922780624,
                improve_to_text: '34%',
                improve_by: 0.0009289005685141598,
              },
              revenue: {
                past: 173240831.043005,
                elift_improve_from: 649429375.3370216,
                elift_improve_to: 650934849.5920658,
                elift_improve_by: 3890133.5,
              },
            },
            low: {
              account_count: 4957,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Usage (minutes)',
                std_platform: 'pendo',
                std_object: 'reports',
                std_field: 'usagetrending',
              },
              metrics: {
                '30-day average': {
                  improve_from: 213.4591836734694,
                  improve_to: 286.9591979980469,
                },
              },
              propensity: {
                improve_from: 0.3409532324007056,
                improve_from_text: '34%',
                improve_to: 0.34160717379903904,
                improve_to_text: '34%',
                improve_by: 0.0006539413983335353,
              },
              revenue: {
                past: 229591619.10095215,
                elift_improve_from: 23017615.355790198,
                elift_improve_to: 22997896.83842331,
                elift_improve_by: 4741481.4,
              },
            },
            medium: {
              account_count: 6186,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Usage (minutes)',
                std_platform: 'pendo',
                std_object: 'reports',
                std_field: 'usagetrending',
              },
              metrics: {
                '30-day average': {
                  improve_from: 7,
                  improve_to: 286.9591979980469,
                },
              },
              propensity: {
                improve_from: 0.3410617099295104,
                improve_from_text: '34%',
                improve_to: 0.341881917219528,
                improve_to_text: '34%',
                improve_by: 0.0008202072900176374,
              },
              revenue: {
                past: 307334192.8273201,
                elift_improve_from: 31940138.06138944,
                elift_improve_to: 31924722.990550708,
                elift_improve_by: 4278018.17,
              },
            },
          },
        },
        {
          driver_id: '2-010',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:numevents@name[Templates]:sum|lift:p90d1',
          family: {
            platform: 'intercom',
            object: 'Conversations',
            field: 'Unread',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: 'numevents@name[Templates]',
          },
          segments: {
            medium: {
              account_count: 5657,
              family: {
                platform: 'intercom',
                object: 'Conversations',
                field: 'Unread',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents@name[Templates]',
              },
              metrics: {
                '180-day average': {
                  improve_from: 1.2142857142857142,
                  improve_to: 25.32608695652174,
                },
              },
              propensity: {
                improve_from: 0.33559339191796944,
                improve_from_text: '34%',
                improve_to: 0.33615674209518204,
                improve_to_text: '34%',
                improve_by: 0.000563350177212594,
              },
              revenue: {
                past: 234614774.54180717,
                elift_improve_from: 30399547.94218122,
                elift_improve_to: 30472673.174062304,
                elift_improve_by: 4713076.29,
              },
            },
            low: {
              account_count: 4675,
              family: {
                platform: 'intercom',
                object: 'Conversations',
                field: 'Unread',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents@name[Templates]',
              },
              metrics: {
                '180-day average': {
                  improve_from: 25.32608695652174,
                  improve_to: 25.32608695652174,
                },
              },
              propensity: {
                improve_from: 0.351452614132188,
                improve_from_text: '35%',
                improve_to: 0.3516261154146641,
                improve_to_text: '35%',
                improve_by: 0.0001735012824760991,
              },
              revenue: {
                past: 228031461.65828705,
                elift_improve_from: 26664448.762319613,
                elift_improve_to: 26682346.668816794,
                elift_improve_by: 4526286.2,
              },
            },
            high: {
              account_count: 4235,
              family: {
                platform: 'intercom',
                object: 'Conversations',
                field: 'Unread',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents@name[Templates]',
              },
              metrics: {
                '180-day average': {
                  improve_from: 15.642857142857142,
                  improve_to: 25.32608695652174,
                },
              },
              propensity: {
                improve_from: 0.34013853751269824,
                improve_from_text: '34%',
                improve_to: 0.3411033277921926,
                improve_to_text: '34%',
                improve_by: 0.0009647902794944221,
              },
              revenue: {
                past: 203362327.22768784,
                elift_improve_from: 642064270.2204851,
                elift_improve_to: 643514333.5934,
                elift_improve_by: 3617496.91,
              },
            },
          },
        },
      ],
    },
  },
}

export const demoGetAllInsightsEnterprise = {
  grid: 'account',
  dimension: 'enterprise',
  train_datetime: '2024-04-16T19-24-41Z',
  infer_datetime: '2024-04-16T20-43-40Z',
  dataset_date: '2024-04-07',
  is_revenue_quantified: true,
  events: {
    churn: {
      full_name: ['churn', 'churn in next 180 days'],
      impact: 'negative',
      drivers: [
        {
          driver_id: '2-001',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:_row@name[Settings > Integrations]:count@universal_user_id:p360d1',
          family: {
            platform: 'snowflake',
            object: 'Product Telemetry',
            field: 'Usage minutes',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: '_row@name[Settings > Integrations]',
          },
          segments: {
            low: {
              account_count: 40,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Usage minutes',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Settings > Integrations]',
              },
              metrics: {
                '90-day total': {
                  improve_from: 2.145833333333333,
                  improve_to: 16.64583333333,
                },
              },
              propensity: {
                improve_from: 0.31107659734893855,
                improve_from_text: '31%',
                improve_to: 0.311015808788202,
                improve_to_text: '31%',
                improve_by: -0.000060788560736588226,
              },
              revenue: {
                past: 167532487.22133636,
                elift_improve_from: 30655587.943233743,
                elift_improve_to: 30650510.857705064,
                elift_improve_by: 3707096.59,
              },
            },
            high: {
              account_count: 42,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Usage minutes',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Settings > Integrations]',
              },
              metrics: {
                '90-day total': {
                  improve_from: 5.454471544715446,
                  improve_to: 22.2458333333,
                },
              },
              propensity: {
                improve_from: 0.3004037536386833,
                improve_from_text: '30%',
                improve_to: 0.2947703142169636,
                improve_to_text: '29%',
                improve_by: -0.00563343942171974,
              },
              revenue: {
                past: 137512220.80507278,
                elift_improve_from: 21612885.768706784,
                elift_improve_to: 21165879.054497775,
                elift_improve_by: 3049956.49299,
              },
            },
            medium: {
              account_count: 54,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Usage minutes',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Settings > Integrations]',
              },
              metrics: {
                '90-day total': {
                  improve_from: 8.311728395061728,
                  improve_to: 12.7458333333,
                },
              },
              propensity: {
                improve_from: 0.28761633387512486,
                improve_from_text: '29%',
                improve_to: 0.2868471052768897,
                improve_to_text: '29%',
                improve_by: -0.0007692285982351604,
              },
              revenue: {
                past: 266170373.97494316,
                elift_improve_from: 48410714.94292186,
                elift_improve_to: 48281618.65093581,
                elift_improve_by: 2469646.74899,
              },
            },
          },
        },
        {
          driver_id: '2-002',
          driver_type: 'insight',
          feature: 'salesforce:task:salesforce_task_custom_field_boolean_1:sum:p90d1',
          family: {
            platform: 'salesforce',
            object: 'Marketing Outreach',
            field: 'Attends webinar',
            std_platform: 'salesforce',
            std_object: 'task',
            std_field: 'salesforce_task_custom_field_boolean_1',
          },
          segments: {
            low: {
              account_count: 50,
              family: {
                platform: 'salesforce',
                object: 'Marketing Outreach',
                field: 'Attends webinar',
                std_platform: 'salesforce',
                std_object: 'task',
                std_field: 'salesforce_task_custom_field_boolean_1',
              },
              metrics: {
                '90-day average': {
                  improve_from: 6.36,
                  improve_to: 6.36,
                },
              },
              propensity: {
                improve_from: 0.2617894250843391,
                improve_from_text: '26%',
                improve_to: 0.2612230017158839,
                improve_to_text: '26%',
                improve_by: -0.0005664233684551334,
              },
              revenue: {
                past: 267297277.7478218,
                elift_improve_from: 47795644.445462525,
                elift_improve_to: 47709372.05028616,
                elift_improve_by: 2700193.79949,
              },
            },
            high: {
              account_count: 66,
              family: {
                platform: 'salesforce',
                object: 'Marketing Outreach',
                field: 'Attends webinar',
                std_platform: 'salesforce',
                std_object: 'task',
                std_field: 'salesforce_task_custom_field_boolean_1',
              },
              metrics: {
                '90-day average': {
                  improve_from: 2.4,
                  improve_to: 6.36,
                },
              },
              propensity: {
                improve_from: 0.3354339960511853,
                improve_from_text: '34%',
                improve_to: 0.332541000635198,
                improve_to_text: '33%',
                improve_by: -0.0028929954159873273,
              },
              revenue: {
                past: 259566144.4337368,
                elift_improve_from: 49197819.1273158,
                elift_improve_to: 48757061.49316439,
                elift_improve_by: 2186419.65159,
              },
            },
            medium: {
              account_count: 52,
              family: {
                platform: 'salesforce',
                object: 'Marketing Outreach',
                field: 'Attends webinar',
                std_platform: 'salesforce',
                std_object: 'task',
                std_field: 'salesforce_task_custom_field_boolean_1',
              },
              metrics: {
                '90-day average': {
                  improve_from: 1.3269230769230769,
                  improve_to: 6.36,
                },
              },
              propensity: {
                improve_from: 0.31059413868917185,
                improve_from_text: '31%',
                improve_to: 0.3084005035512544,
                improve_to_text: '31%',
                improve_by: -0.0021936351379173895,
              },
              revenue: {
                past: 211768396.79374695,
                elift_improve_from: 38697201.33854652,
                elift_improve_to: 38427231.81779869,
                elift_improve_by: 3852409.44077,
              },
            },
          },
        },
        {
          driver_id: '2-011',
          driver_type: 'insight',
          feature: 'chorusai',
          family: {
            platform: 'chorusai',
            object: 'Chorus.ai = [COMPETITOR]',
            field: 'Engagement_tracker_match name',
            std_platform: 'chorusai',
            std_object: 'chorusai',
            std_field: 'chorusai',
          },
          segments: {
            high: {
              account_count: 43,
              family: {
                platform: 'chorusai',
                object: 'Chorus.ai = [COMPETITOR]',
                field: 'Engagement_tracker_match name',
                std_platform: 'chorusai',
                std_object: 'chorusai',
                std_field: 'chorusai',
              },
              metrics: {
                '30-day average': {
                  improve_from: 1,
                  improve_to: 0,
                },
              },
              propensity: {
                improve_from: 0.3230527205390279,
                improve_from_text: '32%',
                improve_to: 0.3219645206605563,
                improve_to_text: '32%',
                improve_by: -0.0010881998784715685,
              },
              revenue: {
                past: 193853322.8416443,
                elift_improve_from: 40927378.06415201,
                elift_improve_to: 40778779.26520206,
                elift_improve_by: 3328151.66,
              },
            },
            low: {
              account_count: 40,
              family: {
                platform: 'chorusai',
                object: 'Chorus.ai = [COMPETITOR]',
                field: 'Engagement_tracker_match name',
                std_platform: 'chorusai',
                std_object: 'chorusai',
                std_field: 'chorusai',
              },
              metrics: {
                '30-day average': {
                  improve_from: 1,
                  improve_to: 0,
                },
              },
              propensity: {
                improve_from: 0.3095759864811509,
                improve_from_text: '31%',
                improve_to: 0.3092825838688459,
                improve_to_text: '31%',
                improve_by: -0.00029340261230495886,
              },
              revenue: {
                past: 211878248.02150726,
                elift_improve_from: 43858903.960561536,
                elift_improve_to: 43824923.65844307,
                elift_improve_by: 2335592.6,
              },
            },
            medium: {
              account_count: 56,
              family: {
                platform: 'chorusai',
                object: 'Chorus.ai = [COMPETITOR]',
                field: 'Engagement_tracker_match name',
                std_platform: 'chorusai',
                std_object: 'chorusai',
                std_field: 'chorusai',
              },
              metrics: {
                '30-day average': {
                  improve_from: 1,
                  improve_to: 0,
                },
              },
              propensity: {
                improve_from: 0.32771773234673357,
                improve_from_text: '33%',
                improve_to: 0.3270290011091582,
                improve_to_text: '33%',
                improve_by: -0.0006887312375752781,
              },
              revenue: {
                past: 235492731.58578873,
                elift_improve_from: 47643495.85442367,
                elift_improve_to: 47540269.98886779,
                elift_improve_by: 2435736.14,
              },
            },
          },
        },
        {
          driver_id: '2-003',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:_row@name[Invoices - Tesorio]:count@universal_user_id:p360d1',
          family: {
            platform: 'intercom',
            object: 'tickets',
            field: 'Time to last ticket',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: '_row@name[Invoices - Tesorio]',
          },
          segments: {
            low: {
              account_count: 49,
              family: {
                platform: 'intercom',
                object: 'tickets',
                field: 'Time to last ticket',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Invoices - Tesorio]',
              },
              metrics: {
                'Time to last ticket': {
                  improve_from: 168.7132664954093,
                  improve_to: 168.7132664954093,
                },
              },
              propensity: {
                improve_from: 0.27705472125931574,
                improve_from_text: '28%',
                improve_to: 0.27657289707257454,
                improve_to_text: '28%',
                improve_by: -0.00048182418674117217,
              },
              revenue: {
                past: 256586683.4552765,
                elift_improve_from: 47250578.864719495,
                elift_improve_to: 47173822.48182945,
                elift_improve_by: 3341425.43109,
              },
            },
            high: {
              account_count: 47,
              family: {
                platform: 'intercom',
                object: 'tickets',
                field: 'Time to last ticket',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Invoices - Tesorio]',
              },
              metrics: {
                'Time to last ticket': {
                  improve_from: 9.636347517730496,
                  improve_to: 168.7132664954093,
                },
              },
              propensity: {
                improve_from: 0.3214313163514601,
                improve_from_text: '32%',
                improve_to: 0.31797464313502116,
                improve_to_text: '32%',
                improve_by: -0.003456673216438888,
              },
              revenue: {
                past: 191270928.03897858,
                elift_improve_from: 34852704.352396116,
                elift_improve_to: 34465139.913920015,
                elift_improve_by: 3703886.0896,
              },
            },
            medium: {
              account_count: 61,
              family: {
                platform: 'intercom',
                object: 'tickets',
                field: 'Time to last ticket',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Invoices - Tesorio]',
              },
              metrics: {
                'Time to last ticket': {
                  improve_from: 47.64017779361686,
                  improve_to: 168.7132664954093,
                },
              },
              propensity: {
                improve_from: 0.3004251338194993,
                improve_from_text: '30%',
                improve_to: 0.297874168777123,
                improve_to_text: '30%',
                improve_by: -0.002550965042376169,
              },
              revenue: {
                past: 249195161.0508442,
                elift_improve_from: 44694916.86129226,
                elift_improve_to: 44322854.13278761,
                elift_improve_by: 3430416.89491,
              },
            },
          },
        },
        {
          driver_id: '2-004',
          driver_type: 'insight',
          feature:
            'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1',
          family: {
            platform: 'snowflake',
            object: 'Product Telemetry',
            field: 'Participants added',
            std_platform: 'pendo',
            std_object: 'feature_telemetry',
            std_field: 'numminutes@name[ar-invoices-table-followup-activity-email-views]',
          },
          segments: {
            medium: {
              account_count: 57,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Participants added',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numminutes@name[ar-invoices-table-followup-activity-email-views]',
              },
              metrics: {
                '90-day total': {
                  improve_from: 1.0701754385964912,
                  improve_to: 284.17619047619036,
                },
              },
              propensity: {
                improve_from: 0.30996492991574104,
                improve_from_text: '31%',
                improve_to: 0.308079201352912,
                improve_to_text: '31%',
                improve_by: -0.0018857285628291085,
              },
              revenue: {
                past: 235492731.58578873,
                elift_improve_from: 44485434.68897082,
                elift_improve_to: 44212235.954419695,
                elift_improve_by: 2017610.83284,
              },
            },
            high: {
              account_count: 40,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Participants added',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numminutes@name[ar-invoices-table-followup-activity-email-views]',
              },
              metrics: {
                '90-day total': {
                  improve_from: 166.06499999999997,
                  improve_to: 284.17619047619036,
                },
              },
              propensity: {
                improve_from: 0.29944546467454847,
                improve_from_text: '30%',
                improve_to: 0.29650323800108175,
                improve_to_text: '30%',
                improve_by: -0.002942226673466748,
              },
              revenue: {
                past: 193853322.8416443,
                elift_improve_from: 36374711.235590585,
                elift_improve_to: 35988238.21483179,
                elift_improve_by: 3759494.95955,
              },
            },
            low: {
              account_count: 42,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Participants added',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numminutes@name[ar-invoices-table-followup-activity-email-views]',
              },
              metrics: {
                '90-day total': {
                  improve_from: 214.476190476,
                  improve_to: 284.17619047619036,
                },
              },
              propensity: {
                improve_from: 0.2633577956607721,
                improve_from_text: '26%',
                improve_to: 0.2625795810592746,
                improve_to_text: '26%',
                improve_by: -0.0007782146014975231,
              },
              revenue: {
                past: 211878248.02150726,
                elift_improve_from: 36426936.25388729,
                elift_improve_to: 36340925.37596378,
                elift_improve_by: 3009707.92148,
              },
            },
          },
        },
        {
          driver_id: '2-005',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:numevents@name[Customer Payments]:mean|lift:p180d1',
          family: {
            platform: 'pendo',
            object: 'pages',
            field: 'Feature A',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: 'numevents@name[Customer Payments]',
          },
          segments: {
            low: {
              account_count: 48,
              family: {
                platform: 'pendo',
                object: 'pages',
                field: 'Feature A',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents@name[Customer Payments]',
              },
              metrics: {
                '90-day total': {
                  improve_from: 4.442909304075212,
                  improve_to: 4.442909304075212,
                },
              },
              propensity: {
                improve_from: 0.28731444573107084,
                improve_from_text: '29%',
                improve_to: 0.28696380133631055,
                improve_to_text: '29%',
                improve_by: -0.00035064439476030956,
              },
              revenue: {
                past: 239975960.256958,
                elift_improve_from: 45430929.15004726,
                elift_improve_to: 45387075.674455725,
                elift_improve_by: 3863757.58811,
              },
            },
            high: {
              account_count: 46,
              family: {
                platform: 'pendo',
                object: 'pages',
                field: 'Feature A',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents@name[Customer Payments]',
              },
              metrics: {
                '90-day total': {
                  improve_from: 1.0922434246212667,
                  improve_to: 4.442909304075212,
                },
              },
              propensity: {
                improve_from: 0.3096523881919177,
                improve_from_text: '31%',
                improve_to: 0.30721790555756295,
                improve_to_text: '31%',
                improve_by: -0.0024344826343546773,
              },
              revenue: {
                past: 213321911.0637188,
                elift_improve_from: 41533622.66858641,
                elift_improve_to: 41210126.54089355,
                elift_improve_by: 3097954.07297,
              },
            },
            medium: {
              account_count: 62,
              family: {
                platform: 'pendo',
                object: 'pages',
                field: 'Feature A',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents@name[Customer Payments]',
              },
              metrics: {
                '90-day total': {
                  improve_from: 2.5549585662662695,
                  improve_to: 4.442909304075212,
                },
              },
              propensity: {
                improve_from: 0.2966214551864271,
                improve_from_text: '30%',
                improve_to: 0.2952760169585801,
                improve_to_text: '30%',
                improve_by: -0.0013454382278470717,
              },
              revenue: {
                past: 250574653.04403305,
                elift_improve_from: 41893787.75660134,
                elift_improve_to: 41700446.170204856,
                elift_improve_by: 3976643.51543,
              },
            },
          },
        },
        {
          driver_id: '2-006',
          driver_type: 'insight',
          feature: 'pendo:feature_telemetry:numevents@name[Invoice Drawer > Notes & Emails Tab]:mean:p360d1',
          family: {
            platform: 'intercom',
            object: 'conversations',
            field: 'Median time to reply',
            std_platform: 'pendo',
            std_object: 'feature_telemetry',
            std_field: 'numevents@name[Invoice Drawer > Notes & Emails Tab]',
          },
          segments: {
            medium: {
              account_count: 65,
              family: {
                platform: 'intercom',
                object: 'conversations',
                field: 'Median time to reply',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numevents@name[Invoice Drawer > Notes & Emails Tab]',
              },
              metrics: {
                'Median time to reply': {
                  improve_from: 2.1905054670916857,
                  improve_to: 4.689318739172428,
                },
              },
              propensity: {
                improve_from: 0.29755459437844484,
                improve_from_text: '30%',
                improve_to: 0.295755553580285,
                improve_to_text: '30%',
                improve_by: -0.001799040798159839,
              },
              revenue: {
                past: 309642364.51334953,
                elift_improve_from: 57665863.706261024,
                elift_improve_to: 57315678.062095605,
                elift_improve_by: 3913445.34974,
              },
            },
            high: {
              account_count: 48,
              family: {
                platform: 'intercom',
                object: 'conversations',
                field: 'Median time to reply',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numevents@name[Invoice Drawer > Notes & Emails Tab]',
              },
              metrics: {
                'Median time to reply': {
                  improve_from: 1.2144507292109186,
                  improve_to: 4.689318739172428,
                },
              },
              propensity: {
                improve_from: 0.32042259554304847,
                improve_from_text: '32%',
                improve_to: 0.31781711285978154,
                improve_to_text: '32%',
                improve_by: -0.0026054826832669162,
              },
              revenue: {
                past: 198141515.2261734,
                elift_improve_from: 37842785.6963893,
                elift_improve_to: 37530231.11098633,
                elift_improve_by: 3954312.15922,
              },
            },
            low: {
              account_count: 49,
              family: {
                platform: 'intercom',
                object: 'conversations',
                field: 'Median time to reply',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numevents@name[Invoice Drawer > Notes & Emails Tab]',
              },
              metrics: {
                'Median time to reply': {
                  improve_from: 4.689318739172428,
                  improve_to: 4.689318739172428,
                },
              },
              propensity: {
                improve_from: 0.2869783896103588,
                improve_from_text: '29%',
                improve_to: 0.28674491091832954,
                improve_to_text: '29%',
                improve_by: -0.0002334786920292328,
              },
              revenue: {
                past: 213605044.94333267,
                elift_improve_from: 36770166.89960753,
                elift_improve_to: 36741259.44494329,
                elift_improve_by: 2553483.11234,
              },
            },
          },
        },
        {
          driver_id: '2-007',
          driver_type: 'insight',
          feature: 'pendo:feature_telemetry:_row@name[dashboard-workspace-menu]:count|lift:p180d1',
          family: {
            platform: 'snowflake',
            object: 'features',
            field: 'Events on [Sync status]',
            std_platform: 'pendo',
            std_object: 'feature_telemetry',
            std_field: '_row@name[dashboard-workspace-menu]',
          },
          segments: {
            medium: {
              account_count: 58,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Events on [Sync status]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[dashboard-workspace-menu]',
              },
              metrics: {
                '90-day average': {
                  improve_from: 0.896551724137931,
                  improve_to: 43.659090909090786,
                },
              },
              propensity: {
                improve_from: 0.31953202582546986,
                improve_from_text: '32%',
                improve_to: 0.318012503851429,
                improve_to_text: '32%',
                improve_by: -0.0015195219740409046,
              },
              revenue: {
                past: 221672186.70816422,
                elift_improve_from: 40868708.50703462,
                elift_improve_to: 40676904.53473556,
                elift_improve_by: 2607911.07922,
              },
            },
            high: {
              account_count: 39,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Events on [Sync status]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[dashboard-workspace-menu]',
              },
              metrics: {
                '90-day average': {
                  improve_from: 21.128205128205128,
                  improve_to: 43.659090909090786,
                },
              },
              propensity: {
                improve_from: 0.2891709313294197,
                improve_from_text: '29%',
                improve_to: 0.28703522535307247,
                improve_to_text: '29%',
                improve_by: -0.002135705976347214,
              },
              revenue: {
                past: 196231291.89004898,
                elift_improve_from: 36861583.090298876,
                elift_improve_to: 36556904.02161536,
                elift_improve_by: 2596831.66085,
              },
            },
            low: {
              account_count: 44,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Events on [Sync status]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[dashboard-workspace-menu]',
              },
              metrics: {
                '90-day average': {
                  improve_from: 43.659090909090786,
                  improve_to: 43.659090909090786,
                },
              },
              propensity: {
                improve_from: 0.2587809320037048,
                improve_from_text: '26%',
                improve_to: 0.25843191684914424,
                improve_to_text: '26%',
                improve_by: -0.0003490151545605896,
              },
              revenue: {
                past: 234714482.32355118,
                elift_improve_from: 40901149.3459399,
                elift_improve_to: 40871977.92288434,
                elift_improve_by: 2075908.5642,
              },
            },
          },
        },
        {
          driver_id: '2-008',
          driver_type: 'insight',
          feature: 'pendo:guide_telemetry:ismultistep:mean:p60d1',
          family: {
            platform: 'snowflake',
            object: 'features',
            field: 'Events on [Feature B]',
            std_platform: 'pendo',
            std_object: 'guide_telemetry',
            std_field: 'ismultistep',
          },
          segments: {
            low: {
              account_count: 73,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Events on [Feature B]',
                std_platform: 'pendo',
                std_object: 'guide_telemetry',
                std_field: 'ismultistep',
              },
              metrics: {
                '90-day total': {
                  improve_from: 0.4392045454545448,
                  improve_to: 0.4392045454545448,
                },
              },
              propensity: {
                improve_from: 0.3081320942682594,
                improve_from_text: '31%',
                improve_to: 0.3081320942682594,
                improve_to_text: '31%',
                improve_by: -1.140640093792969e-18,
              },
              revenue: {
                past: 293357099.2351055,
                elift_improve_from: 51765430.432298675,
                elift_improve_to: 51765430.432298675,
                elift_improve_by: 2975401.15297,
              },
            },
            medium: {
              account_count: 41,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Events on [Feature B]',
                std_platform: 'pendo',
                std_object: 'guide_telemetry',
                std_field: 'ismultistep',
              },
              metrics: {
                '90-day total': {
                  improve_from: 0.392413916899116,
                  improve_to: 0.4392045454545448,
                },
              },
              propensity: {
                improve_from: 0.2908808831552723,
                improve_from_text: '29%',
                improve_to: 0.2904498984050835,
                improve_to_text: '29%',
                improve_by: -0.0004309847501887466,
              },
              revenue: {
                past: 205272450.27980804,
                elift_improve_from: 38491803.064771034,
                elift_improve_to: 38439739.76321006,
                elift_improve_by: 3909550.25215,
              },
            },
            high: {
              account_count: 48,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Events on [Feature B]',
                std_platform: 'pendo',
                std_object: 'guide_telemetry',
                std_field: 'ismultistep',
              },
              metrics: {
                '90-day total': {
                  improve_from: 0.23793695067127,
                  improve_to: 0.4392045454545448,
                },
              },
              propensity: {
                improve_from: 0.2961473863562837,
                improve_from_text: '30%',
                improve_to: 0.2942540068951665,
                improve_to_text: '29%',
                improve_by: -0.0018933794611171138,
              },
              revenue: {
                past: 230355757.20262527,
                elift_improve_from: 43654972.49007069,
                elift_improve_to: 43360964.29240753,
                elift_improve_by: 2343485.92554,
              },
            },
          },
        },
        {
          driver_id: '2-009',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:numminutes@name[Registration]:mean:p90d1',
          family: {
            platform: 'snowflake',
            object: 'features',
            field: 'Events on [Feature C]',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: 'numminutes@name[Registration]',
          },
          segments: {
            low: {
              account_count: 28,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Events on [Feature C]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numminutes@name[Registration]',
              },
              metrics: {
                '90-day total': {
                  improve_from: 1.9028339021966143,
                  improve_to: 1.9028339021966143,
                },
              },
              propensity: {
                improve_from: 0.2890439588164521,
                improve_from_text: '29%',
                improve_to: 0.2886967408572172,
                improve_to_text: '29%',
                improve_by: -0.0003472179592349572,
              },
              revenue: {
                past: 119304951.30615234,
                elift_improve_from: 21155226.242940575,
                elift_improve_to: 21123146.210685186,
                elift_improve_by: 3999063.09722,
              },
            },
            medium: {
              account_count: 21,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Events on [Feature C]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numminutes@name[Registration]',
              },
              metrics: {
                '90-day total': {
                  improve_from: 1.1647866265968405,
                  improve_to: 1.9028339021966143,
                },
              },
              propensity: {
                improve_from: 0.2759555225732895,
                improve_from_text: '28%',
                improve_to: 0.2746046452421648,
                improve_to_text: '27%',
                improve_by: -0.0013508773311245964,
              },
              revenue: {
                past: 89671970.57132721,
                elift_improve_from: 15399711.461783396,
                elift_improve_to: 15321656.631476332,
                elift_improve_by: 2145181.11712,
              },
            },
            high: {
              account_count: 46,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Events on [Feature C]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numminutes@name[Registration]',
              },
              metrics: {
                '90-day total': {
                  improve_from: 1,
                  improve_to: 1.9028339021966143,
                },
              },
              propensity: {
                improve_from: 0.2915336908968734,
                improve_from_text: '29%',
                improve_to: 0.2898642050836574,
                improve_to_text: '29%',
                improve_by: -0.0016694858132159935,
              },
              revenue: {
                past: 250079790.7828331,
                elift_improve_from: 48282316.076543584,
                elift_improve_to: 47999171.43902296,
                elift_improve_by: 3462931.70006,
              },
            },
          },
        },
        {
          driver_id: '2-010',
          driver_type: 'insight',
          feature: 'intercom:conversations:statistics__count_reopens:sum@universal_user_id|lift:p90d1',
          family: {
            platform: 'pendo',
            object: 'pages',
            field: 'Navigations on [Inbox]',
            std_platform: 'intercom',
            std_object: 'conversations',
            std_field: 'statistics__count_reopens',
          },
          segments: {
            high: {
              account_count: 29,
              family: {
                platform: 'pendo',
                object: 'pages',
                field: 'Navigations on [Inbox]',
                std_platform: 'intercom',
                std_object: 'conversations',
                std_field: 'statistics__count_reopens',
              },
              metrics: {
                '': {
                  improve_from: 1.9350574712643678,
                  improve_to: 3.0529279279279278,
                },
              },
              propensity: {
                improve_from: 0.28497329176957087,
                improve_from_text: '28%',
                improve_to: 0.28218308037359513,
                improve_to_text: '28%',
                improve_by: -0.002790211395975758,
              },
              revenue: {
                past: 147754045.37143707,
                elift_improve_from: 28396029.915756896,
                elift_improve_to: 28114783.917878047,
                elift_improve_by: 2978107.20789,
              },
            },
            medium: {
              account_count: 48,
              family: {
                platform: 'pendo',
                object: 'pages',
                field: 'Navigations on [Inbox]',
                std_platform: 'intercom',
                std_object: 'conversations',
                std_field: 'statistics__count_reopens',
              },
              metrics: {
                '': {
                  improve_from: 0.3650793650793651,
                  improve_to: 3.0529279279279278,
                },
              },
              propensity: {
                improve_from: 0.3035076989152355,
                improve_from_text: '30%',
                improve_to: 0.3019389124777094,
                improve_to_text: '30%',
                improve_by: -0.0015687864375261817,
              },
              revenue: {
                past: 205813423.67391586,
                elift_improve_from: 35678377.257978074,
                elift_improve_to: 35494663.68958988,
                elift_improve_by: 3830397.58028,
              },
            },
            low: {
              account_count: 37,
              family: {
                platform: 'pendo',
                object: 'pages',
                field: 'Navigations on [Inbox]',
                std_platform: 'intercom',
                std_object: 'conversations',
                std_field: 'statistics__count_reopens',
              },
              metrics: {
                '': {
                  improve_from: 3.0529279279279278,
                  improve_to: 3.0529279279279278,
                },
              },
              propensity: {
                improve_from: 0.2787796797130925,
                improve_from_text: '28%',
                improve_to: 0.27841986160081456,
                improve_to_text: '28%',
                improve_by: -0.00035981811227792017,
              },
              revenue: {
                past: 183881354.20274734,
                elift_improve_from: 34129720.72210027,
                elift_improve_to: 34084891.6366505,
                elift_improve_by: 2346346.26212,
              },
            },
          },
        },
      ],
    },
    expansion: {
      full_name: ['expansion', 'expansion in next 180 days'],
      impact: 'positive',
      drivers: [
        {
          driver_id: '2-001',
          driver_type: 'insight',
          feature: 'salesforce:task:salesforce_task_custom_field_boolean_1:sum:p90d1',
          family: {
            platform: 'snowflake',
            object: 'features',
            field: 'Minutes on [Sync status]',
            std_platform: 'salesforce',
            std_object: 'task',
            std_field: 'salesforce_task_custom_field_boolean_1',
          },
          segments: {
            low: {
              account_count: 50,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Minutes on [Sync status]',
                std_platform: 'salesforce',
                std_object: 'task',
                std_field: 'salesforce_task_custom_field_boolean_1',
              },
              metrics: {
                '90-day average': {
                  improve_from: 6.36,
                  improve_to: 6.36,
                },
              },
              propensity: {
                improve_from: 0.3739475066043502,
                improve_from_text: '37%',
                improve_to: 0.3746638313186335,
                improve_to_text: '37%',
                improve_by: 0.0007163247142832629,
              },
              revenue: {
                past: 267297277.7478218,
                elift_improve_from: 44027058.47452598,
                elift_improve_to: 44140446.39876447,
                elift_improve_by: 3848596.36616,
              },
            },
            medium: {
              account_count: 52,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Minutes on [Sync status]',
                std_platform: 'salesforce',
                std_object: 'task',
                std_field: 'salesforce_task_custom_field_boolean_1',
              },
              metrics: {
                '90-day average': {
                  improve_from: 1.3269230769230769,
                  improve_to: 6.36,
                },
              },
              propensity: {
                improve_from: 0.33560316685816954,
                improve_from_text: '34%',
                improve_to: 0.33824354296724646,
                improve_to_text: '34%',
                improve_by: 0.0026403761090768437,
              },
              revenue: {
                past: 211768396.79374695,
                elift_improve_from: 33918426.49421565,
                elift_improve_to: 34183194.452825665,
                elift_improve_by: 2912428.31202,
              },
            },
            high: {
              account_count: 66,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Minutes on [Sync status]',
                std_platform: 'salesforce',
                std_object: 'task',
                std_field: 'salesforce_task_custom_field_boolean_1',
              },
              metrics: {
                '90-day average': {
                  improve_from: 0,
                  improve_to: 6.36,
                },
              },
              propensity: {
                improve_from: 0.3289767126344315,
                improve_from_text: '33%',
                improve_to: 0.3323239649494869,
                improve_to_text: '33%',
                improve_by: 0.003347252315055287,
              },
              revenue: {
                past: 259566144.4337368,
                elift_improve_from: 42751938.070078574,
                elift_improve_to: 43179740.924285606,
                elift_improve_by: 3608025.32332,
              },
            },
          },
        },
        {
          driver_id: '2-002',
          driver_type: 'insight',
          feature: 'pendo:feature_telemetry:_row@name[Tesorio Logo]:count|lift:p90d1',
          family: {
            platform: 'snowflake',
            object: 'Product Telemetry',
            field: 'Participants added',
            std_platform: 'pendo',
            std_object: 'feature_telemetry',
            std_field: '_row@name[Tesorio Logo]',
          },
          segments: {
            high: {
              account_count: 38,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Participants added',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[Tesorio Logo]',
              },
              metrics: {
                '180-day total': {
                  improve_from: -4.473684210526315,
                  improve_to: 6.8604651162790695,
                },
              },
              propensity: {
                improve_from: 0.3515037696540188,
                improve_from_text: '35%',
                improve_to: 0.3561936773586407,
                improve_to_text: '36%',
                improve_by: 0.0046899077046219095,
              },
              revenue: {
                past: 206402409.13295746,
                elift_improve_from: 31441312.51601881,
                elift_improve_to: 31832137.712106533,
                elift_improve_by: 2229013.02118,
              },
            },
            medium: {
              account_count: 46,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Participants added',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[Tesorio Logo]',
              },
              metrics: {
                '180-day total': {
                  improve_from: 2.9565217391304346,
                  improve_to: 6.8604651162790695,
                },
              },
              propensity: {
                improve_from: 0.34348955424481353,
                improve_from_text: '34%',
                improve_to: 0.3444451131868736,
                improve_to_text: '34%',
                improve_by: 0.000955558942060034,
              },
              revenue: {
                past: 226047682.71903992,
                elift_improve_from: 29124549.48890476,
                elift_improve_to: 29208724.152111776,
                elift_improve_by: 2428926.95053,
              },
            },
            low: {
              account_count: 43,
              family: {
                platform: 'snowflake',
                object: 'Product Telemetry',
                field: 'Participants added',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: '_row@name[Tesorio Logo]',
              },
              metrics: {
                '180-day total': {
                  improve_from: 6.8604651162790695,
                  improve_to: 21.16046511627,
                },
              },
              propensity: {
                improve_from: 0.3790184562120084,
                improve_from_text: '38%',
                improve_to: 0.3793000102872028,
                improve_to_text: '38%',
                improve_by: 0.00028155407519433177,
              },
              revenue: {
                past: 185339316.7087555,
                elift_improve_from: 32473859.244799923,
                elift_improve_to: 32500082.674855582,
                elift_improve_by: 2814527.90967,
              },
            },
          },
        },
        {
          driver_id: '2-011',
          driver_type: 'insight',
          feature: 'chorusai',
          family: {
            platform: 'chorusai',
            object: 'Chorus.ai = [COMPETITOR]',
            field: 'Engagement_tracker_match name',
            std_platform: 'chorusai',
            std_object: 'chorusai',
            std_field: 'chorusai',
          },
          segments: {
            high: {
              account_count: 43,
              family: {
                platform: 'chorusai',
                object: 'Chorus.ai = [COMPETITOR]',
                field: 'Engagement_tracker_match name',
                std_platform: 'chorusai',
                std_object: 'chorusai',
                std_field: 'chorusai',
              },
              metrics: {
                '30-day average': {
                  improve_from: 1,
                  improve_to: 0,
                },
              },
              propensity: {
                improve_from: 0.3230527205390279,
                improve_from_text: '32%',
                improve_to: 0.3219645206605563,
                improve_to_text: '32%',
                improve_by: -0.0010881998784715685,
              },
              revenue: {
                past: 193853322.8416443,
                elift_improve_from: 40927378.06415201,
                elift_improve_to: 40778779.26520206,
                elift_improve_by: 3748151.66,
              },
            },
            low: {
              account_count: 40,
              family: {
                platform: 'chorusai',
                object: 'Chorus.ai = [COMPETITOR]',
                field: 'Engagement_tracker_match name',
                std_platform: 'chorusai',
                std_object: 'chorusai',
                std_field: 'chorusai',
              },
              metrics: {
                '30-day average': {
                  improve_from: 1,
                  improve_to: 0,
                },
              },
              propensity: {
                improve_from: 0.3095759864811509,
                improve_from_text: '31%',
                improve_to: 0.3092825838688459,
                improve_to_text: '31%',
                improve_by: -0.00029340261230495886,
              },
              revenue: {
                past: 211878248.02150726,
                elift_improve_from: 43858903.960561536,
                elift_improve_to: 43824923.65844307,
                elift_improve_by: 3925592.6,
              },
            },
            medium: {
              account_count: 56,
              family: {
                platform: 'chorusai',
                object: 'Chorus.ai = [COMPETITOR]',
                field: 'Engagement_tracker_match name',
                std_platform: 'chorusai',
                std_object: 'chorusai',
                std_field: 'chorusai',
              },
              metrics: {
                '30-day average': {
                  improve_from: 1,
                  improve_to: 0,
                },
              },
              propensity: {
                improve_from: 0.32771773234673357,
                improve_from_text: '33%',
                improve_to: 0.3270290011091582,
                improve_to_text: '33%',
                improve_by: -0.0006887312375752781,
              },
              revenue: {
                past: 235492731.58578873,
                elift_improve_from: 47643495.85442367,
                elift_improve_to: 47540269.98886779,
                elift_improve_by: 3535736.14,
              },
            },
          },
        },
        {
          driver_id: '2-003',
          driver_type: 'insight',
          feature: 'pendo:feature_telemetry:numevents@name[Sync Status Icon]:sum|lift:p180d1',
          family: {
            platform: 'pendo',
            object: 'guides',
            field: 'Clicks on [Guide A]',
            std_platform: 'pendo',
            std_object: 'feature_telemetry',
            std_field: 'numevents@name[Sync Status Icon]',
          },
          segments: {
            high: {
              account_count: 43,
              family: {
                platform: 'pendo',
                object: 'guides',
                field: 'Clicks on [Guide A]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numevents@name[Sync Status Icon]',
              },
              metrics: {
                '90-day average': {
                  improve_from: 25.74418604651163,
                  improve_to: 36.888888888888886,
                },
              },
              propensity: {
                improve_from: 0.33709019880585367,
                improve_from_text: '34%',
                improve_to: 0.34079278409898794,
                improve_to_text: '34%',
                improve_by: 0.003702585293134311,
              },
              revenue: {
                past: 212911163.0305767,
                elift_improve_from: 34530924.61446114,
                elift_improve_to: 34892830.27538869,
                elift_improve_by: 2623923.85224,
              },
            },
            low: {
              account_count: 45,
              family: {
                platform: 'pendo',
                object: 'guides',
                field: 'Clicks on [Guide A]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numevents@name[Sync Status Icon]',
              },
              metrics: {
                '90-day average': {
                  improve_from: 36.888888888888886,
                  improve_to: 36.888888888888886,
                },
              },
              propensity: {
                improve_from: 0.37937494594697585,
                improve_from_text: '38%',
                improve_to: 0.3798529388261596,
                improve_to_text: '38%',
                improve_by: 0.000477992879183822,
              },
              revenue: {
                past: 192376461.97214127,
                elift_improve_from: 33208575.036226355,
                elift_improve_to: 33249704.480953664,
                elift_improve_by: 3885950.20506,
              },
            },
            medium: {
              account_count: 61,
              family: {
                platform: 'pendo',
                object: 'guides',
                field: 'Clicks on [Guide A]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numevents@name[Sync Status Icon]',
              },
              metrics: {
                '90-day average': {
                  improve_from: 1.2295081967213115,
                  improve_to: 36.888888888888886,
                },
              },
              propensity: {
                improve_from: 0.33947725871473355,
                improve_from_text: '34%',
                improve_to: 0.34157753622416465,
                improve_to_text: '34%',
                improve_by: 0.0021002775094310284,
              },
              revenue: {
                past: 275204129.50401306,
                elift_improve_from: 40384779.62574768,
                elift_improve_to: 40634263.411057375,
                elift_improve_by: 3967447.15745,
              },
            },
          },
        },
        {
          driver_id: '2-004',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:numevents@name[Templates]:sum|lift:p180d1',
          family: {
            platform: 'salesforce',
            object: 'opportunities',
            field: 'Time to renewal date',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: 'numevents@name[Templates]',
          },
          segments: {
            low: {
              account_count: 47,
              family: {
                platform: 'salesforce',
                object: 'opportunities',
                field: 'Time to renewal date',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents@name[Templates]',
              },
              metrics: {
                'Time to renewal date': {
                  improve_from: 50.276595744680854,
                  improve_to: 50.276595744680854,
                },
              },
              propensity: {
                improve_from: 0.3865481702985987,
                improve_from_text: '39%',
                improve_to: 0.3870307268598272,
                improve_to_text: '39%',
                improve_by: 0.0004825565612284601,
              },
              revenue: {
                past: 216897443.98841858,
                elift_improve_from: 34368458.2342584,
                elift_improve_to: 34411811.66764438,
                elift_improve_by: 2533884.02899,
              },
            },
            medium: {
              account_count: 61,
              family: {
                platform: 'salesforce',
                object: 'opportunities',
                field: 'Time to renewal date',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents@name[Templates]',
              },
              metrics: {
                'Time to renewal date': {
                  improve_from: 1.0819672131147542,
                  improve_to: 50.276595744680854,
                },
              },
              propensity: {
                improve_from: 0.3274307068051236,
                improve_from_text: '33%',
                improve_to: 0.3290994927529702,
                improve_to_text: '33%',
                improve_by: 0.0016687859478465494,
              },
              revenue: {
                past: 244340925.29740334,
                elift_improve_from: 39135883.6376652,
                elift_improve_to: 39339683.876048304,
                elift_improve_by: 3182931.13654,
              },
            },
            high: {
              account_count: 46,
              family: {
                platform: 'salesforce',
                object: 'opportunities',
                field: 'Time to renewal date',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents@name[Templates]',
              },
              metrics: {
                'Time to renewal date': {
                  improve_from: 41.23913043478261,
                  improve_to: 50.276595744680854,
                },
              },
              propensity: {
                improve_from: 0.341263483279754,
                improve_from_text: '34%',
                improve_to: 0.3444485700977477,
                improve_to_text: '34%',
                improve_by: 0.00318508681799379,
              },
              revenue: {
                past: 236178755.38158417,
                elift_improve_from: 37109131.117880836,
                elift_improve_to: 37436681.1272631,
                elift_improve_by: 2639869.99363,
              },
            },
          },
        },
        {
          driver_id: '2-005',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:numminutes@name[Settings > Integrations]:sum|lift:p90d1',
          family: {
            platform: 'snowflake',
            object: 'pages',
            field: 'Events on [Page B]',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: 'numminutes@name[Settings > Integrations]',
          },
          segments: {
            low: {
              account_count: 41,
              family: {
                platform: 'snowflake',
                object: 'pages',
                field: 'Events on [Page B]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numminutes@name[Settings > Integrations]',
              },
              metrics: {
                '90-day total': {
                  improve_from: 36.1365853658534,
                  improve_to: 36.1365853658534,
                },
              },
              propensity: {
                improve_from: 0.37776717008024435,
                improve_from_text: '38%',
                improve_to: 0.3782085996951304,
                improve_to_text: '38%',
                improve_by: 0.00044142961488599576,
              },
              revenue: {
                past: 168701932.4541092,
                elift_improve_from: 30037347.20887321,
                elift_improve_to: 30072392.75163323,
                elift_improve_by: 3531038.27875,
              },
            },
            high: {
              account_count: 38,
              family: {
                platform: 'snowflake',
                object: 'pages',
                field: 'Events on [Page B]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numminutes@name[Settings > Integrations]',
              },
              metrics: {
                '90-day total': {
                  improve_from: 28.736842105263158,
                  improve_to: 36.1365853658534,
                },
              },
              propensity: {
                improve_from: 0.3450612845063587,
                improve_from_text: '35%',
                improve_to: 0.3484210464474826,
                improve_to_text: '35%',
                improve_by: 0.0033597619411239676,
              },
              revenue: {
                past: 163991175.27723312,
                elift_improve_from: 34123219.691084154,
                elift_improve_to: 34433709.43213478,
                elift_improve_by: 2609768.5826,
              },
            },
            medium: {
              account_count: 51,
              family: {
                platform: 'snowflake',
                object: 'pages',
                field: 'Events on [Page B]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numminutes@name[Settings > Integrations]',
              },
              metrics: {
                '90-day total': {
                  improve_from: 2.4901960784313726,
                  improve_to: 36.1365853658534,
                },
              },
              propensity: {
                improve_from: 0.3450125178020755,
                improve_from_text: '35%',
                improve_to: 0.34675289114549357,
                improve_to_text: '35%',
                improve_by: 0.0017403733434180836,
              },
              revenue: {
                past: 230787082.3609829,
                elift_improve_from: 33506166.19531742,
                elift_improve_to: 33664841.20792572,
                elift_improve_by: 2533005.25133,
              },
            },
          },
        },
        {
          driver_id: '2-006',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:_row@name[Settings > Groups]:count:p60d1',
          family: {
            platform: 'snowflake',
            object: 'pages',
            field: 'Events on [Customer payments]',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: '_row@name[Settings > Groups]',
          },
          segments: {
            medium: {
              account_count: 41,
              family: {
                platform: 'snowflake',
                object: 'pages',
                field: 'Events on [Customer payments]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Settings > Groups]',
              },
              metrics: {
                '180-day average': {
                  improve_from: 2.658536585365854,
                  improve_to: 8.636363636363637,
                },
              },
              propensity: {
                improve_from: 0.362129126722718,
                improve_from_text: '36%',
                improve_to: 0.3658284560848366,
                improve_to_text: '37%',
                improve_by: 0.00369932936211855,
              },
              revenue: {
                past: 165648712.2239113,
                elift_improve_from: 40574316.349704884,
                elift_improve_to: 40980546.04501546,
                elift_improve_by: 2457251.60494,
              },
            },
            high: {
              account_count: 31,
              family: {
                platform: 'snowflake',
                object: 'pages',
                field: 'Events on [Customer payments]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Settings > Groups]',
              },
              metrics: {
                '180-day average': {
                  improve_from: 1,
                  improve_to: 8.636363636363637,
                },
              },
              propensity: {
                improve_from: 0.3413301256974263,
                improve_from_text: '34%',
                improve_to: 0.3459768586454751,
                improve_to_text: '35%',
                improve_by: 0.004646732948048721,
              },
              revenue: {
                past: 127423195.34540176,
                elift_improve_from: 21059301.561879653,
                elift_improve_to: 21355668.22805689,
                elift_improve_by: 2134237.48693,
              },
            },
            low: {
              account_count: 22,
              family: {
                platform: 'snowflake',
                object: 'pages',
                field: 'Events on [Customer payments]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Settings > Groups]',
              },
              metrics: {
                '180-day average': {
                  improve_from: 8.636363636363637,
                  improve_to: 8.636363636363637,
                },
              },
              propensity: {
                improve_from: 0.405756310598592,
                improve_from_text: '41%',
                improve_to: 0.4066200681764542,
                improve_to_text: '41%',
                improve_by: 0.0008637575778621347,
              },
              revenue: {
                past: 119741517.2756195,
                elift_improve_from: 15802720.441595452,
                elift_improve_to: 15841163.923497817,
                elift_improve_by: 2066142.90583,
              },
            },
          },
        },
        {
          driver_id: '2-007',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:numminutes@name[Registration]:sum:p90d1',
          family: {
            platform: 'salesforce',
            object: 'marketing outreach',
            field: 'Attends webinar',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: 'numminutes@name[Registration]',
          },
          segments: {
            high: {
              account_count: 24,
              family: {
                platform: 'salesforce',
                object: 'marketing outreach',
                field: 'Attends webinar',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numminutes@name[Registration]',
              },
              metrics: {
                '90-day average': {
                  improve_from: 1.4583333333333333,
                  improve_to: 33.36666666666667,
                },
              },
              propensity: {
                improve_from: 0.34035681204825446,
                improve_from_text: '34%',
                improve_to: 0.34631948550757535,
                improve_to_text: '35%',
                improve_by: 0.005962673459320974,
              },
              revenue: {
                past: 113885696.2474823,
                elift_improve_from: 16640440.248608321,
                elift_improve_to: 16936659.26571523,
                elift_improve_by: 3298049.58119,
              },
            },
            medium: {
              account_count: 41,
              family: {
                platform: 'salesforce',
                object: 'marketing outreach',
                field: 'Attends webinar',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numminutes@name[Registration]',
              },
              metrics: {
                '90-day average': {
                  improve_from: 6.463414634146342,
                  improve_to: 33.36666666666667,
                },
              },
              propensity: {
                improve_from: 0.35080437891535027,
                improve_from_text: '35%',
                improve_to: 0.35586581514653615,
                improve_to_text: '36%',
                improve_by: 0.0050614362311858715,
              },
              revenue: {
                past: 196188119.02971268,
                elift_improve_from: 27297906.285147723,
                elift_improve_to: 27685493.10113657,
                elift_improve_by: 2004983.97607,
              },
            },
            low: {
              account_count: 30,
              family: {
                platform: 'salesforce',
                object: 'marketing outreach',
                field: 'Attends webinar',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numminutes@name[Registration]',
              },
              metrics: {
                '90-day average': {
                  improve_from: 33.36666666666667,
                  improve_to: 33.36666666666667,
                },
              },
              propensity: {
                improve_from: 0.3859876490808902,
                improve_from_text: '39%',
                improve_to: 0.38733978332944885,
                improve_to_text: '39%',
                improve_by: 0.0013521342485586377,
              },
              revenue: {
                past: 148982897.38311768,
                elift_improve_from: 30961578.097623423,
                elift_improve_to: 31113746.72551394,
                elift_improve_by: 2466811.73689,
              },
            },
          },
        },
        {
          driver_id: '2-008',
          driver_type: 'insight',
          feature: 'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1',
          family: {
            platform: 'snowflake',
            object: 'features',
            field: 'Events on [Feature C]',
            std_platform: 'pendo',
            std_object: 'feature_telemetry',
            std_field: 'numminutes@name[dashboard-workspace-menu]',
          },
          segments: {
            high: {
              account_count: 22,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Events on [Feature C]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numminutes@name[dashboard-workspace-menu]',
              },
              metrics: {
                '180-day total': {
                  improve_from: 5.331060606060607,
                  improve_to: 11.141999999999953,
                },
              },
              propensity: {
                improve_from: 0.3502209248178522,
                improve_from_text: '35%',
                improve_to: 0.3530333365409963,
                improve_to_text: '35%',
                improve_by: 0.0028124117231440844,
              },
              revenue: {
                past: 103481438.35773468,
                elift_improve_from: 22207000.25888853,
                elift_improve_to: 22481272.24127835,
                elift_improve_by: 2105601.55855,
              },
            },
            medium: {
              account_count: 31,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Events on [Feature C]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numminutes@name[dashboard-workspace-menu]',
              },
              metrics: {
                '180-day total': {
                  improve_from: 1.3270609318996418,
                  improve_to: 11.141999999999953,
                },
              },
              propensity: {
                improve_from: 0.3516239526705609,
                improve_from_text: '35%',
                improve_to: 0.3532980970082228,
                improve_to_text: '35%',
                improve_by: 0.001674144337661715,
              },
              revenue: {
                past: 148057392.62981415,
                elift_improve_from: 22018484.94587095,
                elift_improve_to: 22149113.14897376,
                elift_improve_by: 2941689.46278,
              },
            },
            low: {
              account_count: 25,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Events on [Feature C]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numminutes@name[dashboard-workspace-menu]',
              },
              metrics: {
                '180-day total': {
                  improve_from: 11.141999999999953,
                  improve_to: 11.141999999999953,
                },
              },
              propensity: {
                improve_from: 0.38185839460663695,
                improve_from_text: '38%',
                improve_to: 0.38231488403331654,
                improve_to_text: '38%',
                improve_by: 0.00045648942667955825,
              },
              revenue: {
                past: 132748801.99899673,
                elift_improve_from: 17027683.83600002,
                elift_improve_to: 17057840.440850683,
                elift_improve_by: 3294976.74074,
              },
            },
          },
        },
        {
          driver_id: '2-009',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:_row@name[Settings > Integrations]:count@universal_user_id:p360d1',
          family: {
            platform: 'snowflake',
            object: 'features',
            field: 'Minutes on [Feature D]',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: '_row@name[Settings > Integrations]',
          },
          segments: {
            high: {
              account_count: 41,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Minutes on [Feature D]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Settings > Integrations]',
              },
              metrics: {
                '180-day total': {
                  improve_from: 45.454471544715446,
                  improve_to: 2.145833333333333,
                },
              },
              propensity: {
                improve_from: 0.3489430669597736,
                improve_from_text: '35%',
                improve_to: 0.352216079311948,
                improve_to_text: '35%',
                improve_by: 0.003273012352174394,
              },
              revenue: {
                past: 137512220.80507278,
                elift_improve_from: 28927060.031373963,
                elift_improve_to: 29201301.67404194,
                elift_improve_by: 2529773.75504,
              },
            },
            low: {
              account_count: 40,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Minutes on [Feature D]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Settings > Integrations]',
              },
              metrics: {
                '180-day total': {
                  improve_from: 2.145833333333333,
                  improve_to: 2.145833333333333,
                },
              },
              propensity: {
                improve_from: 0.3368528717946354,
                improve_from_text: '34%',
                improve_to: 0.3368883494389309,
                improve_to_text: '34%',
                improve_by: 0.000035477644295557606,
              },
              revenue: {
                past: 167532487.22133636,
                elift_improve_from: 26314911.761673912,
                elift_improve_to: 26317987.68486151,
                elift_improve_by: 2229743.75508,
              },
            },
            medium: {
              account_count: 54,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Minutes on [Feature D]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Settings > Integrations]',
              },
              metrics: {
                '180-day total': {
                  improve_from: 8.311728395061728,
                  improve_to: 2.145833333333333,
                },
              },
              propensity: {
                improve_from: 0.35480759178232035,
                improve_from_text: '35%',
                improve_to: 0.3552714465531291,
                improve_to_text: '36%',
                improve_by: 0.00046385477080866194,
              },
              revenue: {
                past: 266170373.97494316,
                elift_improve_from: 45639472.13361317,
                elift_improve_to: 45689343.96418306,
                elift_improve_by: 2204440.80444,
              },
            },
          },
        },
        {
          driver_id: '2-010',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:_row@name[Insights]:count|lift:p90d1',
          family: {
            platform: 'snowflake',
            object: 'features',
            field: 'Events on [Feature E]',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: '_row@name[Insights]',
          },
          segments: {
            medium: {
              account_count: 46,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Events on [Feature E]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Insights]',
              },
              metrics: {
                '90-day total': {
                  improve_from: 0.391304347826087,
                  improve_to: 10.325581395348838,
                },
              },
              propensity: {
                improve_from: 0.3371828247012787,
                improve_from_text: '34%',
                improve_to: 0.338259198562191,
                improve_to_text: '34%',
                improve_by: 0.0010763738609122328,
              },
              revenue: {
                past: 206614839.44792747,
                elift_improve_from: 31401410.970831025,
                elift_improve_to: 31503686.198772483,
                elift_improve_by: 3976213.87199,
              },
            },
            high: {
              account_count: 26,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Events on [Feature E]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Insights]',
              },
              metrics: {
                '90-day total': {
                  improve_from: -8,
                  improve_to: 10.325581395348838,
                },
              },
              propensity: {
                improve_from: 0.3533429660943588,
                improve_from_text: '35%',
                improve_to: 0.3564748038741843,
                improve_to_text: '36%',
                improve_by: 0.003131837779825634,
              },
              revenue: {
                past: 107058906.07881546,
                elift_improve_from: 23813002.067551974,
                elift_improve_to: 24083789.005393274,
                elift_improve_by: 2640986.80465,
              },
            },
            low: {
              account_count: 43,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Events on [Feature E]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Insights]',
              },
              metrics: {
                '90-day total': {
                  improve_from: 10.325581395348838,
                  improve_to: 10.325581395348838,
                },
              },
              propensity: {
                improve_from: 0.3767339358603374,
                improve_from_text: '38%',
                improve_to: 0.3771019796932911,
                improve_to_text: '38%',
                improve_by: 0.0003680438329537758,
              },
              revenue: {
                past: 235337225.21839142,
                elift_improve_from: 29647977.477705404,
                elift_improve_to: 29676421.05428686,
                elift_improve_by: 3785139.72734,
              },
            },
          },
        },
      ],
    },
  },
}

export const demoGetAllInsightsSmbScale = {
  grid: 'account',
  dimension: 'SMB/Scale',
  train_datetime: '2024-04-16T19-24-41Z',
  infer_datetime: '2024-04-16T20-43-40Z',
  dataset_date: '2024-04-07',
  is_revenue_quantified: true,
  events: {
    conversion: {
      full_name: ['conversion', 'conversion in next 180 days'],
      impact: 'positive',
      drivers: [
        {
          driver_id: '2-001',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:numevents@name[Customers]:mean:p360d1',
          family: {
            platform: 'salesforce',
            object: 'tasks',
            field: 'Tasks completed',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: 'numevents@name[Customers]',
          },
          segments: {
            low: {
              account_count: 5177,
              family: {
                platform: 'salesforce',
                object: 'tasks',
                field: 'Tasks completed',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents@name[Customers]',
              },
              metrics: {
                '30-day total': {
                  improve_from: 10.638263336609691,
                  improve_to: 10.638263336609691,
                },
              },
              propensity: {
                improve_from: 0.36481500461204014,
                improve_from_text: '36%',
                improve_to: 0.3652352228840688,
                improve_to_text: '37%',
                improve_by: 0.00042021827202856686,
              },
              revenue: {
                past: 282488348.4492302,
                elift_improve_from: -5911990.619360651,
                elift_improve_to: -5527829.563068075,
                elift_improve_by: 384161.0562925761,
              },
            },
            medium: {
              account_count: 6534,
              family: {
                platform: 'salesforce',
                object: 'tasks',
                field: 'Tasks completed',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents@name[Customers]',
              },
              metrics: {
                '30-day total': {
                  improve_from: 5.516605713107802,
                  improve_to: 10.638263336609691,
                },
              },
              propensity: {
                improve_from: 0.3520110735534562,
                improve_from_text: '35%',
                improve_to: 0.3534710661965967,
                improve_to_text: '35%',
                improve_by: 0.0014599926431406363,
              },
              revenue: {
                past: 277521384.2511177,
                elift_improve_from: 1076710.5880788732,
                elift_improve_to: 2506969.181867362,
                elift_improve_by: 1430258.5937884897,
              },
            },
            high: {
              account_count: 5073,
              family: {
                platform: 'salesforce',
                object: 'tasks',
                field: 'Tasks completed',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents@name[Customers]',
              },
              metrics: {
                '30-day total': {
                  improve_from: 3.512734668880512,
                  improve_to: 10.638263336609691,
                },
              },
              propensity: {
                improve_from: 0.3408666882439107,
                improve_from_text: '34%',
                improve_to: 0.34288788544988835,
                improve_to_text: '34%',
                improve_by: 0.0020211972059775494,
              },
              revenue: {
                past: 172535620.5309391,
                elift_improve_from: 163771.82496640334,
                elift_improve_to: 1383975.6625497045,
                elift_improve_by: 1220203.8375833014,
              },
            },
          },
        },
        {
          driver_id: '2-002',
          driver_type: 'insight',
          feature:
            'pendo:feature_telemetry:numevents@name[Customer Details > Invoice Drawer > Send Email button]:sum|lift:p180d1',
          family: {
            platform: 'snowflake',
            object: 'pages',
            field: 'Events on [Page A]',
            std_platform: 'pendo',
            std_object: 'feature_telemetry',
            std_field: 'numevents@name[Customer Details > Invoice Drawer > Send Email button]',
          },
          segments: {
            high: {
              account_count: 3609,
              family: {
                platform: 'snowflake',
                object: 'pages',
                field: 'Events on [Page A]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numevents@name[Customer Details > Invoice Drawer > Send Email button]',
              },
              metrics: {
                '90-day total': {
                  improve_from: 52.788888888888856,
                  improve_to: 422.7243243243246,
                },
              },
              propensity: {
                improve_from: 0.3676267109444694,
                improve_from_text: '37%',
                improve_to: 0.3680187776539758,
                improve_to_text: '37%',
                improve_by: 0.00039206670950640843,
              },
              revenue: {
                past: 190218430.0666809,
                elift_improve_from: -3602571.397553239,
                elift_improve_to: -2442916.7151868194,
                elift_improve_by: 1159654.6823664198,
              },
            },
            medium: {
              account_count: 4711,
              family: {
                platform: 'snowflake',
                object: 'pages',
                field: 'Events on [Page A]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numevents@name[Customer Details > Invoice Drawer > Send Email button]',
              },
              metrics: {
                '90-day total': {
                  improve_from: 14.48936170212766,
                  improve_to: 422.7243243243246,
                },
              },
              propensity: {
                improve_from: 0.3481955760199018,
                improve_from_text: '35%',
                improve_to: 0.3485113402259901,
                improve_to_text: '35%',
                improve_by: 0.0003157642060882998,
              },
              revenue: {
                past: 161750557.18502998,
                elift_improve_from: 2852325.27929632,
                elift_improve_to: 3573356.6488181506,
                elift_improve_by: 721031.3695218303,
              },
            },
            low: {
              account_count: 3790,
              family: {
                platform: 'snowflake',
                object: 'pages',
                field: 'Events on [Page A]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numevents@name[Customer Details > Invoice Drawer > Send Email button]',
              },
              metrics: {
                '90-day total': {
                  improve_from: 422.7243243243246,
                  improve_to: 422.7243243243246,
                },
              },
              propensity: {
                improve_from: 0.3583311745358036,
                improve_from_text: '36%',
                improve_to: 0.3584089501117979,
                improve_to_text: '36%',
                improve_by: 0.00007777557599444648,
              },
              revenue: {
                past: 227207017.17681885,
                elift_improve_from: -4836706.255165309,
                elift_improve_to: -4538641.758118845,
                elift_improve_by: 298064.4970464637,
              },
            },
          },
        },
        {
          driver_id: '2-003',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:numminutes@name[Credit Memos]:sum@universal_user_id:p180d1',
          family: {
            platform: 'snowflake',
            object: 'account_storage',
            field: 'Data usage',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: 'numminutes@name[Credit Memos]',
          },
          segments: {
            high: {
              account_count: 2386,
              family: {
                platform: 'snowflake',
                object: 'account_storage',
                field: 'Data usage',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numminutes@name[Credit Memos]',
              },
              metrics: {
                '30-day total': {
                  improve_from: 1.0434782608695652,
                  improve_to: 16.159040404040397,
                },
              },
              propensity: {
                improve_from: 0.36404411541422094,
                improve_from_text: '36%',
                improve_to: 0.36578294852558274,
                improve_to_text: '37%',
                improve_by: 0.0017388331113617893,
              },
              revenue: {
                past: 102704453.332901,
                elift_improve_from: -1900232.9649559995,
                elift_improve_to: -908872.9290525337,
                elift_improve_by: 991360.0359034654,
              },
            },
            medium: {
              account_count: 4663,
              family: {
                platform: 'snowflake',
                object: 'account_storage',
                field: 'Data usage',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numminutes@name[Credit Memos]',
              },
              metrics: {
                '30-day total': {
                  improve_from: 3.1475155279503104,
                  improve_to: 16.159040404040397,
                },
              },
              propensity: {
                improve_from: 0.35203776795993685,
                improve_from_text: '35%',
                improve_to: 0.3534737562832883,
                improve_to_text: '35%',
                improve_by: 0.0014359883233514945,
              },
              revenue: {
                past: 200526155.26328087,
                elift_improve_from: -982216.2737932052,
                elift_improve_to: -267087.0678818762,
                elift_improve_by: 715129.2059113294,
              },
            },
            low: {
              account_count: 3073,
              family: {
                platform: 'snowflake',
                object: 'account_storage',
                field: 'Data usage',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numminutes@name[Credit Memos]',
              },
              metrics: {
                '30-day total': {
                  improve_from: 16.159040404040397,
                  improve_to: 16.159040404040397,
                },
              },
              propensity: {
                improve_from: 0.3684144519193198,
                improve_from_text: '37%',
                improve_to: 0.3688284326845782,
                improve_to_text: '37%',
                improve_by: 0.00041398076525852033,
              },
              revenue: {
                past: 166441407.2107315,
                elift_improve_from: -208123.75167561954,
                elift_improve_to: -12583.018083654952,
                elift_improve_by: 195540.73359196458,
              },
            },
          },
        },
        {
          driver_id: '2-011',
          driver_type: 'insight',
          feature: 'chorusai',
          family: {
            platform: 'chorusai',
            object: 'Chorus.ai = [COMPETITOR]',
            field: 'Engagement_tracker_match name',
            std_platform: 'chorusai',
            std_object: 'chorusai',
            std_field: 'chorusai',
          },
          segments: {
            high: {
              account_count: 4219,
              family: {
                platform: 'chorusai',
                object: 'Chorus.ai = [COMPETITOR]',
                field: 'Engagement_tracker_match name',
                std_platform: 'chorusai',
                std_object: 'chorusai',
                std_field: 'chorusai',
              },
              metrics: {
                '30-day average': {
                  improve_from: 1,
                  improve_to: 0,
                },
              },
              propensity: {
                improve_from: 0.3230527205390279,
                improve_from_text: '32%',
                improve_to: 0.3219645206605563,
                improve_to_text: '32%',
                improve_by: -0.0010881998784715685,
              },
              revenue: {
                past: 193853322.8416443,
                elift_improve_from: 40927378.06415201,
                elift_improve_to: 40778779.26520206,
                elift_improve_by: 348151.66,
              },
            },
            low: {
              account_count: 4066,
              family: {
                platform: 'chorusai',
                object: 'Chorus.ai = [COMPETITOR]',
                field: 'Engagement_tracker_match name',
                std_platform: 'chorusai',
                std_object: 'chorusai',
                std_field: 'chorusai',
              },
              metrics: {
                '30-day average': {
                  improve_from: 1,
                  improve_to: 0,
                },
              },
              propensity: {
                improve_from: 0.3095759864811509,
                improve_from_text: '31%',
                improve_to: 0.3092825838688459,
                improve_to_text: '31%',
                improve_by: -0.00029340261230495886,
              },
              revenue: {
                past: 211878248.02150726,
                elift_improve_from: 43858903.960561536,
                elift_improve_to: 43824923.65844307,
                elift_improve_by: 325592.6,
              },
            },
            medium: {
              account_count: 5274,
              family: {
                platform: 'chorusai',
                object: 'Chorus.ai = [COMPETITOR]',
                field: 'Engagement_tracker_match name',
                std_platform: 'chorusai',
                std_object: 'chorusai',
                std_field: 'chorusai',
              },
              metrics: {
                '30-day average': {
                  improve_from: 1,
                  improve_to: 0,
                },
              },
              propensity: {
                improve_from: 0.32771773234673357,
                improve_from_text: '33%',
                improve_to: 0.3270290011091582,
                improve_to_text: '33%',
                improve_by: -0.0006887312375752781,
              },
              revenue: {
                past: 235492731.58578873,
                elift_improve_from: 47643495.85442367,
                elift_improve_to: 47540269.98886779,
                elift_improve_by: 335736.14,
              },
            },
          },
        },
        {
          driver_id: '2-004',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:iscoreevent:mean:p360d1',
          family: {
            platform: 'snowflake',
            object: 'features',
            field: 'Events on [Feature B]',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: 'iscoreevent',
          },
          segments: {
            low: {
              account_count: 5037,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Events on [Feature B]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'iscoreevent',
              },
              metrics: {
                '90-day average per user': {
                  improve_from: 0.0012228158648570214,
                  improve_to: 0.0012228158648570214,
                },
              },
              propensity: {
                improve_from: 0.35738609041547276,
                improve_from_text: '36%',
                improve_to: 0.3573881745965127,
                improve_to_text: '36%',
                improve_by: 0.000002084181039849753,
              },
              revenue: {
                past: 268336331.4133644,
                elift_improve_from: -6102405.942820762,
                elift_improve_to: -6095283.648164447,
                elift_improve_by: 7122.294656316256,
              },
            },
            medium: {
              account_count: 6669,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Events on [Feature B]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'iscoreevent',
              },
              metrics: {
                '90-day average per user': {
                  improve_from: 0.004554692373583612,
                  improve_to: 0.0012228158648570214,
                },
              },
              propensity: {
                improve_from: 0.34944572876581187,
                improve_from_text: '35%',
                improve_to: 0.3494736641628968,
                improve_to_text: '35%',
                improve_by: 0.000027935397084909543,
              },
              revenue: {
                past: 263762296.8744278,
                elift_improve_from: 1532506.9938968841,
                elift_improve_to: 1686491.4488639117,
                elift_improve_by: 153984.45496702715,
              },
            },
            high: {
              account_count: 5168,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Events on [Feature B]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'iscoreevent',
              },
              metrics: {
                '90-day average per user': {
                  improve_from: 0.022352548479956335,
                  improve_to: 0.0012228158648570214,
                },
              },
              propensity: {
                improve_from: 0.3501521888685693,
                improve_from_text: '35%',
                improve_to: 0.3503149840930418,
                improve_to_text: '35%',
                improve_by: 0.00016279522447239263,
              },
              revenue: {
                past: 202443985.15162468,
                elift_improve_from: 2593.7288361391097,
                elift_improve_to: 966589.6213779533,
                elift_improve_by: 963995.8925418142,
              },
            },
          },
        },
        {
          driver_id: '2-005',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:numevents:mean:p360d1',
          family: {
            platform: 'pendo',
            object: 'guides',
            field: 'Events',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: 'numevents',
          },
          segments: {
            medium: {
              account_count: 6685,
              family: {
                platform: 'pendo',
                object: 'guides',
                field: 'Events',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents',
              },
              metrics: {
                '90-day total per user': {
                  improve_from: 2.863790268586246,
                  improve_to: 4.413589127407062,
                },
              },
              propensity: {
                improve_from: 0.35428771353466804,
                improve_from_text: '35%',
                improve_to: 0.35534012657882363,
                improve_to_text: '36%',
                improve_by: 0.0010524130441556525,
              },
              revenue: {
                past: 286679340.32621384,
                elift_improve_from: -2331096.985446241,
                elift_improve_to: -1267067.9434248863,
                elift_improve_by: 1064029.0420213551,
              },
            },
            high: {
              account_count: 5058,
              family: {
                platform: 'pendo',
                object: 'guides',
                field: 'Events',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents',
              },
              metrics: {
                '90-day total per user': {
                  improve_from: 2.152331020650463,
                  improve_to: 4.413589127407062,
                },
              },
              propensity: {
                improve_from: 0.33757533909582277,
                improve_from_text: '34%',
                improve_to: 0.33909555645977846,
                improve_to_text: '34%',
                improve_by: 0.0015202173639556483,
              },
              revenue: {
                past: 165623018.94049644,
                elift_improve_from: 2931201.264982204,
                elift_improve_to: 3763007.138174366,
                elift_improve_by: 831805.8731921623,
              },
            },
            low: {
              account_count: 5188,
              family: {
                platform: 'pendo',
                object: 'guides',
                field: 'Events',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents',
              },
              metrics: {
                '90-day total per user': {
                  improve_from: 4.413589127407062,
                  improve_to: 4.413589127407062,
                },
              },
              propensity: {
                improve_from: 0.36330839614774574,
                improve_from_text: '36%',
                improve_to: 0.3636231755852163,
                improve_to_text: '36%',
                improve_by: 0.00031477943747061453,
              },
              revenue: {
                past: 282240254.1727066,
                elift_improve_from: -5167409.499623703,
                elift_improve_to: -4812616.834376552,
                elift_improve_by: 354792.6652471502,
              },
            },
          },
        },
        {
          driver_id: '2-006',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:_row@name[Login]:count@universal_user_id:p360d1',
          family: {
            platform: 'intercom',
            object: 'conversations',
            field: 'Time to admin reply',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: '_row@name[Login]',
          },
          segments: {
            medium: {
              account_count: 6483,
              family: {
                platform: 'intercom',
                object: 'conversations',
                field: 'Time to admin reply',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Login]',
              },
              metrics: {
                '30-day average': {
                  improve_from: 96.00066902857634,
                  improve_to: 183.71223958333334,
                },
              },
              propensity: {
                improve_from: 0.357344311177251,
                improve_from_text: '36%',
                improve_to: 0.35898703814032124,
                improve_to_text: '36%',
                improve_by: 0.0016427269630702387,
              },
              revenue: {
                past: 268785309.65509415,
                elift_improve_from: 1223380.2638375594,
                elift_improve_to: 1818249.836471696,
                elift_improve_by: 594869.5726341364,
              },
            },
            low: {
              account_count: 4833,
              family: {
                platform: 'intercom',
                object: 'conversations',
                field: 'Time to admin reply',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Login]',
              },
              metrics: {
                '30-day average': {
                  improve_from: 183.71223958333334,
                  improve_to: 183.71223958333334,
                },
              },
              propensity: {
                improve_from: 0.36839804922430824,
                improve_from_text: '37%',
                improve_to: 0.36846857638470665,
                improve_to_text: '37%',
                improve_by: 0.00007052716039845804,
              },
              revenue: {
                past: 268306606.30073547,
                elift_improve_from: -4445928.625186159,
                elift_improve_to: -4435473.51829055,
                elift_improve_by: 10455.106895609188,
              },
            },
            high: {
              account_count: 4735,
              family: {
                platform: 'intercom',
                object: 'conversations',
                field: 'Time to admin reply',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: '_row@name[Login]',
              },
              metrics: {
                '30-day average': {
                  improve_from: 25.628438769344736,
                  improve_to: 183.71223958333334,
                },
              },
              propensity: {
                improve_from: 0.33709270844343275,
                improve_from_text: '34%',
                improve_to: 0.33999559987541095,
                improve_to_text: '34%',
                improve_by: 0.0029028914319782885,
              },
              revenue: {
                past: 166787591.01347923,
                elift_improve_from: -656953.5486034151,
                elift_improve_to: 134566.67099063165,
                elift_improve_by: 791520.219594047,
              },
            },
          },
        },
        {
          driver_id: '2-007',
          driver_type: 'insight',
          feature:
            'pendo:feature_telemetry:numminutes@name[Dashboard > Customer Score > All Customer Clicks]:sum@universal_user_id:p360d1',
          family: {
            platform: 'snowflake',
            object: 'pages',
            field: 'Minutes on [Page B]',
            std_platform: 'pendo',
            std_object: 'feature_telemetry',
            std_field: 'numminutes@name[Dashboard > Customer Score > All Customer Clicks]',
          },
          segments: {
            high: {
              account_count: 3764,
              family: {
                platform: 'snowflake',
                object: 'pages',
                field: 'Minutes on [Page B]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numminutes@name[Dashboard > Customer Score > All Customer Clicks]',
              },
              metrics: {
                '90-day average': {
                  improve_from: 1,
                  improve_to: 6.44,
                },
              },
              propensity: {
                improve_from: 0.3572642813838902,
                improve_from_text: '36%',
                improve_to: 0.3596901956588777,
                improve_to_text: '36%',
                improve_by: 0.0024259142749874342,
              },
              revenue: {
                past: 156223521.28458023,
                elift_improve_from: 1754660.7629368638,
                elift_improve_to: 2498371.5607174602,
                elift_improve_by: 743710.7977805963,
              },
            },
            medium: {
              account_count: 4747,
              family: {
                platform: 'snowflake',
                object: 'pages',
                field: 'Minutes on [Page B]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numminutes@name[Dashboard > Customer Score > All Customer Clicks]',
              },
              metrics: {
                '90-day average': {
                  improve_from: 2.2803444782168185,
                  improve_to: 6.44,
                },
              },
              propensity: {
                improve_from: 0.3663362684747703,
                improve_from_text: '37%',
                improve_to: 0.3682264238469978,
                improve_to_text: '37%',
                improve_by: 0.0018901553722274862,
              },
              revenue: {
                past: 241932148.03261757,
                elift_improve_from: -4730633.323305963,
                elift_improve_to: -4169363.2710148096,
                elift_improve_by: 561270.0522911533,
              },
            },
            low: {
              account_count: 2574,
              family: {
                platform: 'snowflake',
                object: 'pages',
                field: 'Minutes on [Page B]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numminutes@name[Dashboard > Customer Score > All Customer Clicks]',
              },
              metrics: {
                '90-day average': {
                  improve_from: 6.44,
                  improve_to: 6.44,
                },
              },
              propensity: {
                improve_from: 0.3553728471661967,
                improve_from_text: '36%',
                improve_to: 0.35578628559494146,
                improve_to_text: '36%',
                improve_by: 0.00041343842874474123,
              },
              revenue: {
                past: 107229135.07204056,
                elift_improve_from: 956796.3038030178,
                elift_improve_to: 1069797.256253882,
                elift_improve_by: 113000.95245086437,
              },
            },
          },
        },
        {
          driver_id: '2-008',
          driver_type: 'insight',
          feature: 'pendo:page_telemetry:numevents@name[Insights]:sum|lift:p180d1',
          family: {
            platform: 'snowflake',
            object: 'features',
            field: 'Minutes on [Feature C]',
            std_platform: 'pendo',
            std_object: 'page_telemetry',
            std_field: 'numevents@name[Insights]',
          },
          segments: {
            low: {
              account_count: 4043,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Minutes on [Feature C]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents@name[Insights]',
              },
              metrics: {
                '30-day average per user': {
                  improve_from: 38.5,
                  improve_to: 49.5,
                },
              },
              propensity: {
                improve_from: 0.36624091013926585,
                improve_from_text: '37%',
                improve_to: 0.36659477038912436,
                improve_to_text: '37%',
                improve_by: 0.0003538602498585161,
              },
              revenue: {
                past: 219762392.63148308,
                elift_improve_from: -5268525.57583308,
                elift_improve_to: -5156962.27641824,
                elift_improve_by: 111563.29941484182,
              },
            },
            high: {
              account_count: 4225,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Minutes on [Feature C]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents@name[Insights]',
              },
              metrics: {
                '30-day average per user': {
                  improve_from: 45.685714285714184,
                  improve_to: -48.5,
                },
              },
              propensity: {
                improve_from: 0.3431376981387194,
                improve_from_text: '34%',
                improve_to: 0.3450810141412231,
                improve_to_text: '35%',
                improve_by: 0.0019433160025037384,
              },
              revenue: {
                past: 190986638.6469841,
                elift_improve_from: 1458115.6164811354,
                elift_improve_to: 2117365.729737756,
                elift_improve_by: 659250.1132566202,
              },
            },
            medium: {
              account_count: 5147,
              family: {
                platform: 'snowflake',
                object: 'features',
                field: 'Minutes on [Feature C]',
                std_platform: 'pendo',
                std_object: 'page_telemetry',
                std_field: 'numevents@name[Insights]',
              },
              metrics: {
                '30-day average per user': {
                  improve_from: 0.47058823529411764,
                  improve_to: -48.5,
                },
              },
              propensity: {
                improve_from: 0.3571132523202355,
                improve_from_text: '36%',
                improve_to: 0.35816259744960327,
                improve_to_text: '36%',
                improve_by: 0.001049345129367792,
              },
              revenue: {
                past: 208852671.82331085,
                elift_improve_from: -1341719.6930769419,
                elift_improve_to: -1003748.4143336532,
                elift_improve_by: 337971.2787432887,
              },
            },
          },
        },
        {
          driver_id: '2-009',
          driver_type: 'insight',
          feature: 'pendo:reports:numvisitors:value:p0d1',
          family: {
            platform: 'snowflake',
            object: 'pages',
            field: 'Events on [Page D]',
            std_platform: 'pendo',
            std_object: 'reports',
            std_field: 'numvisitors',
          },
          segments: {
            high: {
              account_count: 3947,
              family: {
                platform: 'snowflake',
                object: 'pages',
                field: 'Events on [Page D]',
                std_platform: 'pendo',
                std_object: 'reports',
                std_field: 'numvisitors',
              },
              metrics: {
                '90-day average per user': {
                  improve_from: 2.128205128205128,
                  improve_to: 24.239999771118164,
                },
              },
              propensity: {
                improve_from: 0.3351562067274214,
                improve_from_text: '34%',
                improve_to: 0.3354367789967932,
                improve_to_text: '34%',
                improve_by: 0.00028057226937167344,
              },
              revenue: {
                past: 105179202.30431557,
                elift_improve_from: 2934018.3504146365,
                elift_improve_to: 3564104.3216714445,
                elift_improve_by: 630085.9712568076,
              },
            },
            low: {
              account_count: 5073,
              family: {
                platform: 'snowflake',
                object: 'pages',
                field: 'Events on [Page D]',
                std_platform: 'pendo',
                std_object: 'reports',
                std_field: 'numvisitors',
              },
              metrics: {
                '90-day average per user': {
                  improve_from: 24.24,
                  improve_to: 24.239999771118164,
                },
              },
              propensity: {
                improve_from: 0.3645941227271734,
                improve_from_text: '36%',
                improve_to: 0.364628401901012,
                improve_to_text: '36%',
                improve_by: 0.00003427917383858614,
              },
              revenue: {
                past: 309885751.9592285,
                elift_improve_from: -6707710.324622787,
                elift_improve_to: -6219668.278533614,
                elift_improve_by: 488042.0460891721,
              },
            },
            medium: {
              account_count: 7047,
              family: {
                platform: 'snowflake',
                object: 'pages',
                field: 'Events on [Page D]',
                std_platform: 'pendo',
                std_object: 'reports',
                std_field: 'numvisitors',
              },
              metrics: {
                '90-day average per user': {
                  improve_from: 6.314285714285714,
                  improve_to: 24.239999771118164,
                },
              },
              propensity: {
                improve_from: 0.3579092900327757,
                improve_from_text: '36%',
                improve_to: 0.35809608503415025,
                improve_to_text: '36%',
                improve_by: 0.00018679500137453532,
              },
              revenue: {
                past: 299375279.85305786,
                elift_improve_from: -767794.3692419609,
                elift_improve_to: 863307.9941278225,
                elift_improve_by: 1631102.363369784,
              },
            },
          },
        },
        {
          driver_id: '2-010',
          driver_type: 'insight',
          feature: 'pendo:feature_telemetry:numminutes@name[Manage Workspace Button]:mean:p180d1',
          family: {
            platform: 'pendo',
            object: 'features',
            field: 'Occurrence on [Manage workspace]',
            std_platform: 'pendo',
            std_object: 'feature_telemetry',
            std_field: 'numminutes@name[Manage Workspace Button]',
          },
          segments: {
            low: {
              account_count: 5147,
              family: {
                platform: 'pendo',
                object: 'features',
                field: 'Occurrence on [Manage workspace]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numminutes@name[Manage Workspace Button]',
              },
              metrics: {
                '30-day total per user': {
                  improve_from: 3.3342339556201708,
                  improve_to: 3.3342339556201708,
                },
              },
              propensity: {
                improve_from: 0.35568787679899755,
                improve_from_text: '36%',
                improve_to: 0.35571436392333805,
                improve_to_text: '36%',
                improve_by: 0.000026487124340543184,
              },
              revenue: {
                past: 249570852.99797058,
                elift_improve_from: -101247.39863688227,
                elift_improve_to: -33473.215299809875,
                elift_improve_by: 67774.18333707238,
              },
            },
            medium: {
              account_count: 5844,
              family: {
                platform: 'pendo',
                object: 'features',
                field: 'Occurrence on [Manage workspace]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numminutes@name[Manage Workspace Button]',
              },
              metrics: {
                '30-day total per user': {
                  improve_from: 2.5906859954020134,
                  improve_to: 3.3342339556201708,
                },
              },
              propensity: {
                improve_from: 0.3586243439358954,
                improve_from_text: '36%',
                improve_to: 0.35882491021306573,
                improve_to_text: '36%',
                improve_by: 0.00020056627717047307,
              },
              revenue: {
                past: 283716728.96232605,
                elift_improve_from: -3725367.7546612704,
                elift_improve_to: -2923350.4398261732,
                elift_improve_by: 802017.314835097,
              },
            },
            high: {
              account_count: 4675,
              family: {
                platform: 'pendo',
                object: 'features',
                field: 'Occurrence on [Manage workspace]',
                std_platform: 'pendo',
                std_object: 'feature_telemetry',
                std_field: 'numminutes@name[Manage Workspace Button]',
              },
              metrics: {
                '30-day total per user': {
                  improve_from: 1.8726437563924363,
                  improve_to: 3.3342339556201708,
                },
              },
              propensity: {
                improve_from: 0.35048336989162715,
                improve_from_text: '35%',
                improve_to: 0.3509550923317373,
                improve_to_text: '35%',
                improve_by: 0.00047172244010999244,
              },
              revenue: {
                past: 176081637.2856617,
                elift_improve_from: -857293.4750788359,
                elift_improve_to: -302263.2124235644,
                elift_improve_by: 555030.2626552717,
              },
            },
          },
        },
      ],
    },
  },
}

export const demoGetRevenueRetentionForecastOverall = {
  grid: 'account',
  dimension: 'overall',
  train_datetime: '2024-04-16T19-24-41Z',
  infer_datetime: '2024-04-16T20-43-40Z',
  dataset_date: '2024-04-07',
  is_revenue_quantified: true,
  revenue_trend: [
    {
      label: revenueForcastingQuerters[0],
      type: 'actual',
      past_revenue: {
        name: 'Net Revenue Retention',
        short_name: 'NRR',
        value: {
          value: 26700000,
          type: 'number',
          format: 'currency-kmb',
          decimal: 1,
          nullable: false,
        },
        percent: {
          value: 0.8870431893687708,
          type: 'number',
          format: 'percentage',
          decimal: 0,
          nullable: false,
        },
      },
      future_revenue: {
        name: 'Starting ARR',
        short_name: 'Starting ARR',
        value: {
          value: 30100000,
          type: 'number',
          format: 'currency-kmb',
          decimal: 1,
          nullable: false,
        },
      },
      events: [
        {
          name: 'Expansion',
          value: {
            value: 2400000,
            type: 'number',
            format: 'currency-kmb',
            decimal: 1,
            nullable: false,
          },
          percent: {
            value: 0.07973421926910298,
            type: 'number',
            format: 'percentage',
            decimal: 0,
            nullable: false,
          },
        },
        {
          name: 'Renewal',
          value: {
            value: 24300000,
            type: 'number',
            format: 'currency-kmb',
            decimal: 1,
            nullable: false,
          },
          percent: {
            value: 0.8073089700996677,
            type: 'number',
            format: 'percentage',
            decimal: 0,
            nullable: false,
          },
        },
        {
          name: 'Churn',
          value: {
            value: -5800000,
            type: 'number',
            format: 'currency-kmb',
            decimal: 1,
            nullable: false,
          },
          percent: {
            value: -0.19269102990033224,
            type: 'number',
            format: 'percentage',
            decimal: 0,
            nullable: false,
          },
        },
      ],
    },
    {
      label: revenueForcastingQuerters[1],
      type: 'actual',
      past_revenue: {
        name: 'Net Revenue Retention',
        short_name: 'NRR',
        value: {
          value: 21000000,
          type: 'number',
          format: 'currency-kmb',
          decimal: 1,
          nullable: false,
        },
        percent: {
          value: 0.7241379310344828,
          type: 'number',
          format: 'percentage',
          decimal: 0,
          nullable: false,
        },
      },
      future_revenue: {
        name: 'Starting ARR',
        short_name: 'Starting ARR',
        value: {
          value: 29000000,
          type: 'number',
          format: 'currency-kmb',
          decimal: 1,
          nullable: false,
        },
      },
      events: [
        {
          name: 'Expansion',
          value: {
            value: 2100000,
            type: 'number',
            format: 'currency-kmb',
            decimal: 1,
            nullable: false,
          },
          percent: {
            value: 0.07241379310344828,
            type: 'number',
            format: 'percentage',
            decimal: 0,
            nullable: false,
          },
        },
        {
          name: 'Renewal',
          value: {
            value: 19800000,
            type: 'number',
            format: 'currency-kmb',
            decimal: 1,
            nullable: false,
          },
          percent: {
            value: 0.6827586206896552,
            type: 'number',
            format: 'percentage',
            decimal: 0,
            nullable: false,
          },
        },
        {
          name: 'Churn',
          value: {
            value: -9200000,
            type: 'number',
            format: 'currency-kmb',
            decimal: 1,
            nullable: false,
          },
          percent: {
            value: -0.31724137931034485,
            type: 'number',
            format: 'percentage',
            decimal: 0,
            nullable: false,
          },
        },
      ],
    },
    {
      label: revenueForcastingQuerters[2],
      type: 'actual',
      past_revenue: {
        name: 'Net Revenue Retention',
        short_name: 'NRR',
        value: {
          value: 20400000,
          type: 'number',
          format: 'currency-kmb',
          decimal: 1,
          nullable: false,
        },
        percent: {
          value: 0.7640449438202247,
          type: 'number',
          format: 'percentage',
          decimal: 0,
          nullable: false,
        },
      },
      future_revenue: {
        name: 'Starting ARR',
        short_name: 'Starting ARR',
        value: {
          value: 26700000,
          type: 'number',
          format: 'currency-kmb',
          decimal: 1,
          nullable: false,
        },
      },
      events: [
        {
          name: 'Expansion',
          value: {
            value: 2100000,
            type: 'number',
            format: 'currency-kmb',
            decimal: 1,
            nullable: false,
          },
          percent: {
            value: 0.07865168539325842,
            type: 'number',
            format: 'percentage',
            decimal: 0,
            nullable: false,
          },
        },
        {
          name: 'Renewal',
          value: {
            value: 18300000,
            type: 'number',
            format: 'currency-kmb',
            decimal: 1,
            nullable: false,
          },
          percent: {
            value: 0.6853932584269663,
            type: 'number',
            format: 'percentage',
            decimal: 0,
            nullable: false,
          },
        },
        {
          name: 'Churn',
          value: {
            value: -8400000,
            type: 'number',
            format: 'currency-kmb',
            decimal: 1,
            nullable: false,
          },
          percent: {
            value: -0.3146067415730337,
            type: 'number',
            format: 'percentage',
            decimal: 0,
            nullable: false,
          },
        },
      ],
    },
    {
      label: revenueForcastingQuerters[3],
      type: 'actual',
      past_revenue: {
        name: 'Net Revenue Retention',
        short_name: 'NRR',
        value: {
          value: 27700000,
          type: 'number',
          format: 'currency-kmb',
          decimal: 1,
          nullable: false,
        },
        percent: {
          value: 0.8765822784810127,
          type: 'number',
          format: 'percentage',
          decimal: 0,
          nullable: false,
        },
      },
      future_revenue: {
        name: 'Starting ARR',
        short_name: 'Starting ARR',
        value: {
          value: 31600000,
          type: 'number',
          format: 'currency-kmb',
          decimal: 1,
          nullable: false,
        },
      },
      events: [
        {
          name: 'Expansion',
          value: {
            value: 2900000,
            type: 'number',
            format: 'currency-kmb',
            decimal: 1,
            nullable: false,
          },
          percent: {
            value: 0.09177215189873418,
            type: 'number',
            format: 'percentage',
            decimal: 0,
            nullable: false,
          },
        },
        {
          name: 'Renewal',
          value: {
            value: 24800000,
            type: 'number',
            format: 'currency-kmb',
            decimal: 1,
            nullable: false,
          },
          percent: {
            value: 0.7848101265822784,
            type: 'number',
            format: 'percentage',
            decimal: 0,
            nullable: false,
          },
        },
        {
          name: 'Churn',
          value: {
            value: -6800000,
            type: 'number',
            format: 'currency-kmb',
            decimal: 1,
            nullable: false,
          },
          percent: {
            value: -0.21518987341772153,
            type: 'number',
            format: 'percentage',
            decimal: 0,
            nullable: false,
          },
        },
      ],
    },
    {
      label: revenueForcastingQuerters[4],
      type: 'forecast',
      past_revenue: {
        name: 'Net Revenue Retention',
        short_name: 'NRR',
        value: {
          value: 28000000,
          type: 'number',
          format: 'currency-kmb',
          decimal: 1,
          nullable: false,
        },
        percent: {
          value: 1.003584229390681,
          type: 'number',
          format: 'percentage',
          decimal: 0,
          nullable: false,
        },
      },
      future_revenue: {
        name: 'Starting ARR',
        short_name: 'Starting ARR',
        value: {
          value: 27900000,
          type: 'number',
          format: 'currency-kmb',
          decimal: 1,
          nullable: false,
        },
      },
      events: [
        {
          name: 'Expansion',
          value: {
            value: 4800000,
            type: 'number',
            format: 'currency-kmb',
            decimal: 1,
            nullable: false,
          },
          percent: {
            value: 0.17204301075268819,
            type: 'number',
            format: 'percentage',
            decimal: 0,
            nullable: false,
          },
        },
        {
          name: 'Renewal',
          value: {
            value: 23200000,
            type: 'number',
            format: 'currency-kmb',
            decimal: 1,
            nullable: false,
          },
          percent: {
            value: 0.8315412186379928,
            type: 'number',
            format: 'percentage',
            decimal: 0,
            nullable: false,
          },
        },
        {
          name: 'Churn',
          value: {
            value: -4700000,
            type: 'number',
            format: 'currency-kmb',
            decimal: 1,
            nullable: false,
          },
          percent: {
            value: -0.16845878136200718,
            type: 'number',
            format: 'percentage',
            decimal: 0,
            nullable: false,
          },
        },
      ],
    },
    {
      label: revenueForcastingQuerters[5],
      type: 'forecast',
      past_revenue: {
        name: 'Net Revenue Retention',
        short_name: 'NRR',
        value: {
          value: 29800000,
          type: 'number',
          format: 'currency-kmb',
          decimal: 1,
          nullable: false,
        },
        percent: {
          value: 1.0240549828178693,
          type: 'number',
          format: 'percentage',
          decimal: 0,
          nullable: false,
        },
      },
      future_revenue: {
        name: 'Starting ARR',
        short_name: 'Starting ARR',
        value: {
          value: 29100000,
          type: 'number',
          format: 'currency-kmb',
          decimal: 1,
          nullable: false,
        },
      },
      events: [
        {
          name: 'Expansion',
          value: {
            value: 5400000,
            type: 'number',
            format: 'currency-kmb',
            decimal: 1,
            nullable: false,
          },
          percent: {
            value: 0.18556701030927836,
            type: 'number',
            format: 'percentage',
            decimal: 0,
            nullable: false,
          },
        },
        {
          name: 'Renewal',
          value: {
            value: 24400000,
            type: 'number',
            format: 'currency-kmb',
            decimal: 1,
            nullable: false,
          },
          percent: {
            value: 0.8384879725085911,
            type: 'number',
            format: 'percentage',
            decimal: 0,
            nullable: false,
          },
        },
        {
          name: 'Churn',
          value: {
            value: -4700000,
            type: 'number',
            format: 'currency-kmb',
            decimal: 1,
            nullable: false,
          },
          percent: {
            value: -0.16151202749140894,
            type: 'number',
            format: 'percentage',
            decimal: 0,
            nullable: false,
          },
        },
      ],
    },
  ],
}

export const demoGetAccountForecast = {
  results: [
    // Overall
    {
      id: 749,
      tenantId: 'demo',
      accountId: '0011U00001gUmjFQAS',
      accountName: 'Oneill, King and Fleming',
      contractEndDate: '2024-10-01T00:00:00.000Z',
      churn: '0.315567727459153300000000000000',
      expansion: '0.331814940631714200000000000000',
      revenue: '3200000',
      churnSegment: '0.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '1.000000000000000000000000000000',
      revenuePeriod: 'Overall',
      datetime: 'latest',
      pastRevenue: '2800000',
      revenuePlift: '0.12240196509315349',
    },
    {
      id: 370,
      tenantId: 'demo',
      accountId: '0011U0000077rWmQAI',
      accountName: 'Gutierrez Group',
      contractEndDate: '2024-12-01T00:00:00.000Z',
      churn: '0.327089836939610470000000000000',
      expansion: '0.335307812093835260000000000000',
      revenue: '1100000',
      churnSegment: '1.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '1.000000000000000000000000000000',
      revenuePeriod: 'Overall',
      datetime: 'latest',
      pastRevenue: '962723.7489515296',
      revenuePlift: '0.0949242562639026',
    },
    {
      id: 297,
      tenantId: 'demo',
      accountId: '0011U0000077jp0QAA',
      accountName: 'Fisher, Cooper and Barr',
      contractEndDate: '2024-10-01T00:00:00.000Z',
      churn: '0.312073237223542200000000000000',
      expansion: '0.347453598738454800000000000000',
      revenue: '2300000',
      churnSegment: '0.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '2.000000000000000000000000000000',
      revenuePeriod: 'Overall',
      datetime: 'latest',
      pastRevenue: '2822716.015315341',
      revenuePlift: '-0.18219683283035995',
    },
    {
      id: 658,
      tenantId: 'demo',
      accountId: '0011U00001LJgzDQAT',
      accountName: 'Durham, Bennett and Baker',
      contractEndDate: '2024-10-01T00:00:00.000Z',
      churn: '0.335771853886670470000000000000',
      expansion: '0.325943594524087170000000000000',
      revenue: '4900000',
      churnSegment: '2.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '0.000000000000000000000000000000',
      revenuePeriod: 'Overall',
      datetime: 'latest',
      pastRevenue: '4392762.4017922385',
      revenuePlift: '0.1182552568687874',
    },
    {
      id: 595,
      tenantId: 'demo',
      accountId: '0011U00001CwhlFQAR',
      accountName: 'Foster Inc',
      contractEndDate: '2024-10-01T00:00:00.000Z',
      churn: '0.325005588443749770000000000000',
      expansion: '0.341173224763259840000000000000',
      revenue: '816657',
      churnSegment: '1.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '1.000000000000000000000000000000',
      revenuePeriod: 'Overall',
      datetime: 'latest',
      pastRevenue: '1014877.1694902629',
      revenuePlift: '-0.19531394202090882',
    },
    {
      id: 551,
      tenantId: 'demo',
      accountId: '0011U000014ifycQAA',
      accountName: 'Cunningham Group',
      contractEndDate: '2025-01-01T00:00:00.000Z',
      churn: '0.323871200084527360000000000000',
      expansion: '0.335348023517654470000000000000',
      revenue: '2700000',
      churnSegment: '1.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '1.000000000000000000000000000000',
      revenuePeriod: 'Overall',
      datetime: 'latest',
      pastRevenue: '2800000',
      revenuePlift: '-0.04',
    },
    {
      id: 186,
      tenantId: 'demo',
      accountId: '0011U0000077jUUQAY',
      accountName: 'Turner Group',
      contractEndDate: '2025-01-01T00:00:00.000Z',
      churn: '0.308318977436212700000000000000',
      expansion: '0.343657394198037200000000000000',
      revenue: '4900000',
      churnSegment: '0.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '2.000000000000000000000000000000',
      revenuePeriod: 'Overall',
      datetime: 'latest',
      pastRevenue: '6000000',
      revenuePlift: '-0.18',
    },
    {
      id: 125,
      tenantId: 'demo',
      accountId: '0011U0000077j3fQAA',
      accountName: 'Andrews-Crosby',
      contractEndDate: '2025-03-01T00:00:00.000Z',
      churn: '0.331814835080682400000000000000',
      expansion: '0.327626363534406800000000000000',
      revenue: '805100',
      churnSegment: '1.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '0.000000000000000000000000000000',
      revenuePeriod: 'Overall',
      datetime: 'latest',
      pastRevenue: '992700',
      revenuePlift: '-0.19',
    },
    {
      id: 613,
      tenantId: 'demo',
      accountId: '0011U00001CwhoJQAR',
      accountName: 'King-Henderson',
      contractEndDate: '2025-03-01T00:00:00.000Z',
      churn: '0.339555040981986300000000000000',
      expansion: '0.331710926150089100000000000000',
      revenue: '826818.493366241500000000000000000000',
      churnSegment: '2.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '1.000000000000000000000000000000',
      revenuePeriod: 'Overall',
      datetime: 'latest',
      pastRevenue: '747700',
      revenuePlift: '0.11',
    },
    {
      id: 391,
      tenantId: 'demo',
      accountId: '0011U00000LILyCQAX',
      accountName: 'Johnson-Underwood',
      contractEndDate: '2025-03-01T00:00:00.000Z',
      churn: '0.291631647276298900000000000000',
      expansion: '0.370965663911576870000000000000',
      revenue: '3000000',
      churnSegment: '0.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '2.000000000000000000000000000000',
      revenuePeriod: 'Overall',
      datetime: 'latest',
      pastRevenue: '2900000',
      revenuePlift: '0.03',
    },
    // 2024-Q4
    {
      id: 749,
      tenantId: 'demo',
      accountId: '0011U00001gUmjFQAS',
      accountName: 'Oneill, King and Fleming',
      contractEndDate: '2024-10-01T00:00:00.000Z',
      churn: '0.315567727459153300000000000000',
      expansion: '0.331814940631714200000000000000',
      revenue: '3200000',
      churnSegment: '0.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '1.000000000000000000000000000000',
      revenuePeriod: '2024-Q4',
      datetime: 'latest',
      pastRevenue: '2800000',
      revenuePlift: '0.12240196509315349',
    },
    {
      id: 370,
      tenantId: 'demo',
      accountId: '0011U0000077rWmQAI',
      accountName: 'Gutierrez Group',
      contractEndDate: '2024-12-01T00:00:00.000Z',
      churn: '0.327089836939610470000000000000',
      expansion: '0.335307812093835260000000000000',
      revenue: '1100000',
      churnSegment: '1.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '1.000000000000000000000000000000',
      revenuePeriod: '2024-Q4',
      datetime: 'latest',
      pastRevenue: '962723.7489515296',
      revenuePlift: '0.0949242562639026',
    },
    {
      id: 297,
      tenantId: 'demo',
      accountId: '0011U0000077jp0QAA',
      accountName: 'Fisher, Cooper and Barr',
      contractEndDate: '2024-10-01T00:00:00.000Z',
      churn: '0.312073237223542200000000000000',
      expansion: '0.347453598738454800000000000000',
      revenue: '2300000',
      churnSegment: '0.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '2.000000000000000000000000000000',
      revenuePeriod: '2024-Q4',
      datetime: 'latest',
      pastRevenue: '2822716.015315341',
      revenuePlift: '-0.18219683283035995',
    },
    {
      id: 658,
      tenantId: 'demo',
      accountId: '0011U00001LJgzDQAT',
      accountName: 'Durham, Bennett and Baker',
      contractEndDate: '2024-10-01T00:00:00.000Z',
      churn: '0.335771853886670470000000000000',
      expansion: '0.325943594524087170000000000000',
      revenue: '4900000',
      churnSegment: '2.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '0.000000000000000000000000000000',
      revenuePeriod: '2024-Q4',
      datetime: 'latest',
      pastRevenue: '4392762.4017922385',
      revenuePlift: '0.1182552568687874',
    },
    {
      id: 595,
      tenantId: 'demo',
      accountId: '0011U00001CwhlFQAR',
      accountName: 'Foster Inc',
      contractEndDate: '2024-10-01T00:00:00.000Z',
      churn: '0.325005588443749770000000000000',
      expansion: '0.341173224763259840000000000000',
      revenue: '816657',
      churnSegment: '1.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '1.000000000000000000000000000000',
      revenuePeriod: '2024-Q4',
      datetime: 'latest',
      pastRevenue: '1014877.1694902629',
      revenuePlift: '-0.19531394202090882',
    },
    {
      id: 551,
      tenantId: 'demo',
      accountId: '0011U000014ifycQAA',
      accountName: 'Cunningham Group',
      contractEndDate: '2025-01-01T00:00:00.000Z',
      churn: '0.323871200084527360000000000000',
      expansion: '0.335348023517654470000000000000',
      revenue: '2800000',
      churnSegment: '1.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '1.000000000000000000000000000000',
      revenuePeriod: '2024-Q4',
      datetime: 'latest',
      pastRevenue: '2800000',
      revenuePlift: '0.0',
    },
    {
      id: 186,
      tenantId: 'demo',
      accountId: '0011U0000077jUUQAY',
      accountName: 'Turner Group',
      contractEndDate: '2025-01-01T00:00:00.000Z',
      churn: '0.308318977436212700000000000000',
      expansion: '0.343657394198037200000000000000',
      revenue: '6000000',
      churnSegment: '0.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '2.000000000000000000000000000000',
      revenuePeriod: '2024-Q4',
      datetime: 'latest',
      pastRevenue: '6000000',
      revenuePlift: '0.0',
    },
    {
      id: 125,
      tenantId: 'demo',
      accountId: '0011U0000077j3fQAA',
      accountName: 'Andrews-Crosby',
      contractEndDate: '2025-03-01T00:00:00.000Z',
      churn: '0.331814835080682400000000000000',
      expansion: '0.327626363534406800000000000000',
      revenue: '992700',
      churnSegment: '1.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '0.000000000000000000000000000000',
      revenuePeriod: '2024-Q4',
      datetime: 'latest',
      pastRevenue: '992700',
      revenuePlift: '0.0',
    },
    {
      id: 613,
      tenantId: 'demo',
      accountId: '0011U00001CwhoJQAR',
      accountName: 'King-Henderson',
      contractEndDate: '2025-03-01T00:00:00.000Z',
      churn: '0.339555040981986300000000000000',
      expansion: '0.331710926150089100000000000000',
      revenue: '785000',
      churnSegment: '2.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '1.000000000000000000000000000000',
      revenuePeriod: '2024-Q4',
      datetime: 'latest',
      pastRevenue: '747700',
      revenuePlift: '0.05',
    },
    {
      id: 391,
      tenantId: 'demo',
      accountId: '0011U00000LILyCQAX',
      accountName: 'Johnson-Underwood',
      contractEndDate: '2025-03-01T00:00:00.000Z',
      churn: '0.291631647276298900000000000000',
      expansion: '0.370965663911576870000000000000',
      revenue: '2900000',
      churnSegment: '0.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '2.000000000000000000000000000000',
      revenuePeriod: '2024-Q4',
      datetime: 'latest',
      pastRevenue: '2900000',
      revenuePlift: '0.0',
    },
    // 2025-Q1
    {
      id: 749,
      tenantId: 'demo',
      accountId: '0011U00001gUmjFQAS',
      accountName: 'Oneill, King and Fleming',
      contractEndDate: '2024-10-01T00:00:00.000Z',
      churn: '0.315567727459153300000000000000',
      expansion: '0.331814940631714200000000000000',
      revenue: '3200000',
      churnSegment: '0.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '1.000000000000000000000000000000',
      revenuePeriod: '2025-Q1',
      datetime: 'latest',
      pastRevenue: '2800000',
      revenuePlift: '0.12240196509315349',
    },
    {
      id: 370,
      tenantId: 'demo',
      accountId: '0011U0000077rWmQAI',
      accountName: 'Gutierrez Group',
      contractEndDate: '2024-12-01T00:00:00.000Z',
      churn: '0.327089836939610470000000000000',
      expansion: '0.335307812093835260000000000000',
      revenue: '1100000',
      churnSegment: '1.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '1.000000000000000000000000000000',
      revenuePeriod: '2025-Q1',
      datetime: 'latest',
      pastRevenue: '962723.7489515296',
      revenuePlift: '0.0949242562639026',
    },
    {
      id: 297,
      tenantId: 'demo',
      accountId: '0011U0000077jp0QAA',
      accountName: 'Fisher, Cooper and Barr',
      contractEndDate: '2024-10-01T00:00:00.000Z',
      churn: '0.312073237223542200000000000000',
      expansion: '0.347453598738454800000000000000',
      revenue: '2300000',
      churnSegment: '0.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '2.000000000000000000000000000000',
      revenuePeriod: '2025-Q1',
      datetime: 'latest',
      pastRevenue: '2822716.015315341',
      revenuePlift: '-0.18219683283035995',
    },
    {
      id: 658,
      tenantId: 'demo',
      accountId: '0011U00001LJgzDQAT',
      accountName: 'Durham, Bennett and Baker',
      contractEndDate: '2024-10-01T00:00:00.000Z',
      churn: '0.335771853886670470000000000000',
      expansion: '0.325943594524087170000000000000',
      revenue: '4900000',
      churnSegment: '2.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '0.000000000000000000000000000000',
      revenuePeriod: '2025-Q1',
      datetime: 'latest',
      pastRevenue: '4392762.4017922385',
      revenuePlift: '0.1182552568687874',
    },
    {
      id: 595,
      tenantId: 'demo',
      accountId: '0011U00001CwhlFQAR',
      accountName: 'Foster Inc',
      contractEndDate: '2024-10-01T00:00:00.000Z',
      churn: '0.325005588443749770000000000000',
      expansion: '0.341173224763259840000000000000',
      revenue: '816657',
      churnSegment: '1.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '1.000000000000000000000000000000',
      revenuePeriod: '2025-Q1',
      datetime: 'latest',
      pastRevenue: '1014877.1694902629',
      revenuePlift: '-0.19531394202090882',
    },
    {
      id: 551,
      tenantId: 'demo',
      accountId: '0011U000014ifycQAA',
      accountName: 'Cunningham Group',
      contractEndDate: '2025-01-01T00:00:00.000Z',
      churn: '0.323871200084527360000000000000',
      expansion: '0.335348023517654470000000000000',
      revenue: '2700000',
      churnSegment: '1.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '1.000000000000000000000000000000',
      revenuePeriod: '2025-Q1',
      datetime: 'latest',
      pastRevenue: '2800000',
      revenuePlift: '-0.04',
    },
    {
      id: 186,
      tenantId: 'demo',
      accountId: '0011U0000077jUUQAY',
      accountName: 'Turner Group',
      contractEndDate: '2025-01-01T00:00:00.000Z',
      churn: '0.308318977436212700000000000000',
      expansion: '0.343657394198037200000000000000',
      revenue: '4939155.696258539000000000000000000000',
      churnSegment: '0.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '2.000000000000000000000000000000',
      revenuePeriod: '2025-Q1',
      datetime: 'latest',
      pastRevenue: '6000000',
      revenuePlift: '-0.18',
    },
    {
      id: 125,
      tenantId: 'demo',
      accountId: '0011U0000077j3fQAA',
      accountName: 'Andrews-Crosby',
      contractEndDate: '2025-03-01T00:00:00.000Z',
      churn: '0.331814835080682400000000000000',
      expansion: '0.327626363534406800000000000000',
      revenue: '805100',
      churnSegment: '1.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '0.000000000000000000000000000000',
      revenuePeriod: '2025-Q1',
      datetime: 'latest',
      pastRevenue: '992700',
      revenuePlift: '-0.19',
    },
    {
      id: 613,
      tenantId: 'demo',
      accountId: '0011U00001CwhoJQAR',
      accountName: 'King-Henderson',
      contractEndDate: '2025-03-01T00:00:00.000Z',
      churn: '0.339555040981986300000000000000',
      expansion: '0.331710926150089100000000000000',
      revenue: '826800',
      churnSegment: '2.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '1.000000000000000000000000000000',
      revenuePeriod: '2025-Q1',
      datetime: 'latest',
      pastRevenue: '747700',
      revenuePlift: '0.11',
    },
    {
      id: 391,
      tenantId: 'demo',
      accountId: '0011U00000LILyCQAX',
      accountName: 'Johnson-Underwood',
      contractEndDate: '2025-03-01T00:00:00.000Z',
      churn: '0.291631647276298900000000000000',
      expansion: '0.370965663911576870000000000000',
      revenue: '3000000',
      churnSegment: '0.000000000000000000000000000000',
      contractEndPeriod: '2024-Q2',
      expansionSegment: '2.000000000000000000000000000000',
      revenuePeriod: '2025-Q1',
      datetime: 'latest',
      pastRevenue: '2900000',
      revenuePlift: '0.03',
    },
  ],
  totalSize: '10',
  contractPeriodEnds: [
    '2024-Q2',
    '2024-Q3',
    '2024-Q4',
    '2025-Q1',
    '2025-Q2',
    '2025-Q3',
    '2025-Q4',
    '2026-Q1',
    '2026-Q2',
    '2026-Q3',
    '2026-Q4',
    null,
  ],
  revenuePeriods: ['Overall', '2025-Q1', '2024-Q4', null],
}

export const demoGetAccountDetailsOverall = {
  '0011U00001gUmjFQAS': {
    grid: 'account',
    dimension: 'overall',
    train_datetime: '2024-04-16T19-24-41Z',
    infer_datetime: '2024-04-16T20-43-40Z',
    dataset_date: '2024-04-07',
    is_revenue_quantified: true,
    account_name: 'Oneill, King and Fleming',
    user_count: 'nan',
    events: {
      churn: {
        full_name: 'churn in next 180 days',
        impact: 'negative',
        propensity: 0.31503025289234243,
        propensity_text: '32%',
        segment: 'low',
        trend: [
          {
            label: '2024-03-03',
            value: 0.30600591256909515,
          },
          {
            label: '2024-03-10',
            value: 0.3079804688737074,
          },
          {
            label: '2024-03-17',
            value: 0.3146414059329219,
          },
          {
            label: '2024-03-24',
            value: 0.3168295322784976,
          },
          {
            label: '2024-03-31',
            value: 0.31453830811831524,
          },
          {
            label: '2024-04-07',
            value: 0.31503025289234243,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: 0.0004919447740271976,
          percent: 0.0015640218101578584,
        },
        drivers: [
          {
            driver_id: '2-002',
            family: {
              platform: 'snowflake',
              object: 'Customer health',
              field: 'Seat usage',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.3130497146891022,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.001980538203240234,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1679.3878398124873,
              elift_improve_by: 10.870773073672808,
            },
            metrics: {
              '90-day average': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-009',
            family: {
              platform: 'pendo',
              object: 'Page',
              field: 'Minutes on [Job profile]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: 'numminutes@name[ar-invoices-table-followup-activity-notes]',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.31451900600296023,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.0005112468893821998,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1684.7583245628377,
              elift_improve_by: 5.500288323322366,
            },
            metrics: {
              '30-day total': {
                segment: 'medium',
                improve_from: 6,
                improve_to: 1706.2121951219508,
                improve_from_text: '6.00',
                improve_to_text: '1.71K',
              },
            },
          },
          {
            driver_id: '2-001',
            family: {
              platform: 'snowflake',
              object: 'Product Telemetry',
              field: 'Minutes on [Feature A]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: 'numminutes@name[ar-invoices-table-followup-activity-email-views]',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.31438476312083113,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.0006454897715112984,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1686.628014290037,
              elift_improve_by: 3.6305985961230363,
            },
            metrics: {
              '30-day average': {
                segment: 'medium',
                improve_from: 16,
                improve_to: 284.17619047619036,
                improve_from_text: '16.00',
                improve_to_text: '284.18',
              },
            },
          },
        ],
      },
      conversion: {
        full_name: 'conversion in next 180 days',
        impact: 'positive',
        propensity: 0.3325217877539876,
        propensity_text: '33%',
        segment: 'medium',
        trend: [
          {
            label: '2024-03-03',
            value: 0.3304239285307039,
          },
          {
            label: '2024-03-10',
            value: 0.3301258218839187,
          },
          {
            label: '2024-03-17',
            value: 0.3312066901325616,
          },
          {
            label: '2024-03-24',
            value: 0.3310275930120336,
          },
          {
            label: '2024-03-31',
            value: 0.3324182480917543,
          },
          {
            label: '2024-04-07',
            value: 0.3325217877539876,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: 0.00010353966223330957,
          percent: 0.00031147406265353547,
        },
        drivers: [
          {
            driver_id: '2-006',
            family: {
              platform: 'snowflake',
              object: 'Job execution',
              field: 'Is business critical',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.33328234241523425,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0007605546612466374,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 728.1077948092732,
              elift_improve_by: 175.8847443387773,
            },
            metrics: {
              '60-day total': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-005',
            family: {
              platform: 'snowflake',
              object: 'Job execution',
              field: 'Usage (minutes)',
              std_platform: 'pendo',
              std_object: 'page_telemetry',
              std_field: 'numevents',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.3330616528461531,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0005398650921654702,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 727.6256636699015,
              elift_improve_by: 175.40261319940566,
            },
            metrics: {
              '30-day average': {
                segment: 'high',
                improve_from: 2.158766859344894,
                improve_to: 4.413589127407062,
                improve_from_text: '2.16',
                improve_to_text: '4.41',
              },
            },
          },
          {
            driver_id: '2-001',
            family: {
              platform: 'snowflake',
              object: 'Product Telemetry',
              field: 'Jobs exceed quota',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'numvisitors',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.3328588744240152,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0003370866700275754,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 727.1826622534215,
              elift_improve_by: 174.95961178292566,
            },
            metrics: {
              '30-day total': {
                segment: 'high',
                improve_from: 3,
                improve_to: 24.239999771118164,
                improve_from_text: '3.00',
                improve_to_text: '24.24',
              },
            },
          },
        ],
      },
      expansion: {
        full_name: 'expansion in next 180 days',
        impact: 'positive',
        propensity: 0.35244795935367007,
        propensity_text: '35%',
        segment: 'high',
        trend: [
          {
            label: '2024-03-03',
            value: 0.3635701589002009,
          },
          {
            label: '2024-03-10',
            value: 0.3618937092423738,
          },
          {
            label: '2024-03-17',
            value: 0.35415190393451657,
          },
          {
            label: '2024-03-24',
            value: 0.3521428747094688,
          },
          {
            label: '2024-03-31',
            value: 0.35304344378993036,
          },
          {
            label: '2024-04-07',
            value: 0.35244795935367007,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: -0.0005954844362602851,
          percent: -0.0016867171639494123,
        },
        drivers: [
          {
            driver_id: '2-004',
            family: {
              platform: 'gainsight',
              object: 'Playbook',
              field: 'Active plays',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3536679428956636,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.0012199835419935412,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 1054.5320847424443,
              elift_improve_by: 136.51556613831906,
            },
            metrics: {
              '30-day average': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-005',
            family: {
              platform: 'pendo',
              object: 'Page',
              field: 'Minutes on [Home page]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: '_row@name[Invoice Drawer > Add Note]',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3528897285428849,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.00044176918921484676,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 937.9342265178361,
              elift_improve_by: 19.917707913710842,
            },
            metrics: {
              '30-day average': {
                segment: 'low',
                improve_from: 70,
                improve_to: 210.7815789473685,
                improve_from_text: '70.00',
                improve_to_text: '210.78',
              },
            },
          },
          {
            driver_id: '2-003',
            family: {
              platform: 'intercom',
              object: 'Conversations',
              field: 'Time to response',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: '_row@name[Tesorio Logo]',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3530016344443953,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.0005536750907252364,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 931.3857570716496,
              elift_improve_by: 13.36923846752427,
            },
            metrics: {
              '90-day average': {
                segment: 'medium',
                improve_from: 3,
                improve_to: 6.8604651162790695,
                improve_from_text: '-8.00',
                improve_to_text: '6.86',
              },
            },
          },
        ],
      },
    },
    usages: {
      revenues: {
        trend: [
          {
            label: revenueForcastingQuerters[1],
            value: 3086906.365349761,
            lowerbound: null,
            upperbound: null,
            future: false,
          },
          {
            label: revenueForcastingQuerters[2],
            value: 2827351.3448829306,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
          {
            label: revenueForcastingQuerters[3],
            value: 3173424.705505371,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
          {
            label: revenueForcastingQuerters[4],
            value: 3173424.705505371,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
        ],
        lift: {
          from: revenueForcastingQuerters[1],
          to: revenueForcastingQuerters[4],
          value: 388433.4200290193,
          percent: 0.12240196509315349,
        },
      },
    },
    account_id: '0011U00001gUmjFQAS',
  },
  '0011U0000077rWmQAI': {
    grid: 'account',
    dimension: 'overall',
    train_datetime: '2024-04-16T19-24-41Z',
    infer_datetime: '2024-04-16T20-43-40Z',
    dataset_date: '2024-04-07',
    is_revenue_quantified: true,
    account_name: 'Gutierrez Group',
    user_count: 'nan',
    events: {
      churn: {
        full_name: 'churn in next 180 days',
        impact: 'negative',
        propensity: 0.31503025289234243,
        propensity_text: '32%',
        segment: 'low',
        trend: [
          {
            label: '2024-03-03',
            value: 0.30600591256909515,
          },
          {
            label: '2024-03-10',
            value: 0.3079804688737074,
          },
          {
            label: '2024-03-17',
            value: 0.3146414059329219,
          },
          {
            label: '2024-03-24',
            value: 0.3168295322784976,
          },
          {
            label: '2024-03-31',
            value: 0.31453830811831524,
          },
          {
            label: '2024-04-07',
            value: 0.31503025289234243,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: 0.0004919447740271976,
          percent: 0.0015640218101578584,
        },
        drivers: [
          {
            driver_id: '2-002',
            family: {
              platform: 'snowflake',
              object: 'Customer health',
              field: 'Seat usage',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.3130497146891022,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.001980538203240234,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1679.3878398124873,
              elift_improve_by: 10.870773073672808,
            },
            metrics: {
              '90-day average': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-009',
            family: {
              platform: 'pendo',
              object: 'Page',
              field: 'Minutes on [Job profile]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: 'numminutes@name[ar-invoices-table-followup-activity-notes]',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.31451900600296023,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.0005112468893821998,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1684.7583245628377,
              elift_improve_by: 5.500288323322366,
            },
            metrics: {
              '30-day total': {
                segment: 'medium',
                improve_from: 6,
                improve_to: 1706.2121951219508,
                improve_from_text: '6.00',
                improve_to_text: '1.71K',
              },
            },
          },
          {
            driver_id: '2-001',
            family: {
              platform: 'snowflake',
              object: 'Product Telemetry',
              field: 'Minutes on [Feature A]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: 'numminutes@name[ar-invoices-table-followup-activity-email-views]',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.31438476312083113,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.0006454897715112984,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1686.628014290037,
              elift_improve_by: 3.6305985961230363,
            },
            metrics: {
              '30-day average': {
                segment: 'medium',
                improve_from: 16,
                improve_to: 284.17619047619036,
                improve_from_text: '16.00',
                improve_to_text: '284.18',
              },
            },
          },
        ],
      },
      conversion: {
        full_name: 'conversion in next 180 days',
        impact: 'positive',
        propensity: 0.3325217877539876,
        propensity_text: '33%',
        segment: 'medium',
        trend: [
          {
            label: '2024-03-03',
            value: 0.3304239285307039,
          },
          {
            label: '2024-03-10',
            value: 0.3301258218839187,
          },
          {
            label: '2024-03-17',
            value: 0.3312066901325616,
          },
          {
            label: '2024-03-24',
            value: 0.3310275930120336,
          },
          {
            label: '2024-03-31',
            value: 0.3324182480917543,
          },
          {
            label: '2024-04-07',
            value: 0.3325217877539876,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: 0.00010353966223330957,
          percent: 0.00031147406265353547,
        },
        drivers: [
          {
            driver_id: '2-006',
            family: {
              platform: 'snowflake',
              object: 'Job execution',
              field: 'Is business critical',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.33328234241523425,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0007605546612466374,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 728.1077948092732,
              elift_improve_by: 175.8847443387773,
            },
            metrics: {
              '60-day total': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-005',
            family: {
              platform: 'snowflake',
              object: 'Job execution',
              field: 'Usage (minutes)',
              std_platform: 'pendo',
              std_object: 'page_telemetry',
              std_field: 'numevents',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.3330616528461531,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0005398650921654702,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 727.6256636699015,
              elift_improve_by: 175.40261319940566,
            },
            metrics: {
              '30-day average': {
                segment: 'high',
                improve_from: 2.158766859344894,
                improve_to: 4.413589127407062,
                improve_from_text: '2.16',
                improve_to_text: '4.41',
              },
            },
          },
          {
            driver_id: '2-001',
            family: {
              platform: 'snowflake',
              object: 'Product Telemetry',
              field: 'Jobs exceed quota',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'numvisitors',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.3328588744240152,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0003370866700275754,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 727.1826622534215,
              elift_improve_by: 174.95961178292566,
            },
            metrics: {
              '30-day total': {
                segment: 'high',
                improve_from: 3,
                improve_to: 24.239999771118164,
                improve_from_text: '3.00',
                improve_to_text: '24.24',
              },
            },
          },
        ],
      },
      expansion: {
        full_name: 'expansion in next 180 days',
        impact: 'positive',
        propensity: 0.35244795935367007,
        propensity_text: '35%',
        segment: 'high',
        trend: [
          {
            label: '2024-03-03',
            value: 0.3635701589002009,
          },
          {
            label: '2024-03-10',
            value: 0.3618937092423738,
          },
          {
            label: '2024-03-17',
            value: 0.35415190393451657,
          },
          {
            label: '2024-03-24',
            value: 0.3521428747094688,
          },
          {
            label: '2024-03-31',
            value: 0.35304344378993036,
          },
          {
            label: '2024-04-07',
            value: 0.35244795935367007,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: -0.0005954844362602851,
          percent: -0.0016867171639494123,
        },
        drivers: [
          {
            driver_id: '2-004',
            family: {
              platform: 'gainsight',
              object: 'Playbook',
              field: 'Active plays',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3536679428956636,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.0012199835419935412,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 1054.5320847424443,
              elift_improve_by: 136.51556613831906,
            },
            metrics: {
              '30-day average': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-005',
            family: {
              platform: 'pendo',
              object: 'Page',
              field: 'Minutes on [Home page]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: '_row@name[Invoice Drawer > Add Note]',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3528897285428849,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.00044176918921484676,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 937.9342265178361,
              elift_improve_by: 19.917707913710842,
            },
            metrics: {
              '30-day average': {
                segment: 'low',
                improve_from: 70,
                improve_to: 210.7815789473685,
                improve_from_text: '70.00',
                improve_to_text: '210.78',
              },
            },
          },
          {
            driver_id: '2-003',
            family: {
              platform: 'intercom',
              object: 'Conversations',
              field: 'Time to response',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: '_row@name[Tesorio Logo]',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3530016344443953,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.0005536750907252364,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 931.3857570716496,
              elift_improve_by: 13.36923846752427,
            },
            metrics: {
              '90-day average': {
                segment: 'medium',
                improve_from: 2.2,
                improve_to: 6.8604651162790695,
                improve_from_text: '-8.00',
                improve_to_text: '6.86',
              },
            },
          },
        ],
      },
    },
    usages: {
      revenues: {
        trend: [
          {
            label: revenueForcastingQuerters[1],
            value: 1031263.1258441446,
            lowerbound: null,
            upperbound: null,
            future: false,
          },
          {
            label: revenueForcastingQuerters[2],
            value: 962723.7489515296,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
          {
            label: revenueForcastingQuerters[3],
            value: 1054109.5848083496,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
          {
            label: revenueForcastingQuerters[4],
            value: 1054109.5848083496,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
        ],
        lift: {
          from: revenueForcastingQuerters[1],
          to: revenueForcastingQuerters[4],
          value: 100060.56835858375,
          percent: 0.0949242562639026,
        },
      },
    },
    account_id: '0011U0000077rWmQAI',
  },
  '0011U0000077jp0QAA': {
    grid: 'account',
    dimension: 'overall',
    train_datetime: '2024-04-16T19-24-41Z',
    infer_datetime: '2024-04-16T20-43-40Z',
    dataset_date: '2024-04-07',
    is_revenue_quantified: true,
    account_name: 'Fisher, Cooper and Barr',
    user_count: 'nan',
    events: {
      churn: {
        full_name: 'churn in next 180 days',
        impact: 'negative',
        propensity: 0.31503025289234243,
        propensity_text: '32%',
        segment: 'low',
        trend: [
          {
            label: '2024-03-03',
            value: 0.30600591256909515,
          },
          {
            label: '2024-03-10',
            value: 0.3079804688737074,
          },
          {
            label: '2024-03-17',
            value: 0.3146414059329219,
          },
          {
            label: '2024-03-24',
            value: 0.3168295322784976,
          },
          {
            label: '2024-03-31',
            value: 0.31453830811831524,
          },
          {
            label: '2024-04-07',
            value: 0.31503025289234243,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: 0.0004919447740271976,
          percent: 0.0015640218101578584,
        },
        drivers: [
          {
            driver_id: '2-002',
            family: {
              platform: 'snowflake',
              object: 'Customer health',
              field: 'Seat usage',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.3130497146891022,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.001980538203240234,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1679.3878398124873,
              elift_improve_by: 10.870773073672808,
            },
            metrics: {
              '90-day average': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-009',
            family: {
              platform: 'pendo',
              object: 'Page',
              field: 'Minutes on [Job profile]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: 'numminutes@name[ar-invoices-table-followup-activity-notes]',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.31451900600296023,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.0005112468893821998,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1684.7583245628377,
              elift_improve_by: 5.500288323322366,
            },
            metrics: {
              '30-day total': {
                segment: 'medium',
                improve_from: 6,
                improve_to: 1706.2121951219508,
                improve_from_text: '6.00',
                improve_to_text: '1.71K',
              },
            },
          },
          {
            driver_id: '2-001',
            family: {
              platform: 'snowflake',
              object: 'Product Telemetry',
              field: 'Minutes on [Feature A]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: 'numminutes@name[ar-invoices-table-followup-activity-email-views]',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.31438476312083113,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.0006454897715112984,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1686.628014290037,
              elift_improve_by: 3.6305985961230363,
            },
            metrics: {
              '30-day average': {
                segment: 'medium',
                improve_from: 16,
                improve_to: 284.17619047619036,
                improve_from_text: '16.00',
                improve_to_text: '284.18',
              },
            },
          },
        ],
      },
      conversion: {
        full_name: 'conversion in next 180 days',
        impact: 'positive',
        propensity: 0.3325217877539876,
        propensity_text: '33%',
        segment: 'medium',
        trend: [
          {
            label: '2024-03-03',
            value: 0.3304239285307039,
          },
          {
            label: '2024-03-10',
            value: 0.3301258218839187,
          },
          {
            label: '2024-03-17',
            value: 0.3312066901325616,
          },
          {
            label: '2024-03-24',
            value: 0.3310275930120336,
          },
          {
            label: '2024-03-31',
            value: 0.3324182480917543,
          },
          {
            label: '2024-04-07',
            value: 0.3325217877539876,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: 0.00010353966223330957,
          percent: 0.00031147406265353547,
        },
        drivers: [
          {
            driver_id: '2-006',
            family: {
              platform: 'snowflake',
              object: 'Job execution',
              field: 'Is business critical',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.33328234241523425,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0007605546612466374,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 728.1077948092732,
              elift_improve_by: 175.8847443387773,
            },
            metrics: {
              '60-day total': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-005',
            family: {
              platform: 'snowflake',
              object: 'Job execution',
              field: 'Usage (minutes)',
              std_platform: 'pendo',
              std_object: 'page_telemetry',
              std_field: 'numevents',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.3330616528461531,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0005398650921654702,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 727.6256636699015,
              elift_improve_by: 175.40261319940566,
            },
            metrics: {
              '30-day average': {
                segment: 'high',
                improve_from: 2.158766859344894,
                improve_to: 4.413589127407062,
                improve_from_text: '2.16',
                improve_to_text: '4.41',
              },
            },
          },
          {
            driver_id: '2-001',
            family: {
              platform: 'snowflake',
              object: 'Product Telemetry',
              field: 'Jobs exceed quota',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'numvisitors',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.3328588744240152,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0003370866700275754,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 727.1826622534215,
              elift_improve_by: 174.95961178292566,
            },
            metrics: {
              '30-day total': {
                segment: 'high',
                improve_from: 3,
                improve_to: 24.239999771118164,
                improve_from_text: '3.00',
                improve_to_text: '24.24',
              },
            },
          },
        ],
      },
      expansion: {
        full_name: 'expansion in next 180 days',
        impact: 'positive',
        propensity: 0.35244795935367007,
        propensity_text: '35%',
        segment: 'high',
        trend: [
          {
            label: '2024-03-03',
            value: 0.3635701589002009,
          },
          {
            label: '2024-03-10',
            value: 0.3618937092423738,
          },
          {
            label: '2024-03-17',
            value: 0.35415190393451657,
          },
          {
            label: '2024-03-24',
            value: 0.3521428747094688,
          },
          {
            label: '2024-03-31',
            value: 0.35304344378993036,
          },
          {
            label: '2024-04-07',
            value: 0.35244795935367007,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: -0.0005954844362602851,
          percent: -0.0016867171639494123,
        },
        drivers: [
          {
            driver_id: '2-004',
            family: {
              platform: 'gainsight',
              object: 'Playbook',
              field: 'Active plays',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3536679428956636,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.0012199835419935412,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 1054.5320847424443,
              elift_improve_by: 136.51556613831906,
            },
            metrics: {
              '30-day average': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-005',
            family: {
              platform: 'pendo',
              object: 'Page',
              field: 'Minutes on [Home page]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: '_row@name[Invoice Drawer > Add Note]',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3528897285428849,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.00044176918921484676,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 937.9342265178361,
              elift_improve_by: 19.917707913710842,
            },
            metrics: {
              '30-day average': {
                segment: 'low',
                improve_from: 70,
                improve_to: 210.7815789473685,
                improve_from_text: '70.00',
                improve_to_text: '210.78',
              },
            },
          },
          {
            driver_id: '2-003',
            family: {
              platform: 'intercom',
              object: 'Conversations',
              field: 'Time to response',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: '_row@name[Tesorio Logo]',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3530016344443953,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.0005536750907252364,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 931.3857570716496,
              elift_improve_by: 13.36923846752427,
            },
            metrics: {
              '90-day average': {
                segment: 'medium',
                improve_from: 4.7,
                improve_to: 6.8604651162790695,
                improve_from_text: '-8.00',
                improve_to_text: '6.86',
              },
            },
          },
        ],
      },
    },
    usages: {
      revenues: {
        trend: [
          {
            label: revenueForcastingQuerters[1],
            value: 2436998.5768378493,
            lowerbound: null,
            upperbound: null,
            future: false,
          },
          {
            label: revenueForcastingQuerters[2],
            value: 2822716.015315341,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
          {
            label: revenueForcastingQuerters[3],
            value: 2308426.097345352,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
          {
            label: revenueForcastingQuerters[4],
            value: 2308426.097345352,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
        ],
        lift: {
          from: revenueForcastingQuerters[1],
          to: revenueForcastingQuerters[4],
          value: -420587.92375927133,
          percent: -0.18219683283035995,
        },
      },
    },
    account_id: '0011U0000077jp0QAA',
  },
  '0011U00001LJgzDQAT': {
    grid: 'account',
    dimension: 'overall',
    train_datetime: '2024-04-16T19-24-41Z',
    infer_datetime: '2024-04-16T20-43-40Z',
    dataset_date: '2024-04-07',
    is_revenue_quantified: true,
    account_name: 'Durham, Bennett and Baker',
    user_count: 'nan',
    events: {
      churn: {
        full_name: 'churn in next 180 days',
        impact: 'negative',
        propensity: 0.31503025289234243,
        propensity_text: '32%',
        segment: 'low',
        trend: [
          {
            label: '2024-03-03',
            value: 0.30600591256909515,
          },
          {
            label: '2024-03-10',
            value: 0.3079804688737074,
          },
          {
            label: '2024-03-17',
            value: 0.3146414059329219,
          },
          {
            label: '2024-03-24',
            value: 0.3168295322784976,
          },
          {
            label: '2024-03-31',
            value: 0.31453830811831524,
          },
          {
            label: '2024-04-07',
            value: 0.31503025289234243,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: 0.0004919447740271976,
          percent: 0.0015640218101578584,
        },
        drivers: [
          {
            driver_id: '2-002',
            family: {
              platform: 'snowflake',
              object: 'Customer health',
              field: 'Seat usage',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.3130497146891022,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.001980538203240234,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1679.3878398124873,
              elift_improve_by: 10.870773073672808,
            },
            metrics: {
              '90-day average': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-009',
            family: {
              platform: 'pendo',
              object: 'Page',
              field: 'Minutes on [Job profile]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: 'numminutes@name[ar-invoices-table-followup-activity-notes]',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.31451900600296023,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.0005112468893821998,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1684.7583245628377,
              elift_improve_by: 5.500288323322366,
            },
            metrics: {
              '30-day total': {
                segment: 'medium',
                improve_from: 6,
                improve_to: 1706.2121951219508,
                improve_from_text: '6.00',
                improve_to_text: '1.71K',
              },
            },
          },
          {
            driver_id: '2-001',
            family: {
              platform: 'snowflake',
              object: 'Product Telemetry',
              field: 'Minutes on [Feature A]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: 'numminutes@name[ar-invoices-table-followup-activity-email-views]',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.31438476312083113,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.0006454897715112984,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1686.628014290037,
              elift_improve_by: 3.6305985961230363,
            },
            metrics: {
              '30-day average': {
                segment: 'medium',
                improve_from: 16,
                improve_to: 284.17619047619036,
                improve_from_text: '16.00',
                improve_to_text: '284.18',
              },
            },
          },
        ],
      },
      conversion: {
        full_name: 'conversion in next 180 days',
        impact: 'positive',
        propensity: 0.3325217877539876,
        propensity_text: '33%',
        segment: 'medium',
        trend: [
          {
            label: '2024-03-03',
            value: 0.3304239285307039,
          },
          {
            label: '2024-03-10',
            value: 0.3301258218839187,
          },
          {
            label: '2024-03-17',
            value: 0.3312066901325616,
          },
          {
            label: '2024-03-24',
            value: 0.3310275930120336,
          },
          {
            label: '2024-03-31',
            value: 0.3324182480917543,
          },
          {
            label: '2024-04-07',
            value: 0.3325217877539876,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: 0.00010353966223330957,
          percent: 0.00031147406265353547,
        },
        drivers: [
          {
            driver_id: '2-006',
            family: {
              platform: 'snowflake',
              object: 'Job execution',
              field: 'Is business critical',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.33328234241523425,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0007605546612466374,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 728.1077948092732,
              elift_improve_by: 175.8847443387773,
            },
            metrics: {
              '60-day total': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-005',
            family: {
              platform: 'snowflake',
              object: 'Job execution',
              field: 'Usage (minutes)',
              std_platform: 'pendo',
              std_object: 'page_telemetry',
              std_field: 'numevents',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.3330616528461531,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0005398650921654702,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 727.6256636699015,
              elift_improve_by: 175.40261319940566,
            },
            metrics: {
              '30-day average': {
                segment: 'high',
                improve_from: 2.158766859344894,
                improve_to: 4.413589127407062,
                improve_from_text: '2.16',
                improve_to_text: '4.41',
              },
            },
          },
          {
            driver_id: '2-001',
            family: {
              platform: 'snowflake',
              object: 'Product Telemetry',
              field: 'Jobs exceed quota',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'numvisitors',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.3328588744240152,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0003370866700275754,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 727.1826622534215,
              elift_improve_by: 174.95961178292566,
            },
            metrics: {
              '30-day total': {
                segment: 'high',
                improve_from: 3,
                improve_to: 24.239999771118164,
                improve_from_text: '3.00',
                improve_to_text: '24.24',
              },
            },
          },
        ],
      },
      expansion: {
        full_name: 'expansion in next 180 days',
        impact: 'positive',
        propensity: 0.35244795935367007,
        propensity_text: '35%',
        segment: 'high',
        trend: [
          {
            label: '2024-03-03',
            value: 0.3635701589002009,
          },
          {
            label: '2024-03-10',
            value: 0.3618937092423738,
          },
          {
            label: '2024-03-17',
            value: 0.35415190393451657,
          },
          {
            label: '2024-03-24',
            value: 0.3521428747094688,
          },
          {
            label: '2024-03-31',
            value: 0.35304344378993036,
          },
          {
            label: '2024-04-07',
            value: 0.35244795935367007,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: -0.0005954844362602851,
          percent: -0.0016867171639494123,
        },
        drivers: [
          {
            driver_id: '2-004',
            family: {
              platform: 'gainsight',
              object: 'Playbook',
              field: 'Active plays',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3536679428956636,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.0012199835419935412,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 1054.5320847424443,
              elift_improve_by: 136.51556613831906,
            },
            metrics: {
              '30-day average': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-005',
            family: {
              platform: 'pendo',
              object: 'Page',
              field: 'Minutes on [Home page]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: '_row@name[Invoice Drawer > Add Note]',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3528897285428849,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.00044176918921484676,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 937.9342265178361,
              elift_improve_by: 19.917707913710842,
            },
            metrics: {
              '30-day average': {
                segment: 'low',
                improve_from: 70,
                improve_to: 210.7815789473685,
                improve_from_text: '70.00',
                improve_to_text: '210.78',
              },
            },
          },
          {
            driver_id: '2-003',
            family: {
              platform: 'intercom',
              object: 'Conversations',
              field: 'Time to response',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: '_row@name[Tesorio Logo]',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3530016344443953,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.0005536750907252364,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 931.3857570716496,
              elift_improve_by: 13.36923846752427,
            },
            metrics: {
              '90-day average': {
                segment: 'medium',
                improve_from: 1.24,
                improve_to: 6.8604651162790695,
                improve_from_text: '-8.00',
                improve_to_text: '6.86',
              },
            },
          },
        ],
      },
    },
    usages: {
      revenues: {
        trend: [
          {
            label: revenueForcastingQuerters[1],
            value: 4782362.836432857,
            lowerbound: null,
            upperbound: null,
            future: false,
          },
          {
            label: revenueForcastingQuerters[2],
            value: 4392762.4017922385,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
          {
            label: revenueForcastingQuerters[3],
            value: 4912229.647979731,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
          {
            label: revenueForcastingQuerters[4],
            value: 4912229.647979731,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
        ],
        lift: {
          from: revenueForcastingQuerters[1],
          to: revenueForcastingQuerters[4],
          value: 580896.9788203161,
          percent: 0.1182552568687874,
        },
      },
    },
    account_id: '0011U00001LJgzDQAT',
  },
  '0011U00001CwhlFQAR': {
    grid: 'account',
    dimension: 'overall',
    train_datetime: '2024-04-16T19-24-41Z',
    infer_datetime: '2024-04-16T20-43-40Z',
    dataset_date: '2024-04-07',
    is_revenue_quantified: true,
    account_name: 'Foster Inc',
    user_count: 'nan',
    events: {
      churn: {
        full_name: 'churn in next 180 days',
        impact: 'negative',
        propensity: 0.31503025289234243,
        propensity_text: '32%',
        segment: 'low',
        trend: [
          {
            label: '2024-03-03',
            value: 0.30600591256909515,
          },
          {
            label: '2024-03-10',
            value: 0.3079804688737074,
          },
          {
            label: '2024-03-17',
            value: 0.3146414059329219,
          },
          {
            label: '2024-03-24',
            value: 0.3168295322784976,
          },
          {
            label: '2024-03-31',
            value: 0.31453830811831524,
          },
          {
            label: '2024-04-07',
            value: 0.31503025289234243,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: 0.0004919447740271976,
          percent: 0.0015640218101578584,
        },
        drivers: [
          {
            driver_id: '2-002',
            family: {
              platform: 'snowflake',
              object: 'Customer health',
              field: 'Seat usage',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.3130497146891022,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.001980538203240234,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1679.3878398124873,
              elift_improve_by: 10.870773073672808,
            },
            metrics: {
              '90-day average': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-009',
            family: {
              platform: 'pendo',
              object: 'Page',
              field: 'Minutes on [Job profile]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: 'numminutes@name[ar-invoices-table-followup-activity-notes]',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.31451900600296023,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.0005112468893821998,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1684.7583245628377,
              elift_improve_by: 5.500288323322366,
            },
            metrics: {
              '30-day total': {
                segment: 'medium',
                improve_from: 6,
                improve_to: 1706.2121951219508,
                improve_from_text: '6.00',
                improve_to_text: '1.71K',
              },
            },
          },
          {
            driver_id: '2-001',
            family: {
              platform: 'snowflake',
              object: 'Product Telemetry',
              field: 'Minutes on [Feature A]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: 'numminutes@name[ar-invoices-table-followup-activity-email-views]',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.31438476312083113,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.0006454897715112984,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1686.628014290037,
              elift_improve_by: 3.6305985961230363,
            },
            metrics: {
              '30-day average': {
                segment: 'medium',
                improve_from: 16,
                improve_to: 284.17619047619036,
                improve_from_text: '16.00',
                improve_to_text: '284.18',
              },
            },
          },
        ],
      },
      conversion: {
        full_name: 'conversion in next 180 days',
        impact: 'positive',
        propensity: 0.3325217877539876,
        propensity_text: '33%',
        segment: 'medium',
        trend: [
          {
            label: '2024-03-03',
            value: 0.3304239285307039,
          },
          {
            label: '2024-03-10',
            value: 0.3301258218839187,
          },
          {
            label: '2024-03-17',
            value: 0.3312066901325616,
          },
          {
            label: '2024-03-24',
            value: 0.3310275930120336,
          },
          {
            label: '2024-03-31',
            value: 0.3324182480917543,
          },
          {
            label: '2024-04-07',
            value: 0.3325217877539876,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: 0.00010353966223330957,
          percent: 0.00031147406265353547,
        },
        drivers: [
          {
            driver_id: '2-006',
            family: {
              platform: 'snowflake',
              object: 'Job execution',
              field: 'Is business critical',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.33328234241523425,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0007605546612466374,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 728.1077948092732,
              elift_improve_by: 175.8847443387773,
            },
            metrics: {
              '60-day total': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-005',
            family: {
              platform: 'snowflake',
              object: 'Job execution',
              field: 'Usage (minutes)',
              std_platform: 'pendo',
              std_object: 'page_telemetry',
              std_field: 'numevents',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.3330616528461531,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0005398650921654702,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 727.6256636699015,
              elift_improve_by: 175.40261319940566,
            },
            metrics: {
              '30-day average': {
                segment: 'high',
                improve_from: 2.158766859344894,
                improve_to: 4.413589127407062,
                improve_from_text: '2.16',
                improve_to_text: '4.41',
              },
            },
          },
          {
            driver_id: '2-001',
            family: {
              platform: 'snowflake',
              object: 'Product Telemetry',
              field: 'Jobs exceed quota',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'numvisitors',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.3328588744240152,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0003370866700275754,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 727.1826622534215,
              elift_improve_by: 174.95961178292566,
            },
            metrics: {
              '30-day total': {
                segment: 'high',
                improve_from: 3,
                improve_to: 24.239999771118164,
                improve_from_text: '3.00',
                improve_to_text: '24.24',
              },
            },
          },
        ],
      },
      expansion: {
        full_name: 'expansion in next 180 days',
        impact: 'positive',
        propensity: 0.35244795935367007,
        propensity_text: '35%',
        segment: 'high',
        trend: [
          {
            label: '2024-03-03',
            value: 0.3635701589002009,
          },
          {
            label: '2024-03-10',
            value: 0.3618937092423738,
          },
          {
            label: '2024-03-17',
            value: 0.35415190393451657,
          },
          {
            label: '2024-03-24',
            value: 0.3521428747094688,
          },
          {
            label: '2024-03-31',
            value: 0.35304344378993036,
          },
          {
            label: '2024-04-07',
            value: 0.35244795935367007,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: -0.0005954844362602851,
          percent: -0.0016867171639494123,
        },
        drivers: [
          {
            driver_id: '2-004',
            family: {
              platform: 'gainsight',
              object: 'Playbook',
              field: 'Active plays',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3536679428956636,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.0012199835419935412,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 1054.5320847424443,
              elift_improve_by: 136.51556613831906,
            },
            metrics: {
              '30-day average': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-005',
            family: {
              platform: 'pendo',
              object: 'Page',
              field: 'Minutes on [Home page]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: '_row@name[Invoice Drawer > Add Note]',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3528897285428849,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.00044176918921484676,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 937.9342265178361,
              elift_improve_by: 19.917707913710842,
            },
            metrics: {
              '30-day average': {
                segment: 'low',
                improve_from: 70,
                improve_to: 210.7815789473685,
                improve_from_text: '70.00',
                improve_to_text: '210.78',
              },
            },
          },
          {
            driver_id: '2-003',
            family: {
              platform: 'intercom',
              object: 'Conversations',
              field: 'Time to response',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: '_row@name[Tesorio Logo]',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3530016344443953,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.0005536750907252364,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 931.3857570716496,
              elift_improve_by: 13.36923846752427,
            },
            metrics: {
              '90-day average': {
                segment: 'medium',
                improve_from: 2.774,
                improve_to: 6.8604651162790695,
                improve_from_text: '-8.00',
                improve_to_text: '6.86',
              },
            },
          },
        ],
      },
    },
    usages: {
      revenues: {
        trend: [
          {
            label: revenueForcastingQuerters[1],
            value: 866212.424010139,
            lowerbound: null,
            upperbound: null,
            future: false,
          },
          {
            label: revenueForcastingQuerters[2],
            value: 1014877.1694902629,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
          {
            label: revenueForcastingQuerters[3],
            value: 816657.5088500977,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
          {
            label: revenueForcastingQuerters[4],
            value: 816657.5088500977,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
        ],
        lift: {
          from: revenueForcastingQuerters[1],
          to: revenueForcastingQuerters[4],
          value: -159504.5973344878,
          percent: -0.19531394202090882,
        },
      },
    },
    account_id: '0011U00001CwhlFQAR',
  },
  '0011U000014ifycQAA': {
    grid: 'account',
    dimension: 'overall',
    train_datetime: '2024-04-16T19-24-41Z',
    infer_datetime: '2024-04-16T20-43-40Z',
    dataset_date: '2024-04-07',
    is_revenue_quantified: true,
    account_name: 'Cunningham Group',
    user_count: 'nan',
    events: {
      churn: {
        full_name: 'churn in next 180 days',
        impact: 'negative',
        propensity: 0.31503025289234243,
        propensity_text: '32%',
        segment: 'low',
        trend: [
          {
            label: '2024-03-03',
            value: 0.30600591256909515,
          },
          {
            label: '2024-03-10',
            value: 0.3079804688737074,
          },
          {
            label: '2024-03-17',
            value: 0.3146414059329219,
          },
          {
            label: '2024-03-24',
            value: 0.3168295322784976,
          },
          {
            label: '2024-03-31',
            value: 0.31453830811831524,
          },
          {
            label: '2024-04-07',
            value: 0.31503025289234243,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: 0.0004919447740271976,
          percent: 0.0015640218101578584,
        },
        drivers: [
          {
            driver_id: '2-002',
            family: {
              platform: 'snowflake',
              object: 'Customer health',
              field: 'Seat usage',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.3130497146891022,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.001980538203240234,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1679.3878398124873,
              elift_improve_by: 10.870773073672808,
            },
            metrics: {
              '90-day average': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-009',
            family: {
              platform: 'pendo',
              object: 'Page',
              field: 'Minutes on [Job profile]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: 'numminutes@name[ar-invoices-table-followup-activity-notes]',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.31451900600296023,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.0005112468893821998,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1684.7583245628377,
              elift_improve_by: 5.500288323322366,
            },
            metrics: {
              '30-day total': {
                segment: 'medium',
                improve_from: 6,
                improve_to: 1706.2121951219508,
                improve_from_text: '6.00',
                improve_to_text: '1.71K',
              },
            },
          },
          {
            driver_id: '2-001',
            family: {
              platform: 'snowflake',
              object: 'Product Telemetry',
              field: 'Minutes on [Feature A]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: 'numminutes@name[ar-invoices-table-followup-activity-email-views]',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.31438476312083113,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.0006454897715112984,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1686.628014290037,
              elift_improve_by: 3.6305985961230363,
            },
            metrics: {
              '30-day average': {
                segment: 'medium',
                improve_from: 16,
                improve_to: 284.17619047619036,
                improve_from_text: '16.00',
                improve_to_text: '284.18',
              },
            },
          },
        ],
      },
      conversion: {
        full_name: 'conversion in next 180 days',
        impact: 'positive',
        propensity: 0.3325217877539876,
        propensity_text: '33%',
        segment: 'medium',
        trend: [
          {
            label: '2024-03-03',
            value: 0.3304239285307039,
          },
          {
            label: '2024-03-10',
            value: 0.3301258218839187,
          },
          {
            label: '2024-03-17',
            value: 0.3312066901325616,
          },
          {
            label: '2024-03-24',
            value: 0.3310275930120336,
          },
          {
            label: '2024-03-31',
            value: 0.3324182480917543,
          },
          {
            label: '2024-04-07',
            value: 0.3325217877539876,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: 0.00010353966223330957,
          percent: 0.00031147406265353547,
        },
        drivers: [
          {
            driver_id: '2-006',
            family: {
              platform: 'snowflake',
              object: 'Job execution',
              field: 'Is business critical',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.33328234241523425,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0007605546612466374,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 728.1077948092732,
              elift_improve_by: 175.8847443387773,
            },
            metrics: {
              '60-day total': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-005',
            family: {
              platform: 'snowflake',
              object: 'Job execution',
              field: 'Usage (minutes)',
              std_platform: 'pendo',
              std_object: 'page_telemetry',
              std_field: 'numevents',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.3330616528461531,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0005398650921654702,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 727.6256636699015,
              elift_improve_by: 175.40261319940566,
            },
            metrics: {
              '30-day average': {
                segment: 'high',
                improve_from: 2.158766859344894,
                improve_to: 4.413589127407062,
                improve_from_text: '2.16',
                improve_to_text: '4.41',
              },
            },
          },
          {
            driver_id: '2-001',
            family: {
              platform: 'snowflake',
              object: 'Product Telemetry',
              field: 'Jobs exceed quota',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'numvisitors',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.3328588744240152,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0003370866700275754,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 727.1826622534215,
              elift_improve_by: 174.95961178292566,
            },
            metrics: {
              '30-day total': {
                segment: 'high',
                improve_from: 3,
                improve_to: 24.239999771118164,
                improve_from_text: '3.00',
                improve_to_text: '24.24',
              },
            },
          },
        ],
      },
      expansion: {
        full_name: 'expansion in next 180 days',
        impact: 'positive',
        propensity: 0.35244795935367007,
        propensity_text: '35%',
        segment: 'high',
        trend: [
          {
            label: '2024-03-03',
            value: 0.3635701589002009,
          },
          {
            label: '2024-03-10',
            value: 0.3618937092423738,
          },
          {
            label: '2024-03-17',
            value: 0.35415190393451657,
          },
          {
            label: '2024-03-24',
            value: 0.3521428747094688,
          },
          {
            label: '2024-03-31',
            value: 0.35304344378993036,
          },
          {
            label: '2024-04-07',
            value: 0.35244795935367007,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: -0.0005954844362602851,
          percent: -0.0016867171639494123,
        },
        drivers: [
          {
            driver_id: '2-004',
            family: {
              platform: 'gainsight',
              object: 'Playbook',
              field: 'Active plays',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3536679428956636,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.0012199835419935412,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 1054.5320847424443,
              elift_improve_by: 136.51556613831906,
            },
            metrics: {
              '30-day average': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-005',
            family: {
              platform: 'pendo',
              object: 'Page',
              field: 'Minutes on [Home page]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: '_row@name[Invoice Drawer > Add Note]',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3528897285428849,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.00044176918921484676,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 937.9342265178361,
              elift_improve_by: 19.917707913710842,
            },
            metrics: {
              '30-day average': {
                segment: 'low',
                improve_from: 70,
                improve_to: 210.7815789473685,
                improve_from_text: '70.00',
                improve_to_text: '210.78',
              },
            },
          },
          {
            driver_id: '2-003',
            family: {
              platform: 'intercom',
              object: 'Conversations',
              field: 'Time to response',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: '_row@name[Tesorio Logo]',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3530016344443953,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.0005536750907252364,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 931.3857570716496,
              elift_improve_by: 13.36923846752427,
            },
            metrics: {
              '90-day average': {
                segment: 'medium',
                improve_from: 1.67,
                improve_to: 6.8604651162790695,
                improve_from_text: '-8.00',
                improve_to_text: '6.86',
              },
            },
          },
        ],
      },
    },
    usages: {
      revenues: {
        trend: [
          {
            label: revenueForcastingQuerters[1],
            value: 2696088.226390595,
            lowerbound: null,
            upperbound: null,
            future: false,
          },
          {
            label: revenueForcastingQuerters[2],
            value: 2775561.126997927,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
          {
            label: revenueForcastingQuerters[3],
            value: 2669597.2595214844,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
          {
            label: revenueForcastingQuerters[4],
            value: 2669597.2595214844,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
        ],
        lift: {
          from: revenueForcastingQuerters[1],
          to: revenueForcastingQuerters[4],
          value: -101918.43640978525,
          percent: -0.038177457684405086,
        },
      },
    },
    account_id: '0011U000014ifycQAA',
  },
  '0011U0000077jUUQAY': {
    grid: 'account',
    dimension: 'overall',
    train_datetime: '2024-04-16T19-24-41Z',
    infer_datetime: '2024-04-16T20-43-40Z',
    dataset_date: '2024-04-07',
    is_revenue_quantified: true,
    account_name: 'Turner Group',
    user_count: 'nan',
    events: {
      churn: {
        full_name: 'churn in next 180 days',
        impact: 'negative',
        propensity: 0.31503025289234243,
        propensity_text: '32%',
        segment: 'low',
        trend: [
          {
            label: '2024-03-03',
            value: 0.30600591256909515,
          },
          {
            label: '2024-03-10',
            value: 0.3079804688737074,
          },
          {
            label: '2024-03-17',
            value: 0.3146414059329219,
          },
          {
            label: '2024-03-24',
            value: 0.3168295322784976,
          },
          {
            label: '2024-03-31',
            value: 0.31453830811831524,
          },
          {
            label: '2024-04-07',
            value: 0.31503025289234243,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: 0.0004919447740271976,
          percent: 0.0015640218101578584,
        },
        drivers: [
          {
            driver_id: '2-002',
            family: {
              platform: 'snowflake',
              object: 'Customer health',
              field: 'Seat usage',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.3130497146891022,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.001980538203240234,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1679.3878398124873,
              elift_improve_by: 10.870773073672808,
            },
            metrics: {
              '90-day average': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-009',
            family: {
              platform: 'pendo',
              object: 'Page',
              field: 'Minutes on [Job profile]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: 'numminutes@name[ar-invoices-table-followup-activity-notes]',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.31451900600296023,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.0005112468893821998,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1684.7583245628377,
              elift_improve_by: 5.500288323322366,
            },
            metrics: {
              '30-day total': {
                segment: 'medium',
                improve_from: 6,
                improve_to: 1706.2121951219508,
                improve_from_text: '6.00',
                improve_to_text: '1.71K',
              },
            },
          },
          {
            driver_id: '2-001',
            family: {
              platform: 'snowflake',
              object: 'Product Telemetry',
              field: 'Minutes on [Feature A]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: 'numminutes@name[ar-invoices-table-followup-activity-email-views]',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.31438476312083113,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.0006454897715112984,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1686.628014290037,
              elift_improve_by: 3.6305985961230363,
            },
            metrics: {
              '30-day average': {
                segment: 'medium',
                improve_from: 16,
                improve_to: 284.17619047619036,
                improve_from_text: '16.00',
                improve_to_text: '284.18',
              },
            },
          },
        ],
      },
      conversion: {
        full_name: 'conversion in next 180 days',
        impact: 'positive',
        propensity: 0.3325217877539876,
        propensity_text: '33%',
        segment: 'medium',
        trend: [
          {
            label: '2024-03-03',
            value: 0.3304239285307039,
          },
          {
            label: '2024-03-10',
            value: 0.3301258218839187,
          },
          {
            label: '2024-03-17',
            value: 0.3312066901325616,
          },
          {
            label: '2024-03-24',
            value: 0.3310275930120336,
          },
          {
            label: '2024-03-31',
            value: 0.3324182480917543,
          },
          {
            label: '2024-04-07',
            value: 0.3325217877539876,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: 0.00010353966223330957,
          percent: 0.00031147406265353547,
        },
        drivers: [
          {
            driver_id: '2-006',
            family: {
              platform: 'snowflake',
              object: 'Job execution',
              field: 'Is business critical',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.33328234241523425,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0007605546612466374,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 728.1077948092732,
              elift_improve_by: 175.8847443387773,
            },
            metrics: {
              '60-day total': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-005',
            family: {
              platform: 'snowflake',
              object: 'Job execution',
              field: 'Usage (minutes)',
              std_platform: 'pendo',
              std_object: 'page_telemetry',
              std_field: 'numevents',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.3330616528461531,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0005398650921654702,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 727.6256636699015,
              elift_improve_by: 175.40261319940566,
            },
            metrics: {
              '30-day average': {
                segment: 'high',
                improve_from: 2.158766859344894,
                improve_to: 4.413589127407062,
                improve_from_text: '2.16',
                improve_to_text: '4.41',
              },
            },
          },
          {
            driver_id: '2-001',
            family: {
              platform: 'snowflake',
              object: 'Product Telemetry',
              field: 'Jobs exceed quota',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'numvisitors',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.3328588744240152,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0003370866700275754,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 727.1826622534215,
              elift_improve_by: 174.95961178292566,
            },
            metrics: {
              '30-day total': {
                segment: 'high',
                improve_from: 3,
                improve_to: 24.239999771118164,
                improve_from_text: '3.00',
                improve_to_text: '24.24',
              },
            },
          },
        ],
      },
      expansion: {
        full_name: 'expansion in next 180 days',
        impact: 'positive',
        propensity: 0.35244795935367007,
        propensity_text: '35%',
        segment: 'high',
        trend: [
          {
            label: '2024-03-03',
            value: 0.3635701589002009,
          },
          {
            label: '2024-03-10',
            value: 0.3618937092423738,
          },
          {
            label: '2024-03-17',
            value: 0.35415190393451657,
          },
          {
            label: '2024-03-24',
            value: 0.3521428747094688,
          },
          {
            label: '2024-03-31',
            value: 0.35304344378993036,
          },
          {
            label: '2024-04-07',
            value: 0.35244795935367007,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: -0.0005954844362602851,
          percent: -0.0016867171639494123,
        },
        drivers: [
          {
            driver_id: '2-004',
            family: {
              platform: 'gainsight',
              object: 'Playbook',
              field: 'Active plays',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3536679428956636,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.0012199835419935412,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 1054.5320847424443,
              elift_improve_by: 136.51556613831906,
            },
            metrics: {
              '30-day average': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-005',
            family: {
              platform: 'pendo',
              object: 'Page',
              field: 'Minutes on [Home page]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: '_row@name[Invoice Drawer > Add Note]',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3528897285428849,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.00044176918921484676,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 937.9342265178361,
              elift_improve_by: 19.917707913710842,
            },
            metrics: {
              '30-day average': {
                segment: 'low',
                improve_from: 70,
                improve_to: 210.7815789473685,
                improve_from_text: '70.00',
                improve_to_text: '210.78',
              },
            },
          },
          {
            driver_id: '2-003',
            family: {
              platform: 'intercom',
              object: 'Conversations',
              field: 'Time to response',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: '_row@name[Tesorio Logo]',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3530016344443953,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.0005536750907252364,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 931.3857570716496,
              elift_improve_by: 13.36923846752427,
            },
            metrics: {
              '90-day average': {
                segment: 'medium',
                improve_from: 4.1,
                improve_to: 6.8604651162790695,
                improve_from_text: '-8.00',
                improve_to_text: '6.86',
              },
            },
          },
        ],
      },
    },
    usages: {
      revenues: {
        trend: [
          {
            label: revenueForcastingQuerters[1],
            value: 5209159.225551244,
            lowerbound: null,
            upperbound: null,
            future: false,
          },
          {
            label: revenueForcastingQuerters[2],
            value: 6019169.8134293575,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
          {
            label: revenueForcastingQuerters[3],
            value: 4939155.696258539,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
          {
            label: revenueForcastingQuerters[4],
            value: 4939155.696258539,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
        ],
        lift: {
          from: revenueForcastingQuerters[1],
          to: revenueForcastingQuerters[4],
          value: -886228.1750154993,
          percent: -0.1794290825225101,
        },
      },
    },
    account_id: '0011U0000077jUUQAY',
  },
  '0011U0000077j3fQAA': {
    grid: 'account',
    dimension: 'overall',
    train_datetime: '2024-04-16T19-24-41Z',
    infer_datetime: '2024-04-16T20-43-40Z',
    dataset_date: '2024-04-07',
    is_revenue_quantified: true,
    account_name: 'Andrews-Crosby',
    user_count: 'nan',
    events: {
      churn: {
        full_name: 'churn in next 180 days',
        impact: 'negative',
        propensity: 0.31503025289234243,
        propensity_text: '32%',
        segment: 'low',
        trend: [
          {
            label: '2024-03-03',
            value: 0.30600591256909515,
          },
          {
            label: '2024-03-10',
            value: 0.3079804688737074,
          },
          {
            label: '2024-03-17',
            value: 0.3146414059329219,
          },
          {
            label: '2024-03-24',
            value: 0.3168295322784976,
          },
          {
            label: '2024-03-31',
            value: 0.31453830811831524,
          },
          {
            label: '2024-04-07',
            value: 0.31503025289234243,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: 0.0004919447740271976,
          percent: 0.0015640218101578584,
        },
        drivers: [
          {
            driver_id: '2-002',
            family: {
              platform: 'snowflake',
              object: 'Customer health',
              field: 'Seat usage',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.3130497146891022,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.001980538203240234,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1679.3878398124873,
              elift_improve_by: 10.870773073672808,
            },
            metrics: {
              '90-day average': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-009',
            family: {
              platform: 'pendo',
              object: 'Page',
              field: 'Minutes on [Job profile]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: 'numminutes@name[ar-invoices-table-followup-activity-notes]',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.31451900600296023,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.0005112468893821998,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1684.7583245628377,
              elift_improve_by: 5.500288323322366,
            },
            metrics: {
              '30-day total': {
                segment: 'medium',
                improve_from: 6,
                improve_to: 1706.2121951219508,
                improve_from_text: '6.00',
                improve_to_text: '1.71K',
              },
            },
          },
          {
            driver_id: '2-001',
            family: {
              platform: 'snowflake',
              object: 'Product Telemetry',
              field: 'Minutes on [Feature A]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: 'numminutes@name[ar-invoices-table-followup-activity-email-views]',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.31438476312083113,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.0006454897715112984,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1686.628014290037,
              elift_improve_by: 3.6305985961230363,
            },
            metrics: {
              '30-day average': {
                segment: 'medium',
                improve_from: 16,
                improve_to: 284.17619047619036,
                improve_from_text: '16.00',
                improve_to_text: '284.18',
              },
            },
          },
        ],
      },
      conversion: {
        full_name: 'conversion in next 180 days',
        impact: 'positive',
        propensity: 0.3325217877539876,
        propensity_text: '33%',
        segment: 'medium',
        trend: [
          {
            label: '2024-03-03',
            value: 0.3304239285307039,
          },
          {
            label: '2024-03-10',
            value: 0.3301258218839187,
          },
          {
            label: '2024-03-17',
            value: 0.3312066901325616,
          },
          {
            label: '2024-03-24',
            value: 0.3310275930120336,
          },
          {
            label: '2024-03-31',
            value: 0.3324182480917543,
          },
          {
            label: '2024-04-07',
            value: 0.3325217877539876,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: 0.00010353966223330957,
          percent: 0.00031147406265353547,
        },
        drivers: [
          {
            driver_id: '2-006',
            family: {
              platform: 'snowflake',
              object: 'Job execution',
              field: 'Is business critical',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.33328234241523425,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0007605546612466374,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 728.1077948092732,
              elift_improve_by: 175.8847443387773,
            },
            metrics: {
              '60-day total': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-005',
            family: {
              platform: 'snowflake',
              object: 'Job execution',
              field: 'Usage (minutes)',
              std_platform: 'pendo',
              std_object: 'page_telemetry',
              std_field: 'numevents',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.3330616528461531,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0005398650921654702,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 727.6256636699015,
              elift_improve_by: 175.40261319940566,
            },
            metrics: {
              '30-day average': {
                segment: 'high',
                improve_from: 2.158766859344894,
                improve_to: 4.413589127407062,
                improve_from_text: '2.16',
                improve_to_text: '4.41',
              },
            },
          },
          {
            driver_id: '2-001',
            family: {
              platform: 'snowflake',
              object: 'Product Telemetry',
              field: 'Jobs exceed quota',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'numvisitors',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.3328588744240152,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0003370866700275754,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 727.1826622534215,
              elift_improve_by: 174.95961178292566,
            },
            metrics: {
              '30-day total': {
                segment: 'high',
                improve_from: 3,
                improve_to: 24.239999771118164,
                improve_from_text: '3.00',
                improve_to_text: '24.24',
              },
            },
          },
        ],
      },
      expansion: {
        full_name: 'expansion in next 180 days',
        impact: 'positive',
        propensity: 0.35244795935367007,
        propensity_text: '35%',
        segment: 'high',
        trend: [
          {
            label: '2024-03-03',
            value: 0.3635701589002009,
          },
          {
            label: '2024-03-10',
            value: 0.3618937092423738,
          },
          {
            label: '2024-03-17',
            value: 0.35415190393451657,
          },
          {
            label: '2024-03-24',
            value: 0.3521428747094688,
          },
          {
            label: '2024-03-31',
            value: 0.35304344378993036,
          },
          {
            label: '2024-04-07',
            value: 0.35244795935367007,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: -0.0005954844362602851,
          percent: -0.0016867171639494123,
        },
        drivers: [
          {
            driver_id: '2-004',
            family: {
              platform: 'gainsight',
              object: 'Playbook',
              field: 'Active plays',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3536679428956636,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.0012199835419935412,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 1054.5320847424443,
              elift_improve_by: 136.51556613831906,
            },
            metrics: {
              '30-day average': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-005',
            family: {
              platform: 'pendo',
              object: 'Page',
              field: 'Minutes on [Home page]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: '_row@name[Invoice Drawer > Add Note]',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3528897285428849,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.00044176918921484676,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 937.9342265178361,
              elift_improve_by: 19.917707913710842,
            },
            metrics: {
              '30-day average': {
                segment: 'low',
                improve_from: 70,
                improve_to: 210.7815789473685,
                improve_from_text: '70.00',
                improve_to_text: '210.78',
              },
            },
          },
          {
            driver_id: '2-003',
            family: {
              platform: 'intercom',
              object: 'Conversations',
              field: 'Time to response',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: '_row@name[Tesorio Logo]',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3530016344443953,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.0005536750907252364,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 931.3857570716496,
              elift_improve_by: 13.36923846752427,
            },
            metrics: {
              '90-day average': {
                segment: 'medium',
                improve_from: 1.76,
                improve_to: 6.8604651162790695,
                improve_from_text: '-8.00',
                improve_to_text: '6.86',
              },
            },
          },
        ],
      },
    },
    usages: {
      revenues: {
        trend: [
          {
            label: revenueForcastingQuerters[1],
            value: 851984.6981203702,
            lowerbound: null,
            upperbound: null,
            future: false,
          },
          {
            label: revenueForcastingQuerters[2],
            value: 992733.3467150318,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
          {
            label: revenueForcastingQuerters[3],
            value: 805068.4819221497,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
          {
            label: revenueForcastingQuerters[4],
            value: 805068.4819221497,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
        ],
        lift: {
          from: revenueForcastingQuerters[1],
          to: revenueForcastingQuerters[4],
          value: -152188.9723044632,
          percent: -0.1890385423375448,
        },
      },
    },
    account_id: '0011U0000077j3fQAA',
  },
  '0011U00001CwhoJQAR': {
    grid: 'account',
    dimension: 'overall',
    train_datetime: '2024-04-16T19-24-41Z',
    infer_datetime: '2024-04-16T20-43-40Z',
    dataset_date: '2024-04-07',
    is_revenue_quantified: true,
    account_name: 'King-Henderson',
    user_count: 'nan',
    events: {
      churn: {
        full_name: 'churn in next 180 days',
        impact: 'negative',
        propensity: 0.31503025289234243,
        propensity_text: '32%',
        segment: 'low',
        trend: [
          {
            label: '2024-03-03',
            value: 0.30600591256909515,
          },
          {
            label: '2024-03-10',
            value: 0.3079804688737074,
          },
          {
            label: '2024-03-17',
            value: 0.3146414059329219,
          },
          {
            label: '2024-03-24',
            value: 0.3168295322784976,
          },
          {
            label: '2024-03-31',
            value: 0.31453830811831524,
          },
          {
            label: '2024-04-07',
            value: 0.31503025289234243,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: 0.0004919447740271976,
          percent: 0.0015640218101578584,
        },
        drivers: [
          {
            driver_id: '2-002',
            family: {
              platform: 'snowflake',
              object: 'Customer health',
              field: 'Seat usage',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.3130497146891022,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.001980538203240234,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1679.3878398124873,
              elift_improve_by: 10.870773073672808,
            },
            metrics: {
              '90-day average': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-009',
            family: {
              platform: 'pendo',
              object: 'Page',
              field: 'Minutes on [Job profile]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: 'numminutes@name[ar-invoices-table-followup-activity-notes]',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.31451900600296023,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.0005112468893821998,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1684.7583245628377,
              elift_improve_by: 5.500288323322366,
            },
            metrics: {
              '30-day total': {
                segment: 'medium',
                improve_from: 6,
                improve_to: 1706.2121951219508,
                improve_from_text: '6.00',
                improve_to_text: '1.71K',
              },
            },
          },
          {
            driver_id: '2-001',
            family: {
              platform: 'snowflake',
              object: 'Product Telemetry',
              field: 'Minutes on [Feature A]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: 'numminutes@name[ar-invoices-table-followup-activity-email-views]',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.31438476312083113,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.0006454897715112984,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1686.628014290037,
              elift_improve_by: 3.6305985961230363,
            },
            metrics: {
              '30-day average': {
                segment: 'medium',
                improve_from: 16,
                improve_to: 284.17619047619036,
                improve_from_text: '16.00',
                improve_to_text: '284.18',
              },
            },
          },
        ],
      },
      conversion: {
        full_name: 'conversion in next 180 days',
        impact: 'positive',
        propensity: 0.3325217877539876,
        propensity_text: '33%',
        segment: 'medium',
        trend: [
          {
            label: '2024-03-03',
            value: 0.3304239285307039,
          },
          {
            label: '2024-03-10',
            value: 0.3301258218839187,
          },
          {
            label: '2024-03-17',
            value: 0.3312066901325616,
          },
          {
            label: '2024-03-24',
            value: 0.3310275930120336,
          },
          {
            label: '2024-03-31',
            value: 0.3324182480917543,
          },
          {
            label: '2024-04-07',
            value: 0.3325217877539876,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: 0.00010353966223330957,
          percent: 0.00031147406265353547,
        },
        drivers: [
          {
            driver_id: '2-006',
            family: {
              platform: 'snowflake',
              object: 'Job execution',
              field: 'Is business critical',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.33328234241523425,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0007605546612466374,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 728.1077948092732,
              elift_improve_by: 175.8847443387773,
            },
            metrics: {
              '60-day total': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-005',
            family: {
              platform: 'snowflake',
              object: 'Job execution',
              field: 'Usage (minutes)',
              std_platform: 'pendo',
              std_object: 'page_telemetry',
              std_field: 'numevents',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.3330616528461531,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0005398650921654702,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 727.6256636699015,
              elift_improve_by: 175.40261319940566,
            },
            metrics: {
              '30-day average': {
                segment: 'high',
                improve_from: 2.158766859344894,
                improve_to: 4.413589127407062,
                improve_from_text: '2.16',
                improve_to_text: '4.41',
              },
            },
          },
          {
            driver_id: '2-001',
            family: {
              platform: 'snowflake',
              object: 'Product Telemetry',
              field: 'Jobs exceed quota',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'numvisitors',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.3328588744240152,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0003370866700275754,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 727.1826622534215,
              elift_improve_by: 174.95961178292566,
            },
            metrics: {
              '30-day total': {
                segment: 'high',
                improve_from: 3,
                improve_to: 24.239999771118164,
                improve_from_text: '3.00',
                improve_to_text: '24.24',
              },
            },
          },
        ],
      },
      expansion: {
        full_name: 'expansion in next 180 days',
        impact: 'positive',
        propensity: 0.35244795935367007,
        propensity_text: '35%',
        segment: 'high',
        trend: [
          {
            label: '2024-03-03',
            value: 0.3635701589002009,
          },
          {
            label: '2024-03-10',
            value: 0.3618937092423738,
          },
          {
            label: '2024-03-17',
            value: 0.35415190393451657,
          },
          {
            label: '2024-03-24',
            value: 0.3521428747094688,
          },
          {
            label: '2024-03-31',
            value: 0.35304344378993036,
          },
          {
            label: '2024-04-07',
            value: 0.35244795935367007,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: -0.0005954844362602851,
          percent: -0.0016867171639494123,
        },
        drivers: [
          {
            driver_id: '2-004',
            family: {
              platform: 'gainsight',
              object: 'Playbook',
              field: 'Active plays',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3536679428956636,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.0012199835419935412,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 1054.5320847424443,
              elift_improve_by: 136.51556613831906,
            },
            metrics: {
              '30-day average': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-005',
            family: {
              platform: 'pendo',
              object: 'Page',
              field: 'Minutes on [Home page]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: '_row@name[Invoice Drawer > Add Note]',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3528897285428849,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.00044176918921484676,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 937.9342265178361,
              elift_improve_by: 19.917707913710842,
            },
            metrics: {
              '30-day average': {
                segment: 'low',
                improve_from: 70,
                improve_to: 210.7815789473685,
                improve_from_text: '70.00',
                improve_to_text: '210.78',
              },
            },
          },
          {
            driver_id: '2-003',
            family: {
              platform: 'intercom',
              object: 'Conversations',
              field: 'Time to response',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: '_row@name[Tesorio Logo]',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3530016344443953,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.0005536750907252364,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 931.3857570716496,
              elift_improve_by: 13.36923846752427,
            },
            metrics: {
              '90-day average': {
                segment: 'medium',
                improve_from: 4.321,
                improve_to: 6.8604651162790695,
                improve_from_text: '-8.00',
                improve_to_text: '6.86',
              },
            },
          },
        ],
      },
    },
    usages: {
      revenues: {
        trend: [
          {
            label: revenueForcastingQuerters[1],
            value: 807040.3525886058,
            lowerbound: null,
            upperbound: null,
            future: false,
          },
          {
            label: revenueForcastingQuerters[2],
            value: 747705.9302556991,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
          {
            label: revenueForcastingQuerters[3],
            value: 826818.4933662415,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
          {
            label: revenueForcastingQuerters[4],
            value: 826818.4933662415,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
        ],
        lift: {
          from: revenueForcastingQuerters[1],
          to: revenueForcastingQuerters[4],
          value: 87483.23049281017,
          percent: 0.1058070558347553,
        },
      },
    },
    account_id: '0011U00001CwhoJQAR',
  },
  '0011U00000LILyCQAX': {
    grid: 'account',
    dimension: 'overall',
    train_datetime: '2024-04-16T19-24-41Z',
    infer_datetime: '2024-04-16T20-43-40Z',
    dataset_date: '2024-04-07',
    is_revenue_quantified: true,
    account_name: 'Johnson-Underwood',
    user_count: 'nan',
    events: {
      churn: {
        full_name: 'churn in next 180 days',
        impact: 'negative',
        propensity: 0.31503025289234243,
        propensity_text: '32%',
        segment: 'low',
        trend: [
          {
            label: '2024-03-03',
            value: 0.30600591256909515,
          },
          {
            label: '2024-03-10',
            value: 0.3079804688737074,
          },
          {
            label: '2024-03-17',
            value: 0.3146414059329219,
          },
          {
            label: '2024-03-24',
            value: 0.3168295322784976,
          },
          {
            label: '2024-03-31',
            value: 0.31453830811831524,
          },
          {
            label: '2024-04-07',
            value: 0.31503025289234243,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: 0.0004919447740271976,
          percent: 0.0015640218101578584,
        },
        drivers: [
          {
            driver_id: '2-002',
            family: {
              platform: 'snowflake',
              object: 'Customer health',
              field: 'Seat usage',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.3130497146891022,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.001980538203240234,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1679.3878398124873,
              elift_improve_by: 10.870773073672808,
            },
            metrics: {
              '90-day average': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-009',
            family: {
              platform: 'pendo',
              object: 'Page',
              field: 'Minutes on [Job profile]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: 'numminutes@name[ar-invoices-table-followup-activity-notes]',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.31451900600296023,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.0005112468893821998,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1684.7583245628377,
              elift_improve_by: 5.500288323322366,
            },
            metrics: {
              '30-day total': {
                segment: 'medium',
                improve_from: 6,
                improve_to: 1706.2121951219508,
                improve_from_text: '6.00',
                improve_to_text: '1.71K',
              },
            },
          },
          {
            driver_id: '2-001',
            family: {
              platform: 'snowflake',
              object: 'Product Telemetry',
              field: 'Minutes on [Feature A]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: 'numminutes@name[ar-invoices-table-followup-activity-email-views]',
            },
            segment: 'low',
            propensity: {
              improve_from: 0.31503025289234243,
              improve_to: 0.31438476312083113,
              improve_from_text: '32%',
              improve_to_text: '31%',
              improve_by: -0.0006454897715112984,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: -1690.25861288616,
              elift_improve_to: -1686.628014290037,
              elift_improve_by: 3.6305985961230363,
            },
            metrics: {
              '30-day average': {
                segment: 'medium',
                improve_from: 16,
                improve_to: 284.17619047619036,
                improve_from_text: '16.00',
                improve_to_text: '284.18',
              },
            },
          },
        ],
      },
      conversion: {
        full_name: 'conversion in next 180 days',
        impact: 'positive',
        propensity: 0.3325217877539876,
        propensity_text: '33%',
        segment: 'medium',
        trend: [
          {
            label: '2024-03-03',
            value: 0.3304239285307039,
          },
          {
            label: '2024-03-10',
            value: 0.3301258218839187,
          },
          {
            label: '2024-03-17',
            value: 0.3312066901325616,
          },
          {
            label: '2024-03-24',
            value: 0.3310275930120336,
          },
          {
            label: '2024-03-31',
            value: 0.3324182480917543,
          },
          {
            label: '2024-04-07',
            value: 0.3325217877539876,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: 0.00010353966223330957,
          percent: 0.00031147406265353547,
        },
        drivers: [
          {
            driver_id: '2-006',
            family: {
              platform: 'snowflake',
              object: 'Job execution',
              field: 'Is business critical',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.33328234241523425,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0007605546612466374,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 728.1077948092732,
              elift_improve_by: 175.8847443387773,
            },
            metrics: {
              '60-day total': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-005',
            family: {
              platform: 'snowflake',
              object: 'Job execution',
              field: 'Usage (minutes)',
              std_platform: 'pendo',
              std_object: 'page_telemetry',
              std_field: 'numevents',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.3330616528461531,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0005398650921654702,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 727.6256636699015,
              elift_improve_by: 175.40261319940566,
            },
            metrics: {
              '30-day average': {
                segment: 'high',
                improve_from: 2.158766859344894,
                improve_to: 4.413589127407062,
                improve_from_text: '2.16',
                improve_to_text: '4.41',
              },
            },
          },
          {
            driver_id: '2-001',
            family: {
              platform: 'snowflake',
              object: 'Product Telemetry',
              field: 'Jobs exceed quota',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'numvisitors',
            },
            segment: 'medium',
            propensity: {
              improve_from: 0.3325217877539876,
              improve_to: 0.3328588744240152,
              improve_from_text: '33%',
              improve_to_text: '33%',
              improve_by: 0.0003370866700275754,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 552.2230504704959,
              elift_improve_to: 727.1826622534215,
              elift_improve_by: 174.95961178292566,
            },
            metrics: {
              '30-day total': {
                segment: 'high',
                improve_from: 3,
                improve_to: 24.239999771118164,
                improve_from_text: '3.00',
                improve_to_text: '24.24',
              },
            },
          },
        ],
      },
      expansion: {
        full_name: 'expansion in next 180 days',
        impact: 'positive',
        propensity: 0.35244795935367007,
        propensity_text: '35%',
        segment: 'high',
        trend: [
          {
            label: '2024-03-03',
            value: 0.3635701589002009,
          },
          {
            label: '2024-03-10',
            value: 0.3618937092423738,
          },
          {
            label: '2024-03-17',
            value: 0.35415190393451657,
          },
          {
            label: '2024-03-24',
            value: 0.3521428747094688,
          },
          {
            label: '2024-03-31',
            value: 0.35304344378993036,
          },
          {
            label: '2024-04-07',
            value: 0.35244795935367007,
          },
        ],
        lift: {
          from: '2024-03-31',
          to: '2024-04-07',
          value: -0.0005954844362602851,
          percent: -0.0016867171639494123,
        },
        drivers: [
          {
            driver_id: '2-004',
            family: {
              platform: 'gainsight',
              object: 'Playbook',
              field: 'Active plays',
              std_platform: 'pendo',
              std_object: 'reports',
              std_field: 'daysactive',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3536679428956636,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.0012199835419935412,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 1054.5320847424443,
              elift_improve_by: 136.51556613831906,
            },
            metrics: {
              '30-day average': {
                segment: 'high',
                improve_from: 16,
                improve_to: 27.962265014648438,
                improve_from_text: '16.00',
                improve_to_text: '27.96',
              },
            },
          },
          {
            driver_id: '2-005',
            family: {
              platform: 'pendo',
              object: 'Page',
              field: 'Minutes on [Home page]',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: '_row@name[Invoice Drawer > Add Note]',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3528897285428849,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.00044176918921484676,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 937.9342265178361,
              elift_improve_by: 19.917707913710842,
            },
            metrics: {
              '30-day average': {
                segment: 'low',
                improve_from: 70,
                improve_to: 210.7815789473685,
                improve_from_text: '70.00',
                improve_to_text: '210.78',
              },
            },
          },
          {
            driver_id: '2-003',
            family: {
              platform: 'intercom',
              object: 'Conversations',
              field: 'Time to response',
              std_platform: 'pendo',
              std_object: 'feature_telemetry',
              std_field: '_row@name[Tesorio Logo]',
            },
            segment: 'high',
            propensity: {
              improve_from: 0.35244795935367007,
              improve_to: 0.3530016344443953,
              improve_from_text: '35%',
              improve_to_text: '35%',
              improve_by: 0.0005536750907252364,
            },
            revenue: {
              past: 10923.287887573242,
              elift_improve_from: 918.0165186041253,
              elift_improve_to: 931.3857570716496,
              elift_improve_by: 13.36923846752427,
            },
            metrics: {
              '90-day average': {
                segment: 'medium',
                improve_from: 3.25,
                improve_to: 6.8604651162790695,
                improve_from_text: '-8.00',
                improve_to_text: '6.86',
              },
            },
          },
        ],
      },
    },
    usages: {
      revenues: {
        trend: [
          {
            label: revenueForcastingQuerters[1],
            value: 2956479.370871866,
            lowerbound: null,
            upperbound: null,
            future: false,
          },
          {
            label: revenueForcastingQuerters[2],
            value: 2887561.564603188,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
          {
            label: revenueForcastingQuerters[3],
            value: 2979451.972961426,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
          {
            label: revenueForcastingQuerters[4],
            value: 2979451.972961426,
            lowerbound: null,
            upperbound: null,
            future: true,
          },
        ],
        lift: {
          from: revenueForcastingQuerters[1],
          to: revenueForcastingQuerters[4],
          value: 94814.62207951436,
          percent: 0.031822839549003834,
        },
      },
    },
    account_id: '0011U00000LILyCQAX',
  },
}

export const demoGetInsightDetailsAccountsOverallChurn = {
  grid: 'account',
  dimension: 'overall',
  train_datetime: '2024-04-16T19-24-41Z',
  infer_datetime: '2024-04-16T20-43-40Z',
  dataset_date: '2024-04-07',
  is_revenue_quantified: true,
  driver_id: '2-001',
  driver_type: 'insight',
  event: {
    short_name: 'churn',
    full_name: 'churn in next 180 days',
    impact: 'negative',
  },
  table_columns: [
    {
      key: 'account_name',
      title: 'Account Name',
      type: 'string',
      format: 'text',
      nullable: false,
    },
    {
      key: 'revenue_elift_improve_by',
      title: 'Projected ARR Saving',
      type: 'number',
      format: 'currency-kmb',
      decimal: 1,
      nullable: false,
    },
    {
      key: 'propensity_improve_by',
      title: 'Reduced Churn %',
      type: 'number',
      format: 'percentage',
      decimal: 0,
      nullable: false,
    },
    {
      key: 'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1',
      title: '30-day average',
      type: 'number',
      format: 'comma',
      decimal: 2,
      nullable: false,
    },
    {
      key: 'propensity_improve_from',
      title: 'Churn Risk %',
      type: 'number',
      format: 'percentage',
      decimal: 0,
      nullable: false,
    },
    {
      key: 'revenue_past',
      title: 'Current ARR',
      type: 'number',
      format: 'currency-kmb',
      decimal: 1,
      nullable: false,
    },
    {
      key: 'revenue_forecast',
      title: 'Forecasted ARR',
      type: 'number',
      format: 'currency-kmb',
      decimal: 1,
      nullable: false,
    },
  ],
  table_rows: [
    {
      account_id: '0011U00001gythlQAA',
      segment: 'medium',
      account_name: {
        value: 'Goodman Ltd',
      },
      propensity_improve_from: {
        value: 0.30482325277925393,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 1583371.3336752548,
      },
      revenue_past: {
        value: 6790684.982299805,
      },
      revenue_forecast: {
        value: 5974184.35045479,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 0,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.30416102169638304,
      },
      propensity_improve_by: {
        value: 0.0006622310828708899,
      },
      revenue_elift_improve_to: {
        value: 1579879.9920172947,
      },
      revenue_elift_improve_by: {
        value: 3491.34165796022,
      },
    },
    {
      account_id: '0011U00000LILtQQAX',
      segment: 'high',
      account_name: {
        value: 'Meza, Ayala and Walker',
      },
      propensity_improve_from: {
        value: 0.32637602573567714,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 548733.8456376581,
      },
      revenue_past: {
        value: 3318706.8099975586,
      },
      revenue_forecast: {
        value: 3355390.8749359995,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -53,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.32555773049532327,
      },
      propensity_improve_by: {
        value: 0.0008182952403538724,
      },
      revenue_elift_improve_to: {
        value: 547292.7060805671,
      },
      revenue_elift_improve_by: {
        value: 1441.1395570910145,
      },
    },
    {
      account_id: '001EY000002h5yXYAQ',
      segment: 'medium',
      account_name: {
        value: 'Miller LLC',
      },
      propensity_improve_from: {
        value: 0.3308075854299679,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 1164715.60032072,
      },
      revenue_past: {
        value: 5178082.214355469,
      },
      revenue_forecast: {
        value: 4539629.162840843,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -6,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3301030451895963,
      },
      propensity_improve_by: {
        value: 0.0007045402403715872,
      },
      revenue_elift_improve_to: {
        value: 1162178.0157732635,
      },
      revenue_elift_improve_by: {
        value: 2537.584547456663,
      },
    },
    {
      account_id: '0011U0000077jp1QAA',
      segment: 'medium',
      account_name: {
        value: 'Choi Group',
      },
      propensity_improve_from: {
        value: 0.3037314276661844,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 2377037.2485331544,
      },
      revenue_past: {
        value: 9468493.28613281,
      },
      revenue_forecast: {
        value: 7169126.650415306,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 7,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.30308534357860645,
      },
      propensity_improve_by: {
        value: 0.0006460840875779361,
      },
      revenue_elift_improve_to: {
        value: 2371930.910640149,
      },
      revenue_elift_improve_by: {
        value: 5106.33789300573,
      },
    },
    {
      account_id: '0011U0000077jUUQAY',
      segment: 'low',
      account_name: {
        value: 'Turner Group',
      },
      propensity_improve_from: {
        value: 0.3083189774362127,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 2966753.153178925,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8267263.774300636,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 130.20000000000073,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3079566219867563,
      },
      propensity_improve_by: {
        value: 0.00036235544945645293,
      },
      revenue_elift_improve_to: {
        value: 2963238.2147052744,
      },
      revenue_elift_improve_by: {
        value: 3514.9384736505453,
      },
    },
    {
      account_id: '0011U0000077jJFQAY',
      segment: 'high',
      account_name: {
        value: 'Garcia-Williams',
      },
      propensity_improve_from: {
        value: 0.2987195934596703,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 2881408.07185174,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8350085.392571269,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -203,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.2975945733072444,
      },
      propensity_improve_by: {
        value: 0.0011250201524258818,
      },
      revenue_elift_improve_to: {
        value: 2870469.9765765434,
      },
      revenue_elift_improve_by: {
        value: 10938.095275196792,
      },
    },
    {
      account_id: '0011U00000LILxEQAX',
      segment: 'medium',
      account_name: {
        value: 'Brown-Abbott',
      },
      propensity_improve_from: {
        value: 0.3092301539088409,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 833700.367076709,
      },
      revenue_past: {
        value: 4364383.392333984,
      },
      revenue_forecast: {
        value: 4099235.4575243783,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3085604828347149,
      },
      propensity_improve_by: {
        value: 0.0006696710741260214,
      },
      revenue_elift_improve_to: {
        value: 831842.5174542686,
      },
      revenue_elift_improve_by: {
        value: 1857.8496224404716,
      },
    },
    {
      account_id: '0011U000028OMdsQAG',
      segment: 'medium',
      account_name: {
        value: 'Grant-Braun',
      },
      propensity_improve_from: {
        value: 0.29935250454967877,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 298248.9414573098,
      },
      revenue_past: {
        value: 2663013.6337280273,
      },
      revenue_forecast: {
        value: 3218257.712087836,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 15,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.2987219945087395,
      },
      propensity_improve_by: {
        value: 0.0006305100409392805,
      },
      revenue_elift_improve_to: {
        value: 297572.8891862923,
      },
      revenue_elift_improve_by: {
        value: 676.0522710174996,
      },
    },
    {
      account_id: '0011U00000MXjBpQAL',
      segment: 'high',
      account_name: {
        value: 'Griffin-Huffman',
      },
      propensity_improve_from: {
        value: 0.3215941603200026,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 1758990.6461115528,
      },
      revenue_past: {
        value: 7130958.892822266,
      },
      revenue_forecast: {
        value: 5357153.34311385,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -1131.7999999999993,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.318200780967816,
      },
      propensity_improve_by: {
        value: 0.0033933793521865874,
      },
      revenue_elift_improve_to: {
        value: 1740162.0097634196,
      },
      revenue_elift_improve_by: {
        value: 18828.636348133386,
      },
    },
    {
      account_id: '0011U0000077jJcQAI',
      segment: 'low',
      account_name: {
        value: 'Floyd Inc',
      },
      propensity_improve_from: {
        value: 0.3055932484373044,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 2593884.2249809094,
      },
      revenue_past: {
        value: 10152739.929199219,
      },
      revenue_forecast: {
        value: 7566085.16492554,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 51,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3050453539674414,
      },
      propensity_improve_by: {
        value: 0.0005478944698629751,
      },
      revenue_elift_improve_to: {
        value: 2589191.3384761536,
      },
      revenue_elift_improve_by: {
        value: 4692.886504755552,
      },
    },
    {
      account_id: '0011U00001dtB4lQAE',
      segment: 'low',
      account_name: {
        value: 'Jones Inc',
      },
      propensity_improve_from: {
        value: 0.2955615501984495,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 765957.1508715953,
      },
      revenue_past: {
        value: 4255643.87512207,
      },
      revenue_forecast: {
        value: 4089254.559110166,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 886.5999999999985,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.2955615501984495,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 765957.1508715953,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U0000077jp8QAA',
      segment: 'medium',
      account_name: {
        value: 'Wu LLC',
      },
      propensity_improve_from: {
        value: 0.35500324662315746,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 55450.533268721316,
      },
      revenue_past: {
        value: 780986.2838745117,
      },
      revenue_forecast: {
        value: 1726947.578452015,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 3,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.35429473526102556,
      },
      propensity_improve_by: {
        value: 0.0007085113621319028,
      },
      revenue_elift_improve_to: {
        value: 55339.86573756247,
      },
      revenue_elift_improve_by: {
        value: 110.66753115885035,
      },
    },
    {
      account_id: '0011U00000r47g6QAA',
      segment: 'low',
      account_name: {
        value: 'Rivas Group',
      },
      propensity_improve_from: {
        value: 0.3206881959062129,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 650129.7607228692,
      },
      revenue_past: {
        value: 3698630.0354003906,
      },
      revenue_forecast: {
        value: 3347193.3943472025,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 634,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3206881959062129,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 650129.7607228692,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '001EY000003G6ATYA0',
      segment: 'medium',
      account_name: {
        value: 'Wilson, Diaz and Lowe',
      },
      propensity_improve_from: {
        value: 0.3355889328110557,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 282999.9030848122,
      },
      revenue_past: {
        value: 2507671.142578125,
      },
      revenue_forecast: {
        value: 2532130.727227734,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 3,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3348903474442145,
      },
      propensity_improve_by: {
        value: 0.0006985853668411979,
      },
      revenue_elift_improve_to: {
        value: 282354.7362836071,
      },
      revenue_elift_improve_by: {
        value: 645.1668012051528,
      },
    },
    {
      account_id: '0011U00001F03YRQAZ',
      segment: 'high',
      account_name: {
        value: 'Ross, Cardenas and Allen',
      },
      propensity_improve_from: {
        value: 0.3437667268531347,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 1066098.3699308904,
      },
      revenue_past: {
        value: 4763835.5712890625,
      },
      revenue_forecast: {
        value: 3800066.970505327,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -101,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.34280490267842995,
      },
      propensity_improve_by: {
        value: 0.0009618241747047507,
      },
      revenue_elift_improve_to: {
        value: 1063036.9333302502,
      },
      revenue_elift_improve_by: {
        value: 3061.436600640036,
      },
    },
    {
      account_id: '0011U00001lC92GQAS',
      segment: 'medium',
      account_name: {
        value: 'Harris-Benson',
      },
      propensity_improve_from: {
        value: 0.3346095540839548,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 57919.53832649145,
      },
      revenue_past: {
        value: 865479.4464111328,
      },
      revenue_forecast: {
        value: 1940546.1426538576,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -3,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3339038328195768,
      },
      propensity_improve_by: {
        value: 0.000705721264378012,
      },
      revenue_elift_improve_to: {
        value: 57797.38087664855,
      },
      revenue_elift_improve_by: {
        value: 122.15744984289358,
      },
    },
    {
      account_id: '0011U0000077iKhQAI',
      segment: 'high',
      account_name: {
        value: 'Wall LLC',
      },
      propensity_improve_from: {
        value: 0.32333869619240474,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 130354.21245527425,
      },
      revenue_past: {
        value: 2015753.3569335938,
      },
      revenue_forecast: {
        value: 2895702.616736603,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -290,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.32194530069636296,
      },
      propensity_improve_by: {
        value: 0.0013933954960417827,
      },
      revenue_elift_improve_to: {
        value: 129792.46412553778,
      },
      revenue_elift_improve_by: {
        value: 561.7483297364743,
      },
    },
    {
      account_id: '0011U0000077jmlQAA',
      segment: 'medium',
      account_name: {
        value: 'Martinez, Clay and Ellis',
      },
      propensity_improve_from: {
        value: 0.32100666357113633,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 2289119.3251219033,
      },
      revenue_past: {
        value: 8728766.784667969,
      },
      revenue_forecast: {
        value: 6403423.010497722,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -4,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.320318865796034,
      },
      propensity_improve_by: {
        value: 0.0006877977751023057,
      },
      revenue_elift_improve_to: {
        value: 2284159.644389865,
      },
      revenue_elift_improve_by: {
        value: 4959.680732038487,
      },
    },
    {
      account_id: '0011U0000077jgMQAQ',
      segment: 'high',
      account_name: {
        value: 'Lewis PLC',
      },
      propensity_improve_from: {
        value: 0.32778418758027916,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 128024.41909914261,
      },
      revenue_past: {
        value: 1952876.6784667969,
      },
      revenue_forecast: {
        value: 2707979.062351817,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -10,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3270739297441151,
      },
      propensity_improve_by: {
        value: 0.0007102578361640743,
      },
      revenue_elift_improve_to: {
        value: 127747.009906354,
      },
      revenue_elift_improve_by: {
        value: 277.4091927886104,
      },
    },
    {
      account_id: '0011U0000077rWcQAI',
      segment: 'high',
      account_name: {
        value: 'Thompson LLC',
      },
      propensity_improve_from: {
        value: 0.30914488146748953,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 2963178.0977365472,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8299090.170094038,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -198,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3080026762388231,
      },
      propensity_improve_by: {
        value: 0.0011422052286664264,
      },
      revenue_elift_improve_to: {
        value: 2952141.5629500835,
      },
      revenue_elift_improve_by: {
        value: 11036.534786463926,
      },
    },
    {
      account_id: '001EY000003c35bYAA',
      segment: 'low',
      account_name: {
        value: 'Brock, George and Paul',
      },
      propensity_improve_from: {
        value: 0.30162236658767894,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 496377.63924115914,
      },
      revenue_past: {
        value: 3318115.058898926,
      },
      revenue_forecast: {
        value: 3487112.5735853007,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 190,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.30140201186950794,
      },
      propensity_improve_by: {
        value: 0.00022035471817100083,
      },
      revenue_elift_improve_to: {
        value: 495998.10590313585,
      },
      revenue_elift_improve_by: {
        value: 379.5333380232478,
      },
    },
    {
      account_id: '0011U0000077gkeQAA',
      segment: 'medium',
      account_name: {
        value: 'White Group',
      },
      propensity_improve_from: {
        value: 0.34086767449656946,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 2118742.8246966465,
      },
      revenue_past: {
        value: 7885479.8583984375,
      },
      revenue_forecast: {
        value: 5746850.401903163,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -6,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.34015117270872025,
      },
      propensity_improve_by: {
        value: 0.0007165017878492108,
      },
      revenue_elift_improve_to: {
        value: 2114230.482712796,
      },
      revenue_elift_improve_by: {
        value: 4512.341983850729,
      },
    },
    {
      account_id: '0011U00000zmBnFQAU',
      segment: 'medium',
      account_name: {
        value: 'Crawford Group',
      },
      propensity_improve_from: {
        value: 0.3312437802709898,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 324895.7177355317,
      },
      revenue_past: {
        value: 2640821.937561035,
      },
      revenue_forecast: {
        value: 3467735.505190416,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 4,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33056130993267674,
      },
      propensity_improve_by: {
        value: 0.0006824703383130348,
      },
      revenue_elift_improve_to: {
        value: 324171.19342667685,
      },
      revenue_elift_improve_by: {
        value: 724.5243088548477,
      },
    },
    {
      account_id: '0011U00001kO0ZqQAK',
      segment: 'medium',
      account_name: {
        value: 'Avila and Sons',
      },
      propensity_improve_from: {
        value: 0.3374347739610731,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 79375.75562862598,
      },
      revenue_past: {
        value: 1176164.4287109375,
      },
      revenue_forecast: {
        value: 2224202.6688395096,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -6,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3367200854732762,
      },
      propensity_improve_by: {
        value: 0.0007146884877968773,
      },
      revenue_elift_improve_to: {
        value: 79207.63739323479,
      },
      revenue_elift_improve_by: {
        value: 168.11823539117654,
      },
    },
    {
      account_id: '001EY000003yCuwYAE',
      segment: 'low',
      account_name: {
        value: 'Melton and Sons',
      },
      propensity_improve_from: {
        value: 0.2707337735176901,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 290869.4392690682,
      },
      revenue_past: {
        value: 2752568.1678771973,
      },
      revenue_forecast: {
        value: 3261335.9324503834,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 977,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.2707337735176901,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 290869.4392690682,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U0000077jCGQAY',
      segment: 'low',
      account_name: {
        value: 'Miller-Price',
      },
      propensity_improve_from: {
        value: 0.3120728999280994,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 773981.1013921705,
      },
      revenue_past: {
        value: 4121309.6466064453,
      },
      revenue_forecast: {
        value: 3914360.6639519394,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 78,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3115830416559595,
      },
      propensity_improve_by: {
        value: 0.0004898582721398759,
      },
      revenue_elift_improve_to: {
        value: 772727.9475584072,
      },
      revenue_elift_improve_by: {
        value: 1253.1538337633265,
      },
    },
    {
      account_id: '0011U0000077jCBQAY',
      segment: 'high',
      account_name: {
        value: 'Bruce and Sons',
      },
      propensity_improve_from: {
        value: 0.3350229009543097,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 120677.63358992328,
      },
      revenue_past: {
        value: 1801035.5895996094,
      },
      revenue_forecast: {
        value: 2788961.6125155934,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -386,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.33338313603328834,
      },
      propensity_improve_by: {
        value: 0.0016397649210213583,
      },
      revenue_elift_improve_to: {
        value: 120086.978593656,
      },
      revenue_elift_improve_by: {
        value: 590.6549962672898,
      },
    },
    {
      account_id: '0011U0000077jCZQAY',
      segment: 'low',
      account_name: {
        value: 'Jones PLC',
      },
      propensity_improve_from: {
        value: 0.27029145551965195,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 82223.9028906244,
      },
      revenue_past: {
        value: 1521022.9774475098,
      },
      revenue_forecast: {
        value: 3451165.626559171,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 644,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.27029145551965195,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 82223.9028906244,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '001EY000002KtUWYA0',
      segment: 'low',
      account_name: {
        value: 'King PLC',
      },
      propensity_improve_from: {
        value: 0.32599688876122196,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 375757.1912636387,
      },
      revenue_past: {
        value: 2810958.8928222656,
      },
      revenue_forecast: {
        value: 3243117.4398342776,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 466,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.32599688876122196,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 375757.1912636387,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U00001yX5ycQAC',
      segment: 'low',
      account_name: {
        value: 'Trujillo Ltd',
      },
      propensity_improve_from: {
        value: 0.29559432191303203,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 837911.340782394,
      },
      revenue_past: {
        value: 4438356.12487793,
      },
      revenue_forecast: {
        value: 4225725.4352427535,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 56,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.29506112793855077,
      },
      propensity_improve_by: {
        value: 0.0005331939744812608,
      },
      revenue_elift_improve_to: {
        value: 836359.835183019,
      },
      revenue_elift_improve_by: {
        value: 1551.5055993750138,
      },
    },
    {
      account_id: '0011U0000077jp0QAA',
      segment: 'high',
      account_name: {
        value: 'Fisher, Cooper and Barr',
      },
      propensity_improve_from: {
        value: 0.3120732372235422,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 980930.8115577885,
      },
      revenue_past: {
        value: 4809698.770523071,
      },
      revenue_forecast: {
        value: 4505339.222501042,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -117,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3111183431893926,
      },
      propensity_improve_by: {
        value: 0.0009548940341496115,
      },
      revenue_elift_improve_to: {
        value: 977855.0205367013,
      },
      revenue_elift_improve_by: {
        value: 3075.791021087207,
      },
    },
    {
      account_id: '0011U0000077iyUQAQ',
      segment: 'high',
      account_name: {
        value: 'Baker and Sons',
      },
      propensity_improve_from: {
        value: 0.29789524177994764,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 2862138.2832468697,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8398898.48387724,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -9,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.2972151478729467,
      },
      propensity_improve_by: {
        value: 0.0006800939070009671,
      },
      revenue_elift_improve_to: {
        value: 2855552.1590269995,
      },
      revenue_elift_improve_by: {
        value: 6586.124219869816,
      },
    },
    {
      account_id: '0011U0000077jgrQAA',
      segment: 'medium',
      account_name: {
        value: 'Brooks Group',
      },
      propensity_improve_from: {
        value: 0.33368054465078767,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 823341.8874574585,
      },
      revenue_past: {
        value: 4127671.142578125,
      },
      revenue_forecast: {
        value: 3668218.396864528,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -2,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3329805717186696,
      },
      propensity_improve_by: {
        value: 0.0006999729321180426,
      },
      revenue_elift_improve_to: {
        value: 821558.009540404,
      },
      revenue_elift_improve_by: {
        value: 1783.8779170545422,
      },
    },
    {
      account_id: '001EY000003mI79YAE',
      segment: 'low',
      account_name: {
        value: 'Bray, Burgess and Morgan',
      },
      propensity_improve_from: {
        value: 0.31563953661698824,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 213107.243905217,
      },
      revenue_past: {
        value: 2344520.558166504,
      },
      revenue_forecast: {
        value: 3025299.017904291,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 65,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3151083498029669,
      },
      propensity_improve_by: {
        value: 0.0005311868140213116,
      },
      revenue_elift_improve_to: {
        value: 212707.49444938014,
      },
      revenue_elift_improve_by: {
        value: 399.7494558368544,
      },
    },
    {
      account_id: '0011U00001CwhoLQAR',
      segment: 'medium',
      account_name: {
        value: 'Gilmore, Cooper and Reyes',
      },
      propensity_improve_from: {
        value: 0.312345097701145,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 348540.85590291326,
      },
      revenue_past: {
        value: 2766575.294494629,
      },
      revenue_forecast: {
        value: 3393366.194271571,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 5,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.31168699204634015,
      },
      propensity_improve_by: {
        value: 0.0006581056548048414,
      },
      revenue_elift_improve_to: {
        value: 347754.68688626506,
      },
      revenue_elift_improve_by: {
        value: 786.1690166482049,
      },
    },
    {
      account_id: '0011U00001NTrWBQA1',
      segment: 'high',
      account_name: {
        value: 'Lewis Inc',
      },
      propensity_improve_from: {
        value: 0.3117042198591498,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 871853.0289281153,
      },
      revenue_past: {
        value: 4436712.290382385,
      },
      revenue_forecast: {
        value: 4103024.200778989,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -62,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.31088525893013086,
      },
      propensity_improve_by: {
        value: 0.0008189609290189526,
      },
      revenue_elift_improve_to: {
        value: 869498.2865362389,
      },
      revenue_elift_improve_by: {
        value: 2354.7423918764252,
      },
    },
    {
      account_id: '0011U00001zgB2FQAU',
      segment: 'low',
      account_name: {
        value: 'Hernandez-Anderson',
      },
      propensity_improve_from: {
        value: 0.3383849002568855,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 188533.19750456017,
      },
      revenue_past: {
        value: 2219178.062438965,
      },
      revenue_forecast: {
        value: 2711151.5136172106,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 93,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3379112628264985,
      },
      propensity_improve_by: {
        value: 0.00047363743038697503,
      },
      revenue_elift_improve_to: {
        value: 188230.8514386222,
      },
      revenue_elift_improve_by: {
        value: 302.3460659379907,
      },
    },
    {
      account_id: '0011U00001kNoITQA0',
      segment: 'low',
      account_name: {
        value: 'Stout Inc',
      },
      propensity_improve_from: {
        value: 0.31491189708616135,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 999833.9961350684,
      },
      revenue_past: {
        value: 4826301.396560669,
      },
      revenue_forecast: {
        value: 4443596.284051951,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 509.7999999999993,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.31491189708616135,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 999833.9961350684,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U00001gUmjFQAS',
      segment: 'medium',
      account_name: {
        value: 'Oneill, King and Fleming',
      },
      propensity_improve_from: {
        value: 0.3155677274591533,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 1477512.82935964,
      },
      revenue_past: {
        value: 6346849.411010742,
      },
      revenue_forecast: {
        value: 4949639.278630508,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 0,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3148956001154963,
      },
      propensity_improve_by: {
        value: 0.0006721273436570141,
      },
      revenue_elift_improve_to: {
        value: 1474312.6064199742,
      },
      revenue_elift_improve_by: {
        value: 3200.222939665855,
      },
    },
    {
      account_id: '0011U00001EzwRUQAZ',
      segment: 'low',
      account_name: {
        value: 'Harris, Bean and Walters',
      },
      propensity_improve_from: {
        value: 0.3036227196927575,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 431407.3529411891,
      },
      revenue_past: {
        value: 3082191.696166992,
      },
      revenue_forecast: {
        value: 3376096.294836292,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 27,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3030105154392087,
      },
      propensity_improve_by: {
        value: 0.0006122042535487981,
      },
      revenue_elift_improve_to: {
        value: 430491.1031835046,
      },
      revenue_elift_improve_by: {
        value: 916.2497576844544,
      },
    },
    {
      account_id: '0011U00000r480kQAA',
      segment: 'high',
      account_name: {
        value: 'Ramirez-Tran',
      },
      propensity_improve_from: {
        value: 0.32504829725516515,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 249685.34488141528,
      },
      revenue_past: {
        value: 2432219.156742096,
      },
      revenue_forecast: {
        value: 2948333.8740713615,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -23,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.32430181629346366,
      },
      propensity_improve_by: {
        value: 0.00074648096170149,
      },
      revenue_elift_improve_to: {
        value: 249052.63518417964,
      },
      revenue_elift_improve_by: {
        value: 632.709697235623,
      },
    },
    {
      account_id: '0011U000014jSGkQAM',
      segment: 'medium',
      account_name: {
        value: 'Love-Morris',
      },
      propensity_improve_from: {
        value: 0.3211398096100313,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 97274.21685632052,
      },
      revenue_past: {
        value: 1514515.079498291,
      },
      revenue_forecast: {
        value: 2412846.9501025714,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 0,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.32045266126824806,
      },
      propensity_improve_by: {
        value: 0.0006871483417832391,
      },
      revenue_elift_improve_to: {
        value: 97066.07755122392,
      },
      revenue_elift_improve_by: {
        value: 208.13930509659144,
      },
    },
    {
      account_id: '001EY000002oGDhYAM',
      segment: 'high',
      account_name: {
        value: 'Smith-Davis',
      },
      propensity_improve_from: {
        value: 0.3473751761633063,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 118202.73107864139,
      },
      revenue_past: {
        value: 1701369.8616027832,
      },
      revenue_forecast: {
        value: 2235768.308665211,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -55,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3465268436870329,
      },
      propensity_improve_by: {
        value: 0.0008483324762734168,
      },
      revenue_elift_improve_to: {
        value: 117914.06561709128,
      },
      revenue_elift_improve_by: {
        value: 288.66546155010155,
      },
    },
    {
      account_id: '001EY000003TMGvYAO',
      segment: 'low',
      account_name: {
        value: 'Palmer-Matthews',
      },
      propensity_improve_from: {
        value: 0.31137320864921203,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 206623.60587399962,
      },
      revenue_past: {
        value: 2330136.9552612305,
      },
      revenue_forecast: {
        value: 2807254.256948989,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 39,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.31077901861793383,
      },
      propensity_improve_by: {
        value: 0.0005941900312781967,
      },
      revenue_elift_improve_to: {
        value: 206183.94983566296,
      },
      revenue_elift_improve_by: {
        value: 439.6560383366591,
      },
    },
    {
      account_id: '0011U00001BdM65QAF',
      segment: 'low',
      account_name: {
        value: 'Smith, Lambert and Parker',
      },
      propensity_improve_from: {
        value: 0.31591229483042316,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 273265.78321920754,
      },
      revenue_past: {
        value: 2515068.5806274414,
      },
      revenue_forecast: {
        value: 2966997.2486269497,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 509.7999999999993,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.31591229483042316,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 273265.78321920754,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U00001zhwS1QAI',
      segment: 'low',
      account_name: {
        value: 'Mills and Sons',
      },
      propensity_improve_from: {
        value: 0.33218942713002547,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 441186.749343035,
      },
      revenue_past: {
        value: 2995890.312194824,
      },
      revenue_forecast: {
        value: 3022377.426066847,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 56,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.33161912169679836,
      },
      propensity_improve_by: {
        value: 0.000570305433227114,
      },
      revenue_elift_improve_to: {
        value: 440384.2722666246,
      },
      revenue_elift_improve_by: {
        value: 802.477076410355,
      },
    },
    {
      account_id: '0011U00000LIM0hQAH',
      segment: 'medium',
      account_name: {
        value: 'Norton-Flores',
      },
      propensity_improve_from: {
        value: 0.32642845301251305,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 2100000.681720423,
      },
      revenue_past: {
        value: 8098487.484741211,
      },
      revenue_forecast: {
        value: 5959993.967632422,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 0,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.32574524778113007,
      },
      propensity_improve_by: {
        value: 0.0006832052313829884,
      },
      revenue_elift_improve_to: {
        value: 2095550.3360918334,
      },
      revenue_elift_improve_by: {
        value: 4450.345628589457,
      },
    },
    {
      account_id: '0011U00001CwhmyQAB',
      segment: 'medium',
      account_name: {
        value: 'Smith-Cline',
      },
      propensity_improve_from: {
        value: 0.3294670542857814,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 737466.042527973,
      },
      revenue_past: {
        value: 3890225.2853393555,
      },
      revenue_forecast: {
        value: 3514379.606757442,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.32877452309991745,
      },
      propensity_improve_by: {
        value: 0.0006925311858639316,
      },
      revenue_elift_improve_to: {
        value: 735860.094449901,
      },
      revenue_elift_improve_by: {
        value: 1605.948078071924,
      },
    },
    {
      account_id: '0011U0000077joQQAQ',
      segment: 'high',
      account_name: {
        value: 'Harris PLC',
      },
      propensity_improve_from: {
        value: 0.32040973112126475,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 3068671.4570284653,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8187719.765337371,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -51,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.31960092051820005,
      },
      propensity_improve_by: {
        value: 0.0008088106030647024,
      },
      revenue_elift_improve_to: {
        value: 3060861.4375629625,
      },
      revenue_elift_improve_by: {
        value: 7810.019465503137,
      },
    },
    {
      account_id: '0011U00001dtB6lQAE',
      segment: 'low',
      account_name: {
        value: 'Murphy-Smith',
      },
      propensity_improve_from: {
        value: 0.33050337915020767,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 117351.33849479808,
      },
      revenue_past: {
        value: 1775342.4911499023,
      },
      revenue_forecast: {
        value: 2437786.5758144306,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 65,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.32996570348163007,
      },
      propensity_improve_by: {
        value: 0.0005376756685775974,
      },
      revenue_elift_improve_to: {
        value: 117160.42680262143,
      },
      revenue_elift_improve_by: {
        value: 190.9116921766497,
      },
    },
    {
      account_id: '0011U00000NhygKQAR',
      segment: 'low',
      account_name: {
        value: 'Ramirez and Sons',
      },
      propensity_improve_from: {
        value: 0.3057408786005455,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 113082.23966416821,
      },
      revenue_past: {
        value: 1849315.0177001953,
      },
      revenue_forecast: {
        value: 2816350.546218188,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 53,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.30519723446178026,
      },
      propensity_improve_by: {
        value: 0.0005436441387652313,
      },
      revenue_elift_improve_to: {
        value: 112881.16581014756,
      },
      revenue_elift_improve_by: {
        value: 201.07385402064892,
      },
    },
    {
      account_id: '0011U0000077iRhQAI',
      segment: 'high',
      account_name: {
        value: 'Cummings PLC',
      },
      propensity_improve_from: {
        value: 0.2912963754958472,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 2034600.5960325466,
      },
      revenue_past: {
        value: 8632602.716445923,
      },
      revenue_forecast: {
        value: 7211324.036753737,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -199,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.29018375880830083,
      },
      propensity_improve_by: {
        value: 0.001112616687546364,
      },
      revenue_elift_improve_to: {
        value: 2026745.9023631343,
      },
      revenue_elift_improve_by: {
        value: 7854.693669412336,
      },
    },
    {
      account_id: '0011U00001BaloPQAR',
      segment: 'medium',
      account_name: {
        value: 'Carr-Smith',
      },
      propensity_improve_from: {
        value: 0.3302973267596646,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 1729062.9081420458,
      },
      revenue_past: {
        value: 6872251.746368408,
      },
      revenue_forecast: {
        value: 5128349.741434431,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 2,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.32961073090733595,
      },
      propensity_improve_by: {
        value: 0.0006865958523286642,
      },
      revenue_elift_improve_to: {
        value: 1725413.3025453081,
      },
      revenue_elift_improve_by: {
        value: 3649.6055967378197,
      },
    },
    {
      account_id: '0011U00001LHG2kQAH',
      segment: 'medium',
      account_name: {
        value: 'Smith, Taylor and Simpson',
      },
      propensity_improve_from: {
        value: 0.32668128357046894,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 423140.39921998227,
      },
      revenue_past: {
        value: 2958904.151916504,
      },
      revenue_forecast: {
        value: 3045046.8178951326,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -3,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.32598115937846417,
      },
      propensity_improve_by: {
        value: 0.0007001241920047696,
      },
      revenue_elift_improve_to: {
        value: 422177.8219811157,
      },
      revenue_elift_improve_by: {
        value: 962.5772388666292,
      },
    },
    {
      account_id: '0011U00001oQmJMQA0',
      segment: 'medium',
      account_name: {
        value: 'Parker-Fisher',
      },
      propensity_improve_from: {
        value: 0.33424164851397586,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 138458.45332923302,
      },
      revenue_past: {
        value: 2071232.8033447266,
      },
      revenue_forecast: {
        value: 2853336.969637504,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -2,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33353703301710963,
      },
      propensity_improve_by: {
        value: 0.0007046154968662344,
      },
      revenue_elift_improve_to: {
        value: 138166.5687830621,
      },
      revenue_elift_improve_by: {
        value: 291.88454617088837,
      },
    },
    {
      account_id: '0011U0000077iHrQAI',
      segment: 'high',
      account_name: {
        value: 'Trujillo Group',
      },
      propensity_improve_from: {
        value: 0.37670046647532235,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 61365.4350081918,
      },
      revenue_past: {
        value: 814512.3310089111,
      },
      revenue_forecast: {
        value: 1740372.269000222,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -439,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3748417339362668,
      },
      propensity_improve_by: {
        value: 0.001858732539055552,
      },
      revenue_elift_improve_to: {
        value: 61062.64289357015,
      },
      revenue_elift_improve_by: {
        value: 302.79211462164994,
      },
    },
    {
      account_id: '001EY000002QBdgYAG',
      segment: 'low',
      account_name: {
        value: 'Mckinney Group',
      },
      propensity_improve_from: {
        value: 0.3111264071316381,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 1563474.5936321476,
      },
      revenue_past: {
        value: 6678653.604125977,
      },
      revenue_forecast: {
        value: 5134293.37065861,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 847,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3111264071316381,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 1563474.5936321476,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U0000077glfQAA',
      segment: 'low',
      account_name: {
        value: 'Spears, Miller and Vaughan',
      },
      propensity_improve_from: {
        value: 0.3272337547318861,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 131924.50792055673,
      },
      revenue_past: {
        value: 2015753.3569335938,
      },
      revenue_forecast: {
        value: 2421129.5125528667,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 31,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3266164813514641,
      },
      propensity_improve_by: {
        value: 0.000617273380422001,
      },
      revenue_elift_improve_to: {
        value: 131675.65374281045,
      },
      revenue_elift_improve_by: {
        value: 248.85417774628422,
      },
    },
    {
      account_id: '0011U00001dtB6aQAE',
      segment: 'high',
      account_name: {
        value: 'Christian-Monroe',
      },
      propensity_improve_from: {
        value: 0.3310698637538622,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 331565.20736172324,
      },
      revenue_past: {
        value: 2663013.6337280273,
      },
      revenue_forecast: {
        value: 2876441.0824472075,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -91,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.33015893604341695,
      },
      propensity_improve_by: {
        value: 0.000910927710445264,
      },
      revenue_elift_improve_to: {
        value: 330579.17944986036,
      },
      revenue_elift_improve_by: {
        value: 986.0279118628569,
      },
    },
    {
      account_id: '0011U0000077jphQAA',
      segment: 'high',
      account_name: {
        value: 'Jackson Group',
      },
      propensity_improve_from: {
        value: 0.316757528868558,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 3032492.34333094,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8200759.129609074,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -17,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.31603852606838717,
      },
      propensity_improve_by: {
        value: 0.0007190028001708426,
      },
      revenue_elift_improve_to: {
        value: 3025552.2762452275,
      },
      revenue_elift_improve_by: {
        value: 6940.067085712326,
      },
    },
    {
      account_id: '0011U0000077iszQAA',
      segment: 'medium',
      account_name: {
        value: 'Johnson, Ford and Baxter',
      },
      propensity_improve_from: {
        value: 0.3306659872195816,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 1161461.1188672478,
      },
      revenue_past: {
        value: 5178082.214355469,
      },
      revenue_forecast: {
        value: 4710510.717283478,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.32997584243207,
      },
      propensity_improve_by: {
        value: 0.0006901447875116151,
      },
      revenue_elift_improve_to: {
        value: 1158981.3672006961,
      },
      revenue_elift_improve_by: {
        value: 2479.7516665518287,
      },
    },
    {
      account_id: '0011U00000g9EwgQAE',
      segment: 'low',
      account_name: {
        value: 'Macias, Miles and Mcdaniel',
      },
      propensity_improve_from: {
        value: 0.32465141653973545,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 1083453.7456250712,
      },
      revenue_past: {
        value: 5005479.473876953,
      },
      revenue_forecast: {
        value: 4431713.528188739,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 509.7999999999993,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.32465141653973545,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 1083453.7456250712,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U000014ifycQAA',
      segment: 'high',
      account_name: {
        value: 'Cunningham Group',
      },
      propensity_improve_from: {
        value: 0.32387120008452736,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 1208390.999858086,
      },
      revenue_past: {
        value: 5397041.107177734,
      },
      revenue_forecast: {
        value: 4473159.474193479,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -40,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.32308843268565,
      },
      propensity_improve_by: {
        value: 0.0007827673988773376,
      },
      revenue_elift_improve_to: {
        value: 1205408.0788401768,
      },
      revenue_elift_improve_by: {
        value: 2982.921017909166,
      },
    },
    {
      account_id: '0011U0000077jppQAA',
      segment: 'high',
      account_name: {
        value: 'Conley, Price and Fleming',
      },
      propensity_improve_from: {
        value: 0.33048711542632464,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 806655.9415778657,
      },
      revenue_past: {
        value: 4104887.8326416016,
      },
      revenue_forecast: {
        value: 3712900.0228230883,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -83,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.32958619398394884,
      },
      propensity_improve_by: {
        value: 0.0009009214423758016,
      },
      revenue_elift_improve_to: {
        value: 804384.924600952,
      },
      revenue_elift_improve_by: {
        value: 2271.0169769138247,
      },
    },
    {
      account_id: '0011U0000077ggdQAA',
      segment: 'medium',
      account_name: {
        value: 'Nelson and Sons',
      },
      propensity_improve_from: {
        value: 0.32981505240945713,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 463661.62210660725,
      },
      revenue_past: {
        value: 3069863.0447387695,
      },
      revenue_forecast: {
        value: 3349189.040151211,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -2,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.32911289279205475,
      },
      propensity_improve_by: {
        value: 0.000702159617402387,
      },
      revenue_elift_improve_to: {
        value: 462618.442908528,
      },
      revenue_elift_improve_by: {
        value: 1043.1791980792468,
      },
    },
    {
      account_id: '0011U000024CdohQAC',
      segment: 'medium',
      account_name: {
        value: 'Mack, Velez and Martinez',
      },
      propensity_improve_from: {
        value: 0.3232434112180074,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 101383.30478245724,
      },
      revenue_past: {
        value: 1568219.200515747,
      },
      revenue_forecast: {
        value: 2381304.9480890455,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 2,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.32254934570674887,
      },
      propensity_improve_by: {
        value: 0.000694065511258557,
      },
      revenue_elift_improve_to: {
        value: 101165.61541022295,
      },
      revenue_elift_improve_by: {
        value: 217.6893722342868,
      },
    },
    {
      account_id: '0011U0000077iFlQAI',
      segment: 'medium',
      account_name: {
        value: 'Long, Garcia and Harvey',
      },
      propensity_improve_from: {
        value: 0.3360580090688878,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 1077353.9900685176,
      },
      revenue_past: {
        value: 4878657.621002197,
      },
      revenue_forecast: {
        value: 4076045.455732743,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3353664197053238,
      },
      propensity_improve_by: {
        value: 0.0006915893635639492,
      },
      revenue_elift_improve_to: {
        value: 1075080.319924694,
      },
      revenue_elift_improve_by: {
        value: 2273.670143823574,
      },
    },
    {
      account_id: '0011U0000077jgLQAQ',
      segment: 'medium',
      account_name: {
        value: 'Mills Inc',
      },
      propensity_improve_from: {
        value: 0.3070094339200248,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 1598197.6499358641,
      },
      revenue_past: {
        value: 6687123.321533203,
      },
      revenue_forecast: {
        value: 628012558.6042235,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.30633891687609793,
      },
      propensity_improve_by: {
        value: 0.0006705170439268437,
      },
      revenue_elift_improve_to: {
        value: 1594655.1374361194,
      },
      revenue_elift_improve_by: {
        value: 3542.512499744771,
      },
    },
    {
      account_id: '0011U0000077gq1QAA',
      segment: 'medium',
      account_name: {
        value: 'Gibson and Sons',
      },
      propensity_improve_from: {
        value: 0.329779894741464,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 1513802.7901061838,
      },
      revenue_past: {
        value: 6247726.089477539,
      },
      revenue_forecast: {
        value: 4745926.945321389,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -4,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3290726289132087,
      },
      propensity_improve_by: {
        value: 0.0007072658282553101,
      },
      revenue_elift_improve_to: {
        value: 1510499.7455403064,
      },
      revenue_elift_improve_by: {
        value: 3303.0445658774624,
      },
    },
    {
      account_id: '0011U00001fJ0V8QAK',
      segment: 'medium',
      account_name: {
        value: 'Hayes Group',
      },
      propensity_improve_from: {
        value: 0.3496419539261336,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 67562.32059263608,
      },
      revenue_past: {
        value: 966164.3837928772,
      },
      revenue_forecast: {
        value: 1973991.627361206,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 3,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3489377691364602,
      },
      propensity_improve_by: {
        value: 0.0007041847896734343,
      },
      revenue_elift_improve_to: {
        value: 67426.24893995785,
      },
      revenue_elift_improve_by: {
        value: 136.0716526782312,
      },
    },
    {
      account_id: '001EY000003XLOiYAO',
      segment: 'low',
      account_name: {
        value: 'Cummings-Smith',
      },
      propensity_improve_from: {
        value: 0.3013864200929865,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 1089603.590102949,
      },
      revenue_past: {
        value: 5281939.682006836,
      },
      revenue_forecast: {
        value: 4739622.321265863,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 77,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.300899831560555,
      },
      propensity_improve_by: {
        value: 0.0004865885324314978,
      },
      revenue_elift_improve_to: {
        value: 1087807.31457082,
      },
      revenue_elift_improve_by: {
        value: 1796.2755321289478,
      },
    },
    {
      account_id: '0011U0000077j3fQAA',
      segment: 'medium',
      account_name: {
        value: 'Andrews-Crosby',
      },
      propensity_improve_from: {
        value: 0.3318148350806824,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 107998.90883306184,
      },
      revenue_past: {
        value: 1627397.232055664,
      },
      revenue_forecast: {
        value: 2713016.289849161,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33112490091650154,
      },
      propensity_improve_by: {
        value: 0.0006899341641808387,
      },
      revenue_elift_improve_to: {
        value: 107774.34944324412,
      },
      revenue_elift_improve_by: {
        value: 224.55938981771055,
      },
    },
    {
      account_id: '0011U00001R4il6QAB',
      segment: 'medium',
      account_name: {
        value: 'Luna LLC',
      },
      propensity_improve_from: {
        value: 0.31675217863962624,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 878787.9721814274,
      },
      revenue_past: {
        value: 4438356.12487793,
      },
      revenue_forecast: {
        value: 4071112.539029127,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 0,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.31606861496476885,
      },
      propensity_improve_by: {
        value: 0.0006835636748573903,
      },
      revenue_elift_improve_to: {
        value: 876838.0446504215,
      },
      revenue_elift_improve_by: {
        value: 1949.9275310059602,
      },
    },
    {
      account_id: '0011U00001dtB9GQAU',
      segment: 'medium',
      account_name: {
        value: 'Wilson LLC',
      },
      propensity_improve_from: {
        value: 0.32166517817907075,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 247735.67373136207,
      },
      revenue_past: {
        value: 2432219.1009521484,
      },
      revenue_forecast: {
        value: 2989730.5035889507,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.32097647595558626,
      },
      propensity_improve_by: {
        value: 0.0006887022234844942,
      },
      revenue_elift_improve_to: {
        value: 247150.76869656073,
      },
      revenue_elift_improve_by: {
        value: 584.905034801352,
      },
    },
    {
      account_id: '001EY000003UeG1YAK',
      segment: 'low',
      account_name: {
        value: 'Ruiz, Garcia and Schmidt',
      },
      propensity_improve_from: {
        value: 0.2753283092646694,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 107230.94387187046,
      },
      revenue_past: {
        value: 1947328.7029266357,
      },
      revenue_forecast: {
        value: 2708768.906200382,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 144,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.27500897701953264,
      },
      propensity_improve_by: {
        value: 0.00031933224513674974,
      },
      revenue_elift_improve_to: {
        value: 107106.57490252549,
      },
      revenue_elift_improve_by: {
        value: 124.36896934496531,
      },
    },
    {
      account_id: '0011U000028O1gzQAC',
      segment: 'medium',
      account_name: {
        value: 'Maxwell, Roberts and Deleon',
      },
      propensity_improve_from: {
        value: 0.3437609750904879,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 394344.6331652444,
      },
      revenue_past: {
        value: 2810958.8928222656,
      },
      revenue_forecast: {
        value: 2797132.2303668736,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 5,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3430637766579309,
      },
      propensity_improve_by: {
        value: 0.0006971984325570002,
      },
      revenue_elift_improve_to: {
        value: 393487.82947590586,
      },
      revenue_elift_improve_by: {
        value: 856.8036893385397,
      },
    },
    {
      account_id: '0011U0000077jiZQAQ',
      segment: 'low',
      account_name: {
        value: 'Cruz, Garcia and Gilmore',
      },
      propensity_improve_from: {
        value: 0.28783431813631954,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 2273518.319791616,
      },
      revenue_past: {
        value: 9509030.145263672,
      },
      revenue_forecast: {
        value: 7346663.897313924,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 78.20000000000073,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.2873659681587048,
      },
      propensity_improve_by: {
        value: 0.00046834997761474595,
      },
      revenue_elift_improve_to: {
        value: 2269783.726002626,
      },
      revenue_elift_improve_by: {
        value: 3734.593788989696,
      },
    },
    {
      account_id: '0011U000012B90pQAC',
      segment: 'medium',
      account_name: {
        value: 'Davis-Harvey',
      },
      propensity_improve_from: {
        value: 0.35772250085512586,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 759479.849897002,
      },
      revenue_past: {
        value: 3786575.225830078,
      },
      revenue_forecast: {
        value: 3031284.8948444673,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3569961617408023,
      },
      propensity_improve_by: {
        value: 0.0007263391143235709,
      },
      revenue_elift_improve_to: {
        value: 757877.156329646,
      },
      revenue_elift_improve_by: {
        value: 1602.6935673559365,
      },
    },
    {
      account_id: '0011U0000077iz4QAA',
      segment: 'low',
      account_name: {
        value: 'Lee-Cherry',
      },
      propensity_improve_from: {
        value: 0.31901393861078675,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 1095154.1537336668,
      },
      revenue_past: {
        value: 5052772.567749023,
      },
      revenue_forecast: {
        value: 4440161.889289918,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 68,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.31849644360963897,
      },
      propensity_improve_by: {
        value: 0.000517495001147783,
      },
      revenue_elift_improve_to: {
        value: 1093336.6404318353,
      },
      revenue_elift_improve_by: {
        value: 1817.5133018313772,
      },
    },
    {
      account_id: '0011U000028b588QAA',
      segment: 'medium',
      account_name: {
        value: 'Wall-Kelly',
      },
      propensity_improve_from: {
        value: 0.34090013921177925,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 137686.29174281048,
      },
      revenue_past: {
        value: 2019451.9729614258,
      },
      revenue_forecast: {
        value: 2493417.1015262026,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -2,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.34018300214607367,
      },
      propensity_improve_by: {
        value: 0.0007171370657055864,
      },
      revenue_elift_improve_to: {
        value: 137396.64697036587,
      },
      revenue_elift_improve_by: {
        value: 289.6447724445977,
      },
    },
    {
      account_id: '0011U0000077j4OQAQ',
      segment: 'low',
      account_name: {
        value: 'Robinson PLC',
      },
      propensity_improve_from: {
        value: 0.334404931686902,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 649390.3388629269,
      },
      revenue_past: {
        value: 3602389.414215088,
      },
      revenue_forecast: {
        value: 3264693.900806057,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 24,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.33376478775328505,
      },
      propensity_improve_by: {
        value: 0.0006401439336169434,
      },
      revenue_elift_improve_to: {
        value: 648095.5317510292,
      },
      revenue_elift_improve_by: {
        value: 1294.8071118977168,
      },
    },
    {
      account_id: '0011U00001txFQWQA2',
      segment: 'medium',
      account_name: {
        value: 'Aguilar, Stephens and Roberts',
      },
      propensity_improve_from: {
        value: 0.3149546456775766,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 342779.5179329297,
      },
      revenue_past: {
        value: 2756383.594894409,
      },
      revenue_forecast: {
        value: 2947819.428989869,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 0,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3142735598862143,
      },
      propensity_improve_by: {
        value: 0.0006810857913622792,
      },
      revenue_elift_improve_to: {
        value: 341985.09635933116,
      },
      revenue_elift_improve_by: {
        value: 794.4215735984926,
      },
    },
    {
      account_id: '0011U00000r47i7QAA',
      segment: 'medium',
      account_name: {
        value: 'Green, Green and Myers',
      },
      propensity_improve_from: {
        value: 0.34134910342527447,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 288844.5149525667,
      },
      revenue_past: {
        value: 2515068.5806274414,
      },
      revenue_forecast: {
        value: 3023566.86399157,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 2,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3406472134858948,
      },
      propensity_improve_by: {
        value: 0.0007018899393796962,
      },
      revenue_elift_improve_to: {
        value: 288193.36535603966,
      },
      revenue_elift_improve_by: {
        value: 651.1495965270342,
      },
    },
    {
      account_id: '0011U0000077jnvQAA',
      segment: 'high',
      account_name: {
        value: 'Ballard, Woods and Harrell',
      },
      propensity_improve_from: {
        value: 0.31855191585535575,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 505791.3708987188,
      },
      revenue_past: {
        value: 3254794.464111328,
      },
      revenue_forecast: {
        value: 3300617.7954267687,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -26,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.31780770754296706,
      },
      propensity_improve_by: {
        value: 0.0007442083123886944,
      },
      revenue_elift_improve_to: {
        value: 504551.0479022798,
      },
      revenue_elift_improve_by: {
        value: 1240.3229964389539,
      },
    },
    {
      account_id: '0011U0000077jpnQAA',
      segment: 'high',
      account_name: {
        value: 'Williams-Hoover',
      },
      propensity_improve_from: {
        value: 0.32797934601435474,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 722541.1927209264,
      },
      revenue_past: {
        value: 3871170.510864258,
      },
      revenue_forecast: {
        value: 3514560.999824714,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -192,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3268243061144352,
      },
      propensity_improve_by: {
        value: 0.0011550398999195521,
      },
      revenue_elift_improve_to: {
        value: 719903.987719554,
      },
      revenue_elift_improve_by: {
        value: 2637.2050013723765,
      },
    },
    {
      account_id: '0011U00001fIphJQAS',
      segment: 'medium',
      account_name: {
        value: 'Carlson PLC',
      },
      propensity_improve_from: {
        value: 0.31853968039876496,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 179030.5012550124,
      },
      revenue_past: {
        value: 2219178.062438965,
      },
      revenue_forecast: {
        value: 2751823.1494215014,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 0,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3178560232781575,
      },
      propensity_improve_by: {
        value: 0.0006836571206074726,
      },
      revenue_elift_improve_to: {
        value: 178592.4910612552,
      },
      revenue_elift_improve_by: {
        value: 438.01019375721353,
      },
    },
    {
      account_id: '0011U00000LILyCQAX',
      segment: 'low',
      account_name: {
        value: 'Johnson-Underwood',
      },
      propensity_improve_from: {
        value: 0.2916316472762989,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 1670428.8367761872,
      },
      revenue_past: {
        value: 7397260.070800781,
      },
      revenue_forecast: {
        value: 5783922.806137912,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 509.7999999999993,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.29163164727629887,
      },
      propensity_improve_by: {
        value: 5.551115123125783e-17,
      },
      revenue_elift_improve_to: {
        value: 1670428.8367761867,
      },
      revenue_elift_improve_by: {
        value: 5.456968210637569e-10,
      },
    },
    {
      account_id: '0011U0000077irkQAA',
      segment: 'medium',
      account_name: {
        value: 'Henderson Ltd',
      },
      propensity_improve_from: {
        value: 0.34585451794816996,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 306731.9319290904,
      },
      revenue_past: {
        value: 2552054.7409057617,
      },
      revenue_forecast: {
        value: 3043250.5078049265,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -6,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3451275051642912,
      },
      propensity_improve_by: {
        value: 0.0007270127838787666,
      },
      revenue_elift_improve_to: {
        value: 306027.5408262574,
      },
      revenue_elift_improve_by: {
        value: 704.3911028329944,
      },
    },
    {
      account_id: '0011U00001oQbCDQA0',
      segment: 'high',
      account_name: {
        value: 'Little, Patel and Weaver',
      },
      propensity_improve_from: {
        value: 0.30333463128356747,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 432581.24033439753,
      },
      revenue_past: {
        value: 3086301.414012909,
      },
      revenue_forecast: {
        value: 3006255.9521382586,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -55,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3025330078101466,
      },
      propensity_improve_by: {
        value: 0.0008016234734208871,
      },
      revenue_elift_improve_to: {
        value: 431376.9724811235,
      },
      revenue_elift_improve_by: {
        value: 1204.2678532739956,
      },
    },
    {
      account_id: '0011U0000077iIGQAY',
      segment: 'medium',
      account_name: {
        value: 'Munoz-Schultz',
      },
      propensity_improve_from: {
        value: 0.30807982676306267,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 1380600.2007461723,
      },
      revenue_past: {
        value: 6142684.982299805,
      },
      revenue_forecast: {
        value: 4899065.281663223,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -7,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.30739576540670005,
      },
      propensity_improve_by: {
        value: 0.0006840613563626197,
      },
      revenue_elift_improve_to: {
        value: 1377481.4297402853,
      },
      revenue_elift_improve_by: {
        value: 3118.7710058869015,
      },
    },
    {
      account_id: '001EY000003EFkLYAW',
      segment: 'low',
      account_name: {
        value: 'Garcia-Wilkerson',
      },
      propensity_improve_from: {
        value: 0.26933153451276864,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 1737867.307920171,
      },
      revenue_past: {
        value: 8063013.427734375,
      },
      revenue_forecast: {
        value: 7075846.866490048,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 509.7999999999993,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.26933153451276864,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 1737867.307920171,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '001EY0000034teOYAQ',
      segment: 'low',
      account_name: {
        value: 'Dixon Group',
      },
      propensity_improve_from: {
        value: 0.2939786268156592,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 2115659.263155289,
      },
      revenue_past: {
        value: 8876712.24975586,
      },
      revenue_forecast: {
        value: 6794132.05987165,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 169,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.29371301347096307,
      },
      propensity_improve_by: {
        value: 0.0002656133446961584,
      },
      revenue_elift_improve_to: {
        value: 2113727.6007811516,
      },
      revenue_elift_improve_by: {
        value: 1931.6623741374315,
      },
    },
    {
      account_id: '0011U000020RrRuQAK',
      segment: 'low',
      account_name: {
        value: 'Schroeder, Smith and Martinez',
      },
      propensity_improve_from: {
        value: 0.30381761349730285,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 2909293.4652140457,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8358004.971988556,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 509.7999999999993,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.30381761349730285,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 2909293.4652140457,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U0000077ifVQAQ',
      segment: 'high',
      account_name: {
        value: 'Andrade-Sanchez',
      },
      propensity_improve_from: {
        value: 0.3152873218356792,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 503606.67027077684,
      },
      revenue_past: {
        value: 3254794.464111328,
      },
      revenue_forecast: {
        value: 3447499.578063354,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -40,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.31451545551281407,
      },
      propensity_improve_by: {
        value: 0.0007718663228651335,
      },
      revenue_elift_improve_to: {
        value: 502313.0776665808,
      },
      revenue_elift_improve_by: {
        value: 1293.5926041960556,
      },
    },
    {
      account_id: '0011U0000077istQAA',
      segment: 'medium',
      account_name: {
        value: 'Dunn, Chambers and Rogers',
      },
      propensity_improve_from: {
        value: 0.3106856266240392,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 2978545.5236733793,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8238968.526091962,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -4,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3100069798881536,
      },
      propensity_improve_by: {
        value: 0.0006786467358855797,
      },
      revenue_elift_improve_to: {
        value: 2971986.1509003113,
      },
      revenue_elift_improve_by: {
        value: 6559.372773068208,
      },
    },
    {
      account_id: '0011U00001EznBSQAZ',
      segment: 'low',
      account_name: {
        value: 'Williams, Pierce and Thomas',
      },
      propensity_improve_from: {
        value: 0.2874429617375476,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 1646928.667896042,
      },
      revenue_past: {
        value: 7397260.070800781,
      },
      revenue_forecast: {
        value: 5779260.749282633,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 383,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.2874429617375476,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 1646928.667896042,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U0000077jp2QAA',
      segment: 'high',
      account_name: {
        value: 'Rodriguez-Taylor',
      },
      propensity_improve_from: {
        value: 0.34304485539553414,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 366558.36699906713,
      },
      revenue_past: {
        value: 2720313.7481689453,
      },
      revenue_forecast: {
        value: 3024679.5848383517,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -222,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.34178836807621693,
      },
      propensity_improve_by: {
        value: 0.0012564873193172121,
      },
      revenue_elift_improve_to: {
        value: 365112.7673066976,
      },
      revenue_elift_improve_by: {
        value: 1445.5996923695238,
      },
    },
    {
      account_id: '0011U0000077jptQAA',
      segment: 'low',
      account_name: {
        value: 'Coleman LLC',
      },
      propensity_improve_from: {
        value: 0.2997083284559293,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 2869754.9714733614,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8342866.988306462,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 509.7999999999993,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.2997083284559293,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 2869754.9714733614,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U000020S43DQAS',
      segment: 'high',
      account_name: {
        value: 'Hernandez, Adkins and Burton',
      },
      propensity_improve_from: {
        value: 0.3385531048858225,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 767816.3033823224,
      },
      revenue_past: {
        value: 3930719.1524505615,
      },
      revenue_forecast: {
        value: 3339013.3279116983,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -61,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.33769951031711787,
      },
      propensity_improve_by: {
        value: 0.000853594568704652,
      },
      revenue_elift_improve_to: {
        value: 765811.0162130606,
      },
      revenue_elift_improve_by: {
        value: 2005.2871692618282,
      },
    },
    {
      account_id: '0011U00000MXjC2QAL',
      segment: 'medium',
      account_name: {
        value: 'Baldwin, Gonzalez and Owens',
      },
      propensity_improve_from: {
        value: 0.3386743477588192,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 450648.05821279855,
      },
      revenue_past: {
        value: 2995890.312194824,
      },
      revenue_forecast: {
        value: 2971129.2057577837,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 10,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3379872649810459,
      },
      propensity_improve_by: {
        value: 0.0006870827777732957,
      },
      revenue_elift_improve_to: {
        value: 449678.6453020699,
      },
      revenue_elift_improve_by: {
        value: 969.4129107286926,
      },
    },
    {
      account_id: '001EY000003ce9tYAA',
      segment: 'low',
      account_name: {
        value: 'Cameron PLC',
      },
      propensity_improve_from: {
        value: 0.3288143284735019,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 1488201.8811654416,
      },
      revenue_past: {
        value: 6181298.492431641,
      },
      revenue_forecast: {
        value: 4759921.827321054,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 20,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3281589045278771,
      },
      propensity_improve_by: {
        value: 0.000655423945624789,
      },
      revenue_elift_improve_to: {
        value: 1485183.8490804827,
      },
      revenue_elift_improve_by: {
        value: 3018.0320849590316,
      },
    },
    {
      account_id: '0011U00001V96twQAB',
      segment: 'medium',
      account_name: {
        value: 'Allison Inc',
      },
      propensity_improve_from: {
        value: 0.31503025289234243,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 507077.583865848,
      },
      revenue_past: {
        value: 3276986.3662719727,
      },
      revenue_forecast: {
        value: 3210980.6531285113,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 16,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.31438476312083113,
      },
      propensity_improve_by: {
        value: 0.0006454897715112984,
      },
      revenue_elift_improve_to: {
        value: 505988.40428701113,
      },
      revenue_elift_improve_by: {
        value: 1089.1795788369109,
      },
    },
    {
      account_id: '0011U00001tE45RQAS',
      segment: 'high',
      account_name: {
        value: 'Guerrero-Morton',
      },
      propensity_improve_from: {
        value: 0.3304186110923811,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 411333.26160708186,
      },
      revenue_past: {
        value: 2915753.442764282,
      },
      revenue_forecast: {
        value: 2957749.6262981384,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -813.7999999999993,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.32773845085692094,
      },
      propensity_improve_by: {
        value: 0.0026801602354601384,
      },
      revenue_elift_improve_to: {
        value: 407782.554991459,
      },
      revenue_elift_improve_by: {
        value: 3550.7066156228575,
      },
    },
    {
      account_id: '0011U0000077joPQAQ',
      segment: 'high',
      account_name: {
        value: 'Whitaker Group',
      },
      propensity_improve_from: {
        value: 0.33990316870374937,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 691219.589581762,
      },
      revenue_past: {
        value: 3698630.0354003906,
      },
      revenue_forecast: {
        value: 3353365.8816868314,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -18,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3391524288371429,
      },
      propensity_improve_by: {
        value: 0.0007507398666064535,
      },
      revenue_elift_improve_to: {
        value: 689631.8939386986,
      },
      revenue_elift_improve_by: {
        value: 1587.6956430633982,
      },
    },
    {
      account_id: '0011U0000077jgIQAQ',
      segment: 'medium',
      account_name: {
        value: 'Zamora Ltd',
      },
      propensity_improve_from: {
        value: 0.3426685991385501,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 2470405.394795661,
      },
      revenue_past: {
        value: 8876712.24975586,
      },
      revenue_forecast: {
        value: 6397891.957934466,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3419649971839744,
      },
      propensity_improve_by: {
        value: 0.0007036019545756944,
      },
      revenue_elift_improve_to: {
        value: 2465275.2619154006,
      },
      revenue_elift_improve_by: {
        value: 5130.132880260317,
      },
    },
    {
      account_id: '001EY000002tzP1YAI',
      segment: 'low',
      account_name: {
        value: 'Jimenez Inc',
      },
      propensity_improve_from: {
        value: 0.31569864028699784,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 645366.1903700377,
      },
      revenue_past: {
        value: 3698630.0354003906,
      },
      revenue_forecast: {
        value: 3559459.35749032,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 108,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.31527462009013946,
      },
      propensity_improve_by: {
        value: 0.0004240201968583879,
      },
      revenue_elift_improve_to: {
        value: 644466.3233642142,
      },
      revenue_elift_improve_by: {
        value: 899.8670058235348,
      },
    },
    {
      account_id: '001EY000002hXcAYAU',
      segment: 'medium',
      account_name: {
        value: 'Stanley-Jordan',
      },
      propensity_improve_from: {
        value: 0.3168836575118589,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 121891.69134902356,
      },
      revenue_past: {
        value: 1923287.7502441406,
      },
      revenue_forecast: {
        value: 2967631.0963135273,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -7,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.31618874471774566,
      },
      propensity_improve_by: {
        value: 0.0006949127941132116,
      },
      revenue_elift_improve_to: {
        value: 121624.38789614238,
      },
      revenue_elift_improve_by: {
        value: 267.30345288117405,
      },
    },
    {
      account_id: '0011U0000077japQAA',
      segment: 'low',
      account_name: {
        value: 'Kennedy, Berry and Simon',
      },
      propensity_improve_from: {
        value: 0.31395293471561725,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 2233136.0692212954,
      },
      revenue_past: {
        value: 8779763.21926117,
      },
      revenue_forecast: {
        value: 6538100.261746741,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 346,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.31395293471561725,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 2233136.0692212954,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U000025fC2UQAU',
      segment: 'high',
      account_name: {
        value: 'Crawford LLC',
      },
      propensity_improve_from: {
        value: 0.31692821222121,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 644308.1103399814,
      },
      revenue_past: {
        value: 3698630.0354003906,
      },
      revenue_forecast: {
        value: 3178919.780970727,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -18,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.31620171651459705,
      },
      propensity_improve_by: {
        value: 0.0007264957066129285,
      },
      revenue_elift_improve_to: {
        value: 642774.2813184418,
      },
      revenue_elift_improve_by: {
        value: 1533.8290215395318,
      },
    },
    {
      account_id: '001EY000003LXW5YAO',
      segment: 'low',
      account_name: {
        value: 'Jones-Burns',
      },
      propensity_improve_from: {
        value: 0.3327998273674917,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 849090.5442153601,
      },
      revenue_past: {
        value: 4216438.339233398,
      },
      revenue_forecast: {
        value: 3372017.093201548,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 850.5999999999985,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3327998273674917,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 849090.5442153601,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '001PH000009102SYAQ',
      segment: 'medium',
      account_name: {
        value: 'Small PLC',
      },
      propensity_improve_from: {
        value: 0.31862900847355013,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 59579.26006336084,
      },
      revenue_past: {
        value: 934931.510925293,
      },
      revenue_forecast: {
        value: 2053697.823891351,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 6,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3179493436150861,
      },
      propensity_improve_by: {
        value: 0.0006796648584640463,
      },
      revenue_elift_improve_to: {
        value: 59452.17204475152,
      },
      revenue_elift_improve_by: {
        value: 127.08801860932226,
      },
    },
    {
      account_id: '0011U00001CwhiSQAR',
      segment: 'high',
      account_name: {
        value: 'Hatfield and Sons',
      },
      propensity_improve_from: {
        value: 0.31558914291545787,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 940764.7108150792,
      },
      revenue_past: {
        value: 4640547.8633880615,
      },
      revenue_forecast: {
        value: 4334991.420677634,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -251,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.31430275129507873,
      },
      propensity_improve_by: {
        value: 0.0012863916203791348,
      },
      revenue_elift_improve_to: {
        value: 936829.8717893944,
      },
      revenue_elift_improve_by: {
        value: 3934.839025684687,
      },
    },
    {
      account_id: '0011U00001CwhlFQAR',
      segment: 'high',
      account_name: {
        value: 'Foster Inc',
      },
      propensity_improve_from: {
        value: 0.32500558844374977,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 106167.3016883331,
      },
      revenue_past: {
        value: 1633315.0177001953,
      },
      revenue_forecast: {
        value: 2562959.5153063233,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -157,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3239322803591421,
      },
      propensity_improve_by: {
        value: 0.0010733080846076493,
      },
      revenue_elift_improve_to: {
        value: 105816.69164569136,
      },
      revenue_elift_improve_by: {
        value: 350.610042641739,
      },
    },
    {
      account_id: '001EY000002pifOYAQ',
      segment: 'high',
      account_name: {
        value: 'Scott Ltd',
      },
      propensity_improve_from: {
        value: 0.3084480615452208,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 1770517.9143051738,
      },
      revenue_past: {
        value: 7364564.739990234,
      },
      revenue_forecast: {
        value: 5593478.689263443,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -216,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3072692625568562,
      },
      propensity_improve_by: {
        value: 0.0011787989883645933,
      },
      revenue_elift_improve_to: {
        value: 1763660.01916102,
      },
      revenue_elift_improve_by: {
        value: 6857.895144153827,
      },
    },
    {
      account_id: '0011U0000077jmxQAA',
      segment: 'high',
      account_name: {
        value: 'Lopez, Phelps and Jennings',
      },
      propensity_improve_from: {
        value: 0.31624668894951313,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 2542928.697090621,
      },
      revenue_past: {
        value: 9705205.535888672,
      },
      revenue_forecast: {
        value: 7245669.407876161,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -454,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.31444912715581147,
      },
      propensity_improve_by: {
        value: 0.001797561793701663,
      },
      revenue_elift_improve_to: {
        value: 2528336.386727787,
      },
      revenue_elift_improve_by: {
        value: 14592.31036283436,
      },
    },
    {
      account_id: '0011U00001NRkjDQAT',
      segment: 'high',
      account_name: {
        value: 'Floyd, Boyer and Poole',
      },
      propensity_improve_from: {
        value: 0.3158631732642515,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 645203.5113114746,
      },
      revenue_past: {
        value: 3698630.0354003906,
      },
      revenue_forecast: {
        value: 3428835.3514289046,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -16,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3151462580029766,
      },
      propensity_improve_by: {
        value: 0.0007169152612749308,
      },
      revenue_elift_improve_to: {
        value: 643682.7775369785,
      },
      revenue_elift_improve_by: {
        value: 1520.7337744961478,
      },
    },
    {
      account_id: '0011U00000r47mYQAQ',
      segment: 'medium',
      account_name: {
        value: 'Lindsey, Williams and Adams',
      },
      propensity_improve_from: {
        value: 0.32218702017604944,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 2087940.4786651335,
      },
      revenue_past: {
        value: 8136986.572265625,
      },
      revenue_forecast: {
        value: 6035489.685045829,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 8,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.32152280652024456,
      },
      propensity_improve_by: {
        value: 0.0006642136558048795,
      },
      revenue_elift_improve_to: {
        value: 2083583.1666612548,
      },
      revenue_elift_improve_by: {
        value: 4357.312003878633,
      },
    },
    {
      account_id: '0011U0000077jpRQAQ',
      segment: 'medium',
      account_name: {
        value: 'Levine-Riggs',
      },
      propensity_improve_from: {
        value: 0.34265999575495343,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 213675.8131357363,
      },
      revenue_past: {
        value: 2285317.7673339844,
      },
      revenue_forecast: {
        value: 2677615.392843309,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 15,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.34199303248415197,
      },
      propensity_improve_by: {
        value: 0.0006669632708014572,
      },
      revenue_elift_improve_to: {
        value: 213205.10811889288,
      },
      revenue_elift_improve_by: {
        value: 470.70501684341934,
      },
    },
    {
      account_id: '0011U00001acbDrQAI',
      segment: 'low',
      account_name: {
        value: 'Williams, Bowen and Erickson',
      },
      propensity_improve_from: {
        value: 0.32263540862965673,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 1455259.168953672,
      },
      revenue_past: {
        value: 6195624.620819092,
      },
      revenue_forecast: {
        value: 4866740.16115841,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 368.2000000000007,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.32263540862965673,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 1455259.168953672,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U00001gyszsQAA',
      segment: 'medium',
      account_name: {
        value: 'Smith and Sons',
      },
      propensity_improve_from: {
        value: 0.2951856642906815,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 1467581.4478073593,
      },
      revenue_past: {
        value: 6570246.643066406,
      },
      revenue_forecast: {
        value: 5767413.708536009,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 0,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.29452954514604374,
      },
      propensity_improve_by: {
        value: 0.0006561191446377568,
      },
      revenue_elift_improve_to: {
        value: 1464269.580187542,
      },
      revenue_elift_improve_by: {
        value: 3311.86761981744,
      },
    },
    {
      account_id: '0011U00000r480MQAQ',
      segment: 'low',
      account_name: {
        value: 'Lambert, Burton and Meyer',
      },
      propensity_improve_from: {
        value: 0.3358712485959817,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 1721635.245321829,
      },
      revenue_past: {
        value: 6787479.5974731445,
      },
      revenue_forecast: {
        value: 5084962.522866642,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 181,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3356150839012804,
      },
      propensity_improve_by: {
        value: 0.00025616469470129966,
      },
      revenue_elift_improve_to: {
        value: 1720301.5627897917,
      },
      revenue_elift_improve_by: {
        value: 1333.6825320373464,
      },
    },
    {
      account_id: '0011U0000077iBPQAY',
      segment: 'medium',
      account_name: {
        value: 'West-Martin',
      },
      propensity_improve_from: {
        value: 0.31327317580596875,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 728896.197653982,
      },
      revenue_past: {
        value: 3993287.626647949,
      },
      revenue_forecast: {
        value: 3812850.9749822207,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 3,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3125941534655208,
      },
      propensity_improve_by: {
        value: 0.0006790223404479767,
      },
      revenue_elift_improve_to: {
        value: 727263.9863260463,
      },
      revenue_elift_improve_by: {
        value: 1632.2113279356472,
      },
    },
    {
      account_id: '0011U0000077i7RQAQ',
      segment: 'high',
      account_name: {
        value: 'Bright Ltd',
      },
      propensity_improve_from: {
        value: 0.31515237926003065,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 1587854.633893706,
      },
      revenue_past: {
        value: 6633833.4228515625,
      },
      revenue_forecast: {
        value: 5440780.094142639,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -439,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3134372144340206,
      },
      propensity_improve_by: {
        value: 0.0017151648260100294,
      },
      revenue_elift_improve_to: {
        value: 1579078.062391066,
      },
      revenue_elift_improve_by: {
        value: 8776.571502640036,
      },
    },
    {
      account_id: '0011U0000077izcQAA',
      segment: 'medium',
      account_name: {
        value: 'Thomas-White',
      },
      propensity_improve_from: {
        value: 0.3190749391883026,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 404599.51790969115,
      },
      revenue_past: {
        value: 2932273.910522461,
      },
      revenue_forecast: {
        value: 3148637.198961029,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 15,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.31842777831036717,
      },
      propensity_improve_by: {
        value: 0.0006471608779354265,
      },
      revenue_elift_improve_to: {
        value: 403727.86835327506,
      },
      revenue_elift_improve_by: {
        value: 871.649556416105,
      },
    },
    {
      account_id: '0011U00000r47jAQAQ',
      segment: 'high',
      account_name: {
        value: 'Mcdaniel-Beltran',
      },
      propensity_improve_from: {
        value: 0.3176234902838364,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 764811.3425892263,
      },
      revenue_past: {
        value: 4068493.2861328125,
      },
      revenue_forecast: {
        value: 3858930.7932225773,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -17,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.31690304483496307,
      },
      propensity_improve_by: {
        value: 0.0007204454488733414,
      },
      revenue_elift_improve_to: {
        value: 763019.7522947317,
      },
      revenue_elift_improve_by: {
        value: 1791.5902944946538,
      },
    },
    {
      account_id: '0011U0000077jpCQAQ',
      segment: 'medium',
      account_name: {
        value: 'Montoya, Lewis and Blackwell',
      },
      propensity_improve_from: {
        value: 0.31648829599380335,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 526631.5731790172,
      },
      revenue_past: {
        value: 3328767.1966552734,
      },
      revenue_forecast: {
        value: 3789037.050168731,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 8,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.31582824115343744,
      },
      propensity_improve_by: {
        value: 0.000660054840365909,
      },
      revenue_elift_improve_to: {
        value: 525481.3285088933,
      },
      revenue_elift_improve_by: {
        value: 1150.2446701239933,
      },
    },
    {
      account_id: '0011U00001EzvyaQAB',
      segment: 'medium',
      account_name: {
        value: 'Smith and Sons',
      },
      propensity_improve_from: {
        value: 0.33454188644135135,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 1919010.0497243048,
      },
      revenue_past: {
        value: 7397260.070800781,
      },
      revenue_forecast: {
        value: 5459500.867447835,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33384904810943916,
      },
      propensity_improve_by: {
        value: 0.0006928383319121934,
      },
      revenue_elift_improve_to: {
        value: 1914979.4903382426,
      },
      revenue_elift_improve_by: {
        value: 4030.559386062396,
      },
    },
    {
      account_id: '0011U0000077jnLQAQ',
      segment: 'low',
      account_name: {
        value: 'Sanders, Huerta and Scott',
      },
      propensity_improve_from: {
        value: 0.31006685360726066,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 273227.8016996395,
      },
      revenue_past: {
        value: 2546136.9552612305,
      },
      revenue_forecast: {
        value: 3145902.4887286224,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 44,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3094959526583079,
      },
      propensity_improve_by: {
        value: 0.000570900948952735,
      },
      revenue_elift_improve_to: {
        value: 272680.4794643627,
      },
      revenue_elift_improve_by: {
        value: 547.3222352767607,
      },
    },
    {
      account_id: '0011U0000077jiYQAQ',
      segment: 'high',
      account_name: {
        value: 'Brooks-Hamilton',
      },
      propensity_improve_from: {
        value: 0.32300120831393764,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 753363.119431088,
      },
      revenue_past: {
        value: 3999698.8220214844,
      },
      revenue_forecast: {
        value: 3666277.769410514,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -10,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3222915685551508,
      },
      propensity_improve_by: {
        value: 0.0007096397587868508,
      },
      revenue_elift_improve_to: {
        value: 751651.526648444,
      },
      revenue_elift_improve_by: {
        value: 1711.592782643993,
      },
    },
    {
      account_id: '0011U0000077jp4QAA',
      segment: 'high',
      account_name: {
        value: 'Martin Inc',
      },
      propensity_improve_from: {
        value: 0.32558836187330453,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 348512.9406564521,
      },
      revenue_past: {
        value: 2737223.035812378,
      },
      revenue_forecast: {
        value: 3084517.6184717435,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -26,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3248322658412958,
      },
      propensity_improve_by: {
        value: 0.0007560960320087129,
      },
      revenue_elift_improve_to: {
        value: 347643.6295184938,
      },
      revenue_elift_improve_by: {
        value: 869.3111379582888,
      },
    },
    {
      account_id: '0011U0000077rWmQAI',
      segment: 'medium',
      account_name: {
        value: 'Gutierrez Group',
      },
      propensity_improve_from: {
        value: 0.32708983693961047,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 145020.183915134,
      },
      revenue_past: {
        value: 2108219.169616699,
      },
      revenue_forecast: {
        value: 2680959.602316951,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -6,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3263845208827817,
      },
      propensity_improve_by: {
        value: 0.0007053160568287753,
      },
      revenue_elift_improve_to: {
        value: 144651.09210843028,
      },
      revenue_elift_improve_by: {
        value: 369.0918067037103,
      },
    },
    {
      account_id: '0011U0000077jgRQAQ',
      segment: 'high',
      account_name: {
        value: 'Larsen-Adams',
      },
      propensity_improve_from: {
        value: 0.34475315220286024,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 108129.69871754553,
      },
      revenue_past: {
        value: 1568219.1450119019,
      },
      revenue_forecast: {
        value: 2253012.6613440453,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -24,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3439796645667923,
      },
      propensity_improve_by: {
        value: 0.0007734876360679377,
      },
      revenue_elift_improve_to: {
        value: 107887.09909368318,
      },
      revenue_elift_improve_by: {
        value: 242.59962386235543,
      },
    },
    {
      account_id: '0011U0000077jAKQAY',
      segment: 'low',
      account_name: {
        value: 'White, Clark and Vargas',
      },
      propensity_improve_from: {
        value: 0.336055180479266,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 349412.3253139739,
      },
      revenue_past: {
        value: 2691419.1284179688,
      },
      revenue_forecast: {
        value: 3140398.810112036,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 65,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.33551765842421283,
      },
      propensity_improve_by: {
        value: 0.0005375220550531967,
      },
      revenue_elift_improve_to: {
        value: 348809.66228645784,
      },
      revenue_elift_improve_by: {
        value: 602.6630275160414,
      },
    },
    {
      account_id: '001EY000002qEFzYAM',
      segment: 'medium',
      account_name: {
        value: 'Mitchell, Vasquez and Adkins',
      },
      propensity_improve_from: {
        value: 0.35480167418966985,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 985039.5174430815,
      },
      revenue_past: {
        value: 4438356.12487793,
      },
      revenue_forecast: {
        value: 3469998.147889836,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -2,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3540708620456204,
      },
      propensity_improve_by: {
        value: 0.0007308121440494642,
      },
      revenue_elift_improve_to: {
        value: 982950.2374422206,
      },
      revenue_elift_improve_by: {
        value: 2089.28000086089,
      },
    },
    {
      account_id: '0011U00000MXjFjQAL',
      segment: 'medium',
      account_name: {
        value: 'Blake Ltd',
      },
      propensity_improve_from: {
        value: 0.336873334661161,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 838620.4053081258,
      },
      revenue_past: {
        value: 4143797.149658203,
      },
      revenue_forecast: {
        value: 3854749.2214887636,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 2,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3361838410733559,
      },
      propensity_improve_by: {
        value: 0.0006894935878050679,
      },
      revenue_elift_improve_to: {
        value: 836847.4926818095,
      },
      revenue_elift_improve_by: {
        value: 1772.912626316338,
      },
    },
    {
      account_id: '0011U00000MXjCZQA1',
      segment: 'low',
      account_name: {
        value: 'Howell-Morrow',
      },
      propensity_improve_from: {
        value: 0.30465488134337226,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 1081655.616649787,
      },
      revenue_past: {
        value: 5215068.374633789,
      },
      revenue_forecast: {
        value: 4359030.604935708,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 52,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3041073005367371,
      },
      propensity_improve_by: {
        value: 0.0005475808066351395,
      },
      revenue_elift_improve_to: {
        value: 1079669.4383629847,
      },
      revenue_elift_improve_by: {
        value: 1986.1782868022146,
      },
    },
    {
      account_id: '0011U0000077jIIQAY',
      segment: 'high',
      account_name: {
        value: 'Bell-Pierce',
      },
      propensity_improve_from: {
        value: 0.31040078115151315,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 1089350.6112149023,
      },
      revenue_past: {
        value: 5178082.214355469,
      },
      revenue_forecast: {
        value: 4703753.026555145,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -28,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.30966749505926594,
      },
      propensity_improve_by: {
        value: 0.0007332860922472095,
      },
      revenue_elift_improve_to: {
        value: 1086719.5986733686,
      },
      revenue_elift_improve_by: {
        value: 2631.0125415336643,
      },
    },
    {
      account_id: '0011U00001BahqwQAB',
      segment: 'medium',
      account_name: {
        value: 'Reed Group',
      },
      propensity_improve_from: {
        value: 0.34273576317059257,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 495368.2522076313,
      },
      revenue_past: {
        value: 3106849.411010742,
      },
      revenue_forecast: {
        value: 3005088.866004746,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: 0,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.34202812390972026,
      },
      propensity_improve_by: {
        value: 0.0007076392608723059,
      },
      revenue_elift_improve_to: {
        value: 494287.61590198847,
      },
      revenue_elift_improve_by: {
        value: 1080.6363056428154,
      },
    },
    {
      account_id: '001EY000002zZpyYAE',
      segment: 'medium',
      account_name: {
        value: 'Martin-Freeman',
      },
      propensity_improve_from: {
        value: 0.3512718452639463,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 895146.7492463309,
      },
      revenue_past: {
        value: 4206822.006225586,
      },
      revenue_forecast: {
        value: 3306581.4424725925,
      },
      'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1': {
        value: -1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.35055544487333956,
      },
      propensity_improve_by: {
        value: 0.0007164003906067196,
      },
      revenue_elift_improve_to: {
        value: 893261.6334829208,
      },
      revenue_elift_improve_by: {
        value: 1885.1157634101128,
      },
    },
  ],
  accounts: {},
}

export const demoGetInsightDetailsSegmentOverallChurn = {
  grid: 'account',
  dimension: 'overall',
  train_datetime: '2024-04-16T19-24-41Z',
  infer_datetime: '2024-04-16T20-43-40Z',
  dataset_date: '2024-04-07',
  is_revenue_quantified: true,
  driver_id: '2-001',
  driver_type: 'insight',
  event: {
    short_name: 'churn',
    full_name: 'churn in next 180 days',
    impact: 'negative',
  },
  family: {
    platform: 'snowflake',
    object: 'Product Telemetry',
    field: 'Minutes on [Feature A]',
    std_platform: 'pendo',
    std_object: 'feature_telemetry',
    std_field: 'numminutes@name[ar-invoices-table-followup-activity-email-views]',
  },
  metric_value: 284.17619047619036,
  aggregations: [
    {
      key: 'improve_from',
      title: 'If no impact is made to the segment',
      sources: [
        {
          key: 'num_accounts',
          title: '# of Accounts',
          column: 'account_name',
          aggregator: 'count',
          type: 'number',
          format: 'comma',
          decimal: 0,
        },
        {
          key: 'revenue_elift_improve_from',
          title: 'ARR At-Risk',
          column: 'revenue_elift_improve_from',
          aggregator: 'sum',
          type: 'number',
          format: 'currency-kmb',
          decimal: 1,
        },
        {
          key: 'propensity_improve_from',
          title: 'Churn Risk',
          column: 'propensity_improve_from',
          aggregator: 'mean',
          type: 'number',
          format: 'percentage',
          decimal: 1,
        },
      ],
    },
    {
      key: 'improve_by',
      title: 'If segment reaches target',
      sources: [
        {
          key: 'revenue_elift_improve_by',
          title: 'Reduction in ARR At-Risk',
          column: 'revenue_elift_improve_by',
          aggregator: 'sum',
          type: 'number',
          format: 'currency-kmb',
          decimal: 1,
        },
        {
          key: 'propensity_improve_by',
          title: 'Reduction in Churn Risk',
          column: 'propensity_improve_by',
          aggregator: 'mean',
          type: 'number',
          format: 'percentage',
          decimal: 1,
        },
      ],
    },
  ],
  predefined_segments: {
    high: {
      title: 'HIGH IMPACT SEGMENT',
      driver: {
        title: '30-day average',
        column:
          'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1',
        min_metric: {
          aggregator: 'min',
          type: 'number',
          format: 'kmb',
          decimal: 2,
        },
        max_metric: {
          aggregator: 'max',
          type: 'number',
          format: 'kmb',
          decimal: 2,
        },
      },
      num_accounts: {
        title: 'Accounts',
        column: 'account_name',
        aggregator: 'count',
        type: 'number',
        format: 'comma',
        decimal: 0,
      },
      revenue_elift_improve_from: {
        title: 'ARR At-Risk',
        column: 'revenue_elift_improve_from',
        aggregator: 'sum',
        type: 'number',
        format: 'currency-kmb',
        decimal: 1,
      },
    },
    medium: {
      title: 'MEDIUM IMPACT SEGMENT',
      driver: {
        title: '30-day average',
        column:
          'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1',
        min_metric: {
          aggregator: 'min',
          type: 'number',
          format: 'kmb',
          decimal: 2,
        },
        max_metric: {
          aggregator: 'max',
          type: 'number',
          format: 'kmb',
          decimal: 2,
        },
      },
      num_accounts: {
        title: 'Accounts',
        column: 'account_name',
        aggregator: 'count',
        type: 'number',
        format: 'comma',
        decimal: 0,
      },
      revenue_elift_improve_from: {
        title: 'ARR At-Risk',
        column: 'revenue_elift_improve_from',
        aggregator: 'sum',
        type: 'number',
        format: 'currency-kmb',
        decimal: 1,
      },
    },
    low: {
      title: 'LOW IMPACT SEGMENT',
      driver: {
        title: '30-day average',
        column:
          'pendo:feature_telemetry:numminutes@name[ar-invoices-table-followup-activity-email-views]:sum|lift:p180d1',
        min_metric: {
          aggregator: 'min',
          type: 'number',
          format: 'kmb',
          decimal: 2,
        },
        max_metric: {
          aggregator: 'max',
          type: 'number',
          format: 'kmb',
          decimal: 2,
        },
      },
      num_accounts: {
        title: 'Accounts',
        column: 'account_name',
        aggregator: 'count',
        type: 'number',
        format: 'comma',
        decimal: 0,
      },
      revenue_elift_improve_from: {
        title: 'ARR At-Risk',
        column: 'revenue_elift_improve_from',
        aggregator: 'sum',
        type: 'number',
        format: 'currency-kmb',
        decimal: 1,
      },
    },
  },
}

export const demoGetInsightDetailsAccountsOverallConversion = {
  grid: 'account',
  dimension: 'overall',
  train_datetime: '2024-04-16T19-24-41Z',
  infer_datetime: '2024-04-16T20-43-40Z',
  dataset_date: '2024-04-07',
  is_revenue_quantified: true,
  driver_id: '2-001',
  driver_type: 'insight',
  event: {
    short_name: 'conversion',
    full_name: 'conversion in next 180 days',
    impact: 'positive',
  },
  table_columns: [
    {
      key: 'account_name',
      title: 'Account Name',
      type: 'string',
      format: 'text',
      nullable: false,
    },
    {
      key: 'revenue_elift_improve_by',
      title: 'Projected Conversion Revenue',
      type: 'number',
      format: 'currency-kmb',
      decimal: 1,
      nullable: false,
    },
    {
      key: 'propensity_improve_by',
      title: 'Increased Conversion %',
      type: 'number',
      format: 'percentage',
      decimal: 0,
      nullable: false,
    },
    {
      key: 'pendo:reports:numvisitors:value:p0d1',
      title: '30-day total',
      type: 'number',
      format: 'comma',
      decimal: 2,
      nullable: false,
    },
    {
      key: 'propensity_improve_from',
      title: 'Conversion %',
      type: 'number',
      format: 'percentage',
      decimal: 0,
      nullable: false,
    },
    {
      key: 'revenue_past',
      title: 'Current ARR',
      type: 'number',
      format: 'currency-kmb',
      decimal: 1,
      nullable: false,
    },
    {
      key: 'revenue_forecast',
      title: 'Forecasted ARR',
      type: 'number',
      format: 'currency-kmb',
      decimal: 1,
      nullable: false,
    },
  ],
  table_rows: [
    {
      account_id: '0011U0000077jJFQAY',
      segment: 'low',
      account_name: {
        value: 'Garcia-Williams',
      },
      propensity_improve_from: {
        value: 0.3533843973638531,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -794679.2114836479,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8350085.392571269,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 19,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3534667452849729,
      },
      propensity_improve_by: {
        value: 0.00008234792111982969,
      },
      revenue_elift_improve_to: {
        value: -794864.3927805898,
      },
      revenue_elift_improve_by: {
        value: -185.1812969418006,
      },
    },
    {
      account_id: '0011U0000077iKhQAI',
      segment: 'medium',
      account_name: {
        value: 'Wall LLC',
      },
      propensity_improve_from: {
        value: 0.33422657023343766,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 134743.6661848907,
      },
      revenue_past: {
        value: 2015753.3569335938,
      },
      revenue_forecast: {
        value: 2895702.616736603,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 6,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33453291473293767,
      },
      propensity_improve_by: {
        value: 0.00030634449950001086,
      },
      revenue_elift_improve_to: {
        value: 134867.16917553975,
      },
      revenue_elift_improve_by: {
        value: 123.50299064905812,
      },
    },
    {
      account_id: '0011U00001lC92GQAS',
      segment: 'high',
      account_name: {
        value: 'Harris-Benson',
      },
      propensity_improve_from: {
        value: 0.33442343804297336,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 57887.32240486808,
      },
      revenue_past: {
        value: 865479.4464111328,
      },
      revenue_forecast: {
        value: 1940546.1426538576,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 2,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.33482125786337286,
      },
      propensity_improve_by: {
        value: 0.00039781982039949204,
      },
      revenue_elift_improve_to: {
        value: 57956.18338045422,
      },
      revenue_elift_improve_by: {
        value: 68.86097558614495,
      },
    },
    {
      account_id: '0011U00001F03YRQAZ',
      segment: 'medium',
      account_name: {
        value: 'Ross, Cardenas and Allen',
      },
      propensity_improve_from: {
        value: 0.32668859096282105,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: -211649.56671634445,
      },
      revenue_past: {
        value: 4763835.5712890625,
      },
      revenue_forecast: {
        value: 3800066.970505327,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 4,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.32705650339227976,
      },
      propensity_improve_by: {
        value: 0.0003679124294587166,
      },
      revenue_elift_improve_to: {
        value: -111604.65268170662,
      },
      revenue_elift_improve_by: {
        value: 100044.91403463781,
      },
    },
    {
      account_id: '001EY000003G6ATYA0',
      segment: 'medium',
      account_name: {
        value: 'Wilson, Diaz and Lowe',
      },
      propensity_improve_from: {
        value: 0.3237090890920115,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 67560.31712403601,
      },
      revenue_past: {
        value: 2507671.142578125,
      },
      revenue_forecast: {
        value: 2532130.727227734,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 6,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.32402264166431644,
      },
      propensity_improve_by: {
        value: 0.0003135525723049204,
      },
      revenue_elift_improve_to: {
        value: 157161.28292825856,
      },
      revenue_elift_improve_by: {
        value: 89600.96580422258,
      },
    },
    {
      account_id: '0011U00000r47g6QAA',
      segment: 'medium',
      account_name: {
        value: 'Rivas Group',
      },
      propensity_improve_from: {
        value: 0.33177686049886873,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 41612.483523412426,
      },
      revenue_past: {
        value: 3698630.0354003906,
      },
      revenue_forecast: {
        value: 3347193.3943472025,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 7,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33205905083096043,
      },
      propensity_improve_by: {
        value: 0.00028219033209170297,
      },
      revenue_elift_improve_to: {
        value: 128373.56924769534,
      },
      revenue_elift_improve_by: {
        value: 86761.08572428291,
      },
    },
    {
      account_id: '0011U0000077jp8QAA',
      segment: 'high',
      account_name: {
        value: 'Wu LLC',
      },
      propensity_improve_from: {
        value: 0.32733696519266814,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 51129.136004116466,
      },
      revenue_past: {
        value: 780986.2838745117,
      },
      revenue_forecast: {
        value: 1726947.578452015,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 3,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3277465960009727,
      },
      propensity_improve_by: {
        value: 0.0004096308083045841,
      },
      revenue_elift_improve_to: {
        value: 51193.119212664125,
      },
      revenue_elift_improve_by: {
        value: 63.98320854765984,
      },
    },
    {
      account_id: '0011U00001dtB4lQAE',
      segment: 'low',
      account_name: {
        value: 'Jones Inc',
      },
      propensity_improve_from: {
        value: 0.3271456176782386,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 278443.0488290845,
      },
      revenue_past: {
        value: 4255643.87512207,
      },
      revenue_forecast: {
        value: 4089254.559110166,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 17,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.32724476449968976,
      },
      propensity_improve_by: {
        value: 0.00009914682145117926,
      },
      revenue_elift_improve_to: {
        value: 278527.4355417738,
      },
      revenue_elift_improve_by: {
        value: 84.38671268929738,
      },
    },
    {
      account_id: '0011U00001Cwhd3QAB',
      segment: 'high',
      account_name: {
        value: 'Price-Bauer',
      },
      propensity_improve_from: {
        value: 0.3365300765810667,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: -39740.43273759722,
      },
      revenue_past: {
        value: 2600255.298614502,
      },
      revenue_forecast: {
        value: 2510749.856574224,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 3,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.33693571771188313,
      },
      propensity_improve_by: {
        value: 0.0004056411308164254,
      },
      revenue_elift_improve_to: {
        value: 68628.50893669858,
      },
      revenue_elift_improve_by: {
        value: 108368.9416742958,
      },
    },
    {
      account_id: '0011U0000077jJcQAI',
      segment: 'low',
      account_name: {
        value: 'Floyd Inc',
      },
      propensity_improve_from: {
        value: 0.34542320098182416,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -701398.3850159945,
      },
      revenue_past: {
        value: 10152739.929199219,
      },
      revenue_forecast: {
        value: 7566085.16492554,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 60,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.34542320098182416,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: -701398.3850159945,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U00000MXjBpQAL',
      segment: 'low',
      account_name: {
        value: 'Griffin-Huffman',
      },
      propensity_improve_from: {
        value: 0.3443967878104669,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -491175.86733929435,
      },
      revenue_past: {
        value: 7130958.892822266,
      },
      revenue_forecast: {
        value: 5357153.34311385,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 18,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3445052666094143,
      },
      propensity_improve_by: {
        value: 0.00010847879894737567,
      },
      revenue_elift_improve_to: {
        value: -491330.5789105017,
      },
      revenue_elift_improve_by: {
        value: -154.71157120730368,
      },
    },
    {
      account_id: '0011U0000077jgwQAA',
      segment: 'medium',
      account_name: {
        value: 'Drake, Mitchell and Osborn',
      },
      propensity_improve_from: {
        value: 0.3403091990418172,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: -357316.2656981968,
      },
      revenue_past: {
        value: 5249876.68132782,
      },
      revenue_forecast: {
        value: 4078801.8321974357,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 10,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3405567470723479,
      },
      propensity_improve_by: {
        value: 0.0002475480305306754,
      },
      revenue_elift_improve_to: {
        value: -357576.1850247951,
      },
      revenue_elift_improve_by: {
        value: -259.91932659835584,
      },
    },
    {
      account_id: '0011U000028OMdsQAG',
      segment: 'medium',
      account_name: {
        value: 'Grant-Braun',
      },
      propensity_improve_from: {
        value: 0.33868870109005855,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 180386.52571849248,
      },
      revenue_past: {
        value: 2663013.6337280273,
      },
      revenue_forecast: {
        value: 3218257.712087836,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 8,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3389287235346882,
      },
      propensity_improve_by: {
        value: 0.00024002244462967282,
      },
      revenue_elift_improve_to: {
        value: 180514.3623269824,
      },
      revenue_elift_improve_by: {
        value: 127.83660848990621,
      },
    },
    {
      account_id: '0011U00000LILxEQAX',
      segment: 'low',
      account_name: {
        value: 'Brown-Abbott',
      },
      propensity_improve_from: {
        value: 0.3515415401407272,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 272447.95532717125,
      },
      revenue_past: {
        value: 4364383.392333984,
      },
      revenue_forecast: {
        value: 4099235.4575243783,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 12,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3517452268238747,
      },
      propensity_improve_by: {
        value: 0.00020368668314751925,
      },
      revenue_elift_improve_to: {
        value: 307030.20525657386,
      },
      revenue_elift_improve_by: {
        value: 34582.24992940256,
      },
    },
    {
      account_id: '0011U00001jiNJ0QAM',
      segment: 'high',
      account_name: {
        value: 'Dodson LLC',
      },
      propensity_improve_from: {
        value: 0.33173390097841693,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: -78856.78409078211,
      },
      revenue_past: {
        value: 3106849.411010742,
      },
      revenue_forecast: {
        value: 2786306.850376934,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 1,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.33217166659429864,
      },
      propensity_improve_by: {
        value: 0.00043776561588171337,
      },
      revenue_elift_improve_to: {
        value: 37987.45164305959,
      },
      revenue_elift_improve_by: {
        value: 116844.2357338417,
      },
    },
    {
      account_id: '0011U0000077jUUQAY',
      segment: 'low',
      account_name: {
        value: 'Turner Group',
      },
      propensity_improve_from: {
        value: 0.3480236283657501,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -782624.0904535813,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8267263.774300636,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 19,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.34810914260860804,
      },
      propensity_improve_by: {
        value: 0.0000855142428579092,
      },
      revenue_elift_improve_to: {
        value: -782816.3920707208,
      },
      revenue_elift_improve_by: {
        value: -192.30161713953748,
      },
    },
    {
      account_id: '0011U0000077jmlQAA',
      segment: 'medium',
      account_name: {
        value: 'Martinez, Clay and Ellis',
      },
      propensity_improve_from: {
        value: 0.3498716873729144,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -610789.6727272862,
      },
      revenue_past: {
        value: 8728766.784667969,
      },
      revenue_forecast: {
        value: 6403423.010497722,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 6,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3501945161899696,
      },
      propensity_improve_by: {
        value: 0.0003228288170551985,
      },
      revenue_elift_improve_to: {
        value: -599776.3282598609,
      },
      revenue_elift_improve_by: {
        value: 11013.344467425373,
      },
    },
    {
      account_id: '0011U0000077iyUQAQ',
      segment: 'medium',
      account_name: {
        value: 'Baker and Sons',
      },
      propensity_improve_from: {
        value: 0.34722779546906446,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -780834.4476070701,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8398898.48387724,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 10,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.347444269964412,
      },
      propensity_improve_by: {
        value: 0.0002164744953475628,
      },
      revenue_elift_improve_to: {
        value: -781321.2483332831,
      },
      revenue_elift_improve_by: {
        value: -486.80072621305044,
      },
    },
    {
      account_id: '0011U0000077jp0QAA',
      segment: 'medium',
      account_name: {
        value: 'Fisher, Cooper and Barr',
      },
      propensity_improve_from: {
        value: 0.34047316403800293,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 327514.6716939366,
      },
      revenue_past: {
        value: 4809698.770523071,
      },
      revenue_forecast: {
        value: 4505339.222501042,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 8,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3407353570393196,
      },
      propensity_improve_by: {
        value: 0.00026219300131669065,
      },
      revenue_elift_improve_to: {
        value: 327766.88556515105,
      },
      revenue_elift_improve_by: {
        value: 252.2138712145079,
      },
    },
    {
      account_id: '0011U00000r49gnQAA',
      segment: 'high',
      account_name: {
        value: 'Owens LLC',
      },
      propensity_improve_from: {
        value: 0.32601412327148976,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 115661.68247179803,
      },
      revenue_past: {
        value: 1773875.3356933594,
      },
      revenue_forecast: {
        value: 2454559.9391204,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 1,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.32644512823131366,
      },
      propensity_improve_by: {
        value: 0.00043100495982389964,
      },
      revenue_elift_improve_to: {
        value: 115814.59228535666,
      },
      revenue_elift_improve_by: {
        value: 152.90981355862527,
      },
    },
    {
      account_id: '0011U0000077rWcQAI',
      segment: 'medium',
      account_name: {
        value: 'Thompson LLC',
      },
      propensity_improve_from: {
        value: 0.3413291587638967,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: -767569.7873655347,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8299090.170094038,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 6,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3416193925172649,
      },
      propensity_improve_by: {
        value: 0.00029023375336822976,
      },
      revenue_elift_improve_to: {
        value: -768222.4554855567,
      },
      revenue_elift_improve_by: {
        value: -652.6681200220992,
      },
    },
    {
      account_id: '0011U00001fJ0V8QAK',
      segment: 'medium',
      account_name: {
        value: 'Hayes Group',
      },
      propensity_improve_from: {
        value: 0.32918843063764625,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 63610.02744775315,
      },
      revenue_past: {
        value: 966164.3837928772,
      },
      revenue_forecast: {
        value: 1973991.627361206,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 6,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.32953373796207014,
      },
      propensity_improve_by: {
        value: 0.00034530732442389356,
      },
      revenue_elift_improve_to: {
        value: 63676.75217541738,
      },
      revenue_elift_improve_by: {
        value: 66.72472766423141,
      },
    },
    {
      account_id: '001EY000003c35bYAA',
      segment: 'low',
      account_name: {
        value: 'Brock, George and Paul',
      },
      propensity_improve_from: {
        value: 0.34407412319074754,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 228335.5059073327,
      },
      revenue_past: {
        value: 3318115.058898926,
      },
      revenue_forecast: {
        value: 3487112.5735853007,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 28,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.34407412319074754,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 228335.5059073327,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U0000077gkeQAA',
      segment: 'low',
      account_name: {
        value: 'White Group',
      },
      propensity_improve_from: {
        value: 0.335870988886503,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: -529700.783576977,
      },
      revenue_past: {
        value: 7885479.8583984375,
      },
      revenue_forecast: {
        value: 5746850.401903163,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 50,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.33587098888650296,
      },
      propensity_improve_by: {
        value: -5.551115123125783e-17,
      },
      revenue_elift_improve_to: {
        value: -529700.783576977,
      },
      revenue_elift_improve_by: {
        value: 6.821210263296962e-11,
      },
    },
    {
      account_id: '0011U000012AuTtQAK',
      segment: 'high',
      account_name: {
        value: 'Fuentes PLC',
      },
      propensity_improve_from: {
        value: 0.31340649960175276,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: -213288.14914113874,
      },
      revenue_past: {
        value: 3402739.7232055664,
      },
      revenue_forecast: {
        value: 2819081.5763114304,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 1,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.31381105151275973,
      },
      propensity_improve_by: {
        value: 0.0004045519110069673,
      },
      revenue_elift_improve_to: {
        value: -213563.46611267515,
      },
      revenue_elift_improve_by: {
        value: -275.31697153642654,
      },
    },
    {
      account_id: '0011U00000zmBnFQAU',
      segment: 'high',
      account_name: {
        value: 'Crawford Group',
      },
      propensity_improve_from: {
        value: 0.34016266692685826,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 179661.80663194298,
      },
      revenue_past: {
        value: 2640821.937561035,
      },
      revenue_forecast: {
        value: 3467735.505190416,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 3,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3405448269254241,
      },
      propensity_improve_by: {
        value: 0.00038215999856583416,
      },
      revenue_elift_improve_to: {
        value: 179863.64993351715,
      },
      revenue_elift_improve_by: {
        value: 201.84330157417207,
      },
    },
    {
      account_id: '0011U00001kO0ZqQAK',
      segment: 'high',
      account_name: {
        value: 'Avila and Sons',
      },
      propensity_improve_from: {
        value: 0.33505814674565954,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 78816.69475041082,
      },
      revenue_past: {
        value: 1176164.4287109375,
      },
      revenue_forecast: {
        value: 2224202.6688395096,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 2,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3354630964650907,
      },
      propensity_improve_by: {
        value: 0.0004049497194311691,
      },
      revenue_elift_improve_to: {
        value: 78911.95224149311,
      },
      revenue_elift_improve_by: {
        value: 95.25749108228752,
      },
    },
    {
      account_id: '0011U00001CwhnoQAB',
      segment: 'high',
      account_name: {
        value: 'Fuller, Reid and Carter',
      },
      propensity_improve_from: {
        value: 0.3175991441344775,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 97733.52301889373,
      },
      revenue_past: {
        value: 1538630.1383972168,
      },
      revenue_forecast: {
        value: 2206474.296678717,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 3,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.31798884673377914,
      },
      propensity_improve_by: {
        value: 0.00038970259930165563,
      },
      revenue_elift_improve_to: {
        value: 97853.44465175319,
      },
      revenue_elift_improve_by: {
        value: 119.92163285946162,
      },
    },
    {
      account_id: '0011U0000077jCGQAY',
      segment: 'low',
      account_name: {
        value: 'Miller-Price',
      },
      propensity_improve_from: {
        value: 0.3428331779724912,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 282584.33671095443,
      },
      revenue_past: {
        value: 4121309.6466064453,
      },
      revenue_forecast: {
        value: 3914360.6639519394,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 33,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3428331779724912,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 282584.33671095443,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U00000r47gCQAQ',
      segment: 'medium',
      account_name: {
        value: 'Gillespie-Holden',
      },
      propensity_improve_from: {
        value: 0.3378853274503369,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 125328.87805354092,
      },
      revenue_past: {
        value: 2802846.6567993164,
      },
      revenue_forecast: {
        value: 2979469.5714415708,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 5,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.338219858035507,
      },
      propensity_improve_by: {
        value: 0.0003345305851701341,
      },
      revenue_elift_improve_to: {
        value: 189595.67967159205,
      },
      revenue_elift_improve_by: {
        value: 64266.80161805112,
      },
    },
    {
      account_id: '0011U0000077jCBQAY',
      segment: 'low',
      account_name: {
        value: 'Bruce and Sons',
      },
      propensity_improve_from: {
        value: 0.3390085715522234,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 122113.30050897595,
      },
      revenue_past: {
        value: 1801035.5895996094,
      },
      revenue_forecast: {
        value: 2788961.6125155934,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 13,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3392142233103533,
      },
      propensity_improve_by: {
        value: 0.00020565175812986558,
      },
      revenue_elift_improve_to: {
        value: 122187.3777360671,
      },
      revenue_elift_improve_by: {
        value: 74.07722709112932,
      },
    },
    {
      account_id: '0011U0000077jCZQAY',
      segment: 'high',
      account_name: {
        value: 'Jones PLC',
      },
      propensity_improve_from: {
        value: 0.32705597788337376,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 99491.93145443518,
      },
      revenue_past: {
        value: 1521022.9774475098,
      },
      revenue_forecast: {
        value: 3451165.626559171,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 3,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3272955229586983,
      },
      propensity_improve_by: {
        value: 0.00023954507532453206,
      },
      revenue_elift_improve_to: {
        value: 99564.80216717577,
      },
      revenue_elift_improve_by: {
        value: 72.87071274059258,
      },
    },
    {
      account_id: '001EY00000364AAYAY',
      segment: 'high',
      account_name: {
        value: 'Wade, Lee and Oliver',
      },
      propensity_improve_from: {
        value: 0.3271354591596386,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 58077.74810079399,
      },
      revenue_past: {
        value: 887671.2455749512,
      },
      revenue_forecast: {
        value: 1977700.1202158248,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 1,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3275683686523186,
      },
      propensity_improve_by: {
        value: 0.00043290949267998924,
      },
      revenue_elift_improve_to: {
        value: 58154.60436251169,
      },
      revenue_elift_improve_by: {
        value: 76.85626171769115,
      },
    },
    {
      account_id: '0011U00001dtB8sQAE',
      segment: 'medium',
      account_name: {
        value: 'Wilson and Sons',
      },
      propensity_improve_from: {
        value: 0.33191633921925356,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: -454028.8061064607,
      },
      revenue_past: {
        value: 6839506.7138671875,
      },
      revenue_forecast: {
        value: 5120290.892129356,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 7,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3322188861931268,
      },
      propensity_improve_by: {
        value: 0.00030254697387321894,
      },
      revenue_elift_improve_to: {
        value: -454442.66051827394,
      },
      revenue_elift_improve_by: {
        value: -413.85441181323586,
      },
    },
    {
      account_id: '001EY000002KtUWYA0',
      segment: 'medium',
      account_name: {
        value: 'King PLC',
      },
      propensity_improve_from: {
        value: 0.33151971288675836,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 186377.65701698352,
      },
      revenue_past: {
        value: 2810958.8928222656,
      },
      revenue_forecast: {
        value: 3243117.4398342776,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 8,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3317935117527314,
      },
      propensity_improve_by: {
        value: 0.0002737988659730628,
      },
      revenue_elift_improve_to: {
        value: 186531.58448841385,
      },
      revenue_elift_improve_by: {
        value: 153.92747143032466,
      },
    },
    {
      account_id: '0011U00001yX5ycQAC',
      segment: 'low',
      account_name: {
        value: 'Trujillo Ltd',
      },
      propensity_improve_from: {
        value: 0.3349403170689894,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 297316.881546341,
      },
      revenue_past: {
        value: 4438356.12487793,
      },
      revenue_forecast: {
        value: 4225725.4352427535,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 37,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3349403170689894,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 297316.881546341,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U0000077jgrQAA',
      segment: 'medium',
      account_name: {
        value: 'Brooks Group',
      },
      propensity_improve_from: {
        value: 0.33843603383065946,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 83090.77717523991,
      },
      revenue_past: {
        value: 4127671.142578125,
      },
      revenue_forecast: {
        value: 3668218.396864528,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 4,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33880244480508814,
      },
      propensity_improve_by: {
        value: 0.0003664109744286881,
      },
      revenue_elift_improve_to: {
        value: 187065.59107351777,
      },
      revenue_elift_improve_by: {
        value: 103974.81389827785,
      },
    },
    {
      account_id: '0011U0000077jp1QAA',
      segment: 'low',
      account_name: {
        value: 'Choi Group',
      },
      propensity_improve_from: {
        value: 0.35141075003361133,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -575386.1822083255,
      },
      revenue_past: {
        value: 9468493.28613281,
      },
      revenue_forecast: {
        value: 7169126.650415306,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 24,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.35141461434266447,
      },
      propensity_improve_by: {
        value: 0.000003864309053136505,
      },
      revenue_elift_improve_to: {
        value: -574114.8184495403,
      },
      revenue_elift_improve_by: {
        value: 1271.3637587852645,
      },
    },
    {
      account_id: '001EY000002h5yXYAQ',
      segment: 'medium',
      account_name: {
        value: 'Miller LLC',
      },
      propensity_improve_from: {
        value: 0.34313764488489107,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 188594.86804361444,
      },
      revenue_past: {
        value: 5178082.214355469,
      },
      revenue_forecast: {
        value: 4539629.162840843,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 10,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.34339663154493644,
      },
      propensity_improve_by: {
        value: 0.0002589866600453683,
      },
      revenue_elift_improve_to: {
        value: 262817.2525604232,
      },
      revenue_elift_improve_by: {
        value: 74222.38451680877,
      },
    },
    {
      account_id: '0011U00000LILtQQAX',
      segment: 'low',
      account_name: {
        value: 'Meza, Ayala and Walker',
      },
      propensity_improve_from: {
        value: 0.33833874670509084,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 174007.601735257,
      },
      revenue_past: {
        value: 3318706.8099975586,
      },
      revenue_forecast: {
        value: 3355390.8749359995,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 13,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.33853435041412233,
      },
      propensity_improve_by: {
        value: 0.00019560370903148883,
      },
      revenue_elift_improve_to: {
        value: 224699.25082748954,
      },
      revenue_elift_improve_by: {
        value: 50691.649092232525,
      },
    },
    {
      account_id: '0011U00000Nj8zxQAB',
      segment: 'high',
      account_name: {
        value: 'Harding, Pope and Olsen',
      },
      propensity_improve_from: {
        value: 0.33236757726432914,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: -308801.3450599702,
      },
      revenue_past: {
        value: 4645479.375600815,
      },
      revenue_forecast: {
        value: 3604275.506582247,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 3,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.33276684440343923,
      },
      propensity_improve_by: {
        value: 0.00039926713911009326,
      },
      revenue_elift_improve_to: {
        value: -309172.3025119885,
      },
      revenue_elift_improve_by: {
        value: -370.9574520182514,
      },
    },
    {
      account_id: '0011U00000NhygKQAR',
      segment: 'low',
      account_name: {
        value: 'Ramirez and Sons',
      },
      propensity_improve_from: {
        value: 0.3447289148019157,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 127502.47183573477,
      },
      revenue_past: {
        value: 1849315.0177001953,
      },
      revenue_forecast: {
        value: 2816350.546218188,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 14,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3448909632571967,
      },
      propensity_improve_by: {
        value: 0.0001620484552810053,
      },
      revenue_elift_improve_to: {
        value: 127562.40756412402,
      },
      revenue_elift_improve_by: {
        value: 59.93572838925161,
      },
    },
    {
      account_id: '0011U00001dtB6lQAE',
      segment: 'medium',
      account_name: {
        value: 'Murphy-Smith',
      },
      propensity_improve_from: {
        value: 0.33175510611363557,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 117795.78730789639,
      },
      revenue_past: {
        value: 1775342.4911499023,
      },
      revenue_forecast: {
        value: 2437786.5758144306,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 4,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33210524160370286,
      },
      propensity_improve_by: {
        value: 0.0003501354900672937,
      },
      revenue_elift_improve_to: {
        value: 117920.10939053161,
      },
      revenue_elift_improve_by: {
        value: 124.32208263522284,
      },
    },
    {
      account_id: '0011U0000077joQQAQ',
      segment: 'medium',
      account_name: {
        value: 'Harris PLC',
      },
      propensity_improve_from: {
        value: 0.3370034600598446,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: -757842.2983736747,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8187719.765337371,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 9,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3372582252121555,
      },
      propensity_improve_by: {
        value: 0.00025476515231087626,
      },
      revenue_elift_improve_to: {
        value: -758415.2058700502,
      },
      revenue_elift_improve_by: {
        value: -572.9074963755011,
      },
    },
    {
      account_id: '0011U00001CwhmyQAB',
      segment: 'high',
      account_name: {
        value: 'Smith-Cline',
      },
      propensity_improve_from: {
        value: 0.3414081225431579,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 105546.42211947823,
      },
      revenue_past: {
        value: 3890225.2853393555,
      },
      revenue_forecast: {
        value: 3514379.606757442,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 2,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3418064136757083,
      },
      propensity_improve_by: {
        value: 0.00039829113255041015,
      },
      revenue_elift_improve_to: {
        value: 220831.81541953204,
      },
      revenue_elift_improve_by: {
        value: 115285.39330005382,
      },
    },
    {
      account_id: '0011U00000LIM0hQAH',
      segment: 'medium',
      account_name: {
        value: 'Norton-Flores',
      },
      propensity_improve_from: {
        value: 0.34866849710753345,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -564737.492029777,
      },
      revenue_past: {
        value: 8098487.484741211,
      },
      revenue_forecast: {
        value: 5959993.967632422,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 9,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.34894529189500256,
      },
      propensity_improve_by: {
        value: 0.00027679478746911457,
      },
      revenue_elift_improve_to: {
        value: -565185.8158542091,
      },
      revenue_elift_improve_by: {
        value: -448.32382443207734,
      },
    },
    {
      account_id: '0011U00001zhwS1QAI',
      segment: 'medium',
      account_name: {
        value: 'Mills and Sons',
      },
      propensity_improve_from: {
        value: 0.3133408852705661,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 187746.98451932776,
      },
      revenue_past: {
        value: 2995890.312194824,
      },
      revenue_forecast: {
        value: 3022377.426066847,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 4,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3136671405242327,
      },
      propensity_improve_by: {
        value: 0.00032625525366664077,
      },
      revenue_elift_improve_to: {
        value: 187942.46951008026,
      },
      revenue_elift_improve_by: {
        value: 195.48499075251584,
      },
    },
    {
      account_id: '0011U0000077iRhQAI',
      segment: 'low',
      account_name: {
        value: 'Cummings PLC',
      },
      propensity_improve_from: {
        value: 0.3435248834831638,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -17166.682004856455,
      },
      revenue_past: {
        value: 8632602.716445923,
      },
      revenue_forecast: {
        value: 7211324.036753737,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 28,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3435248834831638,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: -17166.682004856455,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U00001BdM65QAF',
      segment: 'medium',
      account_name: {
        value: 'Smith, Lambert and Parker',
      },
      propensity_improve_from: {
        value: 0.3447435058151859,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 173410.71197022556,
      },
      revenue_past: {
        value: 2515068.5806274414,
      },
      revenue_forecast: {
        value: 2966997.2486269497,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 5,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.34506706932966646,
      },
      propensity_improve_by: {
        value: 0.00032356351448054044,
      },
      revenue_elift_improve_to: {
        value: 173573.46885604705,
      },
      revenue_elift_improve_by: {
        value: 162.7568858214886,
      },
    },
    {
      account_id: '001EY000002oGDhYAM',
      segment: 'high',
      account_name: {
        value: 'Smith-Davis',
      },
      propensity_improve_from: {
        value: 0.3284507834274608,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 111763.25278866095,
      },
      revenue_past: {
        value: 1701369.8616027832,
      },
      revenue_forecast: {
        value: 2235768.308665211,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 2,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.32886557169679614,
      },
      propensity_improve_by: {
        value: 0.0004147882693353311,
      },
      revenue_elift_improve_to: {
        value: 111904.39444073965,
      },
      revenue_elift_improve_by: {
        value: 141.14165207870428,
      },
    },
    {
      account_id: '0011U000014jSGkQAM',
      segment: 'medium',
      account_name: {
        value: 'Love-Morris',
      },
      propensity_improve_from: {
        value: 0.33579528570297507,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 101713.40476431853,
      },
      revenue_past: {
        value: 1514515.079498291,
      },
      revenue_forecast: {
        value: 2412846.9501025714,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 9,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3360498160933738,
      },
      propensity_improve_by: {
        value: 0.0002545303903987439,
      },
      revenue_elift_improve_to: {
        value: 101790.50278720842,
      },
      revenue_elift_improve_by: {
        value: 77.09802288989067,
      },
    },
    {
      account_id: '0011U00000r480kQAA',
      segment: 'medium',
      account_name: {
        value: 'Ramirez-Tran',
      },
      propensity_improve_from: {
        value: 0.33529468235601506,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 163102.02991601118,
      },
      revenue_past: {
        value: 2432219.156742096,
      },
      revenue_forecast: {
        value: 2948333.8740713615,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 4,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33563943006092806,
      },
      propensity_improve_by: {
        value: 0.00034474770491299545,
      },
      revenue_elift_improve_to: {
        value: 163269.73031043762,
      },
      revenue_elift_improve_by: {
        value: 167.70039442644702,
      },
    },
    {
      account_id: '0011U00001LJgzDQAT',
      segment: 'medium',
      account_name: {
        value: 'Durham, Bennett and Baker',
      },
      propensity_improve_from: {
        value: 0.3382845515892422,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: -760723.1748753383,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 7999177.002500459,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 4,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33865485984950755,
      },
      propensity_improve_by: {
        value: 0.0003703082602653507,
      },
      revenue_elift_improve_to: {
        value: -761555.911913161,
      },
      revenue_elift_improve_by: {
        value: -832.7370378226988,
      },
    },
    {
      account_id: '0011U0000077jHEQAY',
      segment: 'medium',
      account_name: {
        value: 'Stone Ltd',
      },
      propensity_improve_from: {
        value: 0.3387594987501444,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 185636.49651471974,
      },
      revenue_past: {
        value: 2739945.2590942383,
      },
      revenue_forecast: {
        value: 3247281.060428256,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 8,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33904349241216575,
      },
      propensity_improve_by: {
        value: 0.00028399366202136456,
      },
      revenue_elift_improve_to: {
        value: 185792.12193229338,
      },
      revenue_elift_improve_by: {
        value: 155.62541757363988,
      },
    },
    {
      account_id: '0011U00001EzwRUQAZ',
      segment: 'high',
      account_name: {
        value: 'Harris, Bean and Walters',
      },
      propensity_improve_from: {
        value: 0.32742584784064765,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 201837.84586497626,
      },
      revenue_past: {
        value: 3082191.696166992,
      },
      revenue_forecast: {
        value: 3376096.294836292,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 2,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3277470156394245,
      },
      propensity_improve_by: {
        value: 0.00032116779877683355,
      },
      revenue_elift_improve_to: {
        value: 202035.82600946949,
      },
      revenue_elift_improve_by: {
        value: 197.98014449322636,
      },
    },
    {
      account_id: '0011U00001gUmjFQAS',
      segment: 'medium',
      account_name: {
        value: 'Oneill, King and Fleming',
      },
      propensity_improve_from: {
        value: 0.3526173319091325,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -340893.1951231864,
      },
      revenue_past: {
        value: 6346849.411010742,
      },
      revenue_forecast: {
        value: 4949639.278630508,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 8,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.352899124648767,
      },
      propensity_improve_by: {
        value: 0.0002817927396344677,
      },
      revenue_elift_improve_to: {
        value: -254343.21620133516,
      },
      revenue_elift_improve_by: {
        value: 86549.97892185122,
      },
    },
    {
      account_id: '001EY000003TMGvYAO',
      segment: 'low',
      account_name: {
        value: 'Palmer-Matthews',
      },
      propensity_improve_from: {
        value: 0.3193966565899345,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 148847.59058141735,
      },
      revenue_past: {
        value: 2330136.9552612305,
      },
      revenue_forecast: {
        value: 2807254.256948989,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 16,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3195174868607126,
      },
      propensity_improve_by: {
        value: 0.00012083027077808284,
      },
      revenue_elift_improve_to: {
        value: 148903.9007972682,
      },
      revenue_elift_improve_by: {
        value: 56.31021585085705,
      },
    },
    {
      account_id: '0011U0000077jgMQAQ',
      segment: 'medium',
      account_name: {
        value: 'Lewis PLC',
      },
      propensity_improve_from: {
        value: 0.34567815444199157,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 135013.3612130418,
      },
      revenue_past: {
        value: 1952876.6784667969,
      },
      revenue_forecast: {
        value: 2707979.062351817,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 8,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3459716597500664,
      },
      propensity_improve_by: {
        value: 0.0002935053080748151,
      },
      revenue_elift_improve_to: {
        value: 135127.9971472709,
      },
      revenue_elift_improve_by: {
        value: 114.63593422910208,
      },
    },
    {
      account_id: '0011U00001BaloPQAR',
      segment: 'medium',
      account_name: {
        value: 'Carr-Smith',
      },
      propensity_improve_from: {
        value: 0.34024952972324085,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: -467656.0849683142,
      },
      revenue_past: {
        value: 6872251.746368408,
      },
      revenue_forecast: {
        value: 5128349.741434431,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 9,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.34052261137204554,
      },
      propensity_improve_by: {
        value: 0.000273081648804685,
      },
      revenue_elift_improve_to: {
        value: -468031.42213589407,
      },
      revenue_elift_improve_by: {
        value: -375.3371675798462,
      },
    },
    {
      account_id: '0011U000024CdohQAC',
      segment: 'high',
      account_name: {
        value: 'Mack, Velez and Martinez',
      },
      propensity_improve_from: {
        value: 0.3196805420119756,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 100265.83280289217,
      },
      revenue_past: {
        value: 1568219.200515747,
      },
      revenue_forecast: {
        value: 2381304.9480890455,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 1,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3200464692189365,
      },
      propensity_improve_by: {
        value: 0.000365927206960881,
      },
      revenue_elift_improve_to: {
        value: 100380.6036172816,
      },
      revenue_elift_improve_by: {
        value: 114.77081438942491,
      },
    },
    {
      account_id: '001EY000003yCuwYAE',
      segment: 'low',
      account_name: {
        value: 'Melton and Sons',
      },
      propensity_improve_from: {
        value: 0.32091091719582926,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 176665.83507950287,
      },
      revenue_past: {
        value: 2752568.1678771973,
      },
      revenue_forecast: {
        value: 3261335.9324503834,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 12,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.32104553976347827,
      },
      propensity_improve_by: {
        value: 0.00013462256764901204,
      },
      revenue_elift_improve_to: {
        value: 176739.94663838058,
      },
      revenue_elift_improve_by: {
        value: 74.11155887771201,
      },
    },
    {
      account_id: '0011U00001CwhoLQAR',
      segment: 'medium',
      account_name: {
        value: 'Gilmore, Cooper and Reyes',
      },
      propensity_improve_from: {
        value: 0.35181056675289063,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 194662.08446414015,
      },
      revenue_past: {
        value: 2766575.294494629,
      },
      revenue_forecast: {
        value: 3393366.194271571,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 8,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.35208611104551435,
      },
      propensity_improve_by: {
        value: 0.0002755442926237195,
      },
      revenue_elift_improve_to: {
        value: 194814.54727064248,
      },
      revenue_elift_improve_by: {
        value: 152.46280650234212,
      },
    },
    {
      account_id: '0011U0000077jphQAA',
      segment: 'low',
      account_name: {
        value: 'Jackson Group',
      },
      propensity_improve_from: {
        value: 0.3439745469161808,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -773518.6492468384,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8200759.129609074,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 47,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3439745469161808,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: -773518.6492468384,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U00001NTrWBQA1',
      segment: 'low',
      account_name: {
        value: 'Lewis Inc',
      },
      propensity_improve_from: {
        value: 0.3468312045580277,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 235168.9234810168,
      },
      revenue_past: {
        value: 4436712.290382385,
      },
      revenue_forecast: {
        value: 4103024.200778989,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 29,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3468312045580277,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 235168.9234810168,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U00001LHG2kQAH',
      segment: 'medium',
      account_name: {
        value: 'Smith, Taylor and Simpson',
      },
      propensity_improve_from: {
        value: 0.3338391670871437,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 197559.61951329937,
      },
      revenue_past: {
        value: 2958904.151916504,
      },
      revenue_forecast: {
        value: 3045046.8178951326,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 8,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33411676126381007,
      },
      propensity_improve_by: {
        value: 0.00027759417666639585,
      },
      revenue_elift_improve_to: {
        value: 197723.89442567658,
      },
      revenue_elift_improve_by: {
        value: 164.27491237719778,
      },
    },
    {
      account_id: '0011U00001oQmJMQA0',
      segment: 'high',
      account_name: {
        value: 'Parker-Fisher',
      },
      propensity_improve_from: {
        value: 0.3319548378916418,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 137511.1498940299,
      },
      revenue_past: {
        value: 2071232.8033447266,
      },
      revenue_forecast: {
        value: 2853336.969637504,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 2,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.33234776280156547,
      },
      propensity_improve_by: {
        value: 0.00039292490992365314,
      },
      revenue_elift_improve_to: {
        value: 137673.91768656694,
      },
      revenue_elift_improve_by: {
        value: 162.76779253702784,
      },
    },
    {
      account_id: '0011U0000077iHrQAI',
      segment: 'high',
      account_name: {
        value: 'Trujillo Group',
      },
      propensity_improve_from: {
        value: 0.32131988461850813,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 52343.80164402709,
      },
      revenue_past: {
        value: 814512.3310089111,
      },
      revenue_forecast: {
        value: 1740372.269000222,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 1,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3218035912092876,
      },
      propensity_improve_by: {
        value: 0.00048370659077945355,
      },
      revenue_elift_improve_to: {
        value: 52422.59864058311,
      },
      revenue_elift_improve_by: {
        value: 78.79699655602792,
      },
    },
    {
      account_id: '0011U0000077iFlQAI',
      segment: 'medium',
      account_name: {
        value: 'Long, Garcia and Harvey',
      },
      propensity_improve_from: {
        value: 0.34260361124459404,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -38798.16219656254,
      },
      revenue_past: {
        value: 4878657.621002197,
      },
      revenue_forecast: {
        value: 4076045.455732743,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 6,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3429439205580839,
      },
      propensity_improve_by: {
        value: 0.0003403093134898838,
      },
      revenue_elift_improve_to: {
        value: 55927.24524753424,
      },
      revenue_elift_improve_by: {
        value: 94725.40744409678,
      },
    },
    {
      account_id: '0011U0000077glfQAA',
      segment: 'high',
      account_name: {
        value: 'Spears, Miller and Vaughan',
      },
      propensity_improve_from: {
        value: 0.33449501378586305,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 134851.88938328045,
      },
      revenue_past: {
        value: 2015753.3569335938,
      },
      revenue_forecast: {
        value: 2421129.5125528667,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 3,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.33485999692668433,
      },
      propensity_improve_by: {
        value: 0.00036498314082128447,
      },
      revenue_elift_improve_to: {
        value: 134999.03258154736,
      },
      revenue_elift_improve_by: {
        value: 147.1431982669344,
      },
    },
    {
      account_id: '0011U0000077iszQAA',
      segment: 'high',
      account_name: {
        value: 'Johnson, Ford and Baxter',
      },
      propensity_improve_from: {
        value: 0.338928109925604,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: -14661.516042127992,
      },
      revenue_past: {
        value: 5178082.214355469,
      },
      revenue_forecast: {
        value: 4710510.717283478,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 3,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.33930711077937675,
      },
      propensity_improve_by: {
        value: 0.0003790008537727374,
      },
      revenue_elift_improve_to: {
        value: 94501.98275325698,
      },
      revenue_elift_improve_by: {
        value: 109163.49879538498,
      },
    },
    {
      account_id: '0011U0000077jgLQAQ',
      segment: 'low',
      account_name: {
        value: 'Mills Inc',
      },
      propensity_improve_from: {
        value: 0.34678999138195776,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -36864.17116483052,
      },
      revenue_past: {
        value: 6687123.321533203,
      },
      revenue_forecast: {
        value: 628012558.6042235,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 42,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.34678999138195776,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: -36864.17116482977,
      },
      revenue_elift_improve_by: {
        value: 7.545963853772264e-10,
      },
    },
    {
      account_id: '0011U00000g9EwgQAE',
      segment: 'low',
      account_name: {
        value: 'Macias, Miles and Mcdaniel',
      },
      propensity_improve_from: {
        value: 0.3328891725748669,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 166853.08957228644,
      },
      revenue_past: {
        value: 5005479.473876953,
      },
      revenue_forecast: {
        value: 4431713.528188739,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 12,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3330952674104752,
      },
      propensity_improve_by: {
        value: 0.00020609483560829478,
      },
      revenue_elift_improve_to: {
        value: 228721.76320917925,
      },
      revenue_elift_improve_by: {
        value: 61868.673636892796,
      },
    },
    {
      account_id: '0011U000014ifycQAA',
      segment: 'low',
      account_name: {
        value: 'Cunningham Group',
      },
      propensity_improve_from: {
        value: 0.3407807763978182,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: -77468.04675328672,
      },
      revenue_past: {
        value: 5397041.107177734,
      },
      revenue_forecast: {
        value: 4473159.474193479,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 32,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3407807763978182,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: -77468.04675328672,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U0000077jppQAA',
      segment: 'high',
      account_name: {
        value: 'Conley, Price and Fleming',
      },
      propensity_improve_from: {
        value: 0.33130467149592113,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 137006.77600688624,
      },
      revenue_past: {
        value: 4104887.8326416016,
      },
      revenue_forecast: {
        value: 3712900.0228230883,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 3,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.33167132592804993,
      },
      propensity_improve_by: {
        value: 0.000366654432128799,
      },
      revenue_elift_improve_to: {
        value: 243881.30501526778,
      },
      revenue_elift_improve_by: {
        value: 106874.52900838153,
      },
    },
    {
      account_id: '0011U0000077ggdQAA',
      segment: 'medium',
      account_name: {
        value: 'Nelson and Sons',
      },
      propensity_improve_from: {
        value: 0.33065762067927523,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 203014.7220369114,
      },
      revenue_past: {
        value: 3069863.0447387695,
      },
      revenue_forecast: {
        value: 3349189.040151211,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 4,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.331004796392022,
      },
      propensity_improve_by: {
        value: 0.0003471757127467834,
      },
      revenue_elift_improve_to: {
        value: 203227.87841502985,
      },
      revenue_elift_improve_by: {
        value: 213.15637811843544,
      },
    },
    {
      account_id: '0011U0000077ik5QAA',
      segment: 'high',
      account_name: {
        value: 'Kidd-Andersen',
      },
      propensity_improve_from: {
        value: 0.32102280913030795,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 146692.42726702857,
      },
      revenue_past: {
        value: 2367123.321533203,
      },
      revenue_forecast: {
        value: 2925192.5948770745,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 1,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3214496372190684,
      },
      propensity_improve_by: {
        value: 0.00042682808876043365,
      },
      revenue_elift_improve_to: {
        value: 152182.18659192885,
      },
      revenue_elift_improve_by: {
        value: 5489.759324900279,
      },
    },
    {
      account_id: '0011U00001dtB6aQAE',
      segment: 'medium',
      account_name: {
        value: 'Christian-Monroe',
      },
      propensity_improve_from: {
        value: 0.3433013430077459,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -11852.582568398468,
      },
      revenue_past: {
        value: 2663013.6337280273,
      },
      revenue_forecast: {
        value: 2876441.0824472075,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 5,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3436517989356307,
      },
      propensity_improve_by: {
        value: 0.0003504559278847963,
      },
      revenue_elift_improve_to: {
        value: 88300.98380452406,
      },
      revenue_elift_improve_by: {
        value: 100153.56637292253,
      },
    },
    {
      account_id: '0011U00000Q2kMmQAJ',
      segment: 'high',
      account_name: {
        value: 'Atkinson and Sons',
      },
      propensity_improve_from: {
        value: 0.31092802792912805,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: -181008.1548480816,
      },
      revenue_past: {
        value: 2910772.5677490234,
      },
      revenue_forecast: {
        value: 2445108.761996052,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 2,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.31136868985270516,
      },
      propensity_improve_by: {
        value: 0.00044066192357711254,
      },
      revenue_elift_improve_to: {
        value: -181264.68817584158,
      },
      revenue_elift_improve_by: {
        value: -256.53332775996205,
      },
    },
    {
      account_id: '001EY000003mI79YAE',
      segment: 'high',
      account_name: {
        value: 'Bray, Burgess and Morgan',
      },
      propensity_improve_from: {
        value: 0.32578817402048393,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 152763.4143197101,
      },
      revenue_past: {
        value: 2344520.558166504,
      },
      revenue_forecast: {
        value: 3025299.017904291,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 2,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.326132064784799,
      },
      propensity_improve_by: {
        value: 0.00034389076431506904,
      },
      revenue_elift_improve_to: {
        value: 152924.66611305016,
      },
      revenue_elift_improve_by: {
        value: 161.25179334004542,
      },
    },
    {
      account_id: '0011U0000077gq1QAA',
      segment: 'medium',
      account_name: {
        value: 'Gibson and Sons',
      },
      propensity_improve_from: {
        value: 0.33030684671958127,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: -412733.3407565973,
      },
      revenue_past: {
        value: 6247726.089477539,
      },
      revenue_forecast: {
        value: 4745926.945321389,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 7,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3306021705261062,
      },
      propensity_improve_by: {
        value: 0.0002953238065249164,
      },
      revenue_elift_improve_to: {
        value: -413102.36120677117,
      },
      revenue_elift_improve_by: {
        value: -369.02045017391174,
      },
    },
    {
      account_id: '0011U00001R4il6QAB',
      segment: 'medium',
      account_name: {
        value: 'Luna LLC',
      },
      propensity_improve_from: {
        value: 0.33563905234189,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 202982.08452573183,
      },
      revenue_past: {
        value: 4438356.12487793,
      },
      revenue_forecast: {
        value: 4071112.539029127,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 5,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33595184494455876,
      },
      propensity_improve_by: {
        value: 0.0003127926026687544,
      },
      revenue_elift_improve_to: {
        value: 298214.7857347446,
      },
      revenue_elift_improve_by: {
        value: 95232.70120901278,
      },
    },
    {
      account_id: '0011U00001dtB9GQAU',
      segment: 'medium',
      account_name: {
        value: 'Wilson LLC',
      },
      propensity_improve_from: {
        value: 0.3378586800239252,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 164349.2669953342,
      },
      revenue_past: {
        value: 2432219.1009521484,
      },
      revenue_forecast: {
        value: 2989730.5035889507,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 6,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33816682334080544,
      },
      propensity_improve_by: {
        value: 0.0003081433168802272,
      },
      revenue_elift_improve_to: {
        value: 164499.16140756357,
      },
      revenue_elift_improve_by: {
        value: 149.89441222936648,
      },
    },
    {
      account_id: '0011U0000077jaqQAA',
      segment: 'medium',
      account_name: {
        value: 'Walls-Anderson',
      },
      propensity_improve_from: {
        value: 0.3375957034116497,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: -249728.3216920899,
      },
      revenue_past: {
        value: 3698630.0354003906,
      },
      revenue_forecast: {
        value: 3019405.52954246,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 5,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3379384536591633,
      },
      propensity_improve_by: {
        value: 0.0003427502475136235,
      },
      revenue_elift_improve_to: {
        value: -248838.29678627106,
      },
      revenue_elift_improve_by: {
        value: 890.0249058188479,
      },
    },
    {
      account_id: '001EY000003UeG1YAK',
      segment: 'medium',
      account_name: {
        value: 'Ruiz, Garcia and Schmidt',
      },
      propensity_improve_from: {
        value: 0.3240777777682253,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 126217.19172574893,
      },
      revenue_past: {
        value: 1947328.7029266357,
      },
      revenue_forecast: {
        value: 2708768.906200382,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 5,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3243007222447942,
      },
      propensity_improve_by: {
        value: 0.00022294447656895366,
      },
      revenue_elift_improve_to: {
        value: 126304.02096142527,
      },
      revenue_elift_improve_by: {
        value: 86.82923567633907,
      },
    },
    {
      account_id: '0011U000028O1gzQAC',
      segment: 'medium',
      account_name: {
        value: 'Maxwell, Roberts and Deleon',
      },
      propensity_improve_from: {
        value: 0.3267167338664198,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: -62405.52261065066,
      },
      revenue_past: {
        value: 2810958.8928222656,
      },
      revenue_forecast: {
        value: 2797132.2303668736,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 6,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3270483946142549,
      },
      propensity_improve_by: {
        value: 0.00033166074783508304,
      },
      revenue_elift_improve_to: {
        value: 27902.743634660615,
      },
      revenue_elift_improve_by: {
        value: 90308.26624531127,
      },
    },
    {
      account_id: '0011U0000077jiZQAQ',
      segment: 'low',
      account_name: {
        value: 'Cruz, Garcia and Gilmore',
      },
      propensity_improve_from: {
        value: 0.35220332279484495,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -573426.5128672892,
      },
      revenue_past: {
        value: 9509030.145263672,
      },
      revenue_forecast: {
        value: 7346663.897313924,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 76,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.35220332279484495,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: -573426.5128672892,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U0000077iz4QAA',
      segment: 'medium',
      account_name: {
        value: 'Lee-Cherry',
      },
      propensity_improve_from: {
        value: 0.3441404957508704,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 142142.6685494984,
      },
      revenue_past: {
        value: 5052772.567749023,
      },
      revenue_forecast: {
        value: 4440161.889289918,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 5,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.34446915553744284,
      },
      propensity_improve_by: {
        value: 0.0003286597865724272,
      },
      revenue_elift_improve_to: {
        value: 242682.32152571983,
      },
      revenue_elift_improve_by: {
        value: 100539.65297622143,
      },
    },
    {
      account_id: '0011U0000077j3fQAA',
      segment: 'high',
      account_name: {
        value: 'Andrews-Crosby',
      },
      propensity_improve_from: {
        value: 0.3405588013849109,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 110844.89014519974,
      },
      revenue_past: {
        value: 1627397.232055664,
      },
      revenue_forecast: {
        value: 2713016.289849161,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 1,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3409788407996286,
      },
      propensity_improve_by: {
        value: 0.0004200394147176878,
      },
      revenue_elift_improve_to: {
        value: 110981.6043413729,
      },
      revenue_elift_improve_by: {
        value: 136.71419617316474,
      },
    },
    {
      account_id: '0011U00001fIphJQAS',
      segment: 'medium',
      account_name: {
        value: 'Carlson PLC',
      },
      propensity_improve_from: {
        value: 0.3379416074315418,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 149990.52031948764,
      },
      revenue_past: {
        value: 2219178.062438965,
      },
      revenue_forecast: {
        value: 2751823.1494215014,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 4,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.338277410993648,
      },
      propensity_improve_by: {
        value: 0.0003358035621062183,
      },
      revenue_elift_improve_to: {
        value: 150139.56189915063,
      },
      revenue_elift_improve_by: {
        value: 149.04157966300318,
      },
    },
    {
      account_id: '0011U00001txFQWQA2',
      segment: 'high',
      account_name: {
        value: 'Aguilar, Stephens and Roberts',
      },
      propensity_improve_from: {
        value: 0.3371476989720083,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 185861.6773005685,
      },
      revenue_past: {
        value: 2756383.594894409,
      },
      revenue_forecast: {
        value: 2947819.428989869,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 3,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3374914543870804,
      },
      propensity_improve_by: {
        value: 0.00034375541507208895,
      },
      revenue_elift_improve_to: {
        value: 186051.18165792068,
      },
      revenue_elift_improve_by: {
        value: 189.50435735217752,
      },
    },
    {
      account_id: '001EY000002tzP1YAI',
      segment: 'low',
      account_name: {
        value: 'Jimenez Inc',
      },
      propensity_improve_from: {
        value: 0.33370796470825,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 246852.46026445337,
      },
      revenue_past: {
        value: 3698630.0354003906,
      },
      revenue_forecast: {
        value: 3559459.35749032,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 11,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3339202504857373,
      },
      propensity_improve_by: {
        value: 0.00021228577748733102,
      },
      revenue_elift_improve_to: {
        value: 247009.49357499398,
      },
      revenue_elift_improve_by: {
        value: 157.03331054058935,
      },
    },
    {
      account_id: '0011U0000077jnvQAA',
      segment: 'medium',
      account_name: {
        value: 'Ballard, Woods and Harrell',
      },
      propensity_improve_from: {
        value: 0.340872282273799,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 221893.84346275099,
      },
      revenue_past: {
        value: 3254794.464111328,
      },
      revenue_forecast: {
        value: 3300617.7954267687,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 7,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.34116203408820983,
      },
      propensity_improve_by: {
        value: 0.0002897518144108213,
      },
      revenue_elift_improve_to: {
        value: 222082.4599830531,
      },
      revenue_elift_improve_by: {
        value: 188.61652030211644,
      },
    },
    {
      account_id: '0011U0000077jpnQAA',
      segment: 'medium',
      account_name: {
        value: 'Williams-Hoover',
      },
      propensity_improve_from: {
        value: 0.34061838413803225,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 109348.74282787148,
      },
      revenue_past: {
        value: 3871170.510864258,
      },
      revenue_forecast: {
        value: 3514560.999824714,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 8,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.34090610120605924,
      },
      propensity_improve_by: {
        value: 0.0002877170680269936,
      },
      revenue_elift_improve_to: {
        value: 193312.91399793813,
      },
      revenue_elift_improve_by: {
        value: 83964.17117006665,
      },
    },
    {
      account_id: '0011U00000LILyCQAX',
      segment: 'low',
      account_name: {
        value: 'Johnson-Underwood',
      },
      propensity_improve_from: {
        value: 0.33740268881212415,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: -491734.3265449233,
      },
      revenue_past: {
        value: 7397260.070800781,
      },
      revenue_forecast: {
        value: 5783922.806137912,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 36,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.33740268881212415,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: -491734.3265449233,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U0000077jgIQAQ',
      segment: 'low',
      account_name: {
        value: 'Zamora Ltd',
      },
      propensity_improve_from: {
        value: 0.33103563672835745,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: -587701.6183304683,
      },
      revenue_past: {
        value: 8876712.24975586,
      },
      revenue_forecast: {
        value: 6397891.957934466,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 13,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3312427415255346,
      },
      propensity_improve_by: {
        value: 0.00020710479717717112,
      },
      revenue_elift_improve_to: {
        value: -588069.3002684854,
      },
      revenue_elift_improve_by: {
        value: -367.6819380171537,
      },
    },
    {
      account_id: '0011U00000r1WcKQAU',
      segment: 'medium',
      account_name: {
        value: 'Dixon, Mcdonald and Garcia',
      },
      propensity_improve_from: {
        value: 0.3247490928940982,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: -207214.71793501545,
      },
      revenue_past: {
        value: 3960345.3826904297,
      },
      revenue_forecast: {
        value: 3247867.2477732645,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 6,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3250657223946895,
      },
      propensity_improve_by: {
        value: 0.00031662950059130335,
      },
      revenue_elift_improve_to: {
        value: -117592.99732868552,
      },
      revenue_elift_improve_by: {
        value: 89621.72060632994,
      },
    },
    {
      account_id: '0011U0000077j4OQAQ',
      segment: 'medium',
      account_name: {
        value: 'Robinson PLC',
      },
      propensity_improve_from: {
        value: 0.3329366824360133,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 72021.81588719858,
      },
      revenue_past: {
        value: 3602389.414215088,
      },
      revenue_forecast: {
        value: 3264693.900806057,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 5,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3332784368656453,
      },
      propensity_improve_by: {
        value: 0.00034175442963196856,
      },
      revenue_elift_improve_to: {
        value: 169237.84275244735,
      },
      revenue_elift_improve_by: {
        value: 97216.02686524879,
      },
    },
    {
      account_id: '0011U0000077jpRQAQ',
      segment: 'medium',
      account_name: {
        value: 'Levine-Riggs',
      },
      propensity_improve_from: {
        value: 0.33398162354806926,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 152650.82765149052,
      },
      revenue_past: {
        value: 2285317.7673339844,
      },
      revenue_forecast: {
        value: 2677615.392843309,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 7,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33430297552368876,
      },
      propensity_improve_by: {
        value: 0.00032135197561949935,
      },
      revenue_elift_improve_to: {
        value: 152797.70592738074,
      },
      revenue_elift_improve_by: {
        value: 146.87827589023073,
      },
    },
    {
      account_id: '0011U0000077irkQAA',
      segment: 'low',
      account_name: {
        value: 'Henderson Ltd',
      },
      propensity_improve_from: {
        value: 0.3252943748388091,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 166033.81029947172,
      },
      revenue_past: {
        value: 2552054.7409057617,
      },
      revenue_forecast: {
        value: 3043250.5078049265,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 12,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3255181536780869,
      },
      propensity_improve_by: {
        value: 0.00022377883927782172,
      },
      revenue_elift_improve_to: {
        value: 166148.0294690104,
      },
      revenue_elift_improve_by: {
        value: 114.21916953868276,
      },
    },
    {
      account_id: '0011U00001oQbCDQA0',
      segment: 'high',
      account_name: {
        value: 'Little, Patel and Weaver',
      },
      propensity_improve_from: {
        value: 0.3350025458867867,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 76692.2732304073,
      },
      revenue_past: {
        value: 3086301.414012909,
      },
      revenue_forecast: {
        value: 3006255.9521382586,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 3,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3353193000344333,
      },
      propensity_improve_by: {
        value: 0.0003167541476465918,
      },
      revenue_elift_improve_to: {
        value: 184661.51154544827,
      },
      revenue_elift_improve_by: {
        value: 107969.23831504097,
      },
    },
    {
      account_id: '0011U00001zgB2FQAU',
      segment: 'low',
      account_name: {
        value: 'Hernandez-Anderson',
      },
      propensity_improve_from: {
        value: 0.32989763083271595,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 146420.3170389103,
      },
      revenue_past: {
        value: 2219178.062438965,
      },
      revenue_forecast: {
        value: 2711151.5136172106,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 29,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.32989763083271595,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 146420.3170389103,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U0000077iIGQAY',
      segment: 'medium',
      account_name: {
        value: 'Munoz-Schultz',
      },
      propensity_improve_from: {
        value: 0.34932585576175945,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -283909.2936856148,
      },
      revenue_past: {
        value: 6142684.982299805,
      },
      revenue_forecast: {
        value: 4899065.281663223,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 8,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.34959157208220243,
      },
      propensity_improve_by: {
        value: 0.0002657163204429769,
      },
      revenue_elift_improve_to: {
        value: -198116.5925572013,
      },
      revenue_elift_improve_by: {
        value: 85792.7011284135,
      },
    },
    {
      account_id: '001EY000003EFkLYAW',
      segment: 'low',
      account_name: {
        value: 'Garcia-Wilkerson',
      },
      propensity_improve_from: {
        value: 0.3538004003727975,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 142962.2927754534,
      },
      revenue_past: {
        value: 8063013.427734375,
      },
      revenue_forecast: {
        value: 7075846.866490048,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 101,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3538004003727975,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 142962.2927754534,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '001EY0000034teOYAQ',
      segment: 'low',
      account_name: {
        value: 'Dixon Group',
      },
      propensity_improve_from: {
        value: 0.34745543567918225,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -603498.2566883665,
      },
      revenue_past: {
        value: 8876712.24975586,
      },
      revenue_forecast: {
        value: 6794132.05987165,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 33,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.34745543567918225,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: -603498.2566883665,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U000020RrRuQAK',
      segment: 'low',
      account_name: {
        value: 'Schroeder, Smith and Martinez',
      },
      propensity_improve_from: {
        value: 0.34287436449109615,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -771044.5951896237,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8358004.971988556,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 34,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.34287436449109615,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: -771044.5951896237,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U0000077ifVQAQ',
      segment: 'medium',
      account_name: {
        value: 'Andrade-Sanchez',
      },
      propensity_improve_from: {
        value: 0.3448286436012542,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 224469.27205207606,
      },
      revenue_past: {
        value: 3254794.464111328,
      },
      revenue_forecast: {
        value: 3447499.578063354,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 5,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3451511282889828,
      },
      propensity_improve_by: {
        value: 0.00032248468772860805,
      },
      revenue_elift_improve_to: {
        value: 224679.19632735202,
      },
      revenue_elift_improve_by: {
        value: 209.92427527597783,
      },
    },
    {
      account_id: '0011U0000077istQAA',
      segment: 'low',
      account_name: {
        value: 'Dunn, Chambers and Rogers',
      },
      propensity_improve_from: {
        value: 0.3505096205298034,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -788214.5078784069,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8238968.526091962,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 17,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3506307227761993,
      },
      propensity_improve_by: {
        value: 0.00012110224639588774,
      },
      revenue_elift_improve_to: {
        value: -788486.8386275647,
      },
      revenue_elift_improve_by: {
        value: -272.3307491578453,
      },
    },
    {
      account_id: '0011U00001EznBSQAZ',
      segment: 'low',
      account_name: {
        value: 'Williams, Pierce and Thomas',
      },
      propensity_improve_from: {
        value: 0.34650147124423636,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -512632.29954174295,
      },
      revenue_past: {
        value: 7397260.070800781,
      },
      revenue_forecast: {
        value: 5779260.749282633,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 17,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.34660373980597836,
      },
      propensity_improve_by: {
        value: 0.00010226856174200272,
      },
      revenue_elift_improve_to: {
        value: -512783.6009713974,
      },
      revenue_elift_improve_by: {
        value: -151.30142965444975,
      },
    },
    {
      account_id: '001EY000002RpVDYA0',
      segment: 'high',
      account_name: {
        value: 'Bradley, Jackson and Martin',
      },
      propensity_improve_from: {
        value: 0.3233479255117554,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 129945.81570285858,
      },
      revenue_past: {
        value: 3328767.1966552734,
      },
      revenue_forecast: {
        value: 3109071.293812582,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 1,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.32376304108561477,
      },
      propensity_improve_by: {
        value: 0.00041511557385937037,
      },
      revenue_elift_improve_to: {
        value: 215546.3581310296,
      },
      revenue_elift_improve_by: {
        value: 85600.54242817103,
      },
    },
    {
      account_id: '0011U0000077joPQAQ',
      segment: 'medium',
      account_name: {
        value: 'Whitaker Group',
      },
      propensity_improve_from: {
        value: 0.32833081901434313,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 100539.48831802676,
      },
      revenue_past: {
        value: 3698630.0354003906,
      },
      revenue_forecast: {
        value: 3353365.8816868314,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 10,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.32858642886634193,
      },
      propensity_improve_by: {
        value: 0.0002556098519987948,
      },
      revenue_elift_improve_to: {
        value: 171502.83466002907,
      },
      revenue_elift_improve_by: {
        value: 70963.3463420023,
      },
    },
    {
      account_id: '0011U0000077jptQAA',
      segment: 'low',
      account_name: {
        value: 'Coleman LLC',
      },
      propensity_improve_from: {
        value: 0.35708599674098057,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -803003.2464330895,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8342866.988306462,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 17,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3572022871225837,
      },
      propensity_improve_by: {
        value: 0.00011629038160310134,
      },
      revenue_elift_improve_to: {
        value: -803264.756418943,
      },
      revenue_elift_improve_by: {
        value: -261.5099858535359,
      },
    },
    {
      account_id: '0011U000020S43DQAS',
      segment: 'high',
      account_name: {
        value: 'Hernandez, Adkins and Burton',
      },
      propensity_improve_from: {
        value: 0.3318381616300989,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: -83009.39346070928,
      },
      revenue_past: {
        value: 3930719.1524505615,
      },
      revenue_forecast: {
        value: 3339013.3279116983,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 3,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.33222203920221977,
      },
      propensity_improve_by: {
        value: 0.00038387757212088314,
      },
      revenue_elift_improve_to: {
        value: 23794.687975665587,
      },
      revenue_elift_improve_by: {
        value: 106804.08143637487,
      },
    },
    {
      account_id: '0011U00000MXjC2QAL',
      segment: 'high',
      account_name: {
        value: 'Baldwin, Gonzalez and Owens',
      },
      propensity_improve_from: {
        value: 0.31778922339391386,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 190412.33113714863,
      },
      revenue_past: {
        value: 2995890.312194824,
      },
      revenue_forecast: {
        value: 2971129.2057577837,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 2,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.31816795712401114,
      },
      propensity_improve_by: {
        value: 0.0003787337300972804,
      },
      revenue_elift_improve_to: {
        value: 190639.2600797286,
      },
      revenue_elift_improve_by: {
        value: 226.92894257997978,
      },
    },
    {
      account_id: '001EY000003ce9tYAA',
      segment: 'medium',
      account_name: {
        value: 'Cameron PLC',
      },
      propensity_improve_from: {
        value: 0.31730657976379123,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: -370661.2432283238,
      },
      revenue_past: {
        value: 6181298.492431641,
      },
      revenue_forecast: {
        value: 4759921.827321054,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 4,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3176323574656623,
      },
      propensity_improve_by: {
        value: 0.00032577770187108834,
      },
      revenue_elift_improve_to: {
        value: -273648.19535885303,
      },
      revenue_elift_improve_by: {
        value: 97013.0478694708,
      },
    },
    {
      account_id: '0011U00001V96twQAB',
      segment: 'high',
      account_name: {
        value: 'Allison Inc',
      },
      propensity_improve_from: {
        value: 0.3325217877539876,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 165666.91514114875,
      },
      revenue_past: {
        value: 3276986.3662719727,
      },
      revenue_forecast: {
        value: 3210980.6531285113,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 3,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3328588744240152,
      },
      propensity_improve_by: {
        value: 0.0003370866700275754,
      },
      revenue_elift_improve_to: {
        value: 218154.79867602646,
      },
      revenue_elift_improve_by: {
        value: 52487.8835348777,
      },
    },
    {
      account_id: '0011U00001tE45RQAS',
      segment: 'medium',
      account_name: {
        value: 'Guerrero-Morton',
      },
      propensity_improve_from: {
        value: 0.335749710735552,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 73208.79665994362,
      },
      revenue_past: {
        value: 2915753.442764282,
      },
      revenue_forecast: {
        value: 2957749.6262981384,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 8,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3360354387216654,
      },
      propensity_improve_by: {
        value: 0.00028572798611342654,
      },
      revenue_elift_improve_to: {
        value: 155944.59358005688,
      },
      revenue_elift_improve_by: {
        value: 82735.79692011327,
      },
    },
    {
      account_id: '0011U0000077jp2QAA',
      segment: 'medium',
      account_name: {
        value: 'Rodriguez-Taylor',
      },
      propensity_improve_from: {
        value: 0.33212724668233795,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 180698.0630582924,
      },
      revenue_past: {
        value: 2720313.7481689453,
      },
      revenue_forecast: {
        value: 3024679.5848383517,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 7,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33244676542000023,
      },
      propensity_improve_by: {
        value: 0.0003195187376622832,
      },
      revenue_elift_improve_to: {
        value: 180871.90130126447,
      },
      revenue_elift_improve_by: {
        value: 173.8382429720673,
      },
    },
    {
      account_id: '0011U00001kNoITQA0',
      segment: 'medium',
      account_name: {
        value: 'Stout Inc',
      },
      propensity_improve_from: {
        value: 0.34508380772215685,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 288936.24268230185,
      },
      revenue_past: {
        value: 4826301.396560669,
      },
      revenue_forecast: {
        value: 4443596.284051951,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 7,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3453725121382927,
      },
      propensity_improve_by: {
        value: 0.000288704416135821,
      },
      revenue_elift_improve_to: {
        value: 333374.36753334163,
      },
      revenue_elift_improve_by: {
        value: 44438.124851039836,
      },
    },
    {
      account_id: '001EY000002hXcAYAU',
      segment: 'low',
      account_name: {
        value: 'Stanley-Jordan',
      },
      propensity_improve_from: {
        value: 0.3445066923396376,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 132517.1002507904,
      },
      revenue_past: {
        value: 1923287.7502441406,
      },
      revenue_forecast: {
        value: 2967631.0963135273,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 11,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3447305773961806,
      },
      propensity_improve_by: {
        value: 0.00022388505654302726,
      },
      revenue_elift_improve_to: {
        value: 132603.21932813278,
      },
      revenue_elift_improve_by: {
        value: 86.11907734239139,
      },
    },
    {
      account_id: '0011U0000077jpCQAQ',
      segment: 'low',
      account_name: {
        value: 'Montoya, Lewis and Blackwell',
      },
      propensity_improve_from: {
        value: 0.34241818571477406,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 227966.08482911065,
      },
      revenue_past: {
        value: 3328767.1966552734,
      },
      revenue_forecast: {
        value: 3789037.050168731,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 15,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3425727775433362,
      },
      propensity_improve_by: {
        value: 0.00015459182856214504,
      },
      revenue_elift_improve_to: {
        value: 228069.00487066837,
      },
      revenue_elift_improve_by: {
        value: 102.92004155771792,
      },
    },
    {
      account_id: '0011U00000mLkDbQAK',
      segment: 'medium',
      account_name: {
        value: 'Frank-Evans',
      },
      propensity_improve_from: {
        value: 0.3415809174354422,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -241640.64627178284,
      },
      revenue_past: {
        value: 3894821.8322753906,
      },
      revenue_forecast: {
        value: 3165165.147525713,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 4,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3419505021529457,
      },
      propensity_improve_by: {
        value: 0.00036958471750347943,
      },
      revenue_elift_improve_to: {
        value: -137051.97348927657,
      },
      revenue_elift_improve_by: {
        value: 104588.67278250628,
      },
    },
    {
      account_id: '0011U00001acbDrQAI',
      segment: 'low',
      account_name: {
        value: 'Williams, Bowen and Erickson',
      },
      propensity_improve_from: {
        value: 0.34894069135715194,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -280583.5309044409,
      },
      revenue_past: {
        value: 6195624.620819092,
      },
      revenue_forecast: {
        value: 4866740.16115841,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 16,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3490874969816567,
      },
      propensity_improve_by: {
        value: 0.00014680562450475332,
      },
      revenue_elift_improve_to: {
        value: -237124.64125489435,
      },
      revenue_elift_improve_by: {
        value: 43458.88964954659,
      },
    },
    {
      account_id: '0011U0000077i7RQAQ',
      segment: 'low',
      account_name: {
        value: 'Bright Ltd',
      },
      propensity_improve_from: {
        value: 0.3482911831998072,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -51730.563621173475,
      },
      revenue_past: {
        value: 6633833.4228515625,
      },
      revenue_forecast: {
        value: 5440780.094142639,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 17,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.34841440484113323,
      },
      propensity_improve_by: {
        value: 0.0001232216413260545,
      },
      revenue_elift_improve_to: {
        value: -13534.218240909797,
      },
      revenue_elift_improve_by: {
        value: 38196.34538026368,
      },
    },
    {
      account_id: '0011U00000r47mYQAQ',
      segment: 'medium',
      account_name: {
        value: 'Lindsey, Williams and Adams',
      },
      propensity_improve_from: {
        value: 0.3430715479571948,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -558313.7158108153,
      },
      revenue_past: {
        value: 8136986.572265625,
      },
      revenue_forecast: {
        value: 6035489.685045829,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 5,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.34340476941456083,
      },
      propensity_improve_by: {
        value: 0.0003332214573660286,
      },
      revenue_elift_improve_to: {
        value: -558855.9995156509,
      },
      revenue_elift_improve_by: {
        value: -542.2837048356087,
      },
    },
    {
      account_id: '0011U0000077iLkQAI',
      segment: 'high',
      account_name: {
        value: 'Miller, Richardson and Peterson',
      },
      propensity_improve_from: {
        value: 0.322290730320659,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 104899.0084882078,
      },
      revenue_past: {
        value: 1627397.232055664,
      },
      revenue_forecast: {
        value: 2356435.1708348184,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 2,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.32269802717820045,
      },
      propensity_improve_by: {
        value: 0.0004072968575414282,
      },
      revenue_elift_improve_to: {
        value: 105031.57524392537,
      },
      revenue_elift_improve_by: {
        value: 132.56675571757341,
      },
    },
    {
      account_id: '0011U00001NRkjDQAT',
      segment: 'medium',
      account_name: {
        value: 'Floyd, Boyer and Poole',
      },
      propensity_improve_from: {
        value: 0.34228342858577226,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -14810.657660468563,
      },
      revenue_past: {
        value: 3698630.0354003906,
      },
      revenue_forecast: {
        value: 3428835.3514289046,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 7,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3425703433645105,
      },
      propensity_improve_by: {
        value: 0.00028691477873821203,
      },
      revenue_elift_improve_to: {
        value: 74647.9122358472,
      },
      revenue_elift_improve_by: {
        value: 89458.56989631575,
      },
    },
    {
      account_id: '0011U0000077jmxQAA',
      segment: 'low',
      account_name: {
        value: 'Lopez, Phelps and Jennings',
      },
      propensity_improve_from: {
        value: 0.32039525341332126,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: -621900.3574198838,
      },
      revenue_past: {
        value: 9705205.535888672,
      },
      revenue_forecast: {
        value: 7245669.407876161,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 17,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.32050517343745405,
      },
      propensity_improve_by: {
        value: 0.00010992002413279245,
      },
      revenue_elift_improve_to: {
        value: -622113.7167052276,
      },
      revenue_elift_improve_by: {
        value: -213.35928534376762,
      },
    },
    {
      account_id: '0011U00001gyszsQAA',
      segment: 'low',
      account_name: {
        value: 'Smith and Sons',
      },
      propensity_improve_from: {
        value: 0.347916774112572,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 195767.51207704094,
      },
      revenue_past: {
        value: 6570246.643066406,
      },
      revenue_forecast: {
        value: 5767413.708536009,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 17,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3480253095643778,
      },
      propensity_improve_by: {
        value: 0.00010853545180583568,
      },
      revenue_elift_improve_to: {
        value: 234000.5538382986,
      },
      revenue_elift_improve_by: {
        value: 38233.04176125765,
      },
    },
    {
      account_id: '001EY000002pifOYAQ',
      segment: 'low',
      account_name: {
        value: 'Scott Ltd',
      },
      propensity_improve_from: {
        value: 0.34596883073437423,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -509581.9703924045,
      },
      revenue_past: {
        value: 7364564.739990234,
      },
      revenue_forecast: {
        value: 5593478.689263443,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 12,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3461668146521895,
      },
      propensity_improve_by: {
        value: 0.0001979839178152809,
      },
      revenue_elift_improve_to: {
        value: -509873.58346845006,
      },
      revenue_elift_improve_by: {
        value: -291.6130760455417,
      },
    },
    {
      account_id: '0011U00001CwhiSQAR',
      segment: 'medium',
      account_name: {
        value: 'Hatfield and Sons',
      },
      propensity_improve_from: {
        value: 0.3343830638066609,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 310344.12246023066,
      },
      revenue_past: {
        value: 4640547.8633880615,
      },
      revenue_forecast: {
        value: 4334991.420677634,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 6,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3346759896104849,
      },
      propensity_improve_by: {
        value: 0.0002929258038240068,
      },
      revenue_elift_improve_to: {
        value: 310615.989702844,
      },
      revenue_elift_improve_by: {
        value: 271.867242613348,
      },
    },
    {
      account_id: '0011U0000077iaWQAQ',
      segment: 'low',
      account_name: {
        value: 'Taylor, Guzman and Henderson',
      },
      propensity_improve_from: {
        value: 0.3438400106727369,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 213651.82692810398,
      },
      revenue_past: {
        value: 3106849.411010742,
      },
      revenue_forecast: {
        value: 3339867.960444739,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 12,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.34404755638252843,
      },
      propensity_improve_by: {
        value: 0.00020754570979153453,
      },
      revenue_elift_improve_to: {
        value: 213780.7895813487,
      },
      revenue_elift_improve_by: {
        value: 128.96265324474143,
      },
    },
    {
      account_id: '001EY000003LXW5YAO',
      segment: 'medium',
      account_name: {
        value: 'Jones-Burns',
      },
      propensity_improve_from: {
        value: 0.33083157779802064,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: -278986.1896913302,
      },
      revenue_past: {
        value: 4216438.339233398,
      },
      revenue_forecast: {
        value: 3372017.093201548,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 6,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3311499475866674,
      },
      propensity_improve_by: {
        value: 0.00031836978864674403,
      },
      revenue_elift_improve_to: {
        value: -252721.38016251975,
      },
      revenue_elift_improve_by: {
        value: 26264.80952881043,
      },
    },
    {
      account_id: '0011U000025fC2UQAU',
      segment: 'medium',
      account_name: {
        value: 'Crawford LLC',
      },
      propensity_improve_from: {
        value: 0.3364116450631028,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: -248852.4429377695,
      },
      revenue_past: {
        value: 3698630.0354003906,
      },
      revenue_forecast: {
        value: 3178919.780970727,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 6,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33670951832900053,
      },
      propensity_improve_by: {
        value: 0.0002978732658977523,
      },
      revenue_elift_improve_to: {
        value: -249072.78753936797,
      },
      revenue_elift_improve_by: {
        value: -220.34460159846958,
      },
    },
    {
      account_id: '0011U0000077japQAA',
      segment: 'low',
      account_name: {
        value: 'Kennedy, Berry and Simon',
      },
      propensity_improve_from: {
        value: 0.3454515275892609,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -606596.5231931554,
      },
      revenue_past: {
        value: 8779763.21926117,
      },
      revenue_forecast: {
        value: 6538100.261746741,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 23,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.34547224945787025,
      },
      propensity_improve_by: {
        value: 0.000020721868609352434,
      },
      revenue_elift_improve_to: {
        value: -606632.9098131256,
      },
      revenue_elift_improve_by: {
        value: -36.386619970130596,
      },
    },
    {
      account_id: '001EY000003XLOiYAO',
      segment: 'low',
      account_name: {
        value: 'Cummings-Smith',
      },
      propensity_improve_from: {
        value: 0.33917507548649656,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 167579.72941399322,
      },
      revenue_past: {
        value: 5281939.682006836,
      },
      revenue_forecast: {
        value: 4739622.321265863,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 15,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3393139100220739,
      },
      propensity_improve_by: {
        value: 0.00013883453557733683,
      },
      revenue_elift_improve_to: {
        value: 215145.61390117023,
      },
      revenue_elift_improve_by: {
        value: 47565.88448717701,
      },
    },
    {
      account_id: '0011U00000r47i7QAA',
      segment: 'medium',
      account_name: {
        value: 'Green, Green and Myers',
      },
      propensity_improve_from: {
        value: 0.32855524595324614,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 165267.79521946612,
      },
      revenue_past: {
        value: 2515068.5806274414,
      },
      revenue_forecast: {
        value: 3023566.86399157,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 7,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.32886725524760546,
      },
      propensity_improve_by: {
        value: 0.0003120092943593167,
      },
      revenue_elift_improve_to: {
        value: 165424.74017408752,
      },
      revenue_elift_improve_by: {
        value: 156.9449546213832,
      },
    },
    {
      account_id: '0011U00001CwhlFQAR',
      segment: 'low',
      account_name: {
        value: 'Foster Inc',
      },
      propensity_improve_from: {
        value: 0.33382118679299033,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 109047.03152309865,
      },
      revenue_past: {
        value: 1633315.0177001953,
      },
      revenue_forecast: {
        value: 2562959.5153063233,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 16,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.33396083366319845,
      },
      propensity_improve_by: {
        value: 0.00013964687020812105,
      },
      revenue_elift_improve_to: {
        value: 109092.64898915579,
      },
      revenue_elift_improve_by: {
        value: 45.61746605714916,
      },
    },
    {
      account_id: '0011U00000r47jAQAQ',
      segment: 'medium',
      account_name: {
        value: 'Mcdaniel-Beltran',
      },
      propensity_improve_from: {
        value: 0.3429445146080654,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 279053.4910397981,
      },
      revenue_past: {
        value: 4068493.2861328125,
      },
      revenue_forecast: {
        value: 3858930.7932225773,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 5,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.34326887911771303,
      },
      propensity_improve_by: {
        value: 0.0003243645096476233,
      },
      revenue_elift_improve_to: {
        value: 279317.42600575025,
      },
      revenue_elift_improve_by: {
        value: 263.93496595219403,
      },
    },
    {
      account_id: '0011U00000r480MQAQ',
      segment: 'high',
      account_name: {
        value: 'Lambert, Burton and Meyer',
      },
      propensity_improve_from: {
        value: 0.3250226710306447,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: -441216.9496673452,
      },
      revenue_past: {
        value: 6787479.5974731445,
      },
      revenue_forecast: {
        value: 5084962.522866642,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 3,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.32539029274314557,
      },
      propensity_improve_by: {
        value: 0.0003676217125008763,
      },
      revenue_elift_improve_to: {
        value: -441715.9946419828,
      },
      revenue_elift_improve_by: {
        value: -499.0449746375589,
      },
    },
    {
      account_id: '0011U0000077jnLQAQ',
      segment: 'medium',
      account_name: {
        value: 'Sanders, Huerta and Scott',
      },
      propensity_improve_from: {
        value: 0.3393003573845427,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 172781.03577402537,
      },
      revenue_past: {
        value: 2546136.9552612305,
      },
      revenue_forecast: {
        value: 3145902.4887286224,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 5,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33960552353708523,
      },
      propensity_improve_by: {
        value: 0.0003051661525425575,
      },
      revenue_elift_improve_to: {
        value: 172936.43473772204,
      },
      revenue_elift_improve_by: {
        value: 155.39896369667758,
      },
    },
    {
      account_id: '0011U00001EzvyaQAB',
      segment: 'medium',
      account_name: {
        value: 'Smith and Sons',
      },
      propensity_improve_from: {
        value: 0.33906557646674496,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: -501631.25003610033,
      },
      revenue_past: {
        value: 7397260.070800781,
      },
      revenue_forecast: {
        value: 5459500.867447835,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 4,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33943465171729403,
      },
      propensity_improve_by: {
        value: 0.00036907525054907797,
      },
      revenue_elift_improve_to: {
        value: -502177.2791589018,
      },
      revenue_elift_improve_by: {
        value: -546.0291228014739,
      },
    },
    {
      account_id: '0011U0000077jiYQAQ',
      segment: 'low',
      account_name: {
        value: 'Brooks-Hamilton',
      },
      propensity_improve_from: {
        value: 0.3407887547577961,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 150994.28908936022,
      },
      revenue_past: {
        value: 3999698.8220214844,
      },
      revenue_forecast: {
        value: 3666277.769410514,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 14,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.34096542432912236,
      },
      propensity_improve_by: {
        value: 0.00017666957132628225,
      },
      revenue_elift_improve_to: {
        value: 203966.45408912815,
      },
      revenue_elift_improve_by: {
        value: 52972.16499976795,
      },
    },
    {
      account_id: '0011U0000077jp4QAA',
      segment: 'low',
      account_name: {
        value: 'Martin Inc',
      },
      propensity_improve_from: {
        value: 0.332596981522532,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 182078.42389302768,
      },
      revenue_past: {
        value: 2737223.035812378,
      },
      revenue_forecast: {
        value: 3084517.6184717435,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 14,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3327701477658436,
      },
      propensity_improve_by: {
        value: 0.00017316624331165098,
      },
      revenue_elift_improve_to: {
        value: 182173.2228190712,
      },
      revenue_elift_improve_by: {
        value: 94.79892604352926,
      },
    },
    {
      account_id: '001EY000002jmXgYAI',
      segment: 'high',
      account_name: {
        value: 'Rice-Mcdonald',
      },
      propensity_improve_from: {
        value: 0.31651091040559226,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 188709.8721766477,
      },
      revenue_past: {
        value: 2981095.848083496,
      },
      revenue_forecast: {
        value: 3110097.818095093,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 3,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.31689153493629885,
      },
      propensity_improve_by: {
        value: 0.00038062453070658586,
      },
      revenue_elift_improve_to: {
        value: 188936.80781828132,
      },
      revenue_elift_improve_by: {
        value: 226.93564163362225,
      },
    },
    {
      account_id: '0011U00001CwhoJQAR',
      segment: 'medium',
      account_name: {
        value: 'King-Henderson',
      },
      propensity_improve_from: {
        value: 0.3287340328679246,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 130535.33069342222,
      },
      revenue_past: {
        value: 1985424.654006958,
      },
      revenue_forecast: {
        value: 2579929.9195955815,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 5,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.32907915069404375,
      },
      propensity_improve_by: {
        value: 0.00034511782611917896,
      },
      revenue_elift_improve_to: {
        value: 130672.37178152509,
      },
      revenue_elift_improve_by: {
        value: 137.0410881028647,
      },
    },
    {
      account_id: '0011U0000077jgRQAQ',
      segment: 'high',
      account_name: {
        value: 'Larsen-Adams',
      },
      propensity_improve_from: {
        value: 0.3223205058426781,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 101093.83761848169,
      },
      revenue_past: {
        value: 1568219.1450119019,
      },
      revenue_forecast: {
        value: 2253012.6613440453,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 2,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3227192985538972,
      },
      propensity_improve_by: {
        value: 0.0003987927112191203,
      },
      revenue_elift_improve_to: {
        value: 101218.9164914067,
      },
      revenue_elift_improve_by: {
        value: 125.07887292500754,
      },
    },
    {
      account_id: '0011U0000077rWmQAI',
      segment: 'medium',
      account_name: {
        value: 'Gutierrez Group',
      },
      propensity_improve_from: {
        value: 0.3376023509665542,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 142347.94960307088,
      },
      revenue_past: {
        value: 2108219.169616699,
      },
      revenue_forecast: {
        value: 2680959.602316951,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 8,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.337885049269256,
      },
      propensity_improve_by: {
        value: 0.0002826983027017782,
      },
      revenue_elift_improve_to: {
        value: 142467.14759926568,
      },
      revenue_elift_improve_by: {
        value: 119.19799619479363,
      },
    },
    {
      account_id: '0011U0000077iBPQAY',
      segment: 'high',
      account_name: {
        value: 'West-Martin',
      },
      propensity_improve_from: {
        value: 0.326225265322358,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 260542.2631023432,
      },
      revenue_past: {
        value: 3993287.626647949,
      },
      revenue_forecast: {
        value: 3812850.9749822207,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 3,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3265496475645578,
      },
      propensity_improve_by: {
        value: 0.0003243822421997722,
      },
      revenue_elift_improve_to: {
        value: 260801.3334211593,
      },
      revenue_elift_improve_by: {
        value: 259.0703188161342,
      },
    },
    {
      account_id: '001EY000002zZpyYAE',
      segment: 'medium',
      account_name: {
        value: 'Martin-Freeman',
      },
      propensity_improve_from: {
        value: 0.3273911929074553,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: -275455.29499350575,
      },
      revenue_past: {
        value: 4206822.006225586,
      },
      revenue_forecast: {
        value: 3306581.4424725925,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 4,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3277745362240491,
      },
      propensity_improve_by: {
        value: 0.0003833433165938427,
      },
      revenue_elift_improve_to: {
        value: -275777.8264135431,
      },
      revenue_elift_improve_by: {
        value: -322.5314200373077,
      },
    },
    {
      account_id: '001EY000002qEFzYAM',
      segment: 'high',
      account_name: {
        value: 'Mitchell, Vasquez and Adkins',
      },
      propensity_improve_from: {
        value: 0.3132029247566057,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: -278021.22388463246,
      },
      revenue_past: {
        value: 4438356.12487793,
      },
      revenue_forecast: {
        value: 3469998.147889836,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 1,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3136246259775534,
      },
      propensity_improve_by: {
        value: 0.00042170122094770113,
      },
      revenue_elift_improve_to: {
        value: -278395.5559240048,
      },
      revenue_elift_improve_by: {
        value: -374.33203937234794,
      },
    },
    {
      account_id: '0011U00000MXjFjQAL',
      segment: 'medium',
      account_name: {
        value: 'Blake Ltd',
      },
      propensity_improve_from: {
        value: 0.3429205845730212,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 284198.668182562,
      },
      revenue_past: {
        value: 4143797.149658203,
      },
      revenue_forecast: {
        value: 3854749.2214887636,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 4,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.343300246368207,
      },
      propensity_improve_by: {
        value: 0.0003796617951857817,
      },
      revenue_elift_improve_to: {
        value: 284513.316475507,
      },
      revenue_elift_improve_by: {
        value: 314.6482929449803,
      },
    },
    {
      account_id: '0011U00000MXjCZQA1',
      segment: 'medium',
      account_name: {
        value: 'Howell-Morrow',
      },
      propensity_improve_from: {
        value: 0.3397388104116463,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: -145284.39543802972,
      },
      revenue_past: {
        value: 5215068.374633789,
      },
      revenue_forecast: {
        value: 4359030.604935708,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 7,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3400037116669028,
      },
      propensity_improve_by: {
        value: 0.0002649012552565222,
      },
      revenue_elift_improve_to: {
        value: -56597.033237207106,
      },
      revenue_elift_improve_by: {
        value: 88687.36220082261,
      },
    },
    {
      account_id: '0011U0000077jIIQAY',
      segment: 'low',
      account_name: {
        value: 'Bell-Pierce',
      },
      propensity_improve_from: {
        value: 0.35288950182808343,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 266319.30399377533,
      },
      revenue_past: {
        value: 5178082.214355469,
      },
      revenue_forecast: {
        value: 4703753.026555145,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 11,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.35311262727416604,
      },
      propensity_improve_by: {
        value: 0.00022312544608260598,
      },
      revenue_elift_improve_to: {
        value: 337314.29764887877,
      },
      revenue_elift_improve_by: {
        value: 70994.99365510345,
      },
    },
    {
      account_id: '0011U00001BahqwQAB',
      segment: 'high',
      account_name: {
        value: 'Reed Group',
      },
      propensity_improve_from: {
        value: 0.32877015840600454,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 126922.2772864713,
      },
      revenue_past: {
        value: 3106849.411010742,
      },
      revenue_forecast: {
        value: 3005088.866004746,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 1,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.32919390751852234,
      },
      propensity_improve_by: {
        value: 0.0004237491125178017,
      },
      revenue_elift_improve_to: {
        value: 204551.17953644917,
      },
      revenue_elift_improve_by: {
        value: 77628.90224997787,
      },
    },
    {
      account_id: '0011U0000077izcQAA',
      segment: 'medium',
      account_name: {
        value: 'Thomas-White',
      },
      propensity_improve_from: {
        value: 0.33938516754118014,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 199034.05447985936,
      },
      revenue_past: {
        value: 2932273.910522461,
      },
      revenue_forecast: {
        value: 3148637.198961029,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 7,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3396739766796898,
      },
      propensity_improve_by: {
        value: 0.0002888091385096492,
      },
      revenue_elift_improve_to: {
        value: 199203.42798025382,
      },
      revenue_elift_improve_by: {
        value: 169.37350039446528,
      },
    },
    {
      account_id: '0011U00000P0gF4QAJ',
      segment: 'high',
      account_name: {
        value: 'Curry LLC',
      },
      propensity_improve_from: {
        value: 0.3268467301605494,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 83028.10009445179,
      },
      revenue_past: {
        value: 2685205.4191589355,
      },
      revenue_forecast: {
        value: 3017366.286021816,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 2,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.327230590300984,
      },
      propensity_improve_by: {
        value: 0.000383860140434622,
      },
      revenue_elift_improve_to: {
        value: 175736.2708781559,
      },
      revenue_elift_improve_by: {
        value: 92708.1707837041,
      },
    },
    {
      account_id: '0011U0000077jAKQAY',
      segment: 'medium',
      account_name: {
        value: 'White, Clark and Vargas',
      },
      propensity_improve_from: {
        value: 0.3382480463270814,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 182073.45240694282,
      },
      revenue_past: {
        value: 2691419.1284179688,
      },
      revenue_forecast: {
        value: 3140398.810112036,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 4,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33861885773585537,
      },
      propensity_improve_by: {
        value: 0.00037081140877398555,
      },
      revenue_elift_improve_to: {
        value: 182273.0541906648,
      },
      revenue_elift_improve_by: {
        value: 199.60178372200517,
      },
    },
    {
      account_id: '0011U00001gythlQAA',
      segment: 'low',
      account_name: {
        value: 'Goodman Ltd',
      },
      propensity_improve_from: {
        value: 0.3533963314472488,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 302686.0745027853,
      },
      revenue_past: {
        value: 6790684.982299805,
      },
      revenue_forecast: {
        value: 5974184.35045479,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 19,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.35348194050682147,
      },
      propensity_improve_by: {
        value: 0.00008560905957266662,
      },
      revenue_elift_improve_to: {
        value: 330819.78880735516,
      },
      revenue_elift_improve_by: {
        value: 28133.7143045699,
      },
    },
    {
      account_id: '0011U00000Q2TjkQAF',
      segment: 'low',
      account_name: {
        value: 'York-Alexander',
      },
      propensity_improve_from: {
        value: 0.34679585278262,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: -451187.98667843087,
      },
      revenue_past: {
        value: 7257599.670410156,
      },
      revenue_forecast: {
        value: 5598766.136981396,
      },
      'pendo:reports:numvisitors:value:p0d1': {
        value: 12,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3469908434912214,
      },
      propensity_improve_by: {
        value: 0.00019499070860140577,
      },
      revenue_elift_improve_to: {
        value: -387099.663420346,
      },
      revenue_elift_improve_by: {
        value: 64088.32325808489,
      },
    },
  ],
  accounts: {},
}

export const demoGetInsightDetailsSegmentOverallConversion = {
  grid: 'account',
  dimension: 'overall',
  train_datetime: '2024-04-16T19-24-41Z',
  infer_datetime: '2024-04-16T20-43-40Z',
  dataset_date: '2024-04-07',
  is_revenue_quantified: true,
  driver_id: '2-001',
  driver_type: 'insight',
  event: {
    short_name: 'conversion',
    full_name: 'conversion in next 180 days',
    impact: 'positive',
  },
  family: {
    platform: 'snowflake',
    object: 'Product Telemetry',
    field: 'Jobs exceed quota',
    std_platform: 'pendo',
    std_object: 'reports',
    std_field: 'numvisitors',
  },
  metric_value: 24.239999771118164,
  aggregations: [
    {
      key: 'improve_from',
      title: 'If no impact is made to the segment',
      sources: [
        {
          key: 'num_accounts',
          title: '# of Accounts',
          column: 'account_name',
          aggregator: 'count',
          type: 'number',
          format: 'comma',
          decimal: 0,
        },
        {
          key: 'revenue_elift_improve_from',
          title: 'Trial Conversion',
          column: 'revenue_elift_improve_from',
          aggregator: 'sum',
          type: 'number',
          format: 'currency-kmb',
          decimal: 1,
        },
        {
          key: 'propensity_improve_from',
          title: 'Conversion %',
          column: 'propensity_improve_from',
          aggregator: 'mean',
          type: 'number',
          format: 'percentage',
          decimal: 1,
        },
      ],
    },
    {
      key: 'improve_by',
      title: 'If segment reaches target',
      sources: [
        {
          key: 'revenue_elift_improve_by',
          title: 'Conversion Revenue',
          column: 'revenue_elift_improve_by',
          aggregator: 'sum',
          type: 'number',
          format: 'currency-kmb',
          decimal: 1,
        },
        {
          key: 'propensity_improve_by',
          title: 'Increase in Conversion %',
          column: 'propensity_improve_by',
          aggregator: 'mean',
          type: 'number',
          format: 'percentage',
          decimal: 1,
        },
      ],
    },
  ],
  predefined_segments: {
    high: {
      title: 'HIGH IMPACT SEGMENT',
      driver: {
        title: '30-day total',
        column: 'pendo:reports:numvisitors:value:p0d1',
        min_metric: {
          aggregator: 'min',
          type: 'number',
          format: 'kmb',
          decimal: 2,
        },
        max_metric: {
          aggregator: 'max',
          type: 'number',
          format: 'kmb',
          decimal: 2,
        },
      },
      num_accounts: {
        title: 'Accounts',
        column: 'account_name',
        aggregator: 'count',
        type: 'number',
        format: 'comma',
        decimal: 0,
      },
      revenue_elift_improve_from: {
        title: 'Trial Conversion',
        column: 'revenue_elift_improve_from',
        aggregator: 'sum',
        type: 'number',
        format: 'currency-kmb',
        decimal: 1,
      },
    },
    medium: {
      title: 'MEDIUM IMPACT SEGMENT',
      driver: {
        title: '30-day total',
        column: 'pendo:reports:numvisitors:value:p0d1',
        min_metric: {
          aggregator: 'min',
          type: 'number',
          format: 'kmb',
          decimal: 2,
        },
        max_metric: {
          aggregator: 'max',
          type: 'number',
          format: 'kmb',
          decimal: 2,
        },
      },
      num_accounts: {
        title: 'Accounts',
        column: 'account_name',
        aggregator: 'count',
        type: 'number',
        format: 'comma',
        decimal: 0,
      },
      revenue_elift_improve_from: {
        title: 'Trial Conversion',
        column: 'revenue_elift_improve_from',
        aggregator: 'sum',
        type: 'number',
        format: 'currency-kmb',
        decimal: 1,
      },
    },
    low: {
      title: 'LOW IMPACT SEGMENT',
      driver: {
        title: '30-day total',
        column: 'pendo:reports:numvisitors:value:p0d1',
        min_metric: {
          aggregator: 'min',
          type: 'number',
          format: 'kmb',
          decimal: 2,
        },
        max_metric: {
          aggregator: 'max',
          type: 'number',
          format: 'kmb',
          decimal: 2,
        },
      },
      num_accounts: {
        title: 'Accounts',
        column: 'account_name',
        aggregator: 'count',
        type: 'number',
        format: 'comma',
        decimal: 0,
      },
      revenue_elift_improve_from: {
        title: 'Trial Conversion',
        column: 'revenue_elift_improve_from',
        aggregator: 'sum',
        type: 'number',
        format: 'currency-kmb',
        decimal: 1,
      },
    },
  },
}

export const demoGetInsightDetailsAccountsOverallExpansion = {
  grid: 'account',
  dimension: 'overall',
  train_datetime: '2024-04-16T19-24-41Z',
  infer_datetime: '2024-04-16T20-43-40Z',
  dataset_date: '2024-04-07',
  is_revenue_quantified: true,
  driver_id: '2-001',
  driver_type: 'insight',
  event: {
    short_name: 'expansion',
    full_name: 'expansion in next 180 days',
    impact: 'positive',
  },
  table_columns: [
    {
      key: 'account_name',
      title: 'Account Name',
      type: 'string',
      format: 'text',
      nullable: false,
    },
    {
      key: 'revenue_elift_improve_by',
      title: 'Projected ARR Increase',
      type: 'number',
      format: 'currency-kmb',
      decimal: 1,
      nullable: false,
    },
    {
      key: 'propensity_improve_by',
      title: 'Increased Expansion %',
      type: 'number',
      format: 'percentage',
      decimal: 0,
      nullable: false,
    },
    {
      key: 'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1',
      title: '60-day total',
      type: 'number',
      format: 'comma',
      decimal: 2,
      nullable: false,
    },
    {
      key: 'propensity_improve_from',
      title: 'Expansion %',
      type: 'number',
      format: 'percentage',
      decimal: 0,
      nullable: false,
    },
    {
      key: 'revenue_past',
      title: 'Current ARR',
      type: 'number',
      format: 'currency-kmb',
      decimal: 1,
      nullable: false,
    },
    {
      key: 'revenue_forecast',
      title: 'Forecasted ARR',
      type: 'number',
      format: 'currency-kmb',
      decimal: 1,
      nullable: false,
    },
  ],
  table_rows: [
    {
      account_id: '0011U00001oQbCDQA0',
      segment: 'medium',
      account_name: {
        value: 'Little, Patel and Weaver',
      },
      propensity_improve_from: {
        value: 0.36166282282964585,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 275843.50522933993,
      },
      revenue_past: {
        value: 3086301.414012909,
      },
      revenue_forecast: {
        value: 3006255.9521382586,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 1.6666666666666667,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.36208260976233647,
      },
      propensity_improve_by: {
        value: 0.0004197869326906245,
      },
      revenue_elift_improve_to: {
        value: 303412.081258741,
      },
      revenue_elift_improve_by: {
        value: 27568.57602940106,
      },
    },
    {
      account_id: '001EY000002hXcAYAU',
      segment: 'low',
      account_name: {
        value: 'Stanley-Jordan',
      },
      propensity_improve_from: {
        value: 0.3386096501485035,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 1033717.9371676202,
      },
      revenue_past: {
        value: 1923287.7502441406,
      },
      revenue_forecast: {
        value: 2967631.0963135273,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 3,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3389604658464198,
      },
      propensity_improve_by: {
        value: 0.00035081569791628464,
      },
      revenue_elift_improve_to: {
        value: 1056707.8304456905,
      },
      revenue_elift_improve_by: {
        value: 22989.893278070213,
      },
    },
    {
      account_id: '0011U0000077jpCQAQ',
      segment: 'low',
      account_name: {
        value: 'Montoya, Lewis and Blackwell',
      },
      propensity_improve_from: {
        value: 0.3410935182914225,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 758935.3418633643,
      },
      revenue_past: {
        value: 3328767.1966552734,
      },
      revenue_forecast: {
        value: 3789037.050168731,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 7,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.34127274375724037,
      },
      propensity_improve_by: {
        value: 0.00017922546581788934,
      },
      revenue_elift_improve_to: {
        value: 770560.779259406,
      },
      revenue_elift_improve_by: {
        value: 11625.437396041707,
      },
    },
    {
      account_id: '0011U00001EzvyaQAB',
      segment: 'medium',
      account_name: {
        value: 'Smith and Sons',
      },
      propensity_improve_from: {
        value: 0.32639253709190363,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 482882.09640746034,
      },
      revenue_past: {
        value: 7397260.070800781,
      },
      revenue_forecast: {
        value: 5459500.867447835,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3268264372924139,
      },
      propensity_improve_by: {
        value: 0.0004339002005102621,
      },
      revenue_elift_improve_to: {
        value: 483524.03093304974,
      },
      revenue_elift_improve_by: {
        value: 641.9345255893859,
      },
    },
    {
      account_id: '0011U0000077jiYQAQ',
      segment: 'medium',
      account_name: {
        value: 'Brooks-Hamilton',
      },
      propensity_improve_from: {
        value: 0.33621003692826623,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 268947.77773075725,
      },
      revenue_past: {
        value: 3999698.8220214844,
      },
      revenue_forecast: {
        value: 3666277.769410514,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33664763474700804,
      },
      propensity_improve_by: {
        value: 0.00043759781874180836,
      },
      revenue_elift_improve_to: {
        value: 269297.8296267854,
      },
      revenue_elift_improve_by: {
        value: 350.0518960281738,
      },
    },
    {
      account_id: '0011U0000077rWmQAI',
      segment: 'medium',
      account_name: {
        value: 'Gutierrez Group',
      },
      propensity_improve_from: {
        value: 0.33530781209383526,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 575412.6670123151,
      },
      revenue_past: {
        value: 2108219.169616699,
      },
      revenue_forecast: {
        value: 2680959.602316951,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 0.5,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3357680017977899,
      },
      propensity_improve_by: {
        value: 0.00046018970395461656,
      },
      revenue_elift_improve_to: {
        value: 604581.6688441349,
      },
      revenue_elift_improve_by: {
        value: 29169.001831819856,
      },
    },
    {
      account_id: '0011U0000077jIIQAY',
      segment: 'low',
      account_name: {
        value: 'Bell-Pierce',
      },
      propensity_improve_from: {
        value: 0.3367097170204035,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 348702.1194208028,
      },
      revenue_past: {
        value: 5178082.214355469,
      },
      revenue_forecast: {
        value: 4703753.026555145,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 5,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.336971714900627,
      },
      propensity_improve_by: {
        value: 0.000261997880223519,
      },
      revenue_elift_improve_to: {
        value: 348973.4487335597,
      },
      revenue_elift_improve_by: {
        value: 271.3293127568704,
      },
    },
    {
      account_id: '0011U0000077izcQAA',
      segment: 'high',
      account_name: {
        value: 'Thomas-White',
      },
      propensity_improve_from: {
        value: 0.34153989327051726,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 421928.75186839944,
      },
      revenue_past: {
        value: 2932273.910522461,
      },
      revenue_forecast: {
        value: 3148637.198961029,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: -2.8,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.342145329943681,
      },
      propensity_improve_by: {
        value: 0.0006054366731637617,
      },
      revenue_elift_improve_to: {
        value: 460562.32434524357,
      },
      revenue_elift_improve_by: {
        value: 38633.57247684412,
      },
    },
    {
      account_id: '0011U0000077jnvQAA',
      segment: 'medium',
      account_name: {
        value: 'Ballard, Woods and Harrell',
      },
      propensity_improve_from: {
        value: 0.34057580187084524,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 329720.8587514084,
      },
      revenue_past: {
        value: 3254794.464111328,
      },
      revenue_forecast: {
        value: 3300617.7954267687,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 2,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.34097183337009906,
      },
      propensity_improve_by: {
        value: 0.00039603149925382386,
      },
      revenue_elift_improve_to: {
        value: 354861.29967090924,
      },
      revenue_elift_improve_by: {
        value: 25140.440919500816,
      },
    },
    {
      account_id: '0011U0000077iBPQAY',
      segment: 'medium',
      account_name: {
        value: 'West-Martin',
      },
      propensity_improve_from: {
        value: 0.36050155887167323,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 287917.28288590984,
      },
      revenue_past: {
        value: 3993287.626647949,
      },
      revenue_forecast: {
        value: 3812850.9749822207,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3609545338634196,
      },
      propensity_improve_by: {
        value: 0.0004529749917463466,
      },
      revenue_elift_improve_to: {
        value: 288279.0547718542,
      },
      revenue_elift_improve_by: {
        value: 361.77188594432437,
      },
    },
    {
      account_id: '0011U00001acbDrQAI',
      segment: 'medium',
      account_name: {
        value: 'Williams, Bowen and Erickson',
      },
      propensity_improve_from: {
        value: 0.3284239000131913,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 406958.240197431,
      },
      revenue_past: {
        value: 6195624.620819092,
      },
      revenue_forecast: {
        value: 4866740.16115841,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.32885466054631834,
      },
      propensity_improve_by: {
        value: 0.000430760533127017,
      },
      revenue_elift_improve_to: {
        value: 407492.00631037477,
      },
      revenue_elift_improve_by: {
        value: 533.7661129437947,
      },
    },
    {
      account_id: '0011U00000r47mYQAQ',
      segment: 'medium',
      account_name: {
        value: 'Lindsey, Williams and Adams',
      },
      propensity_improve_from: {
        value: 0.33474143186675576,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 544757.307256152,
      },
      revenue_past: {
        value: 8136986.572265625,
      },
      revenue_forecast: {
        value: 6035489.685045829,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3351774368671877,
      },
      propensity_improve_by: {
        value: 0.00043600500043194934,
      },
      revenue_elift_improve_to: {
        value: 545466.8606229431,
      },
      revenue_elift_improve_by: {
        value: 709.5533667910786,
      },
    },
    {
      account_id: '0011U00001NRkjDQAT',
      segment: 'low',
      account_name: {
        value: 'Floyd, Boyer and Poole',
      },
      propensity_improve_from: {
        value: 0.34185339814997623,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 390219.48500045773,
      },
      revenue_past: {
        value: 3698630.0354003906,
      },
      revenue_forecast: {
        value: 3428835.3514289046,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 16.749999999999865,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.34185339814997623,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 390219.48500045773,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '001PH000009102SYAQ',
      segment: 'medium',
      account_name: {
        value: 'Small PLC',
      },
      propensity_improve_from: {
        value: 0.3605213308728582,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 1118351.0814264729,
      },
      revenue_past: {
        value: 934931.510925293,
      },
      revenue_forecast: {
        value: 2053697.823891351,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 2,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.36093184796337807,
      },
      propensity_improve_by: {
        value: 0.0004105170905198685,
      },
      revenue_elift_improve_to: {
        value: 1145830.7951948936,
      },
      revenue_elift_improve_by: {
        value: 27479.71376842074,
      },
    },
    {
      account_id: '0011U0000077iaWQAQ',
      segment: 'medium',
      account_name: {
        value: 'Taylor, Guzman and Henderson',
      },
      propensity_improve_from: {
        value: 0.33834850050427034,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 477869.534171651,
      },
      revenue_past: {
        value: 3106849.411010742,
      },
      revenue_forecast: {
        value: 3339867.960444739,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33878568755035604,
      },
      propensity_improve_by: {
        value: 0.00043718704608569636,
      },
      revenue_elift_improve_to: {
        value: 505775.9925213996,
      },
      revenue_elift_improve_by: {
        value: 27906.45834974855,
      },
    },
    {
      account_id: '001EY000003LXW5YAO',
      segment: 'medium',
      account_name: {
        value: 'Jones-Burns',
      },
      propensity_improve_from: {
        value: 0.33636859483448767,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 283655.4878748398,
      },
      revenue_past: {
        value: 4216438.339233398,
      },
      revenue_forecast: {
        value: 3372017.093201548,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3368104910981469,
      },
      propensity_improve_by: {
        value: 0.00044189626365925827,
      },
      revenue_elift_improve_to: {
        value: 284028.1335444512,
      },
      revenue_elift_improve_by: {
        value: 372.64566961138144,
      },
    },
    {
      account_id: '0011U000025fC2UQAU',
      segment: 'high',
      account_name: {
        value: 'Crawford LLC',
      },
      propensity_improve_from: {
        value: 0.34666014271568724,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 373450.2988480873,
      },
      revenue_past: {
        value: 3698630.0354003906,
      },
      revenue_forecast: {
        value: 3178919.780970727,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: -4.5,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.34734446377986894,
      },
      propensity_improve_by: {
        value: 0.0006843210641817032,
      },
      revenue_elift_improve_to: {
        value: 417338.5695386306,
      },
      revenue_elift_improve_by: {
        value: 43888.2706905433,
      },
    },
    {
      account_id: '0011U00001gyszsQAA',
      segment: 'medium',
      account_name: {
        value: 'Smith and Sons',
      },
      propensity_improve_from: {
        value: 0.3568975615967465,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 468981.00119992194,
      },
      revenue_past: {
        value: 6570246.643066406,
      },
      revenue_forecast: {
        value: 5767413.708536009,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 1.5,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.35731779257917173,
      },
      propensity_improve_by: {
        value: 0.00042023098242521817,
      },
      revenue_elift_improve_to: {
        value: 469533.2054402403,
      },
      revenue_elift_improve_by: {
        value: 552.2042403183377,
      },
    },
    {
      account_id: '0011U0000077japQAA',
      segment: 'medium',
      account_name: {
        value: 'Kennedy, Berry and Simon',
      },
      propensity_improve_from: {
        value: 0.34059553769512185,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 598069.6349000223,
      },
      revenue_past: {
        value: 8779763.21926117,
      },
      revenue_forecast: {
        value: 6538100.261746741,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 1.25,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.34102215178498174,
      },
      propensity_improve_by: {
        value: 0.00042661408985988913,
      },
      revenue_elift_improve_to: {
        value: 598818.7490390163,
      },
      revenue_elift_improve_by: {
        value: 749.1141389940822,
      },
    },
    {
      account_id: '001EY000002tzP1YAI',
      segment: 'high',
      account_name: {
        value: 'Jimenez Inc',
      },
      propensity_improve_from: {
        value: 0.3505933950047522,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 259343.05219551394,
      },
      revenue_past: {
        value: 3698630.0354003906,
      },
      revenue_forecast: {
        value: 3559459.35749032,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: -1.5,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.35114971377964976,
      },
      propensity_improve_by: {
        value: 0.0005563187748975906,
      },
      revenue_elift_improve_to: {
        value: 259754.5756615326,
      },
      revenue_elift_improve_by: {
        value: 411.5234660186843,
      },
    },
    {
      account_id: '0011U0000077j4OQAQ',
      segment: 'high',
      account_name: {
        value: 'Robinson PLC',
      },
      propensity_improve_from: {
        value: 0.33265838587708463,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 239673.00956669755,
      },
      revenue_past: {
        value: 3602389.414215088,
      },
      revenue_forecast: {
        value: 3264693.900806057,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 0,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.33314107897267353,
      },
      propensity_improve_by: {
        value: 0.00048269309558890416,
      },
      revenue_elift_improve_to: {
        value: 240020.77926627037,
      },
      revenue_elift_improve_by: {
        value: 347.7696995728138,
      },
    },
    {
      account_id: '0011U00000Q2TjkQAF',
      segment: 'medium',
      account_name: {
        value: 'York-Alexander',
      },
      propensity_improve_from: {
        value: 0.34777462384070645,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 504801.79907266545,
      },
      revenue_past: {
        value: 7257599.670410156,
      },
      revenue_forecast: {
        value: 5598766.136981396,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 1.3333333333333335,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3481996821562477,
      },
      propensity_improve_by: {
        value: 0.00042505831554123796,
      },
      revenue_elift_improve_to: {
        value: 505418.7796908209,
      },
      revenue_elift_improve_by: {
        value: 616.980618155435,
      },
    },
    {
      account_id: '0011U00001gythlQAA',
      segment: 'high',
      account_name: {
        value: 'Goodman Ltd',
      },
      propensity_improve_from: {
        value: 0.3417804157734973,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 464184.6273274543,
      },
      revenue_past: {
        value: 6790684.982299805,
      },
      revenue_forecast: {
        value: 5974184.35045479,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: -3.083333333333334,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.342389806341792,
      },
      propensity_improve_by: {
        value: 0.0006093905682946765,
      },
      revenue_elift_improve_to: {
        value: 465012.2632035491,
      },
      revenue_elift_improve_by: {
        value: 827.6358760947915,
      },
    },
    {
      account_id: '0011U00001zgB2FQAU',
      segment: 'high',
      account_name: {
        value: 'Hernandez-Anderson',
      },
      propensity_improve_from: {
        value: 0.3317174689103985,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 534086.3316438957,
      },
      revenue_past: {
        value: 2219178.062438965,
      },
      revenue_forecast: {
        value: 2711151.5136172106,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: -3.666666666666667,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3323602990790429,
      },
      propensity_improve_by: {
        value: 0.0006428301686444149,
      },
      revenue_elift_improve_to: {
        value: 574211.1746939815,
      },
      revenue_elift_improve_by: {
        value: 40124.84305008575,
      },
    },
    {
      account_id: '0011U0000077iIGQAY',
      segment: 'low',
      account_name: {
        value: 'Munoz-Schultz',
      },
      propensity_improve_from: {
        value: 0.34259431747517793,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 420889.7937952054,
      },
      revenue_past: {
        value: 6142684.982299805,
      },
      revenue_forecast: {
        value: 4899065.281663223,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 6.666666666666666,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3427869087706532,
      },
      propensity_improve_by: {
        value: 0.0001925912954752751,
      },
      revenue_elift_improve_to: {
        value: 421126.3993268929,
      },
      revenue_elift_improve_by: {
        value: 236.60553168751903,
      },
    },
    {
      account_id: '001EY000003EFkLYAW',
      segment: 'low',
      account_name: {
        value: 'Garcia-Wilkerson',
      },
      propensity_improve_from: {
        value: 0.3768680651144338,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 607738.4539003905,
      },
      revenue_past: {
        value: 8063013.427734375,
      },
      revenue_forecast: {
        value: 7075846.866490048,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 13.333333333333334,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3768680651144338,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 607738.4539003905,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '001EY0000034teOYAQ',
      segment: 'low',
      account_name: {
        value: 'Dixon Group',
      },
      propensity_improve_from: {
        value: 0.3585659375051586,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 636577.329959447,
      },
      revenue_past: {
        value: 8876712.24975586,
      },
      revenue_forecast: {
        value: 6794132.05987165,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 16.749999999999865,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3585659375051586,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 636577.329959447,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U0000077ifVQAQ',
      segment: 'high',
      account_name: {
        value: 'Andrade-Sanchez',
      },
      propensity_improve_from: {
        value: 0.33988403456306654,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 471842.51217072655,
      },
      revenue_past: {
        value: 3254794.464111328,
      },
      revenue_forecast: {
        value: 3447499.578063354,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: -7,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.340666554836652,
      },
      propensity_improve_by: {
        value: 0.0007825202735854409,
      },
      revenue_elift_improve_to: {
        value: 522014.37592978025,
      },
      revenue_elift_improve_by: {
        value: 50171.86375905371,
      },
    },
    {
      account_id: '0011U00001txFQWQA2',
      segment: 'high',
      account_name: {
        value: 'Aguilar, Stephens and Roberts',
      },
      propensity_improve_from: {
        value: 0.34789765535041506,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 348353.67472782155,
      },
      revenue_past: {
        value: 2756383.594894409,
      },
      revenue_forecast: {
        value: 2947819.428989869,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: -1.3333333333333335,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3484435710542273,
      },
      propensity_improve_by: {
        value: 0.0005459157038122386,
      },
      revenue_elift_improve_to: {
        value: 383424.4936613064,
      },
      revenue_elift_improve_by: {
        value: 35070.81893348489,
      },
    },
    {
      account_id: '0011U0000077istQAA',
      segment: 'medium',
      account_name: {
        value: 'Dunn, Chambers and Rogers',
      },
      propensity_improve_from: {
        value: 0.33880475284615746,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 761892.9863546848,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8238968.526091962,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 2,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3391964194332017,
      },
      propensity_improve_by: {
        value: 0.0003916665870442593,
      },
      revenue_elift_improve_to: {
        value: 762773.7532953836,
      },
      revenue_elift_improve_by: {
        value: 880.766940698777,
      },
    },
    {
      account_id: '0011U0000077jptQAA',
      segment: 'medium',
      account_name: {
        value: 'Coleman LLC',
      },
      propensity_improve_from: {
        value: 0.3432056748030901,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 771789.6349238502,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8342866.988306462,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 2.7222222222222223,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.34356543851566734,
      },
      propensity_improve_by: {
        value: 0.0003597637125772324,
      },
      revenue_elift_improve_to: {
        value: 772598.6597295972,
      },
      revenue_elift_improve_by: {
        value: 809.024805746958,
      },
    },
    {
      account_id: '0011U00001tE45RQAS',
      segment: 'medium',
      account_name: {
        value: 'Guerrero-Morton',
      },
      propensity_improve_from: {
        value: 0.3338316781720669,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 380120.64848099434,
      },
      revenue_past: {
        value: 2915753.442764282,
      },
      revenue_forecast: {
        value: 2957749.6262981384,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33427037949008126,
      },
      propensity_improve_by: {
        value: 0.0004387013180143784,
      },
      revenue_elift_improve_to: {
        value: 407545.467782413,
      },
      revenue_elift_improve_by: {
        value: 27424.819301418644,
      },
    },
    {
      account_id: '0011U00001EznBSQAZ',
      segment: 'low',
      account_name: {
        value: 'Williams, Pierce and Thomas',
      },
      propensity_improve_from: {
        value: 0.36605556701821595,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 541561.6459196376,
      },
      revenue_past: {
        value: 7397260.070800781,
      },
      revenue_forecast: {
        value: 5779260.749282633,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 16.749999999999865,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.36605556701821595,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 541561.6459196376,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U00001dtB9GQAU',
      segment: 'medium',
      account_name: {
        value: 'Wilson LLC',
      },
      propensity_improve_from: {
        value: 0.34047614179700403,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 640897.8093728307,
      },
      revenue_past: {
        value: 2432219.1009521484,
      },
      revenue_forecast: {
        value: 2989730.5035889507,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.34091675868992166,
      },
      propensity_improve_by: {
        value: 0.0004406168929176335,
      },
      revenue_elift_improve_to: {
        value: 669187.8577451184,
      },
      revenue_elift_improve_by: {
        value: 28290.048372287813,
      },
    },
    {
      account_id: '0011U0000077jaqQAA',
      segment: 'medium',
      account_name: {
        value: 'Walls-Anderson',
      },
      propensity_improve_from: {
        value: 0.33106330141211326,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 244896.13404433092,
      },
      revenue_past: {
        value: 3698630.0354003906,
      },
      revenue_forecast: {
        value: 3019405.52954246,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 2,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3314569204576254,
      },
      propensity_improve_by: {
        value: 0.0003936190455121613,
      },
      revenue_elift_improve_to: {
        value: 245187.3042891783,
      },
      revenue_elift_improve_by: {
        value: 291.17024484739886,
      },
    },
    {
      account_id: '0011U0000077jiZQAQ',
      segment: 'high',
      account_name: {
        value: 'Cruz, Garcia and Gilmore',
      },
      propensity_improve_from: {
        value: 0.35996235906883556,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 684578.5847091568,
      },
      revenue_past: {
        value: 9509030.145263672,
      },
      revenue_forecast: {
        value: 7346663.897313924,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: -5.683333333333334,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3606940333532972,
      },
      propensity_improve_by: {
        value: 0.0007316742844616364,
      },
      revenue_elift_improve_to: {
        value: 685970.0872746487,
      },
      revenue_elift_improve_by: {
        value: 1391.5025654919646,
      },
    },
    {
      account_id: '0011U0000077iz4QAA',
      segment: 'low',
      account_name: {
        value: 'Lee-Cherry',
      },
      propensity_improve_from: {
        value: 0.3368455656383427,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 340400.80672506423,
      },
      revenue_past: {
        value: 5052772.567749023,
      },
      revenue_forecast: {
        value: 4440161.889289918,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 9.5,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3369162128137256,
      },
      propensity_improve_by: {
        value: 0.00007064717538285414,
      },
      revenue_elift_improve_to: {
        value: 340472.19954701693,
      },
      revenue_elift_improve_by: {
        value: 71.39282195266787,
      },
    },
    {
      account_id: '001EY000002RpVDYA0',
      segment: 'medium',
      account_name: {
        value: 'Bradley, Jackson and Martin',
      },
      propensity_improve_from: {
        value: 0.3334625150667572,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 222003.81629367726,
      },
      revenue_past: {
        value: 3328767.1966552734,
      },
      revenue_forecast: {
        value: 3109071.293812582,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.333906277660986,
      },
      propensity_improve_by: {
        value: 0.0004437625942287915,
      },
      revenue_elift_improve_to: {
        value: 235170.98533083085,
      },
      revenue_elift_improve_by: {
        value: 13167.169037153577,
      },
    },
    {
      account_id: '001EY000003XLOiYAO',
      segment: 'high',
      account_name: {
        value: 'Cummings-Smith',
      },
      propensity_improve_from: {
        value: 0.3594385044205169,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 379706.49994798354,
      },
      revenue_past: {
        value: 5281939.682006836,
      },
      revenue_forecast: {
        value: 4739622.321265863,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: -25.9,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.36107094379011606,
      },
      propensity_improve_by: {
        value: 0.0016324393695991701,
      },
      revenue_elift_improve_to: {
        value: 381430.9892049348,
      },
      revenue_elift_improve_by: {
        value: 1724.489256951233,
      },
    },
    {
      account_id: '0011U00000MXjCZQA1',
      segment: 'low',
      account_name: {
        value: 'Howell-Morrow',
      },
      propensity_improve_from: {
        value: 0.3556063082449816,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 370902.24238973565,
      },
      revenue_past: {
        value: 5215068.374633789,
      },
      revenue_forecast: {
        value: 4359030.604935708,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 16.749999999999865,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3556063082449816,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 370902.24238973565,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '001EY000003mI79YAE',
      segment: 'low',
      account_name: {
        value: 'Bray, Burgess and Morgan',
      },
      propensity_improve_from: {
        value: 0.3585722893625278,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 741122.289323294,
      },
      revenue_past: {
        value: 2344520.558166504,
      },
      revenue_forecast: {
        value: 3025299.017904291,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 6,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3588017153463507,
      },
      propensity_improve_by: {
        value: 0.00022942598382291735,
      },
      revenue_elift_improve_to: {
        value: 756249.4454521829,
      },
      revenue_elift_improve_by: {
        value: 15127.156128889055,
      },
    },
    {
      account_id: '0011U0000077iszQAA',
      segment: 'high',
      account_name: {
        value: 'Johnson, Ford and Baxter',
      },
      propensity_improve_from: {
        value: 0.33040590285481436,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 708551.1378373846,
      },
      revenue_past: {
        value: 5178082.214355469,
      },
      revenue_forecast: {
        value: 4710510.717283478,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: -3,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3310136784704082,
      },
      propensity_improve_by: {
        value: 0.0006077756155938308,
      },
      revenue_elift_improve_to: {
        value: 747033.3268843996,
      },
      revenue_elift_improve_by: {
        value: 38482.18904701512,
      },
    },
    {
      account_id: '0011U0000077jgLQAQ',
      segment: 'high',
      account_name: {
        value: 'Mills Inc',
      },
      propensity_improve_from: {
        value: 0.34620057469801735,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 622960497.1037911,
      },
      revenue_past: {
        value: 6687123.321533203,
      },
      revenue_forecast: {
        value: 628012558.6042235,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: -20.666666666666664,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.34757781639347923,
      },
      propensity_improve_by: {
        value: 0.0013772416954618838,
      },
      revenue_elift_improve_to: {
        value: 625526542.8475269,
      },
      revenue_elift_improve_by: {
        value: 2566045.7437357865,
      },
    },
    {
      account_id: '0011U000014ifycQAA',
      segment: 'high',
      account_name: {
        value: 'Cunningham Group',
      },
      propensity_improve_from: {
        value: 0.33534802351765447,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 361977.41362711735,
      },
      revenue_past: {
        value: 5397041.107177734,
      },
      revenue_forecast: {
        value: 4473159.474193479,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: -1.1666666666666679,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3358786905332156,
      },
      propensity_improve_by: {
        value: 0.0005306670155611481,
      },
      revenue_elift_improve_to: {
        value: 362550.21996655874,
      },
      revenue_elift_improve_by: {
        value: 572.8063394413994,
      },
    },
    {
      account_id: '0011U0000077jppQAA',
      segment: 'high',
      account_name: {
        value: 'Conley, Price and Fleming',
      },
      propensity_improve_from: {
        value: 0.3382082130777542,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 277661.3557524663,
      },
      revenue_past: {
        value: 4104887.8326416016,
      },
      revenue_forecast: {
        value: 3712900.0228230883,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 0,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3386943383339104,
      },
      propensity_improve_by: {
        value: 0.00048612525615615576,
      },
      revenue_elift_improve_to: {
        value: 278060.45368229336,
      },
      revenue_elift_improve_by: {
        value: 399.09792982704175,
      },
    },
    {
      account_id: '0011U000024CdohQAC',
      segment: 'medium',
      account_name: {
        value: 'Mack, Velez and Martinez',
      },
      propensity_improve_from: {
        value: 0.3570760467700169,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 814203.2195528637,
      },
      revenue_past: {
        value: 1568219.200515747,
      },
      revenue_forecast: {
        value: 2381304.9480890455,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3575308643903919,
      },
      propensity_improve_by: {
        value: 0.00045481762037496987,
      },
      revenue_elift_improve_to: {
        value: 844039.1992564148,
      },
      revenue_elift_improve_by: {
        value: 29835.979703551086,
      },
    },
    {
      account_id: '0011U00001BaloPQAR',
      segment: 'medium',
      account_name: {
        value: 'Carr-Smith',
      },
      propensity_improve_from: {
        value: 0.32945314351709454,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 452816.98817638285,
      },
      revenue_past: {
        value: 6872251.746368408,
      },
      revenue_forecast: {
        value: 5128349.741434431,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3298879858002427,
      },
      propensity_improve_by: {
        value: 0.0004348422831481624,
      },
      revenue_elift_improve_to: {
        value: 453414.65730433486,
      },
      revenue_elift_improve_by: {
        value: 597.6691279520082,
      },
    },
    {
      account_id: '0011U0000077iRhQAI',
      segment: 'low',
      account_name: {
        value: 'Cummings PLC',
      },
      propensity_improve_from: {
        value: 0.36517874102098896,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 630488.5983452182,
      },
      revenue_past: {
        value: 8632602.716445923,
      },
      revenue_forecast: {
        value: 7211324.036753737,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 16.749999999999865,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.36517874102098896,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 630488.5983452182,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U00001LHG2kQAH',
      segment: 'medium',
      account_name: {
        value: 'Smith, Taylor and Simpson',
      },
      propensity_improve_from: {
        value: 0.3394795493423875,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 311723.44568531116,
      },
      revenue_past: {
        value: 2958904.151916504,
      },
      revenue_forecast: {
        value: 3045046.8178951326,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.33992148409170936,
      },
      propensity_improve_by: {
        value: 0.0004419347493218595,
      },
      revenue_elift_improve_to: {
        value: 339509.7285890143,
      },
      revenue_elift_improve_by: {
        value: 27786.282903703137,
      },
    },
    {
      account_id: '0011U00000NhygKQAR',
      segment: 'medium',
      account_name: {
        value: 'Ramirez and Sons',
      },
      propensity_improve_from: {
        value: 0.34953020659753886,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 952615.2963464267,
      },
      revenue_past: {
        value: 1849315.0177001953,
      },
      revenue_forecast: {
        value: 2816350.546218188,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.34997124132216184,
      },
      propensity_improve_by: {
        value: 0.00044103472462297955,
      },
      revenue_elift_improve_to: {
        value: 982007.2828086452,
      },
      revenue_elift_improve_by: {
        value: 29391.986462218483,
      },
    },
    {
      account_id: '0011U00000r47i7QAA',
      segment: 'high',
      account_name: {
        value: 'Green, Green and Myers',
      },
      propensity_improve_from: {
        value: 0.3300956506214795,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 632075.0030972287,
      },
      revenue_past: {
        value: 2515068.5806274414,
      },
      revenue_forecast: {
        value: 3023566.86399157,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 0.33333333333333326,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.33056459485755163,
      },
      propensity_improve_by: {
        value: 0.000468944236072133,
      },
      revenue_elift_improve_to: {
        value: 661350.0031683912,
      },
      revenue_elift_improve_by: {
        value: 29275.00007116246,
      },
    },
    {
      account_id: '0011U00000LIM0hQAH',
      segment: 'medium',
      account_name: {
        value: 'Norton-Flores',
      },
      propensity_improve_from: {
        value: 0.32490304987995355,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 526244.6566414103,
      },
      revenue_past: {
        value: 8098487.484741211,
      },
      revenue_forecast: {
        value: 5959993.967632422,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 0.5,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.32535345915296887,
      },
      propensity_improve_by: {
        value: 0.0004504092730153175,
      },
      revenue_elift_improve_to: {
        value: 526974.1834135156,
      },
      revenue_elift_improve_by: {
        value: 729.5267721051914,
      },
    },
    {
      account_id: '0011U000014jSGkQAM',
      segment: 'medium',
      account_name: {
        value: 'Love-Morris',
      },
      propensity_improve_from: {
        value: 0.34306490468699363,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 893892.6826962823,
      },
      revenue_past: {
        value: 1514515.079498291,
      },
      revenue_forecast: {
        value: 2412846.9501025714,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 1,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3435074463608908,
      },
      propensity_improve_by: {
        value: 0.0004425416738971788,
      },
      revenue_elift_improve_to: {
        value: 922715.1011327688,
      },
      revenue_elift_improve_by: {
        value: 28822.418436486532,
      },
    },
    {
      account_id: '0011U00000r480kQAA',
      segment: 'low',
      account_name: {
        value: 'Ramirez-Tran',
      },
      propensity_improve_from: {
        value: 0.33965702038881984,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 602698.0322946694,
      },
      revenue_past: {
        value: 2432219.156742096,
      },
      revenue_forecast: {
        value: 2948333.8740713615,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 5.5,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.33990253912631324,
      },
      propensity_improve_by: {
        value: 0.0002455187374933998,
      },
      revenue_elift_improve_to: {
        value: 618364.6156966666,
      },
      revenue_elift_improve_by: {
        value: 15666.583401997217,
      },
    },
    {
      account_id: '0011U0000077jHEQAY',
      segment: 'high',
      account_name: {
        value: 'Stone Ltd',
      },
      propensity_improve_from: {
        value: 0.33420348073899897,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 676369.9066245639,
      },
      revenue_past: {
        value: 2739945.2590942383,
      },
      revenue_forecast: {
        value: 3247281.060428256,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 0,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.33468423267407443,
      },
      propensity_improve_by: {
        value: 0.000480751935075463,
      },
      revenue_elift_improve_to: {
        value: 706959.6057477746,
      },
      revenue_elift_improve_by: {
        value: 30589.69912321072,
      },
    },
    {
      account_id: '0011U00001EzwRUQAZ',
      segment: 'medium',
      account_name: {
        value: 'Harris, Bean and Walters',
      },
      propensity_improve_from: {
        value: 0.3689514324665949,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 523474.105745513,
      },
      revenue_past: {
        value: 3082191.696166992,
      },
      revenue_forecast: {
        value: 3376096.294836292,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 2,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3693614648112904,
      },
      propensity_improve_by: {
        value: 0.000410032344695499,
      },
      revenue_elift_improve_to: {
        value: 550874.1914224714,
      },
      revenue_elift_improve_by: {
        value: 27400.085676958362,
      },
    },
    {
      account_id: '0011U00001kNoITQA0',
      segment: 'medium',
      account_name: {
        value: 'Stout Inc',
      },
      propensity_improve_from: {
        value: 0.34000429519168185,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 328192.64094404795,
      },
      revenue_past: {
        value: 4826301.396560669,
      },
      revenue_forecast: {
        value: 4443596.284051951,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 2.5,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3403769256844041,
      },
      propensity_improve_by: {
        value: 0.0003726304927222368,
      },
      revenue_elift_improve_to: {
        value: 328552.3263575332,
      },
      revenue_elift_improve_by: {
        value: 359.6854134852492,
      },
    },
    {
      account_id: '0011U0000077joQQAQ',
      segment: 'medium',
      account_name: {
        value: 'Harris PLC',
      },
      propensity_improve_from: {
        value: 0.3425868088188906,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 770397.9494504494,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8187719.765337371,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 1.5,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.34300685220686616,
      },
      propensity_improve_by: {
        value: 0.00042004338797557716,
      },
      revenue_elift_improve_to: {
        value: 771342.5292078904,
      },
      revenue_elift_improve_by: {
        value: 944.5797574408971,
      },
    },
    {
      account_id: '0011U00001CwhoLQAR',
      segment: 'low',
      account_name: {
        value: 'Gilmore, Cooper and Reyes',
      },
      propensity_improve_from: {
        value: 0.33584433554596427,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 780669.6712157152,
      },
      revenue_past: {
        value: 2766575.294494629,
      },
      revenue_forecast: {
        value: 3393366.194271571,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 10,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3358930561576239,
      },
      propensity_improve_by: {
        value: 0.000048720611659613944,
      },
      revenue_elift_improve_to: {
        value: 783829.4558871394,
      },
      revenue_elift_improve_by: {
        value: 3159.784671424268,
      },
    },
    {
      account_id: '0011U00001CwhmyQAB',
      segment: 'low',
      account_name: {
        value: 'Smith-Cline',
      },
      propensity_improve_from: {
        value: 0.32912482317106073,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 256073.94182658082,
      },
      revenue_past: {
        value: 3890225.2853393555,
      },
      revenue_forecast: {
        value: 3514379.606757442,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 5,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.32938775632453743,
      },
      propensity_improve_by: {
        value: 0.00026293315347669655,
      },
      revenue_elift_improve_to: {
        value: 256278.51566698262,
      },
      revenue_elift_improve_by: {
        value: 204.5738404018266,
      },
    },
    {
      account_id: '0011U00000LILtQQAX',
      segment: 'low',
      account_name: {
        value: 'Meza, Ayala and Walker',
      },
      propensity_improve_from: {
        value: 0.335285227559232,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 411410.3088408418,
      },
      revenue_past: {
        value: 3318706.8099975586,
      },
      revenue_forecast: {
        value: 3355390.8749359995,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 15,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.335285227559232,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 411410.3088408418,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '001EY000003yCuwYAE',
      segment: 'low',
      account_name: {
        value: 'Melton and Sons',
      },
      propensity_improve_from: {
        value: 0.40835530928648056,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 622971.3687627515,
      },
      revenue_past: {
        value: 2752568.1678771973,
      },
      revenue_forecast: {
        value: 3261335.9324503834,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 20.149999999999864,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.40835530928648056,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 622971.3687627515,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U0000077rWcQAI',
      segment: 'low',
      account_name: {
        value: 'Thompson LLC',
      },
      propensity_improve_from: {
        value: 0.3495259597686138,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 786002.4839070579,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8299090.170094038,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 13.333333333333334,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3495259597686138,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 786002.4839070579,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U00001fJ0V8QAK',
      segment: 'medium',
      account_name: {
        value: 'Hayes Group',
      },
      propensity_improve_from: {
        value: 0.3211696154362201,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 1011779.5367132118,
      },
      revenue_past: {
        value: 966164.3837928772,
      },
      revenue_forecast: {
        value: 1973991.627361206,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 0.6666666666666667,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3216192743113229,
      },
      propensity_improve_by: {
        value: 0.00044965887510284297,
      },
      revenue_elift_improve_to: {
        value: 1039953.7950826037,
      },
      revenue_elift_improve_by: {
        value: 28174.25836939192,
      },
    },
    {
      account_id: '0011U00000zmBnFQAU',
      segment: 'high',
      account_name: {
        value: 'Crawford Group',
      },
      propensity_improve_from: {
        value: 0.32859355280215197,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 972147.478732969,
      },
      revenue_past: {
        value: 2640821.937561035,
      },
      revenue_forecast: {
        value: 3467735.505190416,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: -1,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3291137382048193,
      },
      propensity_improve_by: {
        value: 0.0005201854026673502,
      },
      revenue_elift_improve_to: {
        value: 1005424.1266433329,
      },
      revenue_elift_improve_by: {
        value: 33276.64791036386,
      },
    },
    {
      account_id: '0011U0000077jgrQAA',
      segment: 'low',
      account_name: {
        value: 'Brooks Group',
      },
      propensity_improve_from: {
        value: 0.327883421518553,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 280798.3645686211,
      },
      revenue_past: {
        value: 4127671.142578125,
      },
      revenue_forecast: {
        value: 3668218.396864528,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 16.749999999999865,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.327883421518553,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 280798.3645686211,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U0000077jCZQAY',
      segment: 'low',
      account_name: {
        value: 'Jones PLC',
      },
      propensity_improve_from: {
        value: 0.40265256659697424,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 1912874.6205478504,
      },
      revenue_past: {
        value: 1521022.9774475098,
      },
      revenue_forecast: {
        value: 3451165.626559171,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 3,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.4030228071808717,
      },
      propensity_improve_by: {
        value: 0.00037024058389745207,
      },
      revenue_elift_improve_to: {
        value: 1940695.026157479,
      },
      revenue_elift_improve_by: {
        value: 27820.405609628688,
      },
    },
    {
      account_id: '0011U00001yX5ycQAC',
      segment: 'low',
      account_name: {
        value: 'Trujillo Ltd',
      },
      propensity_improve_from: {
        value: 0.36946536101797856,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 327963.7696008761,
      },
      revenue_past: {
        value: 4438356.12487793,
      },
      revenue_forecast: {
        value: 4225725.4352427535,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 21.31666666666653,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.36946536101797856,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 327963.7696008761,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U0000077jp0QAA',
      segment: 'low',
      account_name: {
        value: 'Fisher, Cooper and Barr',
      },
      propensity_improve_from: {
        value: 0.3474535987384548,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 349056.5918418223,
      },
      revenue_past: {
        value: 4809698.770523071,
      },
      revenue_forecast: {
        value: 4505339.222501042,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 5.5,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3476995551444408,
      },
      propensity_improve_by: {
        value: 0.0002459564059859587,
      },
      revenue_elift_improve_to: {
        value: 364883.99212771386,
      },
      revenue_elift_improve_by: {
        value: 15827.40028589153,
      },
    },
    {
      account_id: '0011U0000077iyUQAQ',
      segment: 'low',
      account_name: {
        value: 'Baker and Sons',
      },
      propensity_improve_from: {
        value: 0.3548769627509879,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 798035.6434421163,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8398898.48387724,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 16.749999999999865,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3548769627509879,
      },
      propensity_improve_by: {
        value: 0,
      },
      revenue_elift_improve_to: {
        value: 798035.6434421163,
      },
      revenue_elift_improve_by: {
        value: 0,
      },
    },
    {
      account_id: '0011U00001kO0ZqQAK',
      segment: 'medium',
      account_name: {
        value: 'Avila and Sons',
      },
      propensity_improve_from: {
        value: 0.32750707929326733,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 1048597.3010067875,
      },
      revenue_past: {
        value: 1176164.4287109375,
      },
      revenue_forecast: {
        value: 2224202.6688395096,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 2,
        segment: 'medium',
      },
      propensity_improve_to: {
        value: 0.3279001584765758,
      },
      propensity_improve_by: {
        value: 0.0003930791833084424,
      },
      revenue_elift_improve_to: {
        value: 1073663.7774118343,
      },
      revenue_elift_improve_by: {
        value: 25066.476405047026,
      },
    },
    {
      account_id: '0011U0000077jgwQAA',
      segment: 'high',
      account_name: {
        value: 'Drake, Mitchell and Osborn',
      },
      propensity_improve_from: {
        value: 0.33491195618181374,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 351649.2938113577,
      },
      revenue_past: {
        value: 5249876.68132782,
      },
      revenue_forecast: {
        value: 4078801.8321974357,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: -0.5,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3354138880941402,
      },
      propensity_improve_by: {
        value: 0.0005019319123264476,
      },
      revenue_elift_improve_to: {
        value: 352176.30993978505,
      },
      revenue_elift_improve_by: {
        value: 527.0161284273854,
      },
    },
    {
      account_id: '0011U000028OMdsQAG',
      segment: 'high',
      account_name: {
        value: 'Grant-Braun',
      },
      propensity_improve_from: {
        value: 0.3619587943602628,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 673106.4940986258,
      },
      revenue_past: {
        value: 2663013.6337280273,
      },
      revenue_forecast: {
        value: 3218257.712087836,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: -2.0000000000000013,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3625389906943295,
      },
      propensity_improve_by: {
        value: 0.000580196334066696,
      },
      revenue_elift_improve_to: {
        value: 712025.7806128724,
      },
      revenue_elift_improve_by: {
        value: 38919.2865142465,
      },
    },
    {
      account_id: '0011U00000LILxEQAX',
      segment: 'high',
      account_name: {
        value: 'Brown-Abbott',
      },
      propensity_improve_from: {
        value: 0.33922830595043196,
        segment: 'medium',
      },
      revenue_elift_improve_from: {
        value: 296104.4769399314,
      },
      revenue_past: {
        value: 4364383.392333984,
      },
      revenue_forecast: {
        value: 4099235.4575243783,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 0.4166666666666665,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3396875315058148,
      },
      propensity_improve_by: {
        value: 0.00045922555538285836,
      },
      revenue_elift_improve_to: {
        value: 296505.3242173811,
      },
      revenue_elift_improve_by: {
        value: 400.8472774496795,
      },
    },
    {
      account_id: '0011U00001jiNJ0QAM',
      segment: 'low',
      account_name: {
        value: 'Dodson LLC',
      },
      propensity_improve_from: {
        value: 0.3216066174416774,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 260687.52040517933,
      },
      revenue_past: {
        value: 3106849.411010742,
      },
      revenue_forecast: {
        value: 2786306.850376934,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 5,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.321869747180801,
      },
      propensity_improve_by: {
        value: 0.00026312973912362425,
      },
      revenue_elift_improve_to: {
        value: 276601.86244493345,
      },
      revenue_elift_improve_by: {
        value: 15914.342039754092,
      },
    },
    {
      account_id: '0011U0000077jUUQAY',
      segment: 'low',
      account_name: {
        value: 'Turner Group',
      },
      propensity_improve_from: {
        value: 0.3436573941980372,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 772805.4466440795,
      },
      revenue_past: {
        value: 11243835.57128906,
      },
      revenue_forecast: {
        value: 8267263.774300636,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: 7,
        segment: 'high',
      },
      propensity_improve_to: {
        value: 0.3438360341616042,
      },
      propensity_improve_by: {
        value: 0.00017863996356698442,
      },
      revenue_elift_improve_to: {
        value: 773207.1663194413,
      },
      revenue_elift_improve_by: {
        value: 401.71967536175544,
      },
    },
    {
      account_id: '0011U0000077jmlQAA',
      segment: 'high',
      account_name: {
        value: 'Martinez, Clay and Ellis',
      },
      propensity_improve_from: {
        value: 0.3291216490559492,
        segment: 'low',
      },
      revenue_elift_improve_from: {
        value: 574565.2236789435,
      },
      revenue_past: {
        value: 8728766.784667969,
      },
      revenue_forecast: {
        value: 6403423.010497722,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: -17.9,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3303554715053618,
      },
      propensity_improve_by: {
        value: 0.0012338224494126138,
      },
      revenue_elift_improve_to: {
        value: 576719.1733618656,
      },
      revenue_elift_improve_by: {
        value: 2153.9496829220525,
      },
    },
    {
      account_id: '0011U0000077jp1QAA',
      segment: 'high',
      account_name: {
        value: 'Choi Group',
      },
      propensity_improve_from: {
        value: 0.34485782230020434,
        segment: 'high',
      },
      revenue_elift_improve_from: {
        value: 653056.7950239734,
      },
      revenue_past: {
        value: 9468493.28613281,
      },
      revenue_forecast: {
        value: 7169126.650415306,
      },
      'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1': {
        value: -16.333333333333332,
        segment: 'low',
      },
      propensity_improve_to: {
        value: 0.3460405531419834,
      },
      propensity_improve_by: {
        value: 0.001182730841779056,
      },
      revenue_elift_improve_to: {
        value: 655296.5308309109,
      },
      revenue_elift_improve_by: {
        value: 2239.735806937506,
      },
    },
  ],
  accounts: {},
}

export const demoGetInsightDetailsSegmentOverallExpansion = {
  grid: 'account',
  dimension: 'overall',
  train_datetime: '2024-04-16T19-24-41Z',
  infer_datetime: '2024-04-16T20-43-40Z',
  dataset_date: '2024-04-07',
  is_revenue_quantified: true,
  driver_id: '2-001',
  driver_type: 'insight',
  event: {
    short_name: 'expansion',
    full_name: 'expansion in next 180 days',
    impact: 'positive',
  },
  family: {
    platform: 'snowflake',
    object: 'Job execution',
    field: 'Is business critical',
    std_platform: 'pendo',
    std_object: 'feature_telemetry',
    std_field: 'numminutes@name[dashboard-workspace-menu]',
  },
  metric_value: 11.141999999999953,
  aggregations: [
    {
      key: 'improve_from',
      title: 'If no impact is made to the segment',
      sources: [
        {
          key: 'num_accounts',
          title: '# of Accounts',
          column: 'account_name',
          aggregator: 'count',
          type: 'number',
          format: 'comma',
          decimal: 0,
        },
        {
          key: 'revenue_elift_improve_from',
          title: 'ARR Expansion',
          column: 'revenue_elift_improve_from',
          aggregator: 'sum',
          type: 'number',
          format: 'currency-kmb',
          decimal: 1,
        },
        {
          key: 'propensity_improve_from',
          title: 'Expansion %',
          column: 'propensity_improve_from',
          aggregator: 'mean',
          type: 'number',
          format: 'percentage',
          decimal: 1,
        },
      ],
    },
    {
      key: 'improve_by',
      title: 'If segment reaches target',
      sources: [
        {
          key: 'revenue_elift_improve_by',
          title: 'Increase in ARR Expansion',
          column: 'revenue_elift_improve_by',
          aggregator: 'sum',
          type: 'number',
          format: 'currency-kmb',
          decimal: 1,
        },
        {
          key: 'propensity_improve_by',
          title: 'Increase in Expansion %',
          column: 'propensity_improve_by',
          aggregator: 'mean',
          type: 'number',
          format: 'percentage',
          decimal: 1,
        },
      ],
    },
  ],
  predefined_segments: {
    high: {
      title: 'HIGH IMPACT SEGMENT',
      driver: {
        title: '60-day total',
        column: 'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1',
        min_metric: {
          aggregator: 'min',
          type: 'number',
          format: 'kmb',
          decimal: 2,
        },
        max_metric: {
          aggregator: 'max',
          type: 'number',
          format: 'kmb',
          decimal: 2,
        },
      },
      num_accounts: {
        title: 'Accounts',
        column: 'account_name',
        aggregator: 'count',
        type: 'number',
        format: 'comma',
        decimal: 0,
      },
      revenue_elift_improve_from: {
        title: 'ARR Expansion',
        column: 'revenue_elift_improve_from',
        aggregator: 'sum',
        type: 'number',
        format: 'currency-kmb',
        decimal: 1,
      },
    },
    medium: {
      title: 'MEDIUM IMPACT SEGMENT',
      driver: {
        title: '60-day total',
        column: 'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1',
        min_metric: {
          aggregator: 'min',
          type: 'number',
          format: 'kmb',
          decimal: 2,
        },
        max_metric: {
          aggregator: 'max',
          type: 'number',
          format: 'kmb',
          decimal: 2,
        },
      },
      num_accounts: {
        title: 'Accounts',
        column: 'account_name',
        aggregator: 'count',
        type: 'number',
        format: 'comma',
        decimal: 0,
      },
      revenue_elift_improve_from: {
        title: 'ARR Expansion',
        column: 'revenue_elift_improve_from',
        aggregator: 'sum',
        type: 'number',
        format: 'currency-kmb',
        decimal: 1,
      },
    },
    low: {
      title: 'LOW IMPACT SEGMENT',
      driver: {
        title: '60-day total',
        column: 'pendo:feature_telemetry:numminutes@name[dashboard-workspace-menu]:sum@universal_user_id|lift:p90d1',
        min_metric: {
          aggregator: 'min',
          type: 'number',
          format: 'kmb',
          decimal: 2,
        },
        max_metric: {
          aggregator: 'max',
          type: 'number',
          format: 'kmb',
          decimal: 2,
        },
      },
      num_accounts: {
        title: 'Accounts',
        column: 'account_name',
        aggregator: 'count',
        type: 'number',
        format: 'comma',
        decimal: 0,
      },
      revenue_elift_improve_from: {
        title: 'ARR Expansion',
        column: 'revenue_elift_improve_from',
        aggregator: 'sum',
        type: 'number',
        format: 'currency-kmb',
        decimal: 1,
      },
    },
  },
}

export const demoTenantAccountInsights = {
  grid: 'account',
  dimension: 'overall',
  train_datetime: '2024-04-16T19-24-41Z',
  infer_datetime: '2024-04-16T20-43-40Z',
  dataset_date: '2024-04-07',
  is_revenue_quantified: true,
  events: {
    churn: {
      full_name: 'churn in next 180 days',
      impact: 'negative',
    },
    conversion: {
      full_name: 'conversion in next 180 days',
      impact: 'positive',
    },
    expansion: {
      full_name: 'expansion in next 180 days',
      impact: 'positive',
    },
  },
  usages: {
    '180d revenue': {
      full_name: 'half-year revenue',
    },
    '90d revenue': {
      full_name: 'quarterly revenue',
    },
  },
  accounts: {
    '0011U00000Q2TjkQAF': {
      account_name: 'York-Alexander',
      events: {
        churn: {
          propensity: 0.3054295233766736,
          propensity_text: '31%',
          segment: 0,
        },
        conversion: {
          propensity: 0.34679585278262,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.34777462384070645,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 4354559.802246094,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 1451519.934082031,
          stderr: 9608.013253234702,
          plift: -0.2,
        },
      },
    },
    '0011U00001gythlQAA': {
      account_name: 'Goodman Ltd',
      events: {
        churn: {
          propensity: 0.30482325277925393,
          propensity_text: '30%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3533963314472488,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3417804157734973,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 3823595.4858304188,
          stderr: 32354.480994297137,
          plift: 0.12612954239425775,
        },
        '90d revenue': {
          forecast: 1760593.5801452422,
          stderr: 21770.84515946774,
          plift: 0.03706391018537923,
        },
      },
    },
    '0011U00001zgB2FQAU': {
      account_name: 'Hernandez-Anderson',
      events: {
        churn: {
          propensity: 0.3383849002568855,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.32989763083271595,
          propensity_text: '33%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3317174689103985,
          propensity_text: '33%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 831011.0016421655,
          stderr: 9092.483287315272,
          plift: -0.2510641523476026,
        },
        '90d revenue': {
          forecast: 665753.4187316895,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U0000077iIGQAY': {
      account_name: 'Munoz-Schultz',
      events: {
        churn: {
          propensity: 0.30807982676306267,
          propensity_text: '31%',
          segment: 0,
        },
        conversion: {
          propensity: 0.34932585576175945,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.34259431747517793,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2664975.070870481,
          stderr: 23399.117202415528,
          plift: -0.13230937983971902,
        },
        '90d revenue': {
          forecast: 1273805.370191335,
          stderr: 14878.30037722013,
          plift: -0.17052209327887388,
        },
      },
    },
    '001EY000003EFkLYAW': {
      account_name: 'Garcia-Wilkerson',
      events: {
        churn: {
          propensity: 0.26933153451276864,
          propensity_text: '27%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3538004003727975,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3768680651144338,
          propensity_text: '38%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 4837808.056640625,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 1612602.685546875,
          stderr: 2868.5108692164413,
          plift: -0.2,
        },
      },
    },
    '001EY0000034teOYAQ': {
      account_name: 'Dixon Group',
      events: {
        churn: {
          propensity: 0.2939786268156592,
          propensity_text: '29%',
          segment: 0,
        },
        conversion: {
          propensity: 0.34745543567918225,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3585659375051586,
          propensity_text: '36%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 5326027.349853516,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 1775342.4499511719,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U000020RrRuQAK': {
      account_name: 'Schroeder, Smith and Martinez',
      events: {
        churn: {
          propensity: 0.30381761349730285,
          propensity_text: '30%',
          segment: 0,
        },
        conversion: {
          propensity: 0.34287436449109615,
          propensity_text: '34%',
          segment: 2,
        },
        expansion: {
          propensity: 0.35330802201160094,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 6746301.3427734375,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 2270307.9528808594,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U0000077ifVQAQ': {
      account_name: 'Andrade-Sanchez',
      events: {
        churn: {
          propensity: 0.3152873218356792,
          propensity_text: '32%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3448286436012542,
          propensity_text: '34%',
          segment: 2,
        },
        expansion: {
          propensity: 0.33988403456306654,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1952876.6784667969,
          stderr: 5063.4929435351,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 976438.3392333984,
          stderr: 3875.099901453734,
          plift: 0.2,
        },
      },
    },
    '0011U0000077istQAA': {
      account_name: 'Dunn, Chambers and Rogers',
      events: {
        churn: {
          propensity: 0.3106856266240392,
          propensity_text: '31%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3505096205298034,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.33880475284615746,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 4497534.228515625,
          stderr: 0,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 2270307.9528808594,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '001EY000002RpVDYA0': {
      account_name: 'Bradley, Jackson and Martin',
      events: {
        churn: {
          propensity: 0.34318955942148743,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.3233479255117554,
          propensity_text: '32%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3334625150667572,
          propensity_text: '33%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 831541.3408259123,
          stderr: 14901.186461092579,
          plift: -0.5003908103507867,
        },
        '90d revenue': {
          forecast: 423390.8689150664,
          stderr: 1193.9318031354926,
          plift: -0.4912340288134452,
        },
      },
    },
    '0011U0000077jp2QAA': {
      account_name: 'Rodriguez-Taylor',
      events: {
        churn: {
          propensity: 0.34304485539553414,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.33212724668233795,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3248278979221279,
          propensity_text: '32%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 834410.9893798828,
          stderr: 14641.403206964102,
          plift: -0.3865332703321237,
        },
        '90d revenue': {
          forecast: 834410.9893798828,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U0000077joPQAQ': {
      account_name: 'Whitaker Group',
      events: {
        churn: {
          propensity: 0.33990316870374937,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.32833081901434313,
          propensity_text: '33%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3317660122819075,
          propensity_text: '33%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 832526.0418607105,
          stderr: 15054.7832417529,
          plift: -0.5498192390736986,
        },
        '90d revenue': {
          forecast: 788327.3855143766,
          stderr: 8370.91361969543,
          plift: -0.14743850780518825,
        },
      },
    },
    '0011U0000077jptQAA': {
      account_name: 'Coleman LLC',
      events: {
        churn: {
          propensity: 0.2997083284559293,
          propensity_text: '30%',
          segment: 0,
        },
        conversion: {
          propensity: 0.35708599674098057,
          propensity_text: '36%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3432056748030901,
          propensity_text: '34%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 4497534.228515625,
          stderr: 0,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 2270307.9528808594,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U000020S43DQAS': {
      account_name: 'Hernandez, Adkins and Burton',
      events: {
        churn: {
          propensity: 0.3385531048858225,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.3318381616300989,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3296087334840787,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 831392.2719802504,
          stderr: 16555.991226708877,
          plift: -0.5769770162989496,
        },
        '90d revenue': {
          forecast: 429039.76610918937,
          stderr: 23059.340619559236,
          plift: -0.591190692089542,
        },
      },
    },
    '0011U00000MXjC2QAL': {
      account_name: 'Baldwin, Gonzalez and Owens',
      events: {
        churn: {
          propensity: 0.3386743477588192,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.31778922339391386,
          propensity_text: '32%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3435364288472669,
          propensity_text: '34%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1840666.6273380935,
          stderr: 20002.42730737744,
          plift: 0.22879440535297815,
        },
        '90d revenue': {
          forecast: 898767.0936584473,
          stderr: 10881.139233348105,
          plift: 0.2,
        },
      },
    },
    '001EY000003ce9tYAA': {
      account_name: 'Cameron PLC',
      events: {
        churn: {
          propensity: 0.3288143284735019,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.31730657976379123,
          propensity_text: '32%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3538790917627069,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 3708779.0954589844,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 1854389.5477294922,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U00001V96twQAB': {
      account_name: 'Allison Inc',
      events: {
        churn: {
          propensity: 0.31503025289234243,
          propensity_text: '32%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3325217877539876,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.35244795935367007,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2029196.1908740418,
          stderr: 12753.330432089891,
          plift: 0.23845262937882428,
        },
        '90d revenue': {
          forecast: 914917.6507527693,
          stderr: 5688.461260921648,
          plift: 0.1167793191567229,
        },
      },
    },
    '0011U00001tE45RQAS': {
      account_name: 'Guerrero-Morton',
      events: {
        churn: {
          propensity: 0.3304186110923811,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.335749710735552,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3338316781720669,
          propensity_text: '33%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1566899.5951465885,
          stderr: 20641.047106195972,
          plift: 0.07478195663971379,
        },
        '90d revenue': {
          forecast: 795205.4843902588,
          stderr: 5095.1778980750405,
          plift: 0.2,
        },
      },
    },
    '0011U00001oQbCDQA0': {
      account_name: 'Little, Patel and Weaver',
      events: {
        churn: {
          propensity: 0.30333463128356747,
          propensity_text: '30%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3350025458867867,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.36166282282964585,
          propensity_text: '36%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1924505.228147366,
          stderr: 15343.723628831207,
          plift: 0.24712720501596258,
        },
        '90d revenue': {
          forecast: 909372.6563414795,
          stderr: 7949.35271727783,
          plift: 0.13187046825736343,
        },
      },
    },
    '0011U00001EznBSQAZ': {
      account_name: 'Williams, Pierce and Thomas',
      events: {
        churn: {
          propensity: 0.2874429617375476,
          propensity_text: '29%',
          segment: 0,
        },
        conversion: {
          propensity: 0.34650147124423636,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.36605556701821595,
          propensity_text: '37%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 4438356.042480469,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 1592845.2333203233,
          stderr: 14809.52662346398,
          plift: -0.13868366499225065,
        },
      },
    },
    '0011U0000077irkQAA': {
      account_name: 'Henderson Ltd',
      events: {
        churn: {
          propensity: 0.34585451794816996,
          propensity_text: '35%',
          segment: 2,
        },
        conversion: {
          propensity: 0.3252943748388091,
          propensity_text: '33%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3288511072130209,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 832586.6807825243,
          stderr: 12637.386884289432,
          plift: -0.34751659716591576,
        },
        '90d revenue': {
          forecast: 765616.4222717285,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U000012B90pQAC': {
      account_name: 'Davis-Harvey',
      events: {
        churn: {
          propensity: 0.35772250085512586,
          propensity_text: '36%',
          segment: 2,
        },
        conversion: {
          propensity: 0.31837271518679156,
          propensity_text: '32%',
          segment: 0,
        },
        expansion: {
          propensity: 0.32390478395808253,
          propensity_text: '32%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 831738.7194550439,
          stderr: 15231.246248983756,
          plift: -0.5606907720827263,
        },
        '90d revenue': {
          forecast: 422675.1564128038,
          stderr: 2374.69220043772,
          plift: -0.5790834591763505,
        },
      },
    },
    '0011U0000077j3fQAA': {
      account_name: 'Andrews-Crosby',
      events: {
        churn: {
          propensity: 0.3318148350806824,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3405588013849109,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3276263635344068,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 976438.3392333984,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 488219.1696166992,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U00001R4il6QAB': {
      account_name: 'Luna LLC',
      events: {
        churn: {
          propensity: 0.31675217863962624,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.33563905234189,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.34760876901848375,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2663013.674926758,
          stderr: 8128.461065091674,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 945925.4417188085,
          stderr: 8559.104043127498,
          plift: -0.14749928567769022,
        },
      },
    },
    '0011U00001dtB9GQAU': {
      account_name: 'Wilson LLC',
      events: {
        churn: {
          propensity: 0.32166517817907075,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3378586800239252,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.34047614179700403,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2157288.286216891,
          stderr: 16184.225644646278,
          plift: 0.7739259471914932,
        },
        '90d revenue': {
          forecast: 798904.0901184082,
          stderr: 2219.1024918005696,
          plift: 0.2,
        },
      },
    },
    '0011U0000077jaqQAA': {
      account_name: 'Walls-Anderson',
      events: {
        churn: {
          propensity: 0.3313409951762371,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3375957034116497,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.33106330141211326,
          propensity_text: '33%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1479452.014160156,
          stderr: 13125.058372264002,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 739726.0070800781,
          stderr: 3443.2097595400064,
          plift: -0.2,
        },
      },
    },
    '001EY000003UeG1YAK': {
      account_name: 'Ruiz, Garcia and Schmidt',
      events: {
        churn: {
          propensity: 0.2753283092646694,
          propensity_text: '28%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3240777777682253,
          propensity_text: '32%',
          segment: 0,
        },
        expansion: {
          propensity: 0.40059391296710534,
          propensity_text: '40%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1900355.8604788757,
          stderr: 36141.38497212498,
          plift: 0.95175663730816,
        },
        '90d revenue': {
          forecast: 1173112.6367288353,
          stderr: 16065.295728546776,
          plift: 0.5662958446157521,
        },
      },
    },
    '0011U000028O1gzQAC': {
      account_name: 'Maxwell, Roberts and Deleon',
      events: {
        churn: {
          propensity: 0.3437609750904879,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.3267167338664198,
          propensity_text: '33%',
          segment: 0,
        },
        expansion: {
          propensity: 0.32952229104309233,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 831905.5655169426,
          stderr: 14359.197789823904,
          plift: -0.4080983769337937,
        },
        '90d revenue': {
          forecast: 826895.9318784194,
          stderr: 8020.705532534017,
          plift: 0.17667452767080113,
        },
      },
    },
    '0011U0000077jiZQAQ': {
      account_name: 'Cruz, Garcia and Gilmore',
      events: {
        churn: {
          propensity: 0.28783431813631954,
          propensity_text: '29%',
          segment: 0,
        },
        conversion: {
          propensity: 0.35220332279484495,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.35996235906883556,
          propensity_text: '36%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 5705418.087158203,
          stderr: 9.872026935044678e-10,
          plift: 0.20000000000000004,
        },
        '90d revenue': {
          forecast: 1934620.2795410156,
          stderr: 5732.147958781825,
          plift: -0.19999999999999998,
        },
      },
    },
    '0011U0000077iz4QAA': {
      account_name: 'Lee-Cherry',
      events: {
        churn: {
          propensity: 0.31901393861078675,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3441404957508704,
          propensity_text: '34%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3368455656383427,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2732904.653994485,
          stderr: 22664.77908077682,
          plift: 0.08174457383581601,
        },
        '90d revenue': {
          forecast: 1370396.6659212885,
          stderr: 11895.300083815639,
          plift: 0.08486708835326907,
        },
      },
    },
    '0011U00001fIphJQAS': {
      account_name: 'Carlson PLC',
      events: {
        churn: {
          propensity: 0.31853968039876496,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3379416074315418,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.34351871216969315,
          propensity_text: '34%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1927135.5694712293,
          stderr: 22350.58233831396,
          plift: 0.7368012076987015,
        },
        '90d revenue': {
          forecast: 665753.4187316895,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U000028b588QAA': {
      account_name: 'Wall-Kelly',
      events: {
        churn: {
          propensity: 0.34090013921177925,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.3201586345187048,
          propensity_text: '32%',
          segment: 0,
        },
        expansion: {
          propensity: 0.338941226269516,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 807780.7891845703,
          stderr: 6716.663274989326,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 403890.39459228516,
          stderr: 808.041174931979,
          plift: -0.2,
        },
      },
    },
    '0011U0000077j4OQAQ': {
      account_name: 'Robinson PLC',
      events: {
        churn: {
          propensity: 0.334404931686902,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3329366824360133,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.33265838587708463,
          propensity_text: '33%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 877044.1564804523,
          stderr: 14738.53171606134,
          plift: -0.5130764303161554,
        },
        '90d revenue': {
          forecast: 877044.1564804523,
          stderr: 10872.3201010215,
          plift: -0.11812731268370368,
        },
      },
    },
    '0011U00001txFQWQA2': {
      account_name: 'Aguilar, Stephens and Roberts',
      events: {
        churn: {
          propensity: 0.3149546456775766,
          propensity_text: '31%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3371476989720083,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.34789765535041506,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1878847.190449131,
          stderr: 19224.951592014862,
          plift: 0.3632697523880781,
        },
        '90d revenue': {
          forecast: 838849.328613281,
          stderr: 5433.748794304878,
          plift: 0.2,
        },
      },
    },
    '001EY000002tzP1YAI': {
      account_name: 'Jimenez Inc',
      events: {
        churn: {
          propensity: 0.31569864028699784,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.33370796470825,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3505933950047522,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2219178.0212402344,
          stderr: 9226.828248267415,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 1109589.0106201172,
          stderr: 10097.159589089291,
          plift: 0.2,
        },
      },
    },
    '0011U0000077jnvQAA': {
      account_name: 'Ballard, Woods and Harrell',
      events: {
        churn: {
          propensity: 0.31855191585535575,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.340872282273799,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.34057580187084524,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1952876.6784667969,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 976438.3392333984,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U0000077jpnQAA': {
      account_name: 'Williams-Hoover',
      events: {
        churn: {
          propensity: 0.32797934601435474,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.34061838413803225,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3314022698476129,
          propensity_text: '33%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2096100.2891473365,
          stderr: 23542.275954221608,
          plift: 0.08292842346506288,
        },
        '90d revenue': {
          forecast: 806967.6690604623,
          stderr: 11339.711443388122,
          plift: -0.1701491591820559,
        },
      },
    },
    '0011U00000LILyCQAX': {
      account_name: 'Johnson-Underwood',
      events: {
        churn: {
          propensity: 0.2916316472762989,
          propensity_text: '29%',
          segment: 0,
        },
        conversion: {
          propensity: 0.33740268881212415,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.37096566391157687,
          propensity_text: '37%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 4438356.042480469,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 1479452.014160156,
          stderr: 4420.731557886332,
          plift: -0.2,
        },
      },
    },
    '0011U0000077jgIQAQ': {
      account_name: 'Zamora Ltd',
      events: {
        churn: {
          propensity: 0.3426685991385501,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.33103563672835745,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3262957641330926,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1775342.4499511719,
          stderr: 15189.630672754749,
          plift: -0.6,
        },
        '90d revenue': {
          forecast: 1775342.4499511719,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U00000r1WcKQAU': {
      account_name: 'Dixon, Mcdonald and Garcia',
      events: {
        churn: {
          propensity: 0.33656838890098467,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.3247490928940982,
          propensity_text: '32%',
          segment: 0,
        },
        expansion: {
          propensity: 0.33868251820491724,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2376207.229614258,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 792069.0765380859,
          stderr: 4032.993435204966,
          plift: -0.2,
        },
      },
    },
    '0011U0000077jpRQAQ': {
      account_name: 'Levine-Riggs',
      events: {
        churn: {
          propensity: 0.34265999575495343,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.33398162354806926,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.32335838069697725,
          propensity_text: '32%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 830869.039823443,
          stderr: 10084.224882203205,
          plift: -0.27286344883869545,
        },
        '90d revenue': {
          forecast: 494285.6519869936,
          stderr: 9385.955803694858,
          plift: -0.13747748223718045,
        },
      },
    },
    '001EY000002hXcAYAU': {
      account_name: 'Stanley-Jordan',
      events: {
        churn: {
          propensity: 0.3168836575118589,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3445066923396376,
          propensity_text: '34%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3386096501485035,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1153972.6501464844,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 576986.3250732422,
          stderr: 2603.3855368666095,
          plift: 0.2,
        },
      },
    },
    '0011U00000r47jAQAQ': {
      account_name: 'Mcdaniel-Beltran',
      events: {
        churn: {
          propensity: 0.3176234902838364,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3429445146080654,
          propensity_text: '34%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3394319951080982,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2441095.9716796875,
          stderr: 13273.948213375836,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 946220.3102090153,
          stderr: 11008.06715516297,
          plift: -0.06970935561413463,
        },
      },
    },
    '0011U0000077jpCQAQ': {
      account_name: 'Montoya, Lewis and Blackwell',
      events: {
        churn: {
          propensity: 0.31648829599380335,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.34241818571477406,
          propensity_text: '34%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3410935182914225,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1997260.317993164,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 998630.158996582,
          stderr: 7141.330590285176,
          plift: 0.2,
        },
      },
    },
    '0011U00001EzvyaQAB': {
      account_name: 'Smith and Sons',
      events: {
        churn: {
          propensity: 0.33454188644135135,
          propensity_text: '33%',
          segment: 2,
        },
        conversion: {
          propensity: 0.33906557646674496,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.32639253709190363,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2958904.0283203125,
          stderr: 0,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 1479452.014160156,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U0000077jiYQAQ': {
      account_name: 'Brooks-Hamilton',
      events: {
        churn: {
          propensity: 0.32300120831393764,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3407887547577961,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.33621003692826623,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2221385.900964283,
          stderr: 17393.774881391488,
          plift: 0.11077658584381812,
        },
        '90d revenue': {
          forecast: 1082037.9450825036,
          stderr: 12692.467029593714,
          plift: 0.08211942271756527,
        },
      },
    },
    '0011U0000077jp4QAA': {
      account_name: 'Martin Inc',
      events: {
        churn: {
          propensity: 0.32558836187330453,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.332596981522532,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3418146566041635,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2120084.705929415,
          stderr: 26619.485159300926,
          plift: 0.5490770596267446,
        },
        '90d revenue': {
          forecast: 827709.0476989746,
          stderr: 2285.258053494911,
          plift: 0.2,
        },
      },
    },
    '001EY000002jmXgYAI': {
      account_name: 'Rice-Mcdonald',
      events: {
        churn: {
          propensity: 0.34945010161790624,
          propensity_text: '35%',
          segment: 2,
        },
        conversion: {
          propensity: 0.31651091040559226,
          propensity_text: '32%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3340389879765015,
          propensity_text: '33%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1134121.6019515344,
          stderr: 15165.091822601396,
          plift: -0.23912436248526203,
        },
        '90d revenue': {
          forecast: 1134121.6019515344,
          stderr: 8426.091817849205,
          plift: 0.5217512750294759,
        },
      },
    },
    '0011U00001CwhoJQAR': {
      account_name: 'King-Henderson',
      events: {
        churn: {
          propensity: 0.3395550409819863,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.3287340328679246,
          propensity_text: '33%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3317109261500891,
          propensity_text: '33%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 794169.8616027832,
          stderr: 6232.274996306055,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 397084.9308013916,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U0000077jgRQAQ': {
      account_name: 'Larsen-Adams',
      events: {
        churn: {
          propensity: 0.34475315220286024,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.3223205058426781,
          propensity_text: '32%',
          segment: 0,
        },
        expansion: {
          propensity: 0.33292634195446175,
          propensity_text: '33%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 627287.6580047607,
          stderr: 1512.3556837794224,
          plift: -0.20000000000000004,
        },
        '90d revenue': {
          forecast: 313643.82923126227,
          stderr: 4.1133445562686163e-11,
          plift: -0.1999999999999999,
        },
      },
    },
    '0011U0000077rWmQAI': {
      account_name: 'Gutierrez Group',
      events: {
        churn: {
          propensity: 0.32708983693961047,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3376023509665542,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.33530781209383526,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1264931.5017700195,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 632465.7508850098,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '001EY000002zZpyYAE': {
      account_name: 'Martin-Freeman',
      events: {
        churn: {
          propensity: 0.3512718452639463,
          propensity_text: '35%',
          segment: 2,
        },
        conversion: {
          propensity: 0.3273911929074553,
          propensity_text: '33%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3213369618285984,
          propensity_text: '32%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 829259.998249713,
          stderr: 15002.72602988006,
          plift: -0.6057546542152205,
        },
        '90d revenue': {
          forecast: 656636.0997963266,
          stderr: 8142.358045939895,
          plift: -0.13692298605188566,
        },
      },
    },
    '0011U0000077jAKQAY': {
      account_name: 'White, Clark and Vargas',
      events: {
        churn: {
          propensity: 0.336055180479266,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.3382480463270814,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.32569677319365253,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1614851.477050781,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 807425.7385253906,
          stderr: 1066.4553462016918,
          plift: 0.2,
        },
      },
    },
    '001EY000002qEFzYAM': {
      account_name: 'Mitchell, Vasquez and Adkins',
      events: {
        churn: {
          propensity: 0.35480167418966985,
          propensity_text: '35%',
          segment: 2,
        },
        conversion: {
          propensity: 0.3132029247566057,
          propensity_text: '31%',
          segment: 0,
        },
        expansion: {
          propensity: 0.33199540105372444,
          propensity_text: '33%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1331506.837463379,
          stderr: 15174.053108617998,
          plift: -0.4,
        },
        '90d revenue': {
          forecast: 1331506.837463379,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U00001V8nAcQAJ': {
      account_name: 'Rodriguez PLC',
      events: {
        churn: {
          propensity: 0.3809438123596256,
          propensity_text: '38%',
          segment: 2,
        },
        conversion: {
          propensity: 0.29655166270470124,
          propensity_text: '30%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3225045249356731,
          propensity_text: '32%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 831417.8407547439,
          stderr: 15469.396899792895,
          plift: -0.5933597206313773,
        },
        '90d revenue': {
          forecast: 419079.7587624309,
          stderr: 3397.594645463729,
          plift: -0.5900624166876406,
        },
      },
    },
    '0011U00000MXjFjQAL': {
      account_name: 'Blake Ltd',
      events: {
        churn: {
          propensity: 0.336873334661161,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.3429205845730212,
          propensity_text: '34%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3202060807658178,
          propensity_text: '32%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2486278.289794922,
          stderr: 17989.77876651589,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 1243139.144897461,
          stderr: 6873.035255167549,
          plift: 0.2,
        },
      },
    },
    '0011U00000MXjCZQA1': {
      account_name: 'Howell-Morrow',
      events: {
        churn: {
          propensity: 0.30465488134337226,
          propensity_text: '30%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3397388104116463,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3556063082449816,
          propensity_text: '36%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 3129041.0247802734,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 1199814.3255148702,
          stderr: 14694.015479707874,
          plift: -0.07973262145455708,
        },
      },
    },
    '0011U0000077jIIQAY': {
      account_name: 'Bell-Pierce',
      events: {
        churn: {
          propensity: 0.31040078115151315,
          propensity_text: '31%',
          segment: 0,
        },
        conversion: {
          propensity: 0.35288950182808343,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3367097170204035,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2966382.0348819504,
          stderr: 21869.74687738775,
          plift: 0.14574543704929754,
        },
        '90d revenue': {
          forecast: 1253713.9353894566,
          stderr: 14666.09164658728,
          plift: -0.03152257265153511,
        },
      },
    },
    '0011U00001BahqwQAB': {
      account_name: 'Reed Group',
      events: {
        churn: {
          propensity: 0.34273576317059257,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.32877015840600454,
          propensity_text: '33%',
          segment: 0,
        },
        expansion: {
          propensity: 0.32849407842340284,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 932054.8233032227,
          stderr: 14934.395313793088,
          plift: -0.4,
        },
        '90d revenue': {
          forecast: 932054.8233032227,
          stderr: 2131.357260838565,
          plift: 0.2,
        },
      },
    },
    '0011U0000077izcQAA': {
      account_name: 'Thomas-White',
      events: {
        churn: {
          propensity: 0.3190749391883026,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.33938516754118014,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.34153989327051726,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2083822.8540124637,
          stderr: 16514.89322455534,
          plift: 0.42130163661359754,
        },
        '90d revenue': {
          forecast: 879682.1731567383,
          stderr: 7004.1099137321,
          plift: 0.2,
        },
      },
    },
    '0011U00000P0gF4QAJ': {
      account_name: 'Curry LLC',
      events: {
        churn: {
          propensity: 0.33394387631628974,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3268467301605494,
          propensity_text: '33%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3392093935231609,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2211046.46918533,
          stderr: 26783.944778095745,
          plift: 0.64683599504866,
        },
        '90d revenue': {
          forecast: 812219.1613769531,
          stderr: 475.22169738230843,
          plift: 0.19999999999999993,
        },
      },
    },
    '0011U0000077jnLQAQ': {
      account_name: 'Sanders, Huerta and Scott',
      events: {
        churn: {
          propensity: 0.31006685360726066,
          propensity_text: '31%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3393003573845427,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3506327890081966,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2271566.5093943207,
          stderr: 23399.76230957046,
          plift: 0.7843238987600029,
        },
        '90d revenue': {
          forecast: 763841.0865783691,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U0000077iBPQAY': {
      account_name: 'West-Martin',
      events: {
        churn: {
          propensity: 0.31327317580596875,
          propensity_text: '31%',
          segment: 0,
        },
        conversion: {
          propensity: 0.326225265322358,
          propensity_text: '33%',
          segment: 0,
        },
        expansion: {
          propensity: 0.36050155887167323,
          propensity_text: '36%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2395972.5759887695,
          stderr: 13988.081234684087,
          plift: 0.1999999999999999,
        },
        '90d revenue': {
          forecast: 1137307.4326864695,
          stderr: 12282.992982082887,
          plift: -0.00001874720787306256,
        },
      },
    },
    '0011U00000r480MQAQ': {
      account_name: 'Lambert, Burton and Meyer',
      events: {
        churn: {
          propensity: 0.3358712485959817,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.3250226710306447,
          propensity_text: '33%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3391060803733736,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 4072487.7584838867,
          stderr: 7.678243171701417e-10,
          plift: 0.19999999999999993,
        },
        '90d revenue': {
          forecast: 1472252.074584961,
          stderr: 2.4680067337611694e-10,
          plift: -0.20000000000000004,
        },
      },
    },
    '0011U00001gyszsQAA': {
      account_name: 'Smith and Sons',
      events: {
        churn: {
          propensity: 0.2951856642906815,
          propensity_text: '30%',
          segment: 0,
        },
        conversion: {
          propensity: 0.347916774112572,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3568975615967465,
          propensity_text: '36%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 3942147.9858398438,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 1533895.5172340176,
          stderr: 19489.45012062375,
          plift: -0.06615650792790831,
        },
      },
    },
    '0011U00000mLkDbQAK': {
      account_name: 'Frank-Evans',
      events: {
        churn: {
          propensity: 0.3328613465940156,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3415809174354422,
          propensity_text: '34%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3255577359705421,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1593701.6865852976,
          stderr: 12634.34398517058,
          plift: -0.1816305057249602,
        },
        '90d revenue': {
          forecast: 783435.6115722656,
          stderr: 6718.264637834336,
          plift: -0.19999999999999996,
        },
      },
    },
    '0011U00001acbDrQAI': {
      account_name: 'Williams, Bowen and Erickson',
      events: {
        churn: {
          propensity: 0.32263540862965673,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.34894069135715194,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3284239000131913,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2695761.850468698,
          stderr: 24280.379569015928,
          plift: -0.12978528705236345,
        },
        '90d revenue': {
          forecast: 1324701.3427734375,
          stderr: 10916.285487021387,
          plift: -0.2,
        },
      },
    },
    '0011U0000077i7RQAQ': {
      account_name: 'Bright Ltd',
      events: {
        churn: {
          propensity: 0.31515237926003065,
          propensity_text: '32%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3482911831998072,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3365564375401621,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 3242653.3276301934,
          stderr: 26129.77186043053,
          plift: -0.02238928205214589,
        },
        '90d revenue': {
          forecast: 1402559.3798287108,
          stderr: 10478.46690967484,
          plift: -0.16537067202659234,
        },
      },
    },
    '0011U00000r47mYQAQ': {
      account_name: 'Lindsey, Williams and Adams',
      events: {
        churn: {
          propensity: 0.32218702017604944,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3430715479571948,
          propensity_text: '34%',
          segment: 2,
        },
        expansion: {
          propensity: 0.33474143186675576,
          propensity_text: '33%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 3254794.62890625,
          stderr: 0,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 1627397.314453125,
          stderr: 21.525009332853344,
          plift: -0.2,
        },
      },
    },
    '0011U0000077iLkQAI': {
      account_name: 'Miller, Richardson and Peterson',
      events: {
        churn: {
          propensity: 0.34889227556156654,
          propensity_text: '35%',
          segment: 2,
        },
        conversion: {
          propensity: 0.322290730320659,
          propensity_text: '32%',
          segment: 0,
        },
        expansion: {
          propensity: 0.32881699411777443,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 650958.8928222656,
          stderr: 2188.7695589796217,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 325479.4464111328,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U00001NRkjDQAT': {
      account_name: 'Floyd, Boyer and Poole',
      events: {
        churn: {
          propensity: 0.3158631732642515,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.34228342858577226,
          propensity_text: '34%',
          segment: 2,
        },
        expansion: {
          propensity: 0.34185339814997623,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1827679.9392483076,
          stderr: 15660.973090136697,
          plift: -0.011698968669379561,
        },
        '90d revenue': {
          forecast: 1041328.1297778608,
          stderr: 7677.737753437457,
          plift: 0.1261771194318797,
        },
      },
    },
    '0011U00001gyt7NQAQ': {
      account_name: 'Lucas, Kennedy and Gray',
      events: {
        churn: {
          propensity: 0.3720012417176117,
          propensity_text: '37%',
          segment: 2,
        },
        conversion: {
          propensity: 0.3129462906579434,
          propensity_text: '31%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 312394.5135498047,
          stderr: 5.4844594083581545e-11,
          plift: -0.20000000000000004,
        },
        '90d revenue': {
          forecast: 'nan',
          stderr: 'nan',
          plift: 'nan',
        },
      },
    },
    '0011U0000077jWNQAY': {
      account_name: 'Martinez LLC',
      events: {
        churn: {
          propensity: 0.36049223212419323,
          propensity_text: '36%',
          segment: 2,
        },
        conversion: {
          propensity: 0.3179662700111895,
          propensity_text: '32%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3215414978646172,
          propensity_text: '32%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 830988.087187153,
          stderr: 13861.633909752978,
          plift: -0.38919467943216096,
        },
        '90d revenue': {
          forecast: 429190.632327372,
          stderr: 3057.4962770105612,
          plift: -0.3892608911168317,
        },
      },
    },
    '0011U0000077jmxQAA': {
      account_name: 'Lopez, Phelps and Jennings',
      events: {
        churn: {
          propensity: 0.31624668894951313,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.32039525341332126,
          propensity_text: '32%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3633580576371656,
          propensity_text: '36%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 5823123.321533203,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 2911561.6607666016,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '001EY000002pifOYAQ': {
      account_name: 'Scott Ltd',
      events: {
        churn: {
          propensity: 0.3084480615452208,
          propensity_text: '31%',
          segment: 0,
        },
        conversion: {
          propensity: 0.34596883073437423,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3455831077204049,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2945825.8959960938,
          stderr: 507.759834985119,
          plift: -0.20000000000000004,
        },
        '90d revenue': {
          forecast: 1550554.588623047,
          stderr: 169.58589342705557,
          plift: -0.19999999999999993,
        },
      },
    },
    '0011U00001CwhlFQAR': {
      account_name: 'Foster Inc',
      events: {
        churn: {
          propensity: 0.32500558844374977,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.33382118679299033,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.34117322476325984,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2174860.176152676,
          stderr: 25028.7871217253,
          plift: 1.6631239565959643,
        },
        '90d revenue': {
          forecast: 489994.5053100586,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U00001CwhiSQAR': {
      account_name: 'Hatfield and Sons',
      events: {
        churn: {
          propensity: 0.31558914291545787,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3343830638066609,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3500277932778812,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2784328.718032837,
          stderr: 5515.426139708865,
          plift: 0.1999999999999999,
        },
        '90d revenue': {
          forecast: 1627397.2409820557,
          stderr: 879.7930524982361,
          plift: 0.19999999999999996,
        },
      },
    },
    '001PH000009102SYAQ': {
      account_name: 'Small PLC',
      events: {
        churn: {
          propensity: 0.31862900847355013,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.32084966065359166,
          propensity_text: '32%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3605213308728582,
          propensity_text: '36%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2018485.0512125446,
          stderr: 18692.427024715293,
          plift: 3.31793137278017,
        },
        '90d revenue': {
          forecast: 892472.3785266717,
          stderr: 11786.596859066782,
          plift: 0.9091716732135814,
        },
      },
    },
    '0011U0000077iaWQAQ': {
      account_name: 'Taylor, Guzman and Henderson',
      events: {
        churn: {
          propensity: 0.3178114888229927,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3438400106727369,
          propensity_text: '34%',
          segment: 2,
        },
        expansion: {
          propensity: 0.33834850050427034,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1864109.6466064453,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 932054.8233032227,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '001EY000003LXW5YAO': {
      account_name: 'Jones-Burns',
      events: {
        churn: {
          propensity: 0.3327998273674917,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.33083157779802064,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.33636859483448767,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2529863.003540039,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 900672.9027872068,
          stderr: 9320.090631844256,
          plift: -0.14556046565978198,
        },
      },
    },
    '0011U0000077jU9QAI': {
      account_name: 'Davis, Fox and Berger',
      events: {
        churn: {
          propensity: 0.379868338855968,
          propensity_text: '38%',
          segment: 2,
        },
        conversion: {
          propensity: 0.30204876346562876,
          propensity_text: '30%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 798904.0832519531,
          stderr: 6625.169264290017,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 'nan',
          stderr: 'nan',
          plift: 'nan',
        },
      },
    },
    '0011U000025fC2UQAU': {
      account_name: 'Crawford LLC',
      events: {
        churn: {
          propensity: 0.31692821222121,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3364116450631028,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.34666014271568724,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2387955.3931447584,
          stderr: 17831.85698805354,
          plift: 0.29126480361059065,
        },
        '90d revenue': {
          forecast: 739726.0070800781,
          stderr: 8892.0500884249,
          plift: -0.2,
        },
      },
    },
    '0011U0000077japQAA': {
      account_name: 'Kennedy, Berry and Simon',
      events: {
        churn: {
          propensity: 0.31395293471561725,
          propensity_text: '31%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3454515275892609,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.34059553769512185,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 3511905.287704468,
          stderr: 6.581351290029786e-10,
          plift: -0.19999999999999996,
        },
        '90d revenue': {
          forecast: 1785286.0641860962,
          stderr: 2.7422297041790772e-11,
          plift: -0.19999999999999998,
        },
      },
    },
    '001EY000003XLOiYAO': {
      account_name: 'Cummings-Smith',
      events: {
        churn: {
          propensity: 0.3013864200929865,
          propensity_text: '30%',
          segment: 0,
        },
        conversion: {
          propensity: 0.33917507548649656,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3594385044205169,
          propensity_text: '36%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 3169163.8092041016,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 1379722.9600961204,
          stderr: 17050.805144275324,
          plift: 0.04486082247111479,
        },
      },
    },
    '0011U00000r47i7QAA': {
      account_name: 'Green, Green and Myers',
      events: {
        churn: {
          propensity: 0.34134910342527447,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.32855524595324614,
          propensity_text: '33%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3300956506214795,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 834441.7553485625,
          stderr: 12791.251712983394,
          plift: -0.3364461217670717,
        },
        '90d revenue': {
          forecast: 754520.5741882324,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U0000077gq1QAA': {
      account_name: 'Gibson and Sons',
      events: {
        churn: {
          propensity: 0.329779894741464,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.33030684671958127,
          propensity_text: '33%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3399132585389548,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 3748635.6536865234,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 1249545.2178955078,
          stderr: 1722.3903510103842,
          plift: -0.2,
        },
      },
    },
    '001EY000003mI79YAE': {
      account_name: 'Bray, Burgess and Morgan',
      events: {
        churn: {
          propensity: 0.31563953661698824,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.32578817402048393,
          propensity_text: '33%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3585722893625278,
          propensity_text: '36%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2205695.253437888,
          stderr: 22175.509993344018,
          plift: 0.8815746748348564,
        },
        '90d revenue': {
          forecast: 1008050.0118391949,
          stderr: 17023.023688740457,
          plift: 0.5574105935120537,
        },
      },
    },
    '0011U00000Q2kMmQAJ': {
      account_name: 'Atkinson and Sons',
      events: {
        churn: {
          propensity: 0.3753644079826305,
          propensity_text: '38%',
          segment: 2,
        },
        conversion: {
          propensity: 0.31092802792912805,
          propensity_text: '31%',
          segment: 0,
        },
        expansion: {
          propensity: 0.31370756408824146,
          propensity_text: '31%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 832948.5735639534,
          stderr: 15273.898379899485,
          plift: -0.4276786975437973,
        },
        '90d revenue': {
          forecast: 319246.0235595703,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U0000077jgMQAQ': {
      account_name: 'Lewis PLC',
      events: {
        churn: {
          propensity: 0.32778418758027916,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.34567815444199157,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3265376579777293,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1171726.0070800781,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 585863.0035400391,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U0000077rWcQAI': {
      account_name: 'Thompson LLC',
      events: {
        churn: {
          propensity: 0.30914488146748953,
          propensity_text: '31%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3413291587638967,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3495259597686138,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 6746301.3427734375,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 2270307.9528808594,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U00001fJ0V8QAK': {
      account_name: 'Hayes Group',
      events: {
        churn: {
          propensity: 0.3496419539261336,
          propensity_text: '35%',
          segment: 2,
        },
        conversion: {
          propensity: 0.32918843063764625,
          propensity_text: '33%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3211696154362201,
          propensity_text: '32%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 386465.7535171509,
          stderr: 6.170016834402924e-11,
          plift: -0.19999999999999998,
        },
        '90d revenue': {
          forecast: 357780.8188819885,
          stderr: 6.170016834402924e-11,
          plift: 0.19999999999999996,
        },
      },
    },
    '001EY000003c35bYAA': {
      account_name: 'Brock, George and Paul',
      events: {
        churn: {
          propensity: 0.30162236658767894,
          propensity_text: '30%',
          segment: 0,
        },
        conversion: {
          propensity: 0.34407412319074754,
          propensity_text: '34%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3543035102215736,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2275816.375201352,
          stderr: 21643.724840831695,
          plift: 0.37175253709047257,
        },
        '90d revenue': {
          forecast: 995434.5176696777,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U0000077gkeQAA': {
      account_name: 'White Group',
      events: {
        churn: {
          propensity: 0.34086767449656946,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.335870988886503,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.32326133661692763,
          propensity_text: '32%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1577095.9716796875,
          stderr: 15020.933070619425,
          plift: -0.6,
        },
        '90d revenue': {
          forecast: 1577095.9716796875,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U000012AuTtQAK': {
      account_name: 'Fuentes PLC',
      events: {
        churn: {
          propensity: 0.34646053133099886,
          propensity_text: '35%',
          segment: 2,
        },
        conversion: {
          propensity: 0.31340649960175276,
          propensity_text: '31%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3401329690672484,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 832805.1387405928,
          stderr: 15134.340546733423,
          plift: -0.5105090565339833,
        },
        '90d revenue': {
          forecast: 426049.31481257366,
          stderr: 2704.7134639274827,
          plift: -0.49916908201111315,
        },
      },
    },
    '0011U00000zmBnFQAU': {
      account_name: 'Crawford Group',
      events: {
        churn: {
          propensity: 0.3312437802709898,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.34016266692685826,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.32859355280215197,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1584493.162536621,
          stderr: 10813.379606132834,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 792246.5812683105,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U00001kO0ZqQAK': {
      account_name: 'Avila and Sons',
      events: {
        churn: {
          propensity: 0.3374347739610731,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.33505814674565954,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.32750707929326733,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 470465.771484375,
          stderr: 0,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 352849.32861328125,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U0000077jCGQAY': {
      account_name: 'Miller-Price',
      events: {
        churn: {
          propensity: 0.3120728999280994,
          propensity_text: '31%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3428331779724912,
          propensity_text: '34%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3450939220994094,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2472785.787963867,
          stderr: 3174.4800667185073,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 1077181.3027465325,
          stderr: 13319.150226912325,
          plift: 0.04547475934840411,
        },
      },
    },
    '0011U0000077jgrQAA': {
      account_name: 'Brooks Group',
      events: {
        churn: {
          propensity: 0.33368054465078767,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.33843603383065946,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.327883421518553,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2186592.56060069,
          stderr: 22637.67388820193,
          plift: 0.05948002399966113,
        },
        '90d revenue': {
          forecast: 1238301.3427734375,
          stderr: 6499.155413034771,
          plift: 0.2,
        },
      },
    },
    '0011U00000r47gCQAQ': {
      account_name: 'Gillespie-Holden',
      events: {
        churn: {
          propensity: 0.3267332736354836,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3378853274503369,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3353813989141795,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1586883.9978511336,
          stderr: 8343.649673110607,
          plift: 0.1323373642304502,
        },
        '90d revenue': {
          forecast: 696815.0363665111,
          stderr: 8370.820245627883,
          plift: 0.007189399735506376,
        },
      },
    },
    '0011U0000077jCBQAY': {
      account_name: 'Bruce and Sons',
      events: {
        churn: {
          propensity: 0.3350229009543097,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.3390085715522234,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.325968527493467,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1080621.3537597656,
          stderr: 1.7824493077164002e-10,
          plift: 0.1999999999999999,
        },
        '90d revenue': {
          forecast: 556895.3466796875,
          stderr: 6.170016834402924e-11,
          plift: 0.19999999999999998,
        },
      },
    },
    '0011U0000077jCZQAY': {
      account_name: 'Jones PLC',
      events: {
        churn: {
          propensity: 0.27029145551965195,
          propensity_text: '27%',
          segment: 0,
        },
        conversion: {
          propensity: 0.32705597788337376,
          propensity_text: '33%',
          segment: 0,
        },
        expansion: {
          propensity: 0.40265256659697424,
          propensity_text: '40%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 3135852.886289585,
          stderr: 28144.808917362257,
          plift: 3.123347158833835,
        },
        '90d revenue': {
          forecast: 1241856.0640189638,
          stderr: 26676.237985349584,
          plift: 0.7054964797979352,
        },
      },
    },
    '001EY00000364AAYAY': {
      account_name: 'Wade, Lee and Oliver',
      events: {
        churn: {
          propensity: 0.3482410101914179,
          propensity_text: '35%',
          segment: 2,
        },
        conversion: {
          propensity: 0.3271354591596386,
          propensity_text: '33%',
          segment: 0,
        },
        expansion: {
          propensity: 0.32462353064894356,
          propensity_text: '32%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 355068.49822998047,
          stderr: 0,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 207123.28033447266,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U00001dtB8sQAE': {
      account_name: 'Wilson and Sons',
      events: {
        churn: {
          propensity: 0.3329266934390417,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.33191633921925356,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3351569673417048,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 4103704.0283203125,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 1368995.0948214326,
          stderr: 9343.809512530972,
          plift: -0.19936033278787338,
        },
      },
    },
    '001EY000002KtUWYA0': {
      account_name: 'King PLC',
      events: {
        churn: {
          propensity: 0.32599688876122196,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.33151971288675836,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3424833983520196,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2312878.292967519,
          stderr: 25949.73357590993,
          plift: 0.6456151663216512,
        },
        '90d revenue': {
          forecast: 823343.6588264079,
          stderr: 6432.737136107895,
          plift: 0.17161963617298204,
        },
      },
    },
    '0011U00001yX5ycQAC': {
      account_name: 'Trujillo Ltd',
      events: {
        churn: {
          propensity: 0.29559432191303203,
          propensity_text: '30%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3349403170689894,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.36946536101797856,
          propensity_text: '37%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2663013.674926758,
          stderr: 13268.254692448885,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 1408926.9302281085,
          stderr: 12495.954110910488,
          plift: 0.2697736644707473,
        },
      },
    },
    '0011U00000r49gnQAA': {
      account_name: 'Owens LLC',
      events: {
        churn: {
          propensity: 0.34829932216897747,
          propensity_text: '35%',
          segment: 2,
        },
        conversion: {
          propensity: 0.32601412327148976,
          propensity_text: '33%',
          segment: 0,
        },
        expansion: {
          propensity: 0.32568655455953277,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 709550.1342773438,
          stderr: 3863.6205783839646,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 542944.1024780273,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U0000077jp0QAA': {
      account_name: 'Fisher, Cooper and Barr',
      events: {
        churn: {
          propensity: 0.3120732372235422,
          propensity_text: '31%',
          segment: 0,
        },
        conversion: {
          propensity: 0.34047316403800293,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3474535987384548,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2907156.1581791104,
          stderr: 8449.23737416317,
          plift: 0.20887244581554,
        },
        '90d revenue': {
          forecast: 1442909.6311569214,
          stderr: 10922.287242567896,
          plift: 0.2,
        },
      },
    },
    '0011U0000077iyUQAQ': {
      account_name: 'Baker and Sons',
      events: {
        churn: {
          propensity: 0.29789524177994764,
          propensity_text: '30%',
          segment: 0,
        },
        conversion: {
          propensity: 0.34722779546906446,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3548769627509879,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 6746301.3427734375,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 2270307.9528808594,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U0000077jmlQAA': {
      account_name: 'Martinez, Clay and Ellis',
      events: {
        churn: {
          propensity: 0.32100666357113633,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3498716873729144,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3291216490559492,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 3491506.7138671875,
          stderr: 6089.48267341471,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 1745753.3569335938,
          stderr: 7093.752286262446,
          plift: -0.2,
        },
      },
    },
    '0011U00001dugSxQAI': {
      account_name: 'Davis-Henderson',
      events: {
        churn: {
          propensity: 0.3716048167242962,
          propensity_text: '37%',
          segment: 2,
        },
        conversion: {
          propensity: 0.314297223640675,
          propensity_text: '31%',
          segment: 0,
        },
        expansion: {
          propensity: 0.31409795963502884,
          propensity_text: '31%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 473424.64599609375,
          stderr: 0,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 'nan',
          stderr: 'nan',
          plift: 'nan',
        },
      },
    },
    '0011U0000077iKhQAI': {
      account_name: 'Wall LLC',
      events: {
        churn: {
          propensity: 0.32333869619240474,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.33422657023343766,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.34243473357415766,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2286309.793201902,
          stderr: 24589.63698697829,
          plift: 1.2684420049086604,
        },
        '90d revenue': {
          forecast: 604726.0070800781,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U00001lC92GQAS': {
      account_name: 'Harris-Benson',
      events: {
        churn: {
          propensity: 0.3346095540839548,
          propensity_text: '33%',
          segment: 2,
        },
        conversion: {
          propensity: 0.33442343804297336,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3309670078730718,
          propensity_text: '33%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 346191.7785644531,
          stderr: 0,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 310684.920501709,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U00001F03YRQAZ': {
      account_name: 'Ross, Cardenas and Allen',
      events: {
        churn: {
          propensity: 0.3437667268531347,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.32668859096282105,
          propensity_text: '33%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3295446821840442,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 982512.7706179078,
          stderr: 15068.736415483536,
          plift: -0.5875118878832141,
        },
        '90d revenue': {
          forecast: 982512.7706179078,
          stderr: 6882.620573927586,
          plift: -0.17502377576642825,
        },
      },
    },
    '001EY000003G6ATYA0': {
      account_name: 'Wilson, Diaz and Lowe',
      events: {
        churn: {
          propensity: 0.3355889328110557,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.3237090890920115,
          propensity_text: '32%',
          segment: 0,
        },
        expansion: {
          propensity: 0.34070197809693287,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1605901.5791684347,
          stderr: 23798.41906622978,
          plift: 0.28079121053920564,
        },
        '90d revenue': {
          forecast: 752301.3427734375,
          stderr: 12209.24299640816,
          plift: 0.2,
        },
      },
    },
    '0011U00000r47g6QAA': {
      account_name: 'Rivas Group',
      events: {
        churn: {
          propensity: 0.3206881959062129,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.33177686049886873,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3475349435949183,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2219178.0212402344,
          stderr: 7240.284053892492,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 838449.4807026209,
          stderr: 8030.864851799722,
          plift: -0.09323238855723441,
        },
      },
    },
    '0011U0000077jp8QAA': {
      account_name: 'Wu LLC',
      events: {
        churn: {
          propensity: 0.35500324662315746,
          propensity_text: '36%',
          segment: 2,
        },
        conversion: {
          propensity: 0.32733696519266814,
          propensity_text: '33%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3176597881841744,
          propensity_text: '32%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 312394.5135498047,
          stderr: 5.4844594083581545e-11,
          plift: -0.20000000000000004,
        },
        '90d revenue': {
          forecast: 'nan',
          stderr: 'nan',
          plift: 'nan',
        },
      },
    },
    '0011U00001dtB4lQAE': {
      account_name: 'Jones Inc',
      events: {
        churn: {
          propensity: 0.2955615501984495,
          propensity_text: '30%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3271456176782386,
          propensity_text: '33%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3772928321233119,
          propensity_text: '38%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2553386.325073242,
          stderr: 2535.7944703040566,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 1630756.3617855697,
          stderr: 16563.957039027362,
          plift: 0.532794011565446,
        },
      },
    },
    '0011U00001Cwhd3QAB': {
      account_name: 'Price-Bauer',
      events: {
        churn: {
          propensity: 0.3454301258479543,
          propensity_text: '35%',
          segment: 2,
        },
        conversion: {
          propensity: 0.3365300765810667,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.31803979757097905,
          propensity_text: '32%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 829747.0665416314,
          stderr: 12999.555371836108,
          plift: -0.36179569215088475,
        },
        '90d revenue': {
          forecast: 533984.7947353746,
          stderr: 9679.69911387335,
          plift: -0.1965850343360235,
        },
      },
    },
    '0011U0000077jJcQAI': {
      account_name: 'Floyd Inc',
      events: {
        churn: {
          propensity: 0.3055932484373044,
          propensity_text: '31%',
          segment: 0,
        },
        conversion: {
          propensity: 0.34542320098182416,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3489835505808714,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 6091643.957519531,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 2030547.9858398438,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U00000MXjBpQAL': {
      account_name: 'Griffin-Huffman',
      events: {
        churn: {
          propensity: 0.3215941603200026,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3443967878104669,
          propensity_text: '34%',
          segment: 2,
        },
        expansion: {
          propensity: 0.33400905186953045,
          propensity_text: '33%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2852383.557128906,
          stderr: 0,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 1426191.7785644531,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U0000077jgwQAA': {
      account_name: 'Drake, Mitchell and Osborn',
      events: {
        churn: {
          propensity: 0.324778844776369,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3403091990418172,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.33491195618181374,
          propensity_text: '33%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2099950.672531128,
          stderr: 11633.477471126032,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 1027049.4479656627,
          stderr: 8651.424646361405,
          plift: -0.1974587201486193,
        },
      },
    },
    '0011U000028OMdsQAG': {
      account_name: 'Grant-Braun',
      events: {
        churn: {
          propensity: 0.29935250454967877,
          propensity_text: '30%',
          segment: 0,
        },
        conversion: {
          propensity: 0.33868870109005855,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3619587943602628,
          propensity_text: '36%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2261317.757482676,
          stderr: 17862.49495123303,
          plift: 0.6983148181010204,
        },
        '90d revenue': {
          forecast: 798904.0901184082,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U00000LILxEQAX': {
      account_name: 'Brown-Abbott',
      events: {
        churn: {
          propensity: 0.3092301539088409,
          propensity_text: '31%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3515415401407272,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.33922830595043196,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2569696.335331551,
          stderr: 10719.485942217036,
          plift: 0.17757589300940346,
        },
        '90d revenue': {
          forecast: 1309315.0177001953,
          stderr: 6051.856264925972,
          plift: 0.2,
        },
      },
    },
    '0011U00001jiNJ0QAM': {
      account_name: 'Dodson LLC',
      events: {
        churn: {
          propensity: 0.3466594815799057,
          propensity_text: '35%',
          segment: 2,
        },
        conversion: {
          propensity: 0.33173390097841693,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3216066174416774,
          propensity_text: '32%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 828832.8168620368,
          stderr: 14834.200374653577,
          plift: -0.4664480267858267,
        },
        '90d revenue': {
          forecast: 413978.9515856713,
          stderr: 1476.8534555099125,
          plift: -0.4670112428127081,
        },
      },
    },
    '0011U0000077jJFQAY': {
      account_name: 'Garcia-Williams',
      events: {
        churn: {
          propensity: 0.2987195934596703,
          propensity_text: '30%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3533843973638531,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.34789600917647656,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 4497534.228515625,
          stderr: 2450.952273574982,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 2270307.9528808594,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U0000077jUUQAY': {
      account_name: 'Turner Group',
      events: {
        churn: {
          propensity: 0.3083189774362127,
          propensity_text: '31%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3480236283657501,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3436573941980372,
          propensity_text: '34%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 4497534.228515625,
          stderr: 5156.474281308856,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 2248767.1142578125,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U00001CwhnoQAB': {
      account_name: 'Fuller, Reid and Carter',
      events: {
        churn: {
          propensity: 0.3532009594724408,
          propensity_text: '35%',
          segment: 2,
        },
        conversion: {
          propensity: 0.3175991441344775,
          propensity_text: '32%',
          segment: 0,
        },
        expansion: {
          propensity: 0.32919989639308167,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 914886.463034693,
          stderr: 1206.5303014350402,
          plift: 0.1892220751476058,
        },
        '90d revenue': {
          forecast: 914886.463034693,
          stderr: 10759.168001974182,
          plift: 1.3784441502952116,
        },
      },
    },
    '0011U0000077jp1QAA': {
      account_name: 'Choi Group',
      events: {
        churn: {
          propensity: 0.3037314276661844,
          propensity_text: '30%',
          segment: 0,
        },
        conversion: {
          propensity: 0.35141075003361133,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.34485782230020434,
          propensity_text: '34%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 3915566.2496047784,
          stderr: 7675.3633094981,
          plift: -0.1729272797099905,
        },
        '90d revenue': {
          forecast: 1893698.6572265625,
          stderr: 1250.5121868279819,
          plift: -0.2,
        },
      },
    },
    '001EY000002h5yXYAQ': {
      account_name: 'Miller LLC',
      events: {
        churn: {
          propensity: 0.3308075854299679,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.34313764488489107,
          propensity_text: '34%',
          segment: 2,
        },
        expansion: {
          propensity: 0.326054769685141,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2863850.4597144993,
          stderr: 27722.005270549602,
          plift: 0.10614329443240447,
        },
        '90d revenue': {
          forecast: 1087645.099975468,
          stderr: 8576.643045126424,
          plift: -0.15980855077184186,
        },
      },
    },
    '0011U00000LILtQQAX': {
      account_name: 'Meza, Ayala and Walker',
      events: {
        churn: {
          propensity: 0.32637602573567714,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.33833874670509084,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.335285227559232,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1916503.381804316,
          stderr: 15474.282195477072,
          plift: 0.1549699877258672,
        },
        '90d revenue': {
          forecast: 783528.9358757016,
          stderr: 10734.026909442668,
          plift: -0.06031311385891593,
        },
      },
    },
    '001EY000003yCuwYAE': {
      account_name: 'Melton and Sons',
      events: {
        churn: {
          propensity: 0.2707337735176901,
          propensity_text: '27%',
          segment: 0,
        },
        conversion: {
          propensity: 0.32091091719582926,
          propensity_text: '32%',
          segment: 0,
        },
        expansion: {
          propensity: 0.40835530928648056,
          propensity_text: '41%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2139065.1162842656,
          stderr: 22801.354894512355,
          plift: 0.5542322557148002,
        },
        '90d revenue': {
          forecast: 1591384.5798207386,
          stderr: 1083385.8891052376,
          plift: 0.29761519472478476,
        },
      },
    },
    '0011U00001CwhoLQAR': {
      account_name: 'Gilmore, Cooper and Reyes',
      events: {
        churn: {
          propensity: 0.312345097701145,
          propensity_text: '31%',
          segment: 0,
        },
        conversion: {
          propensity: 0.35181056675289063,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.33584433554596427,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1659945.1766967773,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 829972.5883483887,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U0000077jphQAA': {
      account_name: 'Jackson Group',
      events: {
        churn: {
          propensity: 0.316757528868558,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3439745469161808,
          propensity_text: '34%',
          segment: 2,
        },
        expansion: {
          propensity: 0.33926792421526114,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 4497534.228515625,
          stderr: 0,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 2270307.9528808594,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U00001NTrWBQA1': {
      account_name: 'Lewis Inc',
      events: {
        churn: {
          propensity: 0.3117042198591498,
          propensity_text: '31%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3468312045580277,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.34146457558282245,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2557381.181563996,
          stderr: 13780.946265525601,
          plift: 0.15282714504959868,
        },
        '90d revenue': {
          forecast: 1136354.495766343,
          stderr: 12682.513770433905,
          plift: 0.024121962090274462,
        },
      },
    },
    '0011U00001LHG2kQAH': {
      account_name: 'Smith, Taylor and Simpson',
      events: {
        churn: {
          propensity: 0.32668128357046894,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3338391670871437,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3394795493423875,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1938571.6993481687,
          stderr: 20524.800556007223,
          plift: 0.3103308521112734,
        },
        '90d revenue': {
          forecast: 809808.1855962523,
          stderr: 8774.351011118528,
          plift: 0.09474067968269073,
        },
      },
    },
    '0011U00001oQmJMQA0': {
      account_name: 'Parker-Fisher',
      events: {
        churn: {
          propensity: 0.33424164851397586,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3319548378916418,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3338035135943823,
          propensity_text: '33%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 828493.1213378906,
          stderr: 7326.436441639411,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 621369.841003418,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U0000077iHrQAI': {
      account_name: 'Trujillo Group',
      events: {
        churn: {
          propensity: 0.37670046647532235,
          propensity_text: '38%',
          segment: 2,
        },
        conversion: {
          propensity: 0.32131988461850813,
          propensity_text: '32%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3019796489061695,
          propensity_text: '30%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 325804.93240356445,
          stderr: 0,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 'nan',
          stderr: 'nan',
          plift: 'nan',
        },
      },
    },
    '001EY000002QBdgYAG': {
      account_name: 'Mckinney Group',
      events: {
        churn: {
          propensity: 0.3111264071316381,
          propensity_text: '31%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3372817763890581,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3515918164793038,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 4007192.1624755855,
          stderr: 6.581351290029786e-10,
          plift: 0.1999999999999999,
        },
        '90d revenue': {
          forecast: 1512147.9858398438,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U0000077glfQAA': {
      account_name: 'Spears, Miller and Vaughan',
      events: {
        churn: {
          propensity: 0.3272337547318861,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.33449501378586305,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3382712314822509,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1602737.7495562339,
          stderr: 29644.906314652486,
          plift: 0.5902121596804404,
        },
        '90d revenue': {
          forecast: 604726.0070800781,
          stderr: 1763.347282388582,
          plift: 0.2,
        },
      },
    },
    '0011U00001dtB6aQAE': {
      account_name: 'Christian-Monroe',
      events: {
        churn: {
          propensity: 0.3310698637538622,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3433013430077459,
          propensity_text: '34%',
          segment: 2,
        },
        expansion: {
          propensity: 0.32562879323839194,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1314244.1658289854,
          stderr: 14219.30871701036,
          plift: -0.012964748521292192,
        },
        '90d revenue': {
          forecast: 798904.0901184082,
          stderr: 151.5806128540186,
          plift: 0.2,
        },
      },
    },
    '0011U0000077iszQAA': {
      account_name: 'Johnson, Ford and Baxter',
      events: {
        churn: {
          propensity: 0.3306659872195816,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.338928109925604,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.33040590285481436,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2567411.8654406797,
          stderr: 27468.6468843186,
          plift: -0.008354151533975646,
        },
        '90d revenue': {
          forecast: 1268545.2207345718,
          stderr: 12924.171788126165,
          plift: -0.020065600953405167,
        },
      },
    },
    '0011U0000077jgLQAQ': {
      account_name: 'Mills Inc',
      events: {
        churn: {
          propensity: 0.3070094339200248,
          propensity_text: '31%',
          segment: 0,
        },
        conversion: {
          propensity: 0.34678999138195776,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.34620057469801735,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 3290411.0911409804,
          stderr: 26683.133294239586,
          plift: -0.015896392834410845,
        },
        '90d revenue': {
          forecast: 1337424.6643066406,
          stderr: 4468.928993277448,
          plift: -0.2,
        },
      },
    },
    '0011U00000g9EwgQAE': {
      account_name: 'Macias, Miles and Mcdaniel',
      events: {
        churn: {
          propensity: 0.32465141653973545,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3328891725748669,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3424594108853977,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 3003287.684326172,
          stderr: 4.936013467522339e-10,
          plift: 0.20000000000000004,
        },
        '90d revenue': {
          forecast: 1336042.8250041194,
          stderr: 11151.089748497328,
          plift: 0.10579507709862528,
        },
      },
    },
    '0011U000014ifycQAA': {
      account_name: 'Cunningham Group',
      events: {
        churn: {
          propensity: 0.32387120008452736,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3407807763978182,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.33534802351765447,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2584857.9703114983,
          stderr: 23556.31858824382,
          plift: -0.04212033261195821,
        },
        '90d revenue': {
          forecast: 1102037.8514035214,
          stderr: 10353.33635340478,
          plift: -0.18322812109926045,
        },
      },
    },
    '0011U0000077jppQAA': {
      account_name: 'Conley, Price and Fleming',
      events: {
        churn: {
          propensity: 0.33048711542632464,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.33130467149592113,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3382082130777542,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2462932.699584961,
          stderr: 456.0168951452775,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 926275.8601379191,
          stderr: 9272.801630917977,
          plift: -0.09739228168694045,
        },
      },
    },
    '0011U0000077ggdQAA': {
      account_name: 'Nelson and Sons',
      events: {
        churn: {
          propensity: 0.32981505240945713,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.33065762067927523,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3395273269112677,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2330114.7855169396,
          stderr: 22587.175726281002,
          plift: 0.5180578101100412,
        },
        '90d revenue': {
          forecast: 920958.9134216309,
          stderr: 2836.313829272177,
          plift: 0.2,
        },
      },
    },
    '0011U0000077ik5QAA': {
      account_name: 'Kidd-Andersen',
      events: {
        churn: {
          propensity: 0.3505415682569034,
          propensity_text: '35%',
          segment: 2,
        },
        conversion: {
          propensity: 0.32102280913030795,
          propensity_text: '32%',
          segment: 0,
        },
        expansion: {
          propensity: 0.32843562261278864,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 832134.0459777735,
          stderr: 11264.793045778375,
          plift: -0.29692379065506896,
        },
        '90d revenue': {
          forecast: 426988.74744240684,
          stderr: 2274.82163897941,
          plift: -0.2784680991341962,
        },
      },
    },
    '0011U000024CdohQAC': {
      account_name: 'Mack, Velez and Martinez',
      events: {
        churn: {
          propensity: 0.3232434112180074,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3196805420119756,
          propensity_text: '32%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3570760467700169,
          propensity_text: '36%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1924207.3846344186,
          stderr: 25857.530090458466,
          plift: 1.4540030934471355,
        },
        '90d revenue': {
          forecast: 576474.1578171869,
          stderr: 10338.61806137987,
          plift: 0.2988460777363592,
        },
      },
    },
    '0011U0000077iFlQAI': {
      account_name: 'Long, Garcia and Harvey',
      events: {
        churn: {
          propensity: 0.3360580090688878,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.34260361124459404,
          propensity_text: '34%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3213383796865182,
          propensity_text: '32%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2382706.286798534,
          stderr: 23024.174745676086,
          plift: -0.023212337532689156,
        },
        '90d revenue': {
          forecast: 1344119.1625033987,
          stderr: 10311.029961831451,
          plift: 0.1321183642499009,
        },
      },
    },
    '0011U00001BaloPQAR': {
      account_name: 'Carr-Smith',
      events: {
        churn: {
          propensity: 0.3302973267596646,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.34024952972324085,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.32945314351709454,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2748900.6985473633,
          stderr: 4591.311223425809,
          plift: -0.19999999999999996,
        },
        '90d revenue': {
          forecast: 1444536.9140625,
          stderr: 463.24190869496806,
          plift: -0.2,
        },
      },
    },
    '0011U0000077iRhQAI': {
      account_name: 'Cummings PLC',
      events: {
        churn: {
          propensity: 0.2912963754958472,
          propensity_text: '29%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3435248834831638,
          propensity_text: '34%',
          segment: 2,
        },
        expansion: {
          propensity: 0.36517874102098896,
          propensity_text: '37%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 5179561.629867554,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 1929843.9231518395,
          stderr: 13031.413807520357,
          plift: -0.10578814453012893,
        },
      },
    },
    '0011U00000Nj8zxQAB': {
      account_name: 'Harding, Pope and Olsen',
      events: {
        churn: {
          propensity: 0.345694558104815,
          propensity_text: '35%',
          segment: 2,
        },
        conversion: {
          propensity: 0.33236757726432914,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.32193786463085594,
          propensity_text: '32%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 929095.875120163,
          stderr: 14962.745000401244,
          plift: -0.6,
        },
        '90d revenue': {
          forecast: 929095.875120163,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U00000NhygKQAR': {
      account_name: 'Ramirez and Sons',
      events: {
        churn: {
          propensity: 0.3057408786005455,
          propensity_text: '31%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3447289148019157,
          propensity_text: '34%',
          segment: 2,
        },
        expansion: {
          propensity: 0.34953020659753886,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2287365.621575961,
          stderr: 36294.23600954554,
          plift: 1.4737436290551782,
        },
        '90d revenue': {
          forecast: 554794.5053100586,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U00001dtB6lQAE': {
      account_name: 'Murphy-Smith',
      events: {
        churn: {
          propensity: 0.33050337915020767,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.33175510611363557,
          propensity_text: '33%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3377415147361568,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1867710.1317755159,
          stderr: 19559.80946455297,
          plift: 1.1040561368705666,
        },
        '90d revenue': {
          forecast: 532602.7473449707,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U0000077joQQAQ': {
      account_name: 'Harris PLC',
      events: {
        churn: {
          propensity: 0.32040973112126475,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3370034600598446,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.3425868088188906,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 6746301.3427734375,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 2270307.9528808594,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U00001CwhmyQAB': {
      account_name: 'Smith-Cline',
      events: {
        churn: {
          propensity: 0.3294670542857814,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3414081225431579,
          propensity_text: '34%',
          segment: 2,
        },
        expansion: {
          propensity: 0.32912482317106073,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2099687.790638127,
          stderr: 15029.899639701134,
          plift: 0.07946848145323558,
        },
        '90d revenue': {
          forecast: 949004.6624401261,
          stderr: 9322.05449197408,
          plift: -0.032584619544223675,
        },
      },
    },
    '0011U00000LIM0hQAH': {
      account_name: 'Norton-Flores',
      events: {
        churn: {
          propensity: 0.32642845301251305,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.34866849710753345,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.32490304987995355,
          propensity_text: '32%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 3239394.993896485,
          stderr: 3.8391215858507083e-10,
          plift: -0.1999999999999999,
        },
        '90d revenue': {
          forecast: 1552300.2685546875,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U00001zhwS1QAI': {
      account_name: 'Mills and Sons',
      events: {
        churn: {
          propensity: 0.33218942713002547,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3133408852705661,
          propensity_text: '31%',
          segment: 0,
        },
        expansion: {
          propensity: 0.35446968759940845,
          propensity_text: '35%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1892798.211927787,
          stderr: 25349.438475716437,
          plift: 0.2635964702867248,
        },
        '90d revenue': {
          forecast: 898767.0936584473,
          stderr: 10252.278360389178,
          plift: 0.2,
        },
      },
    },
    '0011U00001BdM65QAF': {
      account_name: 'Smith, Lambert and Parker',
      events: {
        churn: {
          propensity: 0.31591229483042316,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3447435058151859,
          propensity_text: '34%',
          segment: 2,
        },
        expansion: {
          propensity: 0.339344199354391,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1509041.1483764648,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 754520.5741882324,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '001EY000003TMGvYAO': {
      account_name: 'Palmer-Matthews',
      events: {
        churn: {
          propensity: 0.31137320864921203,
          propensity_text: '31%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3193966565899345,
          propensity_text: '32%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3692301347608535,
          propensity_text: '37%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1889404.4223751924,
          stderr: 22968.025278308804,
          plift: 0.6217110484506884,
        },
        '90d revenue': {
          forecast: 1005943.4568648668,
          stderr: 17040.75568520023,
          plift: 0.7268400547762498,
        },
      },
    },
    '001EY000002oGDhYAM': {
      account_name: 'Smith-Davis',
      events: {
        churn: {
          propensity: 0.3473751761633063,
          propensity_text: '35%',
          segment: 2,
        },
        conversion: {
          propensity: 0.3284507834274608,
          propensity_text: '33%',
          segment: 0,
        },
        expansion: {
          propensity: 0.32417404040923287,
          propensity_text: '32%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 680547.9446411133,
          stderr: 3026.7612621317376,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 340273.97232055664,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U000014jSGkQAM': {
      account_name: 'Love-Morris',
      events: {
        churn: {
          propensity: 0.3211398096100313,
          propensity_text: '32%',
          segment: 1,
        },
        conversion: {
          propensity: 0.33579528570297507,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.34306490468699363,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2060061.572577648,
          stderr: 21430.85593617487,
          plift: 1.7204239831802515,
        },
        '90d revenue': {
          forecast: 454354.5238494873,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U00000r480kQAA': {
      account_name: 'Ramirez-Tran',
      events: {
        churn: {
          propensity: 0.32504829725516515,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.33529468235601506,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.33965702038881984,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2103325.2048944384,
          stderr: 29967.831040841596,
          plift: 0.729552371186646,
        },
        '90d revenue': {
          forecast: 729665.7470226288,
          stderr: 0,
          plift: 0.2,
        },
      },
    },
    '0011U00001LJgzDQAT': {
      account_name: 'Durham, Bennett and Baker',
      events: {
        churn: {
          propensity: 0.33577185388667047,
          propensity_text: '34%',
          segment: 2,
        },
        conversion: {
          propensity: 0.3382845515892422,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.32594359452408717,
          propensity_text: '33%',
          segment: 0,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 4497534.228515625,
          stderr: 0,
          plift: -0.2,
        },
        '90d revenue': {
          forecast: 2270307.9528808594,
          stderr: 0,
          plift: -0.2,
        },
      },
    },
    '0011U0000077jHEQAY': {
      account_name: 'Stone Ltd',
      events: {
        churn: {
          propensity: 0.3270370205108566,
          propensity_text: '33%',
          segment: 1,
        },
        conversion: {
          propensity: 0.3387594987501444,
          propensity_text: '34%',
          segment: 1,
        },
        expansion: {
          propensity: 0.33420348073899897,
          propensity_text: '33%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 1643967.155456543,
          stderr: 0,
          plift: 0.2,
        },
        '90d revenue': {
          forecast: 821983.5777282715,
          stderr: 8825.462897167918,
          plift: 0.2,
        },
      },
    },
    '0011U00001EzwRUQAZ': {
      account_name: 'Harris, Bean and Walters',
      events: {
        churn: {
          propensity: 0.3036227196927575,
          propensity_text: '30%',
          segment: 0,
        },
        conversion: {
          propensity: 0.32742584784064765,
          propensity_text: '33%',
          segment: 0,
        },
        expansion: {
          propensity: 0.3689514324665949,
          propensity_text: '37%',
          segment: 2,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2250503.7263045795,
          stderr: 25342.040348823524,
          plift: 0.4603269025111591,
        },
        '90d revenue': {
          forecast: 1109589.0106201172,
          stderr: 2028.8509724838123,
          plift: 0.2,
        },
      },
    },
    '0011U00001gUmjFQAS': {
      account_name: 'Oneill, King and Fleming',
      events: {
        churn: {
          propensity: 0.3155677274591533,
          propensity_text: '32%',
          segment: 0,
        },
        conversion: {
          propensity: 0.3526173319091325,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.3318149406317142,
          propensity_text: '33%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2690049.1532068914,
          stderr: 25112.015084549665,
          plift: -0.15231984280575564,
        },
        '90d revenue': {
          forecast: 1286051.1975092352,
          stderr: 19506.505934930425,
          plift: -0.18948686869542078,
        },
      },
    },
    '0011U00001kNoITQA0': {
      account_name: 'Stout Inc',
      events: {
        churn: {
          propensity: 0.31491189708616135,
          propensity_text: '31%',
          segment: 0,
        },
        conversion: {
          propensity: 0.34508380772215685,
          propensity_text: '35%',
          segment: 2,
        },
        expansion: {
          propensity: 0.34000429519168185,
          propensity_text: '34%',
          segment: 1,
        },
      },
      usages: {
        '180d revenue': {
          forecast: 2831797.17808697,
          stderr: 23827.366577167308,
          plift: 0.1734854272072493,
        },
        '90d revenue': {
          forecast: 1214616.7924646146,
          stderr: 13584.781424134439,
          plift: -0.06705572516765847,
        },
      },
    },
  },
}
