import DOMPurify from 'dompurify'
import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import useStore from 'store/useStore'

import type { Params } from 'react-router-dom'

const PreviewInbrowser = () => {
  const { emailTemplatesStore } = useStore()
  const { getTemplate } = emailTemplatesStore
  const { id, version }: Readonly<Params<string>> = useParams()
  const htmlRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!htmlRef.current) return

    const fetchTemplate = async (id: string, version: number) => {
      const existingTemplate = await getTemplate({
        magnifyTemplateId: id,
        magnifyVersion: version,
      })

      const sanitizedHtml = DOMPurify.sanitize(existingTemplate?.htmlContent ?? '')
      htmlRef.current!.innerHTML = sanitizedHtml
    }

    if (id && version) {
      fetchTemplate(id, parseInt(version)).catch(console.error)
    }
  }, [htmlRef])

  return <div ref={htmlRef} />
}

export default PreviewInbrowser
