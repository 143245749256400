import { message, Row, Select, Skeleton, Table } from 'antd'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { DynamicCell, Heading, Search } from 'components/common'
import { debounce } from 'hooks/useDebounce'
import { sortAlphabetically, sorterNumbers } from 'services/Utils/Sorters'
import useStore from 'store/useStore'

import type { FilterValue, SorterResult, SortOrder, TableCurrentDataSource } from 'antd/es/table/interface'
import type { ColumnProps, TablePaginationConfig } from 'antd/lib/table'

import type { RevenueStats } from 'models/dashboard.model'
import type { AccountForecastTableRow, AccountForecastTableState, TableColumn } from 'models/insights'
import { DataFormatEnum, TableColumnTypeEnum } from 'models/insights'
import type { ExtendedSelectOptions } from 'models/motion/motionBuilder.model'

/**
 * Generate Ant Design table columns from data.
 * @see https://ant.design/components/table/#column
 */
export function getTableColumns(
  data: TableColumn[] = [],
  defaultSortKey: string = '',
  defaultSortDirection: SortOrder = 'ascend',
): ColumnProps<AccountForecastTableRow>[] {
  return data.map((column) => {
    const output: ColumnProps<AccountForecastTableRow> = {
      title: column.title,
      dataIndex: column.key,
      key: column.key,
      render: (_: any, record: AccountForecastTableRow) => {
        // Color code the forecast results
        let className = ''
        if (column.key === 'revenuePlift') {
          if (record.revenuePlift >= 0) {
            className = 'revenue-plift green'
          } else if (record.revenuePlift < 0 && record.revenuePlift >= -0.19) {
            className = 'revenue-plift orange'
          } else if (record.revenuePlift < -0.19) {
            className = 'revenue-plift red'
          } else {
            className = 'revenue-plift missing'
          }
        }
        return (
          <div className='table__row'>
            <DynamicCell column={column} record={record} className={className} />
          </div>
        )
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (a: AccountForecastTableRow, b: AccountForecastTableRow) => {
        // Extract values from objects if they are wrapped
        let valueA = a[column.key as keyof AccountForecastTableRow] as
          | { value: string | number | RevenueStats }
          | string
          | number
          | RevenueStats
        if (valueA !== null && typeof valueA === 'object' && 'value' in valueA) {
          valueA = valueA.value
        }
        let valueB = b[column.key as keyof AccountForecastTableRow] as
          | { value: string | number | RevenueStats }
          | string
          | number
          | RevenueStats
        if (valueB !== null && typeof valueB === 'object' && 'value' in valueB) {
          valueB = valueB.value
        }

        switch (column.type) {
          case TableColumnTypeEnum.String:
            return sortAlphabetically((valueA as string) ?? '', (valueB as string) ?? '')

          case TableColumnTypeEnum.Number:
          case TableColumnTypeEnum.ImpactPercentage:
          case TableColumnTypeEnum.Percentage:
            let firstValue = valueA ?? 0
            let secondValue = valueB ?? 0

            // Check for percentage values that are strings and parse out the value, and check for NaN.
            if (typeof firstValue === 'string') {
              firstValue = parseInt(firstValue, 10)
              if (isNaN(firstValue)) {
                firstValue = 0
              }
            }
            if (typeof secondValue === 'string') {
              secondValue = parseInt(secondValue, 10)
              if (isNaN(secondValue)) {
                secondValue = 0
              }
            }

            return sorterNumbers(firstValue as number, secondValue as number)

          case TableColumnTypeEnum.RevenueForecast:
            return sorterNumbers((valueA as RevenueStats)?.change ?? null, (valueB as RevenueStats)?.change ?? null)

          default:
            return sorterNumbers((valueA as number) ?? 0, (valueB as number) ?? 0)
        }
      },
    }
    if (column.key === defaultSortKey) {
      output.defaultSortOrder = defaultSortDirection
    }
    return output
  })
}

const AccountsForecastTable = observer(() => {
  const navigate = useNavigate()

  const redirectToAccountDetails = useCallback(
    (accountId: string, accountName: string, userCount: number) =>
      navigate(`/accounts/${accountId}`, {
        state: {
          accountName,
          userCount,
        },
      }),
    [navigate],
  )

  const { insightsStore } = useStore()
  const {
    accountForecast,
    accountForecastTableState,
    setAccountForecastTableState,
    fetchAccountForecast,
    isLoadingAccountForecast,
  } = insightsStore

  const [accountsTableSource, setAccountsTableSource] = useState<AccountForecastTableRow[]>([])

  // Setup the table columns
  const accountTableColumns = getTableColumns([
    {
      key: 'accountName',
      title: 'Account',
      type: 'string',
      format: DataFormatEnum.Text,
      nullable: false,
    },
    {
      key: 'contractEndDate',
      title: 'Next Renewal Date',
      type: 'string',
      format: DataFormatEnum.Text,
      nullable: true,
    },
    {
      key: 'revenue',
      title: 'Forecasted ARR',
      type: 'number',
      format: DataFormatEnum.CurrencyKMB,
      decimal: 1,
      nullable: false,
    },
    {
      key: 'pastRevenue',
      title: 'Current ARR',
      type: 'number',
      format: DataFormatEnum.CurrencyKMB,
      decimal: 1,
      nullable: false,
    },
    {
      key: 'revenuePlift',
      title: `${accountForecastTableState.selectedForecast || 'Overall'} Forecast`,
      type: 'number',
      format: DataFormatEnum.Percentage,
      decimal: 0,
      nullable: false,
    },
  ])

  // Initial fetch of the account table data.
  useEffect(() => {
    fetchAccountForecast({
      contractEndPeriod: accountForecastTableState.selectedRenewal,
      revenuePeriod: accountForecastTableState.selectedForecast,
      accountLike: accountForecastTableState.searchInput,
      page: accountForecastTableState.currentPage,
      pageSize: accountForecastTableState.pageSize,
      sortKey: accountForecastTableState.sortKey,
      sortDirection: accountForecastTableState.sortDirection,
    }).catch((error) => {
      if (error instanceof Error) {
        void message.error(error.message)
      }
    })
  }, [])

  // Subsequent fetch anytime a value changes
  useEffect(() => {
    // Only fetch if we have a selected forecast to avoid duplicate rows
    if (accountForecastTableState.selectedForecast) {
      fetchAccountForecast({
        contractEndPeriod: accountForecastTableState.selectedRenewal,
        revenuePeriod: accountForecastTableState.selectedForecast,
        accountLike: accountForecastTableState.searchInput,
        page: accountForecastTableState.currentPage,
        pageSize: accountForecastTableState.pageSize,
        sortKey: accountForecastTableState.sortKey,
        sortDirection: accountForecastTableState.sortDirection,
      }).catch((error) => {
        if (error instanceof Error) {
          void message.error(error.message)
        }
      })
    }
  }, [
    accountForecastTableState.selectedForecast,
    accountForecastTableState.selectedRenewal,
    // We exclude searchInput to debounce it below
    // accountForecastTableState.searchInput,
    accountForecastTableState.currentPage,
    accountForecastTableState.pageSize,
    accountForecastTableState.sortKey,
    accountForecastTableState.sortDirection,
  ])

  // Debounce the search input for fetching the account forecast
  const searchCallback = useCallback(
    debounce((state: AccountForecastTableState) => {
      fetchAccountForecast({
        contractEndPeriod: state.selectedRenewal,
        revenuePeriod: state.selectedForecast,
        accountLike: state.searchInput,
        page: state.currentPage,
        pageSize: state.pageSize,
        sortKey: state.sortKey,
        sortDirection: state.sortDirection,
      }).catch((error) => {
        if (error instanceof Error) {
          void message.error(error.message)
        }
      })
    }, 300),
    [],
  )
  useEffect(() => {
    if (accountForecastTableState.searchInput) {
      searchCallback(accountForecastTableState)
    }
  }, [accountForecastTableState.searchInput])

  useEffect(() => {
    setAccountsTableSource((accountForecast?.results as AccountForecastTableRow[]) || [])
  }, [accountForecast?.results])

  // Load the forecast if none is selected, which is the default
  useEffect(() => {
    if (
      accountForecastTableState.selectedForecast === '' &&
      Array.isArray(accountForecast?.revenuePeriods) &&
      accountForecast?.revenuePeriods?.[0]
    ) {
      setAccountForecastTableState({
        selectedForecast: accountForecast?.revenuePeriods?.[0],
      })
    }
  }, [accountForecast?.revenuePeriods, accountForecastTableState.selectedForecast])

  // Handle search inputs
  const handleOnSearch = (value: string) => {
    // Set the page back to 1 to ensure we see any new data if we are on any other page
    setAccountForecastTableState({
      currentPage: 1,
    })
  }

  // Handle closing the search
  const handleClose = () => {
    // Set the page back to 1 to ensure we see any new data if we are on any other page
    setAccountForecastTableState({
      currentPage: 1,
      searchInput: '',
    })
    // Fetch the data again to reset the table
    fetchAccountForecast({
      contractEndPeriod: accountForecastTableState.selectedRenewal,
      revenuePeriod: accountForecastTableState.selectedForecast,
      accountLike: '',
      page: 1,
      pageSize: accountForecastTableState.pageSize,
      sortKey: accountForecastTableState.sortKey,
      sortDirection: accountForecastTableState.sortDirection,
    }).catch((error) => {
      if (error instanceof Error) {
        void message.error(error.message)
      }
    })
  }

  // Handle pagination changes
  const onChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<AccountForecastTableRow> | SorterResult<AccountForecastTableRow>[],
    extra: TableCurrentDataSource<AccountForecastTableRow>,
  ): void => {
    setAccountForecastTableState({
      currentPage: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? 10,
    })
    const { field, order } = sorter as SorterResult<AccountForecastTableRow>
    if (field) {
      setAccountForecastTableState({
        sortKey: field as string,
        sortDirection: order === 'ascend' ? 'asc' : 'desc',
      })
    }
  }

  // Build select options
  const renewalOptions = useMemo(() => {
    return [
      {
        label: (
          <>
            Next Renewal Date: <strong>All</strong>
          </>
        ),
        value: '',
      },
      ...(accountForecast?.contractPeriodEnds || []).filter(Boolean).map((period) => ({
        label: (
          <>
            Next Renewal Date: <strong>{period}</strong>
          </>
        ),
        value: period,
      })),
    ] as ExtendedSelectOptions[]
  }, [accountForecast?.contractPeriodEnds])

  const forecastOptions = useMemo(() => {
    return [
      ...(accountForecast?.revenuePeriods || []).filter(Boolean).map((period) => ({
        label: (
          <>
            Forecast: <strong>{period}</strong>
          </>
        ),
        value: period,
      })),
    ] as ExtendedSelectOptions[]
  }, [accountForecast?.revenuePeriods])

  return (
    <div className='account-forecast-container' data-testid='accounts-container'>
      <Row justify='space-between'>
        <Heading level='1' variant='1'>
          Accounts
        </Heading>
        <div className='header-right'>
          <Select
            data-testid='renewal-dropdown'
            defaultValue={renewalOptions[0] as unknown as string}
            style={{ width: 220 }}
            onChange={(value) => {
              setAccountForecastTableState({
                selectedRenewal: value,
              })
            }}
            options={renewalOptions}
            value={accountForecastTableState.selectedRenewal}
          />
          <Select
            data-testid='forecast-dropdown'
            defaultValue={forecastOptions[0] as unknown as string}
            style={{ width: 180 }}
            onChange={(value) => {
              setAccountForecastTableState({
                selectedForecast: value,
              })
            }}
            options={forecastOptions}
            value={accountForecastTableState.selectedForecast}
          />
          <Search
            placeholder='Search...'
            onClose={handleClose}
            searchType={['onEnter', 'onType']}
            onFilter={handleOnSearch}
            searchInput={accountForecastTableState.searchInput}
            setSearchInput={(value: string) => {
              setAccountForecastTableState({
                searchInput: value,
              })
            }}
            large
          />
        </div>
      </Row>
      {isLoadingAccountForecast && <Skeleton active />}
      {!isLoadingAccountForecast && (
        <Table
          data-testid='account-forecast-table'
          rowKey={(record) => `${record.accountId}-${record.contractEndPeriod}-${record.revenuePeriod}`}
          onRow={(record) => {
            return {
              onClick: () => {
                redirectToAccountDetails(record.accountId, record.accountName ?? '', 0)
              },
            }
          }}
          onChange={onChange}
          dataSource={accountsTableSource}
          columns={accountTableColumns}
          sortDirections={['ascend', 'descend']}
          pagination={{
            hideOnSinglePage: true,
            total: accountForecast?.totalSize as number,
            current: accountForecastTableState.currentPage,
            pageSize: accountForecastTableState.pageSize,
          }}
          showSorterTooltip={false}
        />
      )}
    </div>
  )
})
AccountsForecastTable.displayName = 'AccountsForecastTable'
export default AccountsForecastTable
